import Drop from "@/common/assets/svg/drop.svg";
import { UserIcon } from "@heroicons/react/24/outline";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import MenuOptions from "../menu-options/MenuOptions";
import { StepTemplateConfig } from "@/types/sequence";
import DripInModal from "@/pages/sequence-builder/create-drip/DripInModal";

export interface SequenceStepDripProps {
  index: number;
  canUpdateSequence: boolean;
  onUpdateDrip: () => void;
  onRemoveDrip: () => void;
  drip: StepTemplateConfig;
}

const options = [
  {
    label: "Edit",
    value: "edit",
    icon: null,
  },
  {
    label: "Delete",
    value: "delete",
    icon: null,
  },
];

export default function SequenceStepDrip({
  canUpdateSequence,
  onUpdateDrip,
  onRemoveDrip,
  drip,
}: SequenceStepDripProps) {
  const [showModal, setShowModal] = useState(false);
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          gap: "8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "654px",
          marginLeft: "60px",
        }}
      >
        {!canUpdateSequence && (
          <Box
            sx={{
              display: "flex",
              background: theme.palette.bg.inverse,
              padding: "4px 16px",
              borderRadius: "8px",
              gap: "4px",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowModal(true);
            }}
          >
            <UserIcon width={17} />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "16px",
                color: theme.palette.txt.default,
              }}
            >
              {drip.prospectsInDrip}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            padding: "16px",
            background: theme.palette.bg.inverse,
            borderRadius: "8px",
            width: "264px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  padding: "16px",
                  background: "#B4DFFF",
                  borderRadius: "100%",
                  width: "42px",
                  height: "42px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="Drop"
                  src={Drop}
                  style={{
                    display: "flex",
                    width: "16px",
                    height: "16px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Drip
              </Typography>
            </Box>
            {canUpdateSequence && (
              <MenuOptions
                open={open}
                anchorEl={anchorEl}
                handleClick={handleClick}
                handleClose={handleClose}
                options={options}
                onClick={(option) => {
                  switch (option.value) {
                    case "edit":
                      onUpdateDrip();
                      break;
                    case "delete":
                      onRemoveDrip();
                      break;
                  }

                  handleClose();
                }}
                horizontalIcon
              />
            )}
          </Box>
          <Divider
            sx={{
              width: 264,
              paddingTop: "8px",
              marginLeft: "-16px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              paddingTop: "8px",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: 12 }}>Batch size: {drip.batchSize}</Typography>
            <Typography sx={{ fontSize: 12 }}>
              Frequency:
              {` ${drip.days ?? 0}:${String(drip.hours ?? 0).padStart(2, "0")}:${String(drip.minutes ?? 0).padStart(
                2,
                "0",
              )}`}
            </Typography>
          </Box>
        </Box>
      </Box>
      {showModal && (
        <DripInModal dripTemplateId={drip.id!} isOpen={showModal} handleClose={() => setShowModal(false)} />
      )}
    </>
  );
}
