import * as React from "react";
import { Task, openAssigneeDrawer } from "@/redux/reducers/tasks/slices/listTask";
import { openEditTaskDrawer } from "@/redux/reducers/tasks/slices/listTask";
import { ReactComponent as PencilSquare } from "@/common/assets/svg/tasks/edit.svg";
import { ReactComponent as UserIcon } from "@/common/assets/svg/tasks/user.svg";
import { ReactComponent as Trash } from "@/common/assets/svg/tasks/trash.svg";
import { Typography } from "@mui/material";
import useConfirmModal from "@/common/hooks/useConfirmModal";
import { useAppDispatch } from "@/redux/store";
import { deleteTask } from "@/redux/reducers/tasks/slices/listTask";
import { setCurrentTask } from "@/redux/reducers/tasks/slices/listTask";
import MenuOptions from "@/common/components/menu-options/MenuOptions";

const iconStyles = {
  width: "24px",
  height: "24px",
  stroke: "#1A2135",
};

const options = [
  {
    label: "Edit Task",
    value: "editTask",
    icon: <PencilSquare style={iconStyles} />,
  },
  {
    label: "Edit Assignee",
    value: "editAssignee",
    icon: <UserIcon style={iconStyles} />,
  },
  {
    label: "Delete Task",
    value: "delete",
    icon: <Trash style={iconStyles} />,
  },
];

export default function TableActionButton(props: {
  row: {
    id: string;
    original: Task;
  };
}) {
  const { row } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { deleteConfirm } = useConfirmModal();

  return (
    <MenuOptions
      open={open}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      options={options}
      onClick={(option) => {
        if (option.value === "delete") {
          deleteConfirm({
            textDiv: (
              <>
                <Typography variant="body1">Are you sure you want to delete this task?</Typography>
                <Typography variant="body1">This action cannot be undone.</Typography>
              </>
            ),
          })
            .then(() => {
              dispatch(deleteTask(row.original.id!.toString()));
            })
            .catch(() => {
              return;
            });
        } else if (option.value === "editTask") {
          dispatch(openEditTaskDrawer());

          if (!row?.original?.id) return;
          dispatch(setCurrentTask(row?.original?.id?.toString()));
        } else {
          dispatch(openAssigneeDrawer());
          if (!row?.original?.id) return;
          dispatch(setCurrentTask(row?.original?.id?.toString()));
        }
        handleClose();
      }}
    />
  );
}
