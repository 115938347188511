export function sortByParentsIds(a: { id: string; parentsIds: string[] }, b: { id: string; parentsIds: string[] }) {
  if (a.parentsIds.length === 0 && b.parentsIds.length > 0) {
    return -1;
  }
  if (b.parentsIds.length === 0 && a.parentsIds.length > 0) {
    return 1;
  }

  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
}
