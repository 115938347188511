import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";
import {
  DashboardRequestDateRangeEnum,
  EntityActivities,
  ProspectAutomationByWeek,
  ReportLeadFlowPerf,
  LeadFlowPerf,
  ReportLeadFlowPerfByLeadSource,
  LeadFlowPerfByLeadSource,
} from "@/services/generated";

type State = {
  activities: EntityActivities[];
  activitiesError: boolean;
  activitiesErrorMessage: string;
  activitiesPending: boolean;

  leadFlowPerformanceBySource: LeadFlowPerfByLeadSource[];
  leadFlowPerformanceBySourceError: boolean;
  leadFlowPerformanceBySourceErrorMessage: string;
  leadFlowPerformanceBySourcePending: boolean;

  leadFlowPerformance: LeadFlowPerf;
  leadFlowPerformanceError: boolean;
  leadFlowPerformanceErrorMessage: string;
  leadFlowPerformancePending: boolean;

  prospectsInAutomation: ProspectAutomationByWeek[];
  prospectsInAutomationError: boolean;
  prospectsInAutomationErrorMessage: string;
  prospectsInAutomationPending: boolean;
};

const initialState: State = {
  activities: [],
  activitiesError: false,
  activitiesErrorMessage: "",
  activitiesPending: false,

  leadFlowPerformanceBySource: [],
  leadFlowPerformanceBySourceError: false,
  leadFlowPerformanceBySourceErrorMessage: "",
  leadFlowPerformanceBySourcePending: false,

  leadFlowPerformance: {},
  leadFlowPerformanceError: false,
  leadFlowPerformanceErrorMessage: "",
  leadFlowPerformancePending: false,

  prospectsInAutomation: [],
  prospectsInAutomationError: false,
  prospectsInAutomationErrorMessage: "",
  prospectsInAutomationPending: false,
};

export const getActivities = createAsyncThunk(
  "dashboard/getActivities",
  async (dateRangeFilter: DashboardRequestDateRangeEnum) => {
    try {
      const response = await Services.Reports.reportActivities({
        date_range: dateRangeFilter,
      });

      return response?.data;
    } catch (error: any) {
      return error;
    }
  },
);

export const getLeadFlowPerformanceBySource = createAsyncThunk(
  "dashboard/getLeadFlowPerfBySource",
  async (dateRangeFilter: DashboardRequestDateRangeEnum): Promise<ReportLeadFlowPerfByLeadSource> => {
    try {
      const response = await Services.Reports.reportLeadFlowPerfBySource({
        date_range: dateRangeFilter,
      });

      return response.data;
    } catch (error: any) {
      return error;
    }
  },
);

export const getLeadFlowPerformance = createAsyncThunk(
  "dashboard/getLeadFlowPerformance",
  async (dateRangeFilter: DashboardRequestDateRangeEnum): Promise<ReportLeadFlowPerf> => {
    try {
      const response = await Services.Reports.reportLeadFlowPerformance({
        date_range: dateRangeFilter,
      });

      return response.data;
    } catch (error: any) {
      return error;
    }
  },
);

export const getProspectsInAutomation = createAsyncThunk(
  "dashboard/getProspectsInAutomation",
  async (dateRangeFilter: DashboardRequestDateRangeEnum, { rejectWithValue }): Promise<ProspectAutomationByWeek[]> => {
    try {
      const response = await Services.Reports.reportProspectsInAutomation({
        date_range: dateRangeFilter,
      });

      if (!response?.data?.data) {
        return [];
      }
      return response?.data?.data.sort(
        (a: ProspectAutomationByWeek, b: ProspectAutomationByWeek) => (a.week || 0) - (b.week || 0),
      );
    } catch (error) {
      return error;
    }
  },
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: (builder) => {
    // Activities
    builder.addCase(getActivities.pending, (state) => {
      state.activitiesPending = true;
      state.activitiesErrorMessage = "";
    });
    builder.addCase(getActivities.fulfilled, (state, { payload }) => {
      state.activitiesPending = false;
      state.activities = payload.entity_activities;
    });
    builder.addCase(getActivities.rejected, (state, { error }) => {
      state.activitiesError = true;
      state.activitiesErrorMessage = error.message || "An error occurred";
      state.activitiesPending = false;
    });

    // Lead Flow Performance By Source
    builder.addCase(getLeadFlowPerformanceBySource.pending, (state) => {
      state.leadFlowPerformanceBySourcePending = true;
    });
    builder.addCase(getLeadFlowPerformanceBySource.fulfilled, (state, { payload }) => {
      state.leadFlowPerformanceBySourcePending = false;
      state.leadFlowPerformanceBySource = payload?.data || [];
    });
    builder.addCase(getLeadFlowPerformanceBySource.rejected, (state, { error }) => {
      state.leadFlowPerformanceBySourceError = true;
      state.leadFlowPerformanceBySourceErrorMessage = error.message || "An error occurred";
      state.leadFlowPerformanceBySourcePending = false;
    });

    // Lead Flow Performance
    builder.addCase(getLeadFlowPerformance.pending, (state) => {
      state.leadFlowPerformancePending = true;
    });
    builder.addCase(getLeadFlowPerformance.fulfilled, (state, { payload }) => {
      state.leadFlowPerformancePending = false;
      state.leadFlowPerformance = payload?.data || {};
      // state.leadFlowPerformance = [];
    });
    builder.addCase(getLeadFlowPerformance.rejected, (state, { error }) => {
      state.leadFlowPerformanceError = true;
      state.leadFlowPerformanceErrorMessage = error.message || "An error occurred";
      state.leadFlowPerformancePending = false;
    });

    // Prospects in Automation
    builder.addCase(getProspectsInAutomation.pending, (state, { payload }) => {
      state.prospectsInAutomationPending = true;
    });
    builder.addCase(getProspectsInAutomation.fulfilled, (state, { payload }) => {
      state.prospectsInAutomationPending = false;
      state.prospectsInAutomation = payload;
    });
    builder.addCase(getProspectsInAutomation.rejected, (state, { error }) => {
      state.prospectsInAutomationError = true;
      state.prospectsInAutomationErrorMessage = error.message || "An error occurred";
      state.prospectsInAutomationPending = false;
    });
  },
  reducers: {},
});

export default dashboardSlice.reducer;
