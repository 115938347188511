import { combineReducers } from "@reduxjs/toolkit";
import listLeadsReducer from "./listLeads";
import configLeadFlowReducer from "./configLeadFlow";
import getReportsReducer from "./getReports";

const leadFlowReducer = combineReducers({
  configLeadFlow: configLeadFlowReducer,
  listLeads: listLeadsReducer,
  getReports: getReportsReducer,
});

export default leadFlowReducer;
