import { TaskRequestStatusEnum, TaskRequestTypeEnum } from "@/services/generated";
import config from "@/common/constants/config";

export const TaskStatusSelectOptions = [
  { label: TaskRequestStatusEnum.Complete, value: TaskRequestStatusEnum.Complete },
  { label: TaskRequestStatusEnum.Due, value: TaskRequestStatusEnum.Due },
  { label: TaskRequestStatusEnum.Overdue, value: TaskRequestStatusEnum.Overdue },
  { label: TaskRequestStatusEnum.Upcoming, value: TaskRequestStatusEnum.Upcoming },
  { label: TaskRequestStatusEnum.Unspecified, value: TaskRequestStatusEnum.Unspecified },
];

export const TaskTypeSelectOptions = [
  { label: "Approve Ai Message", value: TaskRequestTypeEnum.ApproveAiMessage, show: config.flagOutreach },
  { label: "Attend Meeting", value: TaskRequestTypeEnum.AttendMeeting, show: true },
  { label: "Call", value: TaskRequestTypeEnum.Call, show: true },
  { label: "Other", value: TaskRequestTypeEnum.Other, show: true },
];
