/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";
import { AxiosResponse } from "axios";

export const getOrCreateUser = createAsyncThunk(
  "settings/getUser",
  async (_, { rejectWithValue, dispatch }): Promise<AxiosResponse<any>> => {
    try {
      return await Services.Users.getOrCreate();
    } catch (error: any) {
      return error;
    }
  },
);
