import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Prospect } from "@/redux/reducers/prospects/slices/types";
import { useEffect } from "react";
import { getSequencesByLeadSourceId } from "@/redux/reducers/sequences/slices/listSequences";
import { LeadSourceTriggerResponseStatusEnum } from "@/services/generated";
import StatusChip from "@/common/components/status-chip";
import { dateFormatStr } from "@/common/functions/dateFormat";
import ActionButton from "./ActionButton";
import { PlusIcon } from "@heroicons/react/24/outline";
import Accordion from "@/common/components/accordion";
import Button from "@/common/components/button";
import { showSeqEnrollDialog } from "@/redux/reducers/dataImport/slices/list";
import { TitleAndSubtitle } from "@/common/components/title-and-subtitle";

interface ProspectInfoTypeProps extends Prospect {
  isInbox?: boolean;
}

const LeadSourceInfo = ({ isInbox = false }: ProspectInfoTypeProps) => {
  const currentLeadSource = useAppSelector((state) => state.dataImport.list.currentLeadSource);
  const dispatch = useAppDispatch();
  const leadSourceSequences = useAppSelector(({ sequences }) => sequences.listSequences.leadSourceSequences);

  useEffect(() => {
    if ((currentLeadSource as any).id) {
      dispatch(getSequencesByLeadSourceId((currentLeadSource as any).id));
    }
  }, [currentLeadSource, dispatch]);

  const handleShowEnrollDialog = () => {
    dispatch(showSeqEnrollDialog());
  };

  return (
    <Box
      sx={({ palette }) => ({
        background: isInbox ? palette.midnight[20] : null,
        width: "100%",
        overflowY: isInbox ? "auto" : "hidden",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        display: "inline-flex",
        gap: "24px",
        padding: isInbox ? "10px 10px" : "0px",
        height: isInbox ? "100%" : "90vh",
        borderRadius: "8px",
      })}
    >
      <Accordion summaryTitle="General Information" defaultExpanded id="LeadSource-info">
        <TitleAndSubtitle title="Import Name" subtitle={currentLeadSource?.importJobName} />
        <TitleAndSubtitle title="Total Records" subtitle={currentLeadSource?.createdDocumentResultCount} />
      </Accordion>

      {leadSourceSequences?.map((sequence) => (
        <Accordion summaryTitle="Sequence" defaultExpanded id="Sequence-info">
          <Box
            style={{
              alignSelf: "stretch",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "16px",
              display: "flex",
              paddingLeft: "4px",
            }}
          >
            <TitleAndSubtitle
              title="Sequence Name"
              subtitle={sequence.sequenceTemplateName}
              customWidth={isInbox ? null : "80%"}
            />
            <Box
              style={{
                alignSelf: "stretch",
                justifyContent: "space-between",
                alignItems: "flex-start",
                display: "inline-flex",
              }}
            >
              {sequence.status && <StatusChip status={sequence.status} type="sequence" />}
              {(sequence.status === LeadSourceTriggerResponseStatusEnum.InProgress ||
                sequence.status === LeadSourceTriggerResponseStatusEnum.Paused) && (
                <ActionButton leadSourceTriggerId={sequence.leadSourceTriggerId} />
              )}
            </Box>

            {sequence.triggeredAt && (
              <TitleAndSubtitle title="Enrollment Date" subtitle={dateFormatStr(sequence.triggeredAt)} />
            )}

            <TitleAndSubtitle title="Currently in Sequence" subtitle={sequence.countProspectsCurrentlyInSequence} />
            <TitleAndSubtitle title="Completed Sequence" subtitle={sequence.countProspectsCompletedSequence} />
            <TitleAndSubtitle title="Unenrolled from Sequence" subtitle={sequence.countProspectsCancelledSequence} />
          </Box>
        </Accordion>
      ))}
      <Button
        sx={{ mx: "auto" }}
        onClick={handleShowEnrollDialog}
        size="sm"
        variant="secondary"
        leftIcon={<PlusIcon height={16} />}
      >
        Enroll Into Sequence
      </Button>
    </Box>
  );
};

export default LeadSourceInfo;
