import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Dropdown from "@/common/components/dropdown";
import { ChevronLeft, ChevronRight } from "heroicons-react";
import React from "react";
import { useAppSelector } from "@/redux/store";

const PAGE_SIZE_OPTIONS = [
  {
    label: "10",
    value: 10,
  },
  {
    label: "25",
    value: 25,
  },
];

type InboxListPagination = {
  page: number;
  pageSize: number;
  onSelectPageSize: (s: number) => void;
  onClickPrev: () => void;
  onClickNext: () => void;
};

export default function InboxListPagination(props: InboxListPagination) {
  const { page, pageSize, onSelectPageSize, onClickPrev, onClickNext } = props;
  const threadCount = useAppSelector(({ inbox }) => inbox.getInboxData.threadCount) || 0;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "5px",
        background: "#fff",
        padding: "12px 16px",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <Typography variant="body2">Display</Typography>
        <Dropdown label="" options={PAGE_SIZE_OPTIONS} value={pageSize} onChange={(o) => onSelectPageSize(o.value)} />
        <Typography variant="body2">of {threadCount} items</Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <ChevronLeft onClick={onClickPrev} style={{ cursor: page > 0 ? "pointer" : "not-allowed" }} />
        {/* TODO Page? */}
        <Typography variant="body2">
          {page + 1} of {Math.ceil(threadCount / pageSize)}
        </Typography>
        <ChevronRight
          onClick={onClickNext}
          style={{ cursor: page + pageSize < threadCount ? "pointer" : "not-allowed" }}
        />
      </Box>
    </Box>
  );
}
