import { combineReducers } from "@reduxjs/toolkit";
import listCallsReducer from "./listCalls";
import listAgentsReducer from "./listAgents";
import upsertAgentReducer from "./upsertAgent";

const aiVoiceReducer = combineReducers({
  listCalls: listCallsReducer,
  listAgents: listAgentsReducer,
  upsertAgent: upsertAgentReducer,
});

export default aiVoiceReducer;
