import { Provider as ReduxProvider } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import store from "@/redux/store";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AuthenticationProvider from "./AuthenticationProvider";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "@/theme/theme";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";

export default function ProvidersWrapper(props: { children: React.ReactNode }) {
  const { children } = props;
  const queryClient = new QueryClient();

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AuthenticationProvider>{children}</AuthenticationProvider>
          </LocalizationProvider>
        </ReduxProvider>
      </QueryClientProvider>
    </MuiThemeProvider>
  );
}
