import Services from "@/services";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getConsentsForCurrentUser = createAsyncThunk("consent/getConsentsForCurrentUser", async () => {
  try {
    const response = await Services.Consent.getConsentsForCurrentUser();
    return response?.data;
  } catch (error: any) {
    return error;
  }
});
