import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { useEffect } from "react";
import { Line } from "react-chartjs-2";
import { useAppDispatch } from "@/redux/store";
import { getProspectsInAutomation } from "@/redux/reducers/dashboard/slices/list";
import { useAppSelector } from "@/redux/store";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import { useFormContext } from "react-hook-form";
import NoProspectsInAutomation from "./emptyData/NoProspectsInAutomation";

ChartJS.register(CategoryScale, BarElement, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function ProspectsAutomation() {
  const dispatch = useAppDispatch();
  const prospectsInAutomationPending = useAppSelector(({ dashboard }) => dashboard.list.prospectsInAutomationPending);
  const prospectsInAutomation = useAppSelector(({ dashboard }) => dashboard.list.prospectsInAutomation);

  const { watch } = useFormContext();
  const dateRangeFilter = watch("dateRangeFilter");

  useEffect(() => {
    dispatch(getProspectsInAutomation(dateRangeFilter.value));
  }, [dateRangeFilter, dispatch]);

  return (
    <div
      style={{
        height: "270px",
        width: "100%",
      }}
    >
      {prospectsInAutomationPending ? (
        <LoadingSpinner />
      ) : !prospectsInAutomation?.length ? (
        <NoProspectsInAutomation />
      ) : (
        <Line
          data={{
            labels: ["Week1", "Week2", "Week3", "Week4"],
            datasets: [
              {
                label: "Prospects in sequence",
                backgroundColor: "#05CF74",
                data: [
                  prospectsInAutomation[0]?.prospect_in_sequence,
                  prospectsInAutomation[1]?.prospect_in_sequence,
                  prospectsInAutomation[2]?.prospect_in_sequence,
                  prospectsInAutomation[3]?.prospect_in_sequence,
                ],
                borderColor: "#05CF74",
              },
              {
                label: "Prospects enrolled in lead flow",
                backgroundColor: "#374667",
                data: [
                  prospectsInAutomation[0]?.prospect_in_lead_flow,
                  prospectsInAutomation[1]?.prospect_in_lead_flow,
                  prospectsInAutomation[2]?.prospect_in_lead_flow,
                  prospectsInAutomation[3]?.prospect_in_lead_flow,
                ],
                borderColor: "#374667",
              },
            ],
          }}
          options={{
            indexAxis: "x",
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: false,
              },

              legend: {
                display: true,
                position: "top",
                align: "start",

                labels: {
                  boxHeight: 8,
                  usePointStyle: true,
                  pointStyle: "circle",

                  color: "#54658D",
                  font: {
                    size: 12,
                    weight: 400,
                    lineHeight: 16,
                  },
                },
              },
              tooltip: {
                backgroundColor: "#374667",
              },
            },
            interaction: {
              intersect: true,
            },
            scales: {
              y: {},

              x: {
                stacked: true,
                grid: {
                  display: false,
                },
              },
            },
            responsive: true,
          }}
        />
      )}
    </div>
  );
}
