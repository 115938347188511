import { Typography } from "@mui/material";

export default function NoTasks() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        height: "100%",
      }}
    >
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18 25.5L22.5 30L30 19.5M42 24C42 33.9411 33.9411 42 24 42C14.0589 42 6 33.9411 6 24C6 14.0589 14.0589 6 24 6C33.9411 6 42 14.0589 42 24Z"
          stroke="#05CF74"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <Typography
        variant="body1"
        sx={{
          color: "#6B747B",
          textAlign: "center",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0.2px",
        }}
      >
        You are up to date! No task pending.
      </Typography>
    </div>
  );
}
