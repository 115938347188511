import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "./datepicker.css";
import "./calendar.css";
import { DatePicker as ReactDatePicker } from "react-date-picker";
import { DateRangePicker as ReactDateRangePicker } from "@wojtekmaj/react-daterange-picker";
import dayjs from "dayjs";
import { styled } from "@mui/material";

type ValuePiece = Date | null;
type SingleValue = ValuePiece;
type RangeValue = [ValuePiece, ValuePiece];

export const KnDateRangePicker = styled(ReactDateRangePicker)(({ theme }) => ({
  width: "100%",
}));

interface DatePickerProps {
  range?: boolean;
  value: SingleValue | RangeValue;
  onChange: (formattedDate: string | { dateStart: string | null; dateEnd: string | null }) => void;
}

export function DatePicker({ range = false, value, onChange }: DatePickerProps) {
  const handleRangeChange = (date: RangeValue) => {
    const formattedRange = {
      dateStart: date?.[0] ? dayjs(date[0]).format("YYYY-MM-DDTHH:mm:ss") : null,
      dateEnd: date?.[1] ? dayjs(date[1]).format("YYYY-MM-DDTHH:mm:ss") : null,
    };
    onChange(formattedRange);
  };

  const handleSingleDateChange = (date: SingleValue) => {
    const formattedDate = date ? dayjs(date).format("YYYY-MM-DDTHH:mm:ss") : ""; // Replace null with an empty string
    onChange(formattedDate);
  };

  return (
    <div>
      {range ? (
        <KnDateRangePicker value={value as RangeValue} onChange={handleRangeChange} />
      ) : (
        <ReactDatePicker
          onChange={handleSingleDateChange}
          value={value as SingleValue}
          monthPlaceholder="mm"
          yearPlaceholder="yyyy"
          dayPlaceholder="dd"
          format="yyyy-M-dd"
        />
      )}
    </div>
  );
}
