import { createColumnHelper } from "@tanstack/react-table";
import Typography from "@mui/material/Typography";
import IndeterminateCheckbox from "../checkbox/IntermediateCheckbox";
import { SequenceResponse } from "@/services/generated";

const columnHelper = createColumnHelper<SequenceResponse>();

export const Columns = [
  columnHelper.display({
    id: "checkbox",
    header: ({ table }) => (
      <div
        style={{
          display: "flex",
          gap: "17px",
          alignItems: "center",
          margin: "0 auto",

          maxWidth: "0px",
        }}
      >
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      </div>
    ),

    cell: ({ row }) => {
      return (
        <div
          style={{
            display: "flex",
            gap: "17px",
            alignItems: "center",
            margin: "0 auto",
            width: "0px",
            maxWidth: "0px",
          }}
        >
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      );
    },
  }),

  columnHelper.accessor("name", {
    header: () => <Typography fontWeight={600}>Name</Typography>,
    cell: (info) => {
      return <Typography>{info.getValue()}</Typography>;
    },
  }),
];
