import { Switch as MuiSwitch, Stack, SwitchProps as MuiSwitchProps, Typography, useTheme } from "@mui/material";

export type SwitchProps = {
  label?: string;
  value: boolean;
  defaultValue?: boolean;
  onChange: (e?: React.ChangeEvent<HTMLInputElement>, checked?: boolean) => void;
  error?: string;
} & MuiSwitchProps;

const Switch = (props: SwitchProps) => {
  const { value, error, onChange, label, ...rest } = props;
  const theme = useTheme();

  return (
    <>
      {label ? (
        <Stack alignItems="center" direction="row" gap={1}>
          <MuiSwitch
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            checked={value}
            onChange={onChange}
            sx={{
              width: "32px",
              height: "18px",
              padding: 0,
              "& .MuiSwitch-switchBase": {
                padding: 0,
                margin: "3px",
                transitionDuration: "300ms",
                "&.Mui-checked": {
                  transform: "translateX(14px)",
                  color: "#fff",
                  "& + .MuiSwitch-track": {
                    backgroundColor: "#374667",
                    opacity: 1,
                    border: "1px solid #E7EBED",
                  },
                  "&.Mui-disabled + .MuiSwitch-track": {
                    opacity: 0.5,
                  },
                },
                "&.Mui-focusVisible .MuiSwitch-thumb": {
                  color: "#1C73C1",
                  border: "6px solid #fff",
                },
                "&.Mui-disabled .MuiSwitch-thumb": {
                  color: "#D5D5D5",
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                  opacity: 0.5,
                },
              },
              "& .MuiSwitch-thumb": {
                width: "12px",
                height: "12px",
                flexShrink: 0,
                background: value ? "#fff" : "#D5D5D5",
              },
              "& .MuiSwitch-track": {
                borderRadius: 26 / 2,
                border: "1px solid #E7EBED",
                backgroundColor: "#fff",
                opacity: 1,
                transition: "background-color 300ms",
              },
            }}
            {...rest}
          />
          <Typography variant="body1" sx={{ color: "#1A2135" }}>
            {label}
          </Typography>
        </Stack>
      ) : (
        <MuiSwitch
          focusVisibleClassName=".Mui-focusVisible"
          disableRipple
          checked={value}
          onChange={onChange}
          sx={{
            width: "32px",
            height: "18px",
            padding: 0,
            "& .MuiSwitch-switchBase": {
              padding: 0,
              margin: "3px",
              transitionDuration: "300ms",
              "&.Mui-checked": {
                transform: "translateX(14px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                  backgroundColor: "#374667",
                  opacity: 1,
                  border: "1px solid #E7EBED",
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                  opacity: 0.5,
                },
              },
              "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: "#1C73C1",
                border: "6px solid #fff",
              },
              "&.Mui-disabled .MuiSwitch-thumb": {
                color: "#D5D5D5",
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "& .MuiSwitch-thumb": {
              width: "12px",
              height: "12px",
              flexShrink: 0,
              background: value ? "#fff" : "#D5D5D5",
            },
            "& .MuiSwitch-track": {
              borderRadius: 26 / 2,
              border: "1px solid #E7EBED",
              backgroundColor: "#fff",
              opacity: 1,
              transition: "background-color 300ms",
            },
          }}
          {...rest}
        />
      )}
      {error && (
        <Typography variant="body2" style={{ color: theme.palette.commonColors.danger, fontSize: "12px" }}>
          {error}
        </Typography>
      )}
    </>
  );
};

export default Switch;
