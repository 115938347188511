export const Roles = [
  {
    roleName: "Sales Manager",
    roleCode: "SALES_MANAGER",
    roleDescription: "Main SalesAi admin. Add user, set global settings.",
  },
  {
    roleName: "Sales Representative",
    roleCode: "SALES_REPRESENTATIVE",
    roleDescription: "Sales administrative user: Can edit personal settings",
  },
];
