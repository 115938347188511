import Box from "@mui/material/Box";
import MuiStepper, { StepperProps as MuiStepperProps } from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel, { stepLabelClasses } from "@mui/material/StepLabel";
import { StepConnector, Typography, stepConnectorClasses, styled, svgIconClasses } from "@mui/material";

export interface StepperProps extends MuiStepperProps {
  steps: { label: string; idx: number }[];
  activeStep: number;
  onStepChange?: (step: number) => void;
}
const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: "30px",
    display: "flex",
    background: theme.palette.border.default,
    borderRadius: "6px",
    height: "2px",
    left: "calc(-50% + 50px)",
    right: "calc(50% + 50px)",
  },
}));

export default function Stepper({ onStepChange, steps, activeStep = 0 }: StepperProps) {
  return (
    <Box sx={{ width: "100%" }}>
      <MuiStepper activeStep={activeStep} alternativeLabel connector={<Connector />}>
        {steps.map(({ label }, index) => (
          <Step
            key={label}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            completed={false}
            onClick={() => onStepChange?.(index)}
          >
            <StepLabel
              content="test"
              sx={(theme) => ({
                [`& .${stepLabelClasses.iconContainer}`]: {
                  [`& .${svgIconClasses.root}`]: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: activeStep > index ? theme.palette.midnight.p : theme.palette.midnight[20],
                    width: "42px",
                    height: "42px",
                    "& text": {
                      fill: activeStep > index ? theme.palette.txt.inverse : theme.palette.txt.default,
                    },
                    [`&.Mui-active`]: {
                      color: theme.palette.btnPrimary.default,
                    },
                    [`&.Mui-completed`]: {
                      color: theme.palette.midnight.p,
                    },
                  },
                },
                [`& .${stepLabelClasses.labelContainer}`]: {
                  [`& .${stepLabelClasses.alternativeLabel}`]: {
                    marginTop: "4px",
                  },
                },
              })}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: "12px",
                  fontWeight: 600,
                  lineHeight: "16px",
                  letterSpacing: "0.2px",
                }}
              >
                {label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </MuiStepper>
    </Box>
  );
}
