import { Box, useTheme } from "@mui/material";
import { PaperClipOutline } from "heroicons-react";
import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import ReactQuill, { Quill } from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";

interface TextEditorProps {
  value: string;
  onChange: (value: string) => void;
  handleDropInterval?: (index: number) => void;
  addFile?: () => void;
  quillRef?: (quill: ReactQuill | null) => void;
  error?: string;
  hideToolbar?: boolean;
  disabled?: boolean;
}

const Embed: any = Quill.import("blots/embed");

export interface VariableEmbedDelta {
  variableEmbed: VariableData;
}

interface VariableData {
  value: string;
  valid: boolean;
}

class VariableEmbed extends Embed {
  static create(data: VariableData) {
    const node = super.create(data.value);
    node.innerHTML += data.value;
    node.classList.add(data.valid ? "valid" : "invalid");
    node.setAttribute("contenteditable", false);
    node.setAttribute("value", data.value);
    node.setAttribute("valid", data.valid);
    return node;
  }

  static value(node: any) {
    return {
      value: node.getAttribute("value"),
      valid: node.getAttribute("valid") === "true",
    };
  }
}

VariableEmbed.blotName = "variableEmbed";
VariableEmbed.tagName = "span";
VariableEmbed.className = "variable";

Quill.register(VariableEmbed);

export const TextEditor: React.FC<TextEditorProps> = ({
  value,
  onChange,
  addFile,
  quillRef,
  error,
  hideToolbar = false,
  disabled = false,
}) => {
  const theme = useTheme();

  const handleChanges = (content: string) => {
    onChange(content);
  };

  const { setValue, getValues } = useFormContext();

  const FileUploader: React.FC = () => {
    const onDrop = useCallback((acceptedFiles: File[]) => {
      const attachmentsValue = getValues("attachments") || [];

      setValue("attachments", [...attachmentsValue, ...acceptedFiles]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
      <Box {...getRootProps()} style={{ cursor: "pointer" }}>
        <input {...getInputProps()} />
        <PaperClipOutline
          style={{
            width: "24px",
            height: "17px",
            position: "absolute",
            bottom: 12,
            stroke: "#374667",
          }}
        />
      </Box>
    );
  };

  const modules = useMemo(() => {
    const modules = {
      toolbar:
        !hideToolbar && !disabled
          ? {
              disabled,
              container: "#toolbar",
            }
          : false,
      clipboard: {
        matchVisual: true,
      },
    };
    return modules;
  }, [disabled, hideToolbar]);

  const formats = ["header", "bold", "italic", "underline", "list", "variableEmbed"];

  return (
    <>
      <div
        style={{
          borderRadius: "8px",
          border: "1px solid #E7EBED",
        }}
      >
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={value}
          onChange={handleChanges}
          formats={formats}
          modules={modules}
          style={{
            height: "150px",
            border: error ? "1px solid" : "none",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            borderColor: error ? theme.palette.commonColors.danger : "",
          }}
          readOnly={disabled}
        />
        <Box id="toolbar">
          {!hideToolbar && !disabled && (
            <>
              <select className="ql-header" defaultValue={""} onChange={(e) => e.persist()}>
                <option value="2">Heading 2</option>
                <option value="3">Heading 3</option>
                <option value="4">Heading 4</option>
                <option value="">Normal</option>
              </select>
              <button className="ql-bold" />
              <button className="ql-italic" />
              <button className="ql-underline" />
              <button className="ql-list" value="bullet" />
              <button className="ql-list" value="ordered" />
              {addFile && <FileUploader />}
            </>
          )}
        </Box>
      </div>
    </>
  );
};
