import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useTheme } from "@mui/material";
import dayjs from "dayjs";
import { Typography } from "@mui/material";

interface FormInputProps {
  value: any;
  onChange: any;
  dueDate?: string | undefined;
  disabled?: boolean;
  error?: string;
}

export const TimePickerInput = ({ value, error, onChange, dueDate, disabled = false }: FormInputProps) => {
  const theme = useTheme();

  return (
    <>
      <TimePicker
        views={["hours", "minutes"]}
        format="hh:mm A"
        disabled={disabled}
        value={dueDate ? dayjs(dueDate) : value ? dayjs(value) : null}
        onChange={onChange}
        sx={{
          width: "100%",

          "& .MuiInputBase-root": {
            fontSize: "14px",
            fontFamily: theme.typography.fontFamily,
            fontWeight: 400,
            lineHeight: "20px",
            letterSpacing: "0.2px",
            borderRadius: "8px",
            boxShadow: "none",
            margin: "0px",
            outline: "none",
          },

          "& .MuiInputBase-input": {
            padding: "12px 16px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: disabled
              ? "none"
              : error
              ? `1px solid ${theme.palette.commonColors.danger}!important`
              : "1px solid #C4C4C4",
            borderColor: error ? theme.palette.commonColors.danger : theme.palette.commonColors.light,
          },

          "& .MuiInputBase-root.Mui-disabled": {
            backgroundColor: "hsl(0, 0%, 95%)",
            border: error ? `1px solid ${theme.palette.commonColors.danger}` : "1px solid #E7EBED",
          },
        }}
      />
      {error && (
        <Typography variant="body2" style={{ color: theme.palette.commonColors.danger, fontSize: "12px" }}>
          This field is required
        </Typography>
      )}
    </>
  );
};
