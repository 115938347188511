import { Box, IconButton, SvgIcon, Typography } from "@mui/material";
import { PlusOutline } from "heroicons-react";
import { useTheme } from "@mui/material";

export interface SequenceConnectionProps {
  onClick: () => void;
  isLastOne: boolean;
  canUpdateSequence?: boolean;
}

export const SequenceConnection = ({ canUpdateSequence, onClick, isLastOne = false }: SequenceConnectionProps) => {
  const theme = useTheme();

  const handleClick = () => {
    onClick();
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "4px" }}>
      {canUpdateSequence && (
        <>
          <SvgIcon sx={{ fontSize: "40px" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="2" height="30" viewBox="0 0 2 30" fill="none">
              <path d="M1 0L0.999999 30" stroke={theme.palette.midnight.p} strokeWidth="1.5" />
            </svg>
          </SvgIcon>
          <IconButton sx={{ padding: "0px" }} onClick={handleClick}>
            <SvgIcon
              sx={{
                fontSize: "40px",
                border: `2px solid ${theme.palette.midnight.p}`,
                borderRadius: "50px",
                backgroundColor: isLastOne ? theme.palette.midnight.p : "transparent",
              }}
            >
              <PlusOutline color={isLastOne ? theme.palette.common.white : theme.palette.btnSecondary.default} />
            </SvgIcon>
          </IconButton>
        </>
      )}

      <SvgIcon sx={{ fontSize: "40px" }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="31" viewBox="0 0 12 31" fill="none">
          <path
            d="M5.46967 30.5303C5.76256 30.8232 6.23744 30.8232 6.53033 30.5303L11.3033 25.7574C11.5962 25.4645 11.5962 24.9896 11.3033 24.6967C11.0104 24.4038 10.5355 24.4038 10.2426 24.6967L6 28.9393L1.75736 24.6967C1.46447 24.4038 0.989591 24.4038 0.696698 24.6967C0.403805 24.9896 0.403805 25.4645 0.696698 25.7574L5.46967 30.5303ZM5.25 -3.27835e-08L5.25 30L6.75 30L6.75 3.27835e-08L5.25 -3.27835e-08Z"
            fill={theme.palette.midnight.p}
          />
        </svg>
      </SvgIcon>
      {isLastOne && (
        <Box
          bgcolor={theme.palette.bg.midnight}
          padding="4px 8px"
          borderRadius="4px"
          sx={{ display: "flex", gap: "8px", alignItems: "center" }}
        >
          <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.8566 8.19113C14.1002 7.85614 14.0261 7.38708 13.6911 7.14345C13.3561 6.89982 12.8871 6.97388 12.6434 7.30887L9.15969 12.099L7.28033 10.2197C6.98744 9.92678 6.51256 9.92678 6.21967 10.2197C5.92678 10.5126 5.92678 10.9874 6.21967 11.2803L8.71967 13.7803C8.87477 13.9354 9.08999 14.0149 9.30867 13.9977C9.52734 13.9805 9.72754 13.8685 9.85655 13.6911L13.8566 8.19113Z"
                fill="#05CF74"
              />
            </svg>
          </SvgIcon>
          <Typography
            fontSize="12px"
            fontWeight={600}
            lineHeight="16px"
            letterSpacing="0.2px"
            sx={{ color: theme.palette.common.white }}
          >
            End of Sequence
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SequenceConnection;
