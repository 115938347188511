import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompanyDetailsRequest, SalesProcessRequest } from "@/services/generated";
import Services from "@/services";
import { toast } from "react-toastify";

export const persistSalesProcess = createAsyncThunk(
  "aiLearning/persistSalesProcess",
  async (request: SalesProcessRequest, { rejectWithValue, dispatch }) => {
    try {
      return await Services.Ai.salesProcessPersist(request);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
