import Services from "@/services";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DealCard } from "./types";
import { getFormattedDeal } from "./helpers";
import { KnFilterOption2OperatorEnum } from "@/services/generated";

type State = {
  loading: boolean;
  error: string | null;
  cards?: DealCard[];
  createDealDrawerIsOpen: boolean;
  currentDeal: DealCard | null;
};

const initialState: State = {
  loading: false,
  error: null,
  cards: undefined,
  createDealDrawerIsOpen: false,
  currentDeal: null,
};

export const getDeals = createAsyncThunk("dealFlow/getDeals", async (pipelineId: string, { rejectWithValue }) => {
  try {
    const response = await Services.Deals.getDealsList({
      filters: [
        { property: "PIPELINE_ID", options: [{ operator: KnFilterOption2OperatorEnum.Equal, values: [pipelineId] }] },
      ],
      sortDirection: "ASC",
      pageSize: 30,
    });
    const cards: DealCard[] = response.data.map(getFormattedDeal);
    return cards;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const updateDealStage = createAsyncThunk(
  "dealFlow/updateDealStage",
  async ({ id, stage }: { id: string; stage: { id: string; name: string } }, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Deals.updateDealStage(id, stage.id);
      if (response.status === 200) {
        dispatch(updateCardStage({ id, stage }));
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const listDealsSlice = createSlice({
  name: "listDeals",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDeals.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getDeals.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.cards = payload;
    });
  },
  reducers: {
    openCreateDealDrawer: (state, action) => {
      const { deal } = action.payload;

      state.createDealDrawerIsOpen = true;

      if (deal) state.currentDeal = deal;
    },
    closeCreateDealDrawer: (state) => {
      state.createDealDrawerIsOpen = false;
      state.currentDeal = null;
    },
    addNewDeal: (state, action) => {
      if (!state.cards) {
        state.cards = [action.payload];
      } else {
        state.cards.unshift(action.payload);
      }
    },
    updateDealById: (state, action: PayloadAction<{ id: string; data: DealCard }>) => {
      const { id, data } = action.payload;
      state.cards = state.cards?.map((card) => {
        if (card.id === id) {
          return data;
        } else {
          return card;
        }
      });
    },
    updateCardStage: (state, action) => {
      const { id, stage } = action.payload;
      if (!state.cards) return;

      state.cards = state.cards.map((card) => {
        if (card.id === id) {
          return {
            ...card,
            stage,
          };
        }
        return card;
      });
    },
  },
});

export const { addNewDeal, updateDealById, openCreateDealDrawer, closeCreateDealDrawer, updateCardStage } =
  listDealsSlice.actions;

export default listDealsSlice.reducer;
