import EmailMessages from "./EmailMessages";
import NoData from "./NoData";
import SmsMessages from "./SmsMessages";
import { Box } from "@mui/system";
import { useAppSelector } from "@/redux/store";

export default function ActivityTab() {
  const smsCurrentInbox = useAppSelector(({ inbox }) => inbox.getInboxData.smsCurrentInbox);
  const emailCurrentInbox = useAppSelector((state) => state.inbox.getInboxData.emailCurrentInbox);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        overflowY: "auto",
        height: "calc(100vh - 164px)",
      }}
    >
      {smsCurrentInbox?.length > 0 || emailCurrentInbox?.length > 0 ? (
        <Box sx={{ border: "1px solid #ccc", borderRadius: "8px", padding: "16px" }}>
          <SmsMessages activityTab={true} />
          <EmailMessages activityTab={true} />
        </Box>
      ) : (
        <NoData message="No activity found" />
      )}
    </Box>
  );
}
