import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Services from "@/services";
import { AxiosResponse } from "axios";
import {
  AiAgentCallOutboundTestRequest,
  KnAiAgentCallDirectionEnum,
  KnFilter,
  KnFilterOptionOperatorEnum,
} from "@/services/generated";
import { AiAgentFiltersInput } from "@/pages/ai-voice/ai-agents";
import { AiAgent } from "@/types/ai-voice";
import toast from "@/lib/toast";

type State = {
  data?: AiAgent[];
  loading?: boolean;
  outboundData?: AiAgent[];
  outboundLoading?: boolean;
  error: string | null;
};

const initialState: State = {
  error: null,
};

export const lookupAgents = createAsyncThunk(
  "aiVoice/lookupAgents",
  async (filters: AiAgentFiltersInput, { rejectWithValue, dispatch }) => {
    try {
      const f: KnFilter[] = [];

      if (filters.text) {
        f.push({
          property: "text",
          options: [
            {
              values: [filters.text],
            },
          ],
        });
      }
      if (filters.callDirection) {
        f.push({
          property: "callDirection",
          options: [
            {
              operator: KnFilterOptionOperatorEnum.Equal,
              values: [filters.callDirection],
            },
          ],
        });
      }
      if (filters.callFlow) {
        f.push({
          property: "callFlow",
          options: [
            {
              operator: KnFilterOptionOperatorEnum.Equal,
              values: [filters.callFlow],
            },
          ],
        });
      }
      const request = {
        filters: f,
        page: 0,
        pageSize: 100,
      };
      return await Services.AiVoice.lookupAiAgent(request);
    } catch (error) {
      toast.error("Something went wrong!");
      console.error(error);
      return rejectWithValue(error.message);
    }
  },
);

export const lookupAgentsOutbound = createAsyncThunk(
  "aiVoice/lookupAgentsOutbound",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const request = {
        filters: [
          {
            property: "callDirection",
            options: [
              {
                operator: KnFilterOptionOperatorEnum.Equal,
                values: [KnAiAgentCallDirectionEnum.Outbound],
              },
            ],
          },
        ],
        page: 0,
        pageSize: 100,
      };
      return await Services.AiVoice.lookupAiAgent(request);
    } catch (error) {
      toast.error("Something went wrong!");
      console.error(error);
      return rejectWithValue(error.message);
    }
  },
);

export const deleteAgent = createAsyncThunk(
  "aiVoice/deleteAgent",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.AiVoice.deleteAiAgent(id);
      if (response.status === 200) {
        dispatch(deleteAgentById(id));
      }
    } catch (error) {
      console.error(error.message);
      return rejectWithValue(error.message);
    }
  },
);

export const sendCallTest = createAsyncThunk(
  "aiVoice/sendCallTest",
  async (request: AiAgentCallOutboundTestRequest, { rejectWithValue, dispatch }): Promise<AxiosResponse<void>> => {
    return await Services.AiVoice.sendCallTest(request);
  },
);

const listAgentsSlice = createSlice({
  name: "listAgents",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(lookupAgents.pending, (state) => {
        state.loading = true;
      })
      .addCase(lookupAgents.fulfilled, (state, { payload }) => {
        state.data = payload.data?.results || [];
        state.loading = false;
      })
      .addCase(lookupAgents.rejected, (state, { payload }) => {
        state.loading = false;
      })

      .addCase(lookupAgentsOutbound.pending, (state) => {
        state.outboundLoading = true;
      })
      .addCase(lookupAgentsOutbound.fulfilled, (state, { payload }) => {
        state.outboundData = payload.data?.results || [];
        state.outboundLoading = false;
      })
      .addCase(lookupAgentsOutbound.rejected, (state, { payload }) => {
        state.outboundLoading = false;
      })

      .addCase(deleteAgent.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAgent.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(deleteAgent.fulfilled, (state, { payload }) => {
        state.loading = false;
      });
  },
  reducers: {
    deleteAgentById: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.data = state.data?.filter((agent) => agent.agent_id !== id);
    },
  },
});

export const { deleteAgentById } = listAgentsSlice.actions;

export default listAgentsSlice.reducer;
