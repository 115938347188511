import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import tasks from "./reducers/tasks/slices";
import team from "./reducers/team/slices";
import dealFlow from "./reducers/dealFlow/slices";
import trigger from "./reducers/triggers";
import triggers from "./reducers/triggers/slices";
import prospects from "./reducers/prospects/slices";
import companies from "./reducers/prospects/companies/slices";
import prospectFinder from "./reducers/prospects/finder/slices";
import sequences from "./reducers/sequences/slices";
import drips from "./reducers/sequences/drips/slices";
import productLibrary from "./reducers/productLibrary/slices";
import settings from "./reducers/settings";
import dataImport from "./reducers/dataImport/slices";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import deals from "./reducers/deals";
import dashboard from "./reducers/dashboard/slices";
import companyDetails from "@/redux/reducers/ai-learning/company-details";
import aiVoice from "@/redux/reducers/ai-voice";
import aiPersona from "@/redux/reducers/ai-learning/ai-persona";
import salesProcess from "@/redux/reducers/ai-learning/sales-process";
import leadFlow from "./reducers/leadFlow/slices";
import oauth from "@/redux/reducers/oauth";
import consent from "./reducers/consent/slices";
import inbox from "./reducers/inbox/slices";
import teams from "./reducers/admin/teams/slices";

const store = configureStore({
  reducer: {
    teams,
    deals,
    consent,
    companyDetails,
    aiVoice,
    aiPersona,
    salesProcess,
    dealFlow,
    tasks,
    team,
    prospects,
    companies,
    sequences,
    drips,
    triggers,
    productLibrary,
    leadFlow,
    settings,
    dataImport,
    trigger,
    inbox,
    dashboard,
    prospectFinder,
    oauth,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
