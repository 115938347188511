import Drawer from "@mui/material/Drawer";
import Button from "@/common/components/button";
import CreateComapnyForm from "@/pages/prospects/companies/create/CreateComapnyForm";
import { useAppDispatch } from "@/redux/store";
import {
  createCompanyCloseDrawer,
  createCompanyOpenDrawer,
} from "@/redux/reducers/prospects/companies/slices/listCompanies";
import { useAppSelector } from "@/redux/store";

export default function CreateNewCompany() {
  const dispatch = useAppDispatch();
  const { createCompanyDrawerOpen } = useAppSelector(({ companies }) => companies.listCompanies);

  return (
    <>
      <Button data-testid="openCompanyDrawer" variant="primary" onClick={() => dispatch(createCompanyOpenDrawer())}>
        Add New Company
      </Button>

      <Drawer open={createCompanyDrawerOpen} onClose={() => dispatch(createCompanyCloseDrawer())} anchor="right">
        <CreateComapnyForm />
      </Drawer>
    </>
  );
}
