/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";
import { AxiosResponse } from "axios";
import { AiVoiceTeamSettings } from "@/services/generated";

export const getTeamAiVoiceSettings = createAsyncThunk(
  "settings/getTeamAiVoiceSettings",
  async (_, { rejectWithValue, dispatch }): Promise<AxiosResponse<AiVoiceTeamSettings>> => {
    try {
      return await Services.AiVoice.getAiVoiceTeamSettings();
    } catch (error: any) {
      return error;
    }
  },
);
