import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOrCreateUser } from "./getOrCreate";
import { KnUser } from "@/services/generated";
import services from "@/services";
import toast from "@/lib/toast";
import { AxiosResponse } from "axios";
import Services from "@/services";
import { RootState } from "@/redux/store";

export interface User extends KnUser {
  id?: string;
  email?: string;
  phone?: string;
  media?: File | null;
  prevImg?: File | null;
}

type State = {
  user?: User | null;
  draftUser?: User | null;
  loading: boolean;
  error: string | null;
  media: File | null;
  prevImg?: File | null;
  canUpdateUserInfo: boolean;
};

const initialState: State = {
  user: null,
  draftUser: null,
  loading: false,
  error: null,
  media: null,
  prevImg: null,
  canUpdateUserInfo: true,
};

export const acceptTermsOfService = createAsyncThunk(
  "user/acceptTermsOfService",
  async (_, { rejectWithValue, dispatch }): Promise<AxiosResponse<void>> => {
    try {
      return await Services.Users.acceptTermsOfService({ version: "1" });
    } catch (error) {
      return error;
    }
  },
);

export const updateUser = createAsyncThunk("user/updateUser", async (_, { rejectWithValue, getState }) => {
  const state = getState() as RootState;

  let draftUser = state.settings.user.draftUser;

  try {
    if (!draftUser?.id) {
      return rejectWithValue("No draft user");
    }

    if (draftUser?.media) {
      const response = await Services.MediaController.uploadFile(draftUser.media);

      draftUser = { ...draftUser, imgUrl: response.data };
    }

    await services.Users.updateUserProfile(draftUser);

    toast.success("User successfully updated");

    return draftUser;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    set: (state, action) => {
      state.user = {
        ...action.payload,
      };
    },
    setDraft: (state, { payload }) => {
      state.draftUser = {
        ...payload,
      };
    },
    setPrevImg: (state, { payload }) => {
      state.prevImg = payload;
    },
    setCanUpdateUserInfo: (state, { payload }) => {
      state.canUpdateUserInfo = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(acceptTermsOfService.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(acceptTermsOfService.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = {
          ...state.user,
          tosAccepted: true,
        };
      })
      .addCase(acceptTermsOfService.rejected, (state, { payload }) => {
        state.loading = false;
      })

      .addCase(getOrCreateUser.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.user = payload.data;
        }
        state.loading = false;
      })
      .addCase(getOrCreateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrCreateUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload as string;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.draftUser = null;
        state.loading = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload as string;
      });
  },
});

export const { set, setDraft, setPrevImg, setCanUpdateUserInfo } = userSlice.actions;

export default userSlice.reducer;
