import { Box } from "@kennected/components";
import Spinner from "@/common/assets/png/spinner.png";

export const LoadingSpinner = ({
  loading = true,
  height = "60vh",
  maxHeight = "100%",
}: {
  loading?: boolean | undefined;
  height?: string;
  maxHeight?: string;
}) => {
  if (!loading) return null;

  const styles = {
    overlay: {
      display: "flex",
      width: "100%",
      height,
      maxHeight,
      zIndex: 200,
      justifyContent: "center",
      alignItems: "center",
    },
    spinner: {
      width: 80,
      height: 80,
      animation: "spin 2s linear infinite",
      marginBottom: 100,
    },
    keyframe: `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `,
  };

  return (
    <Box sx={styles.overlay}>
      <style>{styles.keyframe}</style>
      <img src={Spinner} alt="Loading..." style={styles.spinner} />
    </Box>
  );
};
