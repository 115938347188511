import Drawer from "@mui/material/Drawer";
import { useTranslation } from "react-i18next";
import Button from "@/common/components/button";
import CreateTaskForm from "@/pages/tasks/create/CreateTaskForm";
import { useAppDispatch } from "@/redux/store";
import { closeCreateTaskDrawer, openCreateTaskDrawer } from "@/redux/reducers/tasks/slices/listTask";
import { useAppSelector } from "@/redux/store";

export default function CreateNewTask() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { createTaskDrawerIsOpen } = useAppSelector(({ tasks }) => tasks.listTask);

  const closeTaskDrawer = () => {
    dispatch(closeCreateTaskDrawer());
  };

  const openTaskDrawer = () => {
    dispatch(openCreateTaskDrawer());
  };

  return (
    <div>
      <Button data-testid="openTaskDrawer" variant="primary" onClick={openTaskDrawer}>
        {t("tasks.createTask")}
      </Button>

      <Drawer open={createTaskDrawerIsOpen} onClose={closeTaskDrawer} anchor="right">
        <CreateTaskForm />
      </Drawer>
    </div>
  );
}
