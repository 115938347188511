import LeadsWon from "./LeadsWon";
import LeadsLost from "./LeadsLost";
import Unqualified from "./Unqualified";
import { useEffect } from "react";
import { getLeadFlowPerformance } from "@/redux/reducers/dashboard/slices/list";
import { useFormContext } from "react-hook-form";
import { useAppDispatch } from "@/redux/store";
import { useAppSelector } from "@/redux/store";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import NoLeadFlowPerformance from "./emptyData/NoLeadFlowPerformance";

export default function LeadFlowPerformance() {
  const dispatch = useAppDispatch();
  const { watch } = useFormContext();
  const dateRangeFilter = watch("dateRangeFilter");
  const leadFlowPerformance = useAppSelector(({ dashboard }) => dashboard.list.leadFlowPerformance);
  const leadFlowPerformancePending = useAppSelector(({ dashboard }) => dashboard.list.leadFlowPerformancePending);

  useEffect(() => {
    dispatch(getLeadFlowPerformance(dateRangeFilter.value));
  }, [dateRangeFilter, dispatch]);

  if (leadFlowPerformancePending) return <LoadingSpinner />;

  if (!leadFlowPerformance) return <NoLeadFlowPerformance />;

  return (
    <>
      <LeadsWon />
      <LeadsLost />
      <Unqualified />
    </>
  );
}
