import TableCell from "@/common/components/table-cell";
import TableHeader from "@/common/components/table-header";
import { Team } from "@/types/admin";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<Team>();

export const TeamsColumns = [
  columnHelper.accessor("name", {
    header: () => <TableHeader>Team Name</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("phone", {
    header: () => <TableHeader>Phone</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("location", {
    header: () => <TableHeader>Location</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("userCount", {
    header: () => <TableHeader>User Count</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("domain", {
    header: () => <TableHeader>Domain</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),
];
