import { createSelector } from "@reduxjs/toolkit";

const getRoot = (state: any) => state.inbox.getInboxData;

export const getInbox = createSelector(getRoot, (state) => state);

export const selectInboxMessages = createSelector(getRoot, (state) => state.messages);

export const selectCurrentInbox = createSelector(getRoot, (state) => state.currentInbox);

export const selectCurrentInboxProspect = createSelector(getRoot, (state) => state.currentProspect);

export const selectInboxViewDrawerIsOpen = createSelector(getRoot, (state) => state.viewDrawerIsOpen);

export const getMessagesCount = createSelector(getRoot, (state) => state.messages?.length || 0);
