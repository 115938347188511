import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const schema = Yup.object({
  name: Yup.string().label("Title").required(),
  schedule: Yup.boolean().required(),
  daysOfWeek: Yup.array()
    .of(Yup.string())
    .when("schedule", {
      is: true,
      then: (schema) => schema.min(1, "Weekdays are required").required(),
    }),
  businessHoursStart: Yup.string().when("schedule", {
    is: true,
    then: (schema) => schema.required(),
  }),
  businessHoursEnd: Yup.string().when("schedule", {
    is: true,
    then: (schema) =>
      schema.test("is-valid", "Invalid End Time", (value, context) => {
        if (!value) {
          return context.createError({ message: "This field is required" });
        }

        const endTime = moment(value, ["HH:mm"]);
        const startTime = moment(context.parent.businessHoursStart, ["HH:mm"]);

        if (endTime <= startTime) {
          return context.createError({ message: "Invalid End Time" });
        }

        return endTime > startTime;
      }),
  }),
  businessHoursTimezone: Yup.string().when("schedule", {
    is: true,
    then: (schema) => schema.required(),
  }),
});

export type SequenceFormType = Yup.InferType<typeof schema>;

export const useSequenceForm = () => {
  const formMethods = useForm<SequenceFormType>({
    resolver: yupResolver(schema),
  });

  return formMethods;
};
