import * as React from "react";
import { useAppDispatch } from "@/redux/store";
import { ReactComponent as SquaresPlus } from "@/common/assets/svg/sidebar/squares-plus.svg";
import MenuOptions from "@/common/components/menu-options/MenuOptions";
import {
  setCurrentLeadSource,
  setSeqEnrollLeadSourceIdList,
  showSeqEnrollDialog,
  showSeqUnEnrollDialog,
} from "@/redux/reducers/dataImport/slices/list";
import { XCircleIcon } from "@heroicons/react/24/outline";

const iconStyles = {
  width: "24px",
  height: "24px",
  stroke: "#1A2135",
};

const options = [
  {
    label: "Enroll in Sequence",
    value: "enroll_in_sequence",
    icon: <SquaresPlus style={iconStyles} />,
  },
  {
    label: "Un-enroll From Sequence",
    value: "un_enroll_from_sequence",
    icon: <XCircleIcon style={iconStyles} />,
  },
];

export default function TableActionButton({ params }: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MenuOptions
      open={open}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      options={options}
      onClick={(option) => {
        if (option.value === "enroll_in_sequence") {
          dispatch(setSeqEnrollLeadSourceIdList([params.row.original.id]));
          dispatch(showSeqEnrollDialog());
          handleClose();
        } else if (option.value === "un_enroll_from_sequence") {
          dispatch(setCurrentLeadSource(params.row.original));
          dispatch(showSeqUnEnrollDialog());
          handleClose();
        }
      }}
    />
  );
}
