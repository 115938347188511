import { Components, Theme } from "@mui/material";
export const ToolTipStyles: Components<Theme>["MuiTooltip"] = {
  defaultProps: { arrow: true },
  styleOverrides: {
    tooltip: ({ theme }) => ({
      backgroundColor: theme.palette.midnight.p,
      color: theme.palette.txt.inverse,
      fontSize: "14px",
      fontWeight: 400,
      padding: "10px",
      borderRadius: "8px",
      lineHeight: "20px",
      letterSpacing: "0.2px",
      "& .MuiTooltip-arrow": {
        color: theme.palette.midnight.p,
      },
    }),
  },
};
