import { Box, useTheme } from "@mui/material";
import { ReactNode } from "react";
import Button from "@/common/components/button";
import Loading from "../loading-overlay/LoadingOverlay";

export type IntegrationProps = {
  logo?: ReactNode;
  name?: string;
  desc?: string;
  connected?: boolean;
  connect?: () => void;
  disconnect?: () => void;
  isLoading?: boolean;
};

export default function Integration({ logo, name, desc, connected, isLoading, connect, disconnect }: IntegrationProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: theme.palette.border.default,
        borderRadius: "8px",
        padding: "8px",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {logo && logo}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2px",
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "20px",
              letterSpacing: "0.2px",
            }}
          >
            {name}
          </Box>
          <Box
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "16px",
              letterSpacing: "0.2px",
            }}
          >
            {desc}
          </Box>
        </Box>
      </Box>
      {isLoading ? (
        <Button disabled size="sm" variant="secondary" onClick={disconnect}>
          <Box sx={{ position: "relative", top: "-20px", left: "-20px", height: "100%", width: "100%" }}>
            <Loading loading={isLoading} />
          </Box>
        </Button>
      ) : connected ? (
        <Button size="sm" variant="secondary" onClick={disconnect}>
          Disconnect
        </Button>
      ) : (
        <Button size="sm" variant="secondary" onClick={connect}>
          Connect
        </Button>
      )}
    </Box>
  );
}
