import { FormControl } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Dropdown from "@/common/components/dropdown/Dropdown";
import { TaskTypeSelectOptions } from "@/redux/reducers/tasks/slices/helpers";
import FormLabel from "@/common/components/form-label/FormLabel";
import { useEffect } from "react";
import { getTeamUsers } from "@/redux/reducers/team/slices/listUsers";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Stack } from "@mui/material";
import AttachmentInput from "@/common/components/attachment/Attachment";

export default function CreateTaskForm() {
  const { control } = useFormContext();
  const dispatch = useAppDispatch();
  const users = useAppSelector(({ team }) => team.listUsers.users);

  useEffect(() => {
    if (users === undefined) {
      dispatch(getTeamUsers());
    }
  }, [users, dispatch]);

  return (
    <div style={{ width: "100%", display: "flex", gap: "16px", flexDirection: "column" }}>
      <Stack direction={{ xs: "column", md: "row" }} spacing={1} width="100%">
        <FormControl sx={{ width: "100%" }}>
          <Controller
            name="action.type"
            control={control}
            render={({ field }) => (
              <>
                <FormLabel label="Task Type" aria-required />
                <Dropdown
                  label="Task Type"
                  options={TaskTypeSelectOptions}
                  value={field.value}
                  onChange={(option) => field.onChange(option.value)}
                />
              </>
            )}
          />
        </FormControl>

        <FormControl sx={{ width: "100%" }}>
          <Controller
            name="action.assignee"
            control={control}
            render={({ field }) => (
              <>
                <FormLabel label="Assignee" aria-required />
                <Dropdown
                  label="Assignee"
                  options={(users || []).map((user) => ({
                    label: `${user.firstName!} ${user.lastName!}`,
                    value: user.user_id!,
                  }))}
                  value={field.value}
                  onChange={(option) => field.onChange(option.value)}
                />
              </>
            )}
          />
        </FormControl>
      </Stack>

      <FormControl sx={{ width: "50%" }}>
        <Controller
          name="action.attachments"
          control={control}
          render={({ field }) => (
            <>
              <FormLabel label="Attachments" />
              <AttachmentInput value={field.value} onChange={field.onChange} />
            </>
          )}
        />
      </FormControl>

      <FormControl sx={{ width: "100%" }}>
        <Controller
          name="isDelayed"
          control={control}
          render={({ field }) => (
            <>
              <FormLabel label="Delay" aria-required />
              <Dropdown
                label="Delay"
                options={[
                  { label: "No", value: false },
                  { label: "Yes", value: true },
                ]}
                value={field.value}
                onChange={(value) => field.onChange(value.value)}
              />
            </>
          )}
        />
      </FormControl>
    </div>
  );
}
