import { Controller, useFormContext } from "react-hook-form";
import { ReactComponent as Contract } from "@/common/assets/svg/triggers/contact_created.svg";
import { ReactComponent as Meeting } from "@/common/assets/svg/triggers/meeting.svg";
import Box from "@mui/material/Box";
import { useAppSelector } from "@/redux/store";
import { useEffect } from "react";
import { useAppDispatch } from "@/redux/store";
import { getDealsList } from "@/redux/reducers/deals/slices/listDeals";
import { ListByEventTypeEventEnum } from "@/services/generated";
import DealFlowStateChangeForm from "@/pages/triggers/edit/steps/event/deal/DealFlowStateChange";
import DealAssigneeChangeForm from "@/pages/triggers/edit/steps/event/deal/DealAssigneeChangeForm";
import TriggerEventTypeOption from "@/pages/triggers/edit/steps/event/TriggerEventTypeOption";

const EVENT_TYPE_OPTIONS = [
  {
    title: "Deal State Change",
    icon: <Contract />,
    value: ListByEventTypeEventEnum.DealFlowStateChange,
    form: <DealFlowStateChangeForm />,
  },
  {
    title: "Deal Assignee Change",
    icon: <Meeting />,
    value: ListByEventTypeEventEnum.DealAssigneeChange,
    form: <DealAssigneeChangeForm />,
  },
];

export default function Index() {
  const methods = useFormContext();
  const { control } = methods;
  const deals = useAppSelector(({ deals }) => deals.listDeals.deals);
  const dispatch = useAppDispatch();
  const eventType = methods.watch("event.eventType");

  useEffect(() => {
    if (deals === undefined) {
      dispatch(getDealsList());
    }
  }, [dispatch, deals]);

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "800px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        margin: "0 auto",
      }}
    >
      {EVENT_TYPE_OPTIONS.map(({ title, icon, value, form }) => (
        <div key={title}>
          <Controller
            name="event.eventType"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TriggerEventTypeOption title={title} icon={icon} value={value} field={field} />
            )}
          />
          {eventType === value && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 2,
                mx: 5,
              }}
            >
              {form}
            </Box>
          )}
        </div>
      ))}
    </div>
  );
}
