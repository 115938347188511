import Searchbar from "@/common/components/searchbar/Searchbar";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";

type CompanyFiltersInput = {
  searchString?: string;
};

type CompanyFiltersProps = {
  setFilters: (filters: CompanyFiltersInput | ((prevFilters: CompanyFiltersInput) => CompanyFiltersInput)) => void;
};
export default function CompanyFilters({ setFilters }: CompanyFiltersProps) {
  const [search, setSearch] = useState<string>("");

  const debouncedSetSearch = useMemo(
    () =>
      debounce((searchString: string) => {
        setFilters({
          searchString: searchString,
        });
      }, 500),
    [setFilters],
  );

  useEffect(() => {
    debouncedSetSearch(search);
  }, [debouncedSetSearch, search]);

  return (
    <Searchbar
      sx={{ width: "450px" }}
      placeholder="Search for a company"
      value={search}
      onChange={(e) => {
        setSearch(e.target.value);
      }}
    />
  );
}
