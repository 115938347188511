import { Components, Theme } from "@mui/material";
export const TabStyles: Components<Theme>["MuiTab"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: "4px",
      color: theme.palette.txt.default,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0.2px",
      minHeight: "42px",
      height: "42px",
      maxHeight: "42px",

      padding: "0px 24px",

      "&:hover": {
        backgroundColor: theme.palette.midnight[20],
      },
      "&.Mui-selected": {
        color: theme.palette.txt.default,
        backgroundColor: theme.palette.bg.default,
      },
    }),
  },
};
