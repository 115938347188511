import React from "react";
import { Box, FormControl, useTheme } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ArrowDownLeftIcon, ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { AiAgentForm } from "..";

export default function AgentTypeForm() {
  const { control } = useFormContext<AiAgentForm>();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
      <Controller
        name="call_direction"
        control={control}
        render={({ field }) => (
          <FormControl component="fieldset">
            <Box sx={{ display: "flex", gap: 3 }}>
              <Box
                onClick={() => field.onChange("INBOUND")}
                sx={{
                  textAlign: "center",
                  maxWidth: 260,
                  cursor: "pointer",
                  border: `1px solid ${
                    field.value === "INBOUND" ? theme.palette.midnight.p : theme.palette.commonColors.light
                  }`,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: 2,
                  p: 2,
                  gap: 2,
                }}
              >
                <Box sx={{ display: "flex", alignSelf: "end" }}>
                  <input type="radio" checked={field.value === "INBOUND"} onChange={() => field.onChange("INBOUND")} />
                </Box>
                <Box
                  sx={{
                    bgcolor: theme.palette.midnight[20],
                    borderRadius: "100%",
                    width: 42,
                    height: 42,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ArrowDownLeftIcon height={20} />
                </Box>
                <Box sx={{ fontWeight: "bold" }}>{t("agentType.inbound.title")}</Box>
                <Box sx={{ fontSize: "0.8rem", color: "text.secondary" }}>{t("agentType.inbound.description")}</Box>
              </Box>
              <Box
                onClick={() => field.onChange("OUTBOUND")}
                sx={{
                  textAlign: "center",
                  display: "flex",
                  cursor: "pointer",
                  flexDirection: "column",
                  alignItems: "center",
                  maxWidth: 260,
                  border: `1px solid ${
                    field.value === "OUTBOUND" ? theme.palette.midnight.p : theme.palette.commonColors.light
                  }`,
                  borderRadius: 2,
                  p: 2,
                  gap: 2,
                }}
              >
                <Box sx={{ display: "flex", alignSelf: "end" }}>
                  <input
                    type="radio"
                    checked={field.value === "OUTBOUND"}
                    onChange={() => field.onChange("OUTBOUND")}
                  />
                </Box>
                <Box
                  sx={{
                    bgcolor: theme.palette.midnight[20],
                    borderRadius: "100%",
                    width: 42,
                    height: 42,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ArrowUpRightIcon height={20} />
                </Box>

                <Box sx={{ fontWeight: "bold" }}>{t("agentType.outbound.title")}</Box>
                <Box sx={{ fontSize: "0.8rem", color: "text.secondary", mt: 1 }}>
                  {t("agentType.outbound.description")}
                </Box>
              </Box>
            </Box>
          </FormControl>
        )}
      />
    </Box>
  );
}
