import { createAsyncThunk } from "@reduxjs/toolkit";
import { KnCallMessage } from "@/services/generated";
import { AxiosResponse } from "axios";
import Services from "@/services";

export const getCallSummary = createAsyncThunk(
  "aiVoice/getCallSummary",
  async (id: string, { rejectWithValue, dispatch }): Promise<AxiosResponse<Array<KnCallMessage>>> => {
    try {
      return await Services.AiVoice.getCallDetails(id);
    } catch (error: any) {
      return error;
    }
  },
);
