import { ReactComponent as LogIcon } from "@/common/assets/svg/log.svg";
import { ReactComponent as SaveIcon } from "@/common/assets/svg/save.svg";
import Button from "@/common/components/button";
import { useUploadPercents } from "@/common/components/drag-and-drop/useUploadPercent";
import IconButton from "@/common/components/icon-button";
import LoadingOverlay from "@/common/components/loading-overlay";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import { DelayForm } from "@/common/components/sequence-action/ActionSetDelay";
import SequenceAction from "@/common/components/sequence-action/SequenceAction";
import SequenceStepDrip from "@/common/components/sequence-action/SequenceStepDrip";
import { SequenceConnection } from "@/common/components/sequence-connection";
import Summary from "@/common/components/summary/Summary";
import routes from "@/common/constants/routes";
import useConfirmModal from "@/common/hooks/useConfirmModal";
import toast from "@/lib/toast";
import CreateAiVoice from "@/pages/sequence-builder/create-ai-voice/CreateAiVoice";
import DraftTemplateEmail from "@/pages/sequence-builder/send-email/DraftTemplateEmail";
import {
  createStepTemplateDrip,
  removeStepTemplateDrip,
  updateStepTemplateDrip,
} from "@/redux/reducers/sequences/drips/slices/createDrip";
import {
  createSequence,
  createStepTemplate,
  removeSequenceStepTemplate,
  switchConsecutiveStepsOrder,
  updateSequence,
  updateStepTemplateBehavior,
  updateStepTemplateInfo,
  uploadAttachment,
} from "@/redux/reducers/sequences/slices/createSequence";
import { getSequenceById } from "@/redux/reducers/sequences/slices/listSequences";
import { getCurrentSequence } from "@/redux/selectors/sequences";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  AttachmentResponse,
  SequenceTriggerScheduleResponseDaysOfWeekEnum,
  StepTemplateRequestCreateTypeEnum,
  StepTemplateRequestUpdateInfo,
  StepTemplateResponse,
} from "@/services/generated";
import { SequenceTemplate, StepTemplate, StepTemplateConfig, StepTemplateType } from "@/types/sequence";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Box, Divider, Typography } from "@mui/material";
import { inRange } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DraftTemplateDrip, { DripForm } from "../create-drip/DraftTemplateDrip";
import DraftTemplateSMS from "../send-sms/DraftTemplateSMS";
import StatusBadge from "../sequence-status";
import SequenceVersions from "../versions";
import SequenceForm from "./SequenceForm";
import "./TagStyles.css";
import { useSequenceForm } from "./useSequenceForm";

interface summaryProps {
  totalSteps: number;
  daysToComplete: number;
  automation: number;
}

interface LinkedStepData {
  linkedStep?: StepTemplate;
  linkedStepIndex: number;
  drip: StepTemplateConfig | null;
  dripIndex: number | null;
}

const getSummarySequenceData = ({ totalSteps, daysToComplete, automation }: summaryProps) => [
  { title: "Total Steps", value: totalSteps.toString() },
  { title: "Days to Complete", value: daysToComplete.toFixed(2) },
  { title: "Automation", value: automation.toString() + "%" },
];

const getDaysToComplete = (steps: StepTemplate[]) => {
  return steps.reduce((acc: number, item: StepTemplate) => {
    let amount = item.delayAmount || 0;

    switch (item.delayType) {
      case "HOURS":
        amount /= 24;
        break;
      case "MINUTES":
        amount /= 60 * 24;
    }

    return acc + amount;
  }, 0);
};

const DRAFT_DRIP_ID = "draft-drip";

export default function CreateSequencePage() {
  const params = useParams();
  const currentSequence = useAppSelector(getCurrentSequence);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uploadPercents = useUploadPercents();

  const formMethods = useSequenceForm();
  const { getValues, watch, handleSubmit } = formMethods;

  const [actions, setActions] = useState<(StepTemplate | StepTemplateConfig)[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [versionsDrawerOpen, setVersionsDrawerOpen] = useState<boolean>(false);
  const [isLoadingCurrentSequence, setIsLoadingCurrentSequence] = useState<boolean>(false);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  const { deleteConfirm } = useConfirmModal();

  const currentStep = useMemo(() => {
    return actions.length > 0 ? actions[currentStepIndex] : undefined;
  }, [actions, currentStepIndex]);

  const canUpdateSequence = useMemo(
    () =>
      !currentSequence ||
      (currentSequence?.status && !["ACTIVE", "INACTIVE", "DELETED"].includes(currentSequence.status)),
    [currentSequence],
  );

  const sequenceName = watch("name");

  const totalSteps = useMemo(() => actions.length, [actions]);

  const daysToComplete = useMemo(() => getDaysToComplete(actions), [actions]);

  const { loading: pendingCreateSequence } = useAppSelector(({ sequences }) => sequences.createSequence);

  const summarySequenceData = useMemo(() => {
    const automation =
      Math.ceil(
        (actions.filter((action) => !["CALL", "MANUAL", undefined].includes(action.actionType)).length / totalSteps) *
          100,
      ) || 0;

    return getSummarySequenceData({ totalSteps, daysToComplete, automation });
  }, [totalSteps, actions, daysToComplete]);

  useEffect(() => {
    async function fetchCurrentSequence() {
      if (!params.id) {
        return;
      }

      setIsLoadingCurrentSequence(true);

      try {
        await dispatch(getSequenceById({ id: params.id }));

        navigate(routes.sequence.update.with({ id: params.id }));
      } catch (error) {
        toast.error("Something went wrong!");
      } finally {
        setIsLoadingCurrentSequence(false);
      }
    }

    fetchCurrentSequence();
  }, [dispatch, navigate, params.id]);

  useEffect(() => {
    const steps = (currentSequence?.steps as StepTemplate[]) || [];

    const stepsOrder = order(steps);

    setActions(stepsOrder);
  }, [currentSequence]);

  const order = (steps: StepTemplate[]): (StepTemplate | StepTemplateConfig)[] => {
    if (steps.length === 0) {
      return [];
    }

    const first = steps.find((s) => s.parentsIds?.length === 0);

    if (!first) {
      throw new Error("Invalid State: If production, send URL to support@salesai.com");
    }
    const ordered = [];

    if (first.dripTemplate) {
      ordered.push({
        buildType: "config",
        ...first.dripTemplate,
      } as StepTemplateConfig);
    }

    ordered.push(first);

    if (steps.length === 1) {
      return ordered;
    }

    let parentId = first?.id;

    // Keep finding children
    while (true) {
      const child = steps.find((s) => (s.parentsIds ? s.parentsIds[0] : "bad") === parentId);

      if (!child) {
        break;
      }

      if (child.dripTemplate) {
        ordered.push({
          buildType: "config",
          ...child.dripTemplate,
        } as StepTemplateConfig);
      }

      ordered.push(child);

      parentId = child.id;
    }

    return ordered;
  };

  const handleCancel = () => {
    navigate(routes.sequence.path);
  };

  const handleDelete = () => {
    if (!currentSequence) {
      return;
    }

    deleteConfirm({})
      .then(() => {
        dispatch(updateSequence({ ...currentSequence, status: "DELETED" }));

        toast.success(`Sequence deleted successfully!`);

        navigate(routes.sequence.path);
      })
      .catch(() => {
        return;
      });
  };

  const getSequenceFormData = (): Partial<SequenceTemplate> => {
    const formData = getValues();

    const udpateSequenceData: Partial<SequenceTemplate> = {
      name: formData.name,
    };

    if (formData.schedule) {
      udpateSequenceData.schedule = {
        businessHoursEnd: formData.businessHoursEnd,
        businessHoursStart: formData.businessHoursStart,
        businessHoursTimezone: formData.businessHoursTimezone,
        daysOfWeek: formData.daysOfWeek as SequenceTriggerScheduleResponseDaysOfWeekEnum[],
      };
    }

    return udpateSequenceData;
  };

  const handleActivate = () => {
    if (!currentSequence) {
      return;
    }

    const formData = getSequenceFormData();

    dispatch(
      updateSequence({
        id: currentSequence.id,
        status: "ACTIVE",
        ...formData,
      }),
    );

    toast.success(`Sequence activated successfully!`);
  };

  const handleSave = () => {
    const formData = getSequenceFormData();

    const willCreateSequence = !currentSequence;

    if (willCreateSequence) {
      dispatch(createSequence(formData))
        .unwrap()
        .then((sequence) => {
          if (!sequence) {
            return;
          }

          navigate(routes.sequence.update.with({ id: sequence.id }));

          toast.success(`Sequence ${willCreateSequence ? "created" : "updated"}  successfully`);
        });
    } else {
      dispatch(updateSequence({ id: currentSequence.id, status: currentSequence.status, ...formData })).then(() => {
        navigate(routes.sequence.path);

        toast.success(`Sequence ${willCreateSequence ? "created" : "updated"}  successfully`);
      });
    }
  };

  const handleAddStep = (index: number) => {
    const isLastIndex = index === totalSteps - 1;

    if ((index >= 0 && !actions[index].id) || (!isLastIndex && !actions[index + 1]?.id)) {
      return;
    }

    const newSequenceData = [...actions];

    const { linkedStep: parentStep } = getLinkedStep(index + 1, "prev");

    const newStepParentId = parentStep?.id;

    const newStep = newStepParentId ? { parentsIds: [newStepParentId] } : {};

    newSequenceData.splice(index + 1, 0, newStep);

    setActions(newSequenceData);
  };

  const handleSelectActionType = (index: number, actionType: StepTemplateType) => {
    setCurrentStepIndex(index);

    const newSequenceData = [...actions];

    if (!newSequenceData[index]) {
      newSequenceData[index] = {};
    }

    newSequenceData[index].actionType = actionType;

    if (actionType === "DRIP") {
      (newSequenceData[index] as StepTemplateConfig).buildType = "config";
    }

    setActions(newSequenceData);

    setDrawerOpen(true);
  };

  const handleAddDrip = async (dripData: DripForm) => {
    setDrawerOpen(false);

    const newSequenceData = [...actions];

    const step: StepTemplateConfig = {
      ...currentStep,
      ...dripData,
      buildType: "config",
    };

    newSequenceData[currentStepIndex] = step;

    const stepToAddDrip = actions[currentStepIndex + 1];

    if (currentStepIndex !== totalSteps - 1) {
      let response;

      if (!!currentStep?.id) {
        response = await dispatch(
          updateStepTemplateDrip({
            ...dripData,
            id: currentStep.id,
            sequenceTemplateStepId: stepToAddDrip.id!,
          }),
        ).unwrap();
      } else {
        response = await dispatch(
          createStepTemplateDrip({
            ...dripData,
            sequenceTemplateStepId: stepToAddDrip.id!,
          }),
        ).unwrap();
      }

      if (!response.id) return;

      newSequenceData[currentStepIndex].id = response.id;
    } else {
      newSequenceData[currentStepIndex].id = DRAFT_DRIP_ID;
    }

    setActions(newSequenceData);
  };

  const handleRemoveDrip = async (id: string) => {
    if (id !== DRAFT_DRIP_ID) await dispatch(removeStepTemplateDrip(id));

    const index = actions.findIndex((item) => item.id === id);
    const newSequenceData = [...actions];

    newSequenceData.splice(index, 1);

    setActions(newSequenceData);

    toast.success("Drip Template Removed Successfuly.");
  };

  const handleAddAction = async (data: StepTemplate) => {
    if (!currentSequence?.id) return;

    const newSequenceData = [...actions];

    const step = { ...currentStep, ...data };

    if (currentStepIndex !== -1) {
      newSequenceData[currentStepIndex] = step;
    } else {
      newSequenceData.push(step);
    }

    const stepData = {
      id: currentStep?.id,
      sequenceTemplateId: currentSequence.id,
      type: step.actionType,
      parentsIds: step.parentsIds,
      ...(step.actionType === "CALL"
        ? { agentId: step.aiAgentId }
        : step.actionType === "EMAIL"
        ? {
            messageTemplate: step.messageTemplate,
            subjectTemplate: step.subjectTemplate,
            fromEmail: step.fromEmail,
          }
        : step.actionType === "SMS"
        ? {
            messageTemplate: step.messageTemplate,
          }
        : {}),
    };

    let response: StepTemplateResponse;

    const { linkedStep: previousStep, drip, dripIndex } = getLinkedStep(currentStepIndex, "prev");

    if (!!currentStep?.id) {
      response = await dispatch(updateStepTemplateInfo(stepData as StepTemplateRequestUpdateInfo)).unwrap();
    } else {
      const createStepData = stepData as StepTemplate;

      // TODO: For non branching sequences, this is always true
      createStepData.automaticallyUpdateParents = true;

      if (drip) {
        if (drip.id === DRAFT_DRIP_ID) {
          createStepData.dripTemplate = drip;
        } else {
          createStepData.reclaimDrip = true;
        }
      }

      createStepData.parentsIds = previousStep?.id ? [previousStep.id] : [];

      response = await dispatch(createStepTemplate(createStepData as StepTemplateRequestUpdateInfo)).unwrap();
    }

    if (!response.id) {
      toast.error("Something went wrong!");

      newSequenceData.splice(currentStepIndex, 1);
    } else {
      if (response.dripTemplate?.id) {
        newSequenceData[dripIndex!].id = response.dripTemplate.id;
      }

      newSequenceData[currentStepIndex].id = response.id;

      // TODO: Error handling
      if (data.attachments) {
        const attachments: (AttachmentResponse | File)[] = data.attachments;

        const promises = attachments.map((attachment, index) => {
          if (!(attachment as AttachmentResponse).id) {
            delete (attachment as AttachmentResponse).fileName;

            return dispatch(
              uploadAttachment({
                files: [attachment as File],
                id: response.id!,
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? 1));

                  uploadPercents.setPercent(index, percentCompleted);
                },
              }),
            ).unwrap();
          }

          return new Promise((resolve) => resolve(data));
        });

        const results = await Promise.all(promises);

        setDrawerOpen(false);

        step.attachments = results
          .map((result) => (result as StepTemplate).attachments)
          .reduce((previous, current) => {
            if (
              current &&
              previous &&
              current.every((attachment) => attachment.id) &&
              current.length >= previous.length
            )
              return current;

            return previous;
          }, []);
      }
    }

    setDrawerOpen(false);

    setActions(newSequenceData);
  };

  const removeAttachment = (index: number) => {
    const newAttachments = (currentStep as StepTemplate).attachments?.filter((_, i) => i !== index);

    const newStep: StepTemplate = { ...currentStep, attachments: newAttachments };

    const newActions = [...actions];

    newActions[currentStepIndex] = newStep;

    setActions(newActions);
  };

  const handleUpdateAction = (id: string) => {
    const index = actions.findIndex((action) => action.id === id);

    setCurrentStepIndex(index);

    setDrawerOpen(true);
  };

  const handleMoveUp = (id: string) => {
    const index = actions.findIndex((item) => item.id === id);

    const { linkedStep: prevStep, linkedStepIndex: prevIndex } = getLinkedStep(index, "prev");

    if (!prevStep) return;

    const currIndex = index;

    const currStep = actions[currIndex];

    const updtPrevStep = {
      ...currStep,
      parentsIds: prevStep ? prevStep.parentsIds : [],
    };

    const updtCurrStep = {
      ...prevStep,
      parentsIds: [updtPrevStep.id!],
    };

    dispatch(
      switchConsecutiveStepsOrder({
        stepId1: updtCurrStep.id!,
        stepId2: updtPrevStep.id!,
      }),
    );

    const updtActions = [...actions];

    updtActions[prevIndex] = updtPrevStep;
    updtActions[currIndex] = updtCurrStep;

    setActions(updtActions);
  };

  const handleMoveDown = (id: string) => {
    const index = actions.findIndex((item) => item.id === id);

    const { linkedStep: nextStep, linkedStepIndex: nextIndex } = getLinkedStep(index, "next");

    if (!nextStep) return;

    const currIndex = index;

    const currStep: StepTemplate = actions[currIndex];

    const updtCurrStep = {
      ...nextStep,
      parentsIds: currStep.parentsIds,
    };

    const updtNextStep = {
      ...currStep,
      parentsIds: [updtCurrStep.id!],
    };

    dispatch(
      switchConsecutiveStepsOrder({
        stepId1: updtCurrStep.id!,
        stepId2: updtNextStep.id!,
      }),
    );

    const updtActions = [...actions];

    updtActions[currIndex] = updtCurrStep;
    updtActions[nextIndex] = updtNextStep;

    setActions(updtActions);
  };

  const handleDuplicateAction = async (id: string) => {
    try {
      if (!currentSequence?.id) {
        return;
      }

      const index = actions.findIndex((item) => item.id === id);
      const newSequenceData = [...actions];
      const currentAction: StepTemplate = newSequenceData[index];
      const type = currentAction.actionType as StepTemplateRequestCreateTypeEnum;

      const stepData = {
        sequenceTemplateId: currentSequence.id,
        type,
        parentsIds: [id],
        ...(currentAction.delayAmount && {
          delayAmount: currentAction.delayAmount,
          delayType: currentAction.delayType,
        }),
        ...(type === "CALL"
          ? { agentId: currentAction.aiAgentId }
          : type === "EMAIL"
          ? {
              messageTemplate: currentAction.messageTemplate,
              subjectTemplate: currentAction.subjectTemplate,
              fromEmail: currentAction.fromEmail,
            }
          : type === "SMS"
          ? {
              messageTemplate: currentAction.messageTemplate,
            }
          : {}),
      };

      const response = await dispatch(createStepTemplate(stepData));

      const newStepId = (response.payload as StepTemplateResponse).id!;

      const nextStep = newSequenceData[index + 1];

      if (nextStep) {
        newSequenceData[index + 1] = { ...nextStep, parentsIds: [newStepId] };

        await dispatch(
          updateStepTemplateBehavior({
            stepTemplateId: newSequenceData[index + 1].id,
            parentsIds: [newStepId],
          }),
        );
      }

      newSequenceData.splice(index + 1, 0, { ...currentAction, parentsIds: [id], id: newStepId });

      setActions(newSequenceData);
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleSetDelay = async (id: string, delay: DelayForm) => {
    try {
      if (!currentSequence?.id) {
        return;
      }

      const index = actions.findIndex((item) => item.id === id);
      const newSequenceData = [...actions];
      const currentAction: StepTemplate = newSequenceData[index];
      const type = currentAction.actionType as StepTemplateRequestCreateTypeEnum;

      await dispatch(
        updateStepTemplateInfo({
          id,
          type,
          ...delay,
          ...(type === "CALL"
            ? { agentId: currentAction.aiAgentId }
            : {
                messageTemplate: currentAction.messageTemplate,
                subjectTemplate: currentAction.subjectTemplate,
              }),
        }),
      );

      newSequenceData[index] = { ...currentAction, ...delay } as StepTemplate;

      setActions(newSequenceData);

      toast.success("Step delay updated successfully");
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const getLinkedStep = (index: number, direction: "next" | "prev"): LinkedStepData => {
    if (direction === "next") {
      index++;
    } else {
      index--;
    }

    let currentItem = actions[index] as StepTemplateConfig;

    let drip = null;
    let dripIndex = null;

    while (currentItem && (!currentItem.id || currentItem.buildType) && inRange(index, actions.length)) {
      if (!!currentItem.buildType) {
        drip = currentItem;
        dripIndex = index;
      }

      if (direction === "next") {
        index++;
      } else {
        index--;
      }

      currentItem = actions[index] as StepTemplateConfig;
    }

    return { linkedStep: currentItem, linkedStepIndex: index, drip, dripIndex };
  };

  const handleRemoveAction = async (id: string) => {
    const index = actions.findIndex((item) => item.id === id);

    const newSequenceData = [...actions];

    const previousStepData = getLinkedStep(index, "prev");
    const nextStepData = getLinkedStep(index, "next");

    const { drip, dripIndex } = previousStepData;
    const { linkedStep: nextStep, drip: nextDrip } = nextStepData;

    if (drip && (!nextStep || nextDrip)) {
      newSequenceData.splice(dripIndex!, index - dripIndex! + 1);
    } else {
      newSequenceData.splice(index, 1);
    }

    await dispatch(removeSequenceStepTemplate(id));

    setActions(newSequenceData);

    toast.success("Step Template Removed Successfuly.");
  };

  const handleRemoveDraftAction = (index: number) => {
    const newSequenceData = [...actions];

    newSequenceData.splice(index, 1);

    setActions(newSequenceData);
  };

  if (isLoadingCurrentSequence) return <LoadingOverlay />;

  const closeDrawer = () => {
    if (!currentStep?.id) handleRemoveDraftAction(currentStepIndex);
    setDrawerOpen(false);
  };

  return (
    <Box>
      <FullLoadingOverlay loading={pendingCreateSequence} overlay={true} />
      <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", marginY: "15px" }}>
        <Box
          sx={{
            display: "flex",
            gap: "25px",
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000000",
            }}
          >
            Sequence Builder
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          {currentSequence && (
            <>
              <IconButton variant="danger" onClick={handleDelete}>
                <TrashIcon width={24} />
              </IconButton>
              <IconButton variant="secondary" onClick={handleSubmit(handleSave)} disabled={!canUpdateSequence}>
                <SaveIcon />
              </IconButton>
              <IconButton variant="secondary" onClick={() => setVersionsDrawerOpen(true)}>
                <LogIcon width={24} />
              </IconButton>
            </>
          )}

          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          {currentSequence && (
            <Button
              variant="primary"
              disabled={!totalSteps || !canUpdateSequence}
              onClick={handleSubmit(handleActivate)}
            >
              Activate
            </Button>
          )}
          {!currentSequence && (
            <Button
              variant="primary"
              onClick={handleSubmit(handleSave)}
              disabled={!canUpdateSequence || (!!currentSequence && totalSteps === 0) || !sequenceName}
            >
              {totalSteps === 0 ? "Next" : "Create Sequence"}
            </Button>
          )}
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {currentSequence && (
          <Box
            sx={{
              display: "flex",
              marginTop: 3,
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "28px",
                letterSpacing: "0.2px",
                color: "#1A2135",
                marginBottom: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Summary
              {currentSequence?.status && <StatusBadge status={currentSequence.status} />}
            </Typography>
            <Summary title="Summary" data={summarySequenceData} />
          </Box>
        )}

        <SequenceForm formMethods={formMethods} canUpdateSequence={!!canUpdateSequence} />

        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            maxWidth: "774px",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {totalSteps > 0 && (
            <Box sx={{ mt: 1 }}>
              <SequenceConnection
                canUpdateSequence={canUpdateSequence}
                isLastOne={false}
                onClick={() => handleAddStep(-1)}
              />
            </Box>
          )}
          {actions.map((action, index) => {
            return (
              <Box key={`${index}-${action.id}`}>
                {"buildType" in action ? (
                  <SequenceStepDrip
                    canUpdateSequence={!!canUpdateSequence}
                    onUpdateDrip={() => handleUpdateAction(action.id!)}
                    onRemoveDrip={() => handleRemoveDrip(action.id!)}
                    index={index}
                    drip={action}
                  />
                ) : (
                  <SequenceAction
                    {...action}
                    aiAgentId={action.aiAgentId}
                    canClose
                    canUpdateSequence={canUpdateSequence}
                    onSelectActionType={(type) => handleSelectActionType(index, type)}
                    onUpdatedAction={() => action.id && handleUpdateAction(action.id)}
                    onMoveUp={handleMoveUp}
                    onMoveDown={handleMoveDown}
                    onDuplicateAction={handleDuplicateAction}
                    onSetDelay={handleSetDelay}
                    onRemoveAction={handleRemoveAction}
                    onRemoveDraftAction={handleRemoveDraftAction}
                    index={index}
                    hiddenTypes={
                      index === totalSteps - 1 ||
                      (actions[index - 1] as StepTemplateConfig)?.buildType ||
                      (actions[index + 1] as StepTemplateConfig)?.buildType
                        ? ["DRIP"]
                        : []
                    }
                  />
                )}
                <Box sx={{ mt: 1 }}>
                  <SequenceConnection
                    canUpdateSequence={canUpdateSequence}
                    isLastOne={index + 1 === totalSteps}
                    onClick={() => handleAddStep(index)}
                  />
                </Box>
              </Box>
            );
          })}
          {currentSequence && totalSteps === 0 && (
            <SequenceAction index={0} onSelectActionType={(type) => handleSelectActionType(0, type)} />
          )}
        </Box>
      </Box>

      {currentSequence?.versions && (
        <SequenceVersions
          open={versionsDrawerOpen}
          sequence={currentSequence}
          versions={currentSequence?.versions ?? []}
          onClose={() => setVersionsDrawerOpen(false)}
        />
      )}

      {drawerOpen && currentStep ? (
        !("buildType" in currentStep) ? (
          currentStep.actionType === "EMAIL" ? (
            <DraftTemplateEmail
              isOpen={drawerOpen}
              closeDrawer={closeDrawer}
              onAddAction={handleAddAction}
              messageTemplate={(currentStep as StepTemplate).messageTemplate}
              subjectTemplate={(currentStep as StepTemplate).subjectTemplate}
              fromEmail={(currentStep as StepTemplate).fromEmail}
              attachments={(currentStep as StepTemplate).attachments}
              uploadPercents={uploadPercents}
              removeAttachment={removeAttachment}
            />
          ) : currentStep.actionType === "SMS" ? (
            <DraftTemplateSMS
              isNew={!currentStep?.id}
              messageType={currentStep?.actionType}
              isOpen={drawerOpen}
              closeDrawer={closeDrawer}
              onAddAction={handleAddAction}
              messageTemplate={(currentStep as StepTemplate).messageTemplate}
            />
          ) : currentStep.actionType === "CALL" ? (
            <CreateAiVoice isOpen={drawerOpen} closeDrawer={closeDrawer} onAddAction={handleAddAction} />
          ) : (
            <></>
          )
        ) : (
          <DraftTemplateDrip
            isOpen={drawerOpen}
            closeDrawer={closeDrawer}
            onAddAction={handleAddDrip}
            dripData={currentStep}
          />
        )
      ) : null}
    </Box>
  );
}
