import React from "react";
import { TabListProps as MuiTabListProps } from "@mui/lab";
import { Typography } from "@mui/material";
import { dateFormatMs, dateFormatTs, dateFormat } from "@/common/functions/dateFormat";

export interface TableDateProps extends MuiTabListProps {
  timestamp?: { seconds: number; nanos: number };
  nanos?: number;
  millis?: number;
  date?: string;
}

export default function TableDate({ date, nanos, millis, timestamp }: TableDateProps) {
  // TODO change to instanceof
  if (date) {
    return <Typography variant="body2">{dateFormat(date)}</Typography>;
  }

  if (nanos) {
    return <Typography variant="body2">{dateFormatMs(nanos / 1000)}</Typography>;
  }

  if (millis) {
    return <Typography variant="body2">{dateFormatMs(millis)}</Typography>;
  }

  if (timestamp) {
    return <Typography variant="body2">{dateFormatTs(timestamp)}</Typography>;
  }

  return <Typography variant="body2">Unknown</Typography>;
}
