import Box from "@mui/material/Box";

interface NotificationLabelProps {
  label: string;
  notifications: {
    label: string;
    count: number;
  };
}

// How this component works?
// Passing a label and a notification object

// example:
// label: "My label"
// notification: {
//   label: "My label",
//   count: 5
// }

// This will display:
// My label 5 (in this specific format)

export default function NotificationLabel({ label, notifications }: NotificationLabelProps) {
  const equalLabels = notifications.label === label;

  return (
    <Box
      sx={() => ({
        fontSize: "14px",
      })}
    >
      {equalLabels ? (
        <Box
          sx={() => ({
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            fontSize: "14px",
          })}
        >
          <div>{label}</div>
          <Box
            sx={(theme) => ({
              // background: theme.palette.secondary[700],
              padding: "4px 8px",
              borderRadius: "4px",
              color: theme.palette.common.white,
              fontSize: "12px",
            })}
          >
            {notifications.count}
          </Box>
        </Box>
      ) : (
        label
      )}
    </Box>
  );
}
