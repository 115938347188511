import { combineReducers } from "@reduxjs/toolkit";
import { createStepTemplateDrip } from "./createDrip";
import listDripsSlice from "./listDrips";

const dripsReducer = combineReducers({
  listDrips: listDripsSlice,
  createDrip: createStepTemplateDrip,
});

export default dripsReducer;
