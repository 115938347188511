import { Chip, Theme, useTheme } from "@mui/material";
import { ProspectStatus } from "@/types/prospects/";

import { SequenceResponseStatusEnum, TaskRequestStatusEnum } from "@/services/generated";
import { ConsentLevel } from "@/common/constants/consent-level";
import { BrandRegistrationEnum } from "@/common/constants/brand-registration";

type Props = {
  consent: boolean;
};

const ConsentStatusChip = ({ consent }: Props) => {
  const theme = useTheme();

  const defaultStyles = {
    borderRadius: "16px",
    padding: "4px 8px",
    color: "#fff",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0.2px",
    "& .MuiChip-label": {
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  };

  const getConsentStatusStyles = () => {
    if (consent) {
      return {
        ...defaultStyles,
        backgroundColor: theme.palette.mint.p,
        color: theme.palette.txt.default,
      };
    }
    return {
      ...defaultStyles,
      backgroundColor: theme.palette.icon.disabled,
      color: theme.palette.txt.default,
    };
  };

  const getBadgeColor = () => {
    return getConsentStatusStyles();
  };

  return <Chip label={consent ? "Consent" : "No Consent"} sx={() => getBadgeColor()} />;
};

export default ConsentStatusChip;
