import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";
import { AxiosPromise, AxiosResponse } from "axios";
import { KnFilter, KnLookupResponseMessageDTO, KnPageResponseMessageDTO } from "@/services/generated";
import { InboxFilterTypes } from "@/types/inbox";
import { InboxFiltersInput } from "@/pages/inbox/filters/InboxFilters";

export const pageMessageThreads = createAsyncThunk(
  "inbox/lookupMessageThreads",
  async ({
    filters,
    page,
    pageSize,
  }: {
    filters: InboxFiltersInput;
    page: number;
    pageSize: number;
  }): Promise<AxiosResponse<KnPageResponseMessageDTO>> => {
    try {
      const f: KnFilter[] = [];
      if (filters.text) {
        f.push({
          property: "text",
          options: [
            {
              values: [filters.text],
            },
          ],
        });
      }
      if (filters.updatedAtS && filters.updatedAtE) {
        const s = filters.updatedAtS;
        const e = filters.updatedAtE;
        f.push({
          property: "updatedAt",
          options: [
            {
              operator: "between",
              values: [getFilterFormattedDate(s), getFilterFormattedDate(e)],
            },
          ],
        });
      }

      return await Services.Inbox.pageOutreachThreads({
        sortDirection: "desc",
        sortBy: "updatedAt",
        filters: f,
        page: page,
        pageSize: pageSize,
      });
    } catch (error) {
      return error;
    }
  },
);

const padZeroes = (n: number, l: number) => {
  return n.toString().padStart(l, "0");
};

export const getFilterFormattedDate = (date: Date) => {
  return `${date.getFullYear()}-${padZeroes(date.getMonth() + 1, 2)}-${padZeroes(date.getDate(), 2)}`;
};

export const lookupMessages = createAsyncThunk("inbox/lookupMessages", async (prospectId: string) => {
  try {
    const messages = await Services.Inbox.lookupMessages({
      pageSize: 10,
      page: 0,
      sortDirection: "asc",
      sortBy: "createdAt",
      filters: [
        {
          property: "type",
          options: [
            {
              operator: "equal",
              values: ["sms", "email"],
            },
          ],
        },
        {
          property: "prospectId",
          options: [
            {
              operator: "equal",
              values: [prospectId],
            },
          ],
        },
      ],
    });

    return messages;
  } catch (error) {
    return error;
  }
});
