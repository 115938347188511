import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";
import { AxiosResponse } from "axios/index";
import { AiPersona } from "@/services/generated";

export const getAiPersona = createAsyncThunk(
  "aiLearning/getAiPersona",
  async (d, { rejectWithValue, dispatch }): Promise<AxiosResponse<AiPersona>> => {
    try {
      return await Services.Ai.aiPersonaGet();
    } catch (error: any) {
      return error;
    }
  },
);
