import Dropdown from "@/common/components/dropdown/Dropdown";
import Switch from "@/common/components/switch/Switch";
import { Box, Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import objectMapFields from "../objectMapFields";
import { ColumnMappingStrategyItem, DataImportSchema } from "../types";
import { capitalize } from "@/utils/capitalize";

const columnHelper = createColumnHelper<ColumnMappingStrategyItem & { id: string }>();

const useMapStepColumns = () => {
  const methods = useFormContext<DataImportSchema>();
  const importType = methods.watch("importType");

  return useMemo(
    () => [
      columnHelper.accessor("originalFileColumnName", {
        header: () => <Typography variant="body3">CSV HEADER</Typography>,
        cell: (info) => <Typography variant="body2">{info.getValue()}</Typography>,
      }),

      columnHelper.accessor("sample", {
        header: () => <Typography variant="body3">CSV_SAMPLE</Typography>,
        cell: (info) => <Typography variant="body2">{info.getValue()}</Typography>,
      }),

      columnHelper.accessor("mappedFirestoreFieldName", {
        header: () => <Typography variant="body3">MAPPED</Typography>,
        cell: (info) => <Typography variant="body2">{info.getValue() ? "Yes" : "No"}</Typography>,
      }),

      columnHelper.accessor("object", {
        header: () => <Typography variant="body3">OBJECT</Typography>,
        cell: (info) => <Typography variant="body2">{capitalize(info.getValue())}</Typography>,
      }),

      columnHelper.accessor("mappedFirestoreFieldName", {
        header: () => <Typography variant="body3">PROPERTY</Typography>,
        cell: (info) => {
          if (!importType) return null;
          return (
            <Controller
              name={`columnMappingStrategyList.${info.row.index}.mappedFirestoreFieldName`}
              control={methods.control}
              render={({ field, fieldState }) => (
                <Dropdown
                  error={fieldState.error?.message}
                  defaultValue={field.value === "<UNKNOWN>" ? "" : field.value}
                  value={field.value === "<UNKNOWN>" ? "" : field.value}
                  isSearchable
                  options={objectMapFields[importType].map((field: string) => ({
                    label: field,
                    value: field,
                  }))}
                  onChange={({ value }) => field.onChange(value)}
                />
              )}
            />
          );
        },
      }),

      columnHelper.accessor("overwriteExistingValueIfPresent", {
        header: () => <Typography variant="body3">CONFLICT STRATEGY</Typography>,
        cell: (info) => (
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Controller
              name={`columnMappingStrategyList.${info.row.index}.overwriteExistingValueIfPresent`}
              control={methods.control}
              render={({ field }) => (
                <Switch
                  {...field}
                  label={!!field.value ? "Overwrite" : "Don't overwrite"}
                  value={!!field.value}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          </Box>
        ),
      }),
    ],
    [],
  );
};

export default useMapStepColumns;
