import { createSlice } from "@reduxjs/toolkit";
import { getCampaignRegistration } from "./get";
import { CampaignRegistrationFormValues, mapCampaignRegistrationResponse } from "./mapper";
import toast from "@/lib/toast";
import { createCampaignRegistration } from "./create";
type State = {
  campaignRegistration?: CampaignRegistrationFormValues;
  loading: boolean;
  error: string | null;
};

const initialState: State = {
  campaignRegistration: undefined,
  loading: false,
  error: null,
};

const campaignRegistration = createSlice({
  name: "campaignRegistration",
  initialState,
  reducers: {
    set: (state, action) => {
      state.campaignRegistration = {
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaignRegistration.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCampaignRegistration.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.campaignRegistration = mapCampaignRegistrationResponse(payload.data);
        }
        state.loading = false;
      })
      .addCase(getCampaignRegistration.rejected, (state, { payload }) => {
        state.error = "Failed to get campaign registration";
        state.loading = false;
      })
      .addCase(createCampaignRegistration.rejected, (state, { payload }) => {
        state.error = "Failed to create campaign registration";
        state.loading = false;
        toast.error("Failed to create campaign registration");
      })
      .addCase(createCampaignRegistration.fulfilled, (state, { payload }) => {
        state.loading = false;
        toast.success("Campaign registration created successfully");
      });
  },
});

export const { set } = campaignRegistration.actions;

export default campaignRegistration.reducer;
