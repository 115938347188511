import { combineReducers } from "@reduxjs/toolkit";
import listDealsReducer from "./listDeals";
import createDealReducer from "./createDeal";
import listPipelinesReducer from "./listPipelines";
import createPipelineReducer from "./createPipeline";

const dealFlowReducer = combineReducers({
  listDeals: listDealsReducer,
  createDeal: createDealReducer,
  listPipelines: listPipelinesReducer,
  createPipeline: createPipelineReducer,
});

export default dealFlowReducer;
