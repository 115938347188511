import toast from "@/lib/toast";
import Services from "@/services";
import { KnAiAgentRequest, KnPhoneNumber, KnVoice } from "@/services/generated";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type State = {
  voices?: KnVoice[];
  isLoadingVoices?: boolean;
  phones?: KnPhoneNumber[];
  isLoadingPhones?: boolean;
  faqId?: string;
  isLoadingFaq?: boolean;
  loading?: boolean;
  error: string | null;
};

const initialState: State = {
  error: null,
};

export const getAgent = createAsyncThunk("aiVoice/getAgent", async (id: string, { rejectWithValue }) => {
  try {
    const response = await Services.AiVoice.getAiAgent(id);
    return response.data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

export const createAgent = createAsyncThunk(
  "aiVoice/createAgent",
  async (body: KnAiAgentRequest, { rejectWithValue }) => {
    try {
      const response = await Services.AiVoice.createAiAgent(body);
      return response.data;
    } catch (error) {
      console.error(error.message);
      return rejectWithValue(error.message);
    }
  },
);

export const updateAgent = createAsyncThunk(
  "aiVoice/updateAgent",
  async ({ id, data }: { id: string; data: KnAiAgentRequest }, { rejectWithValue }) => {
    try {
      const response = await Services.AiVoice.updateAiAgent(id, data);
      return response.data;
    } catch (error) {
      console.error(error.message);
      return rejectWithValue(error.message);
    }
  },
);

export const listPhoneNumbers = createAsyncThunk("aiVoice/listPhoneNumbers", async () => {
  const response = await Services.AiVoice.listTwilioPhoneNumbers();
  return response.data;
});

export const listVoices = createAsyncThunk("aiVoice/listVoices", async () => {
  const response = await Services.AiVoice.lookupAiVoices();
  return response.data;
});

export const processFaqUrl = createAsyncThunk("aiVoice/processFaqUrl", async (url: string) => {
  const response = await Services.Faq.crawl({ url });
  console.log(response);
  return response.data;
});

const upsertAgent = createSlice({
  name: "upsertAgent",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createAgent.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAgent.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(createAgent.rejected, (state, { payload }) => {
        state.loading = false;
      })

      .addCase(updateAgent.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(updateAgent.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(updateAgent.rejected, (state, { payload }) => {
        state.loading = false;
      })

      .addCase(getAgent.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getAgent.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(getAgent.rejected, (state, { payload }) => {
        state.loading = false;
      })

      .addCase(listPhoneNumbers.pending, (state, { payload }) => {
        state.isLoadingPhones = true;
      })
      .addCase(listPhoneNumbers.fulfilled, (state, { payload }) => {
        state.phones = payload;
        state.isLoadingPhones = false;
      })
      .addCase(listPhoneNumbers.rejected, (state, { payload }) => {
        state.isLoadingPhones = false;
        toast.error("Something went wrong!");
      })

      .addCase(listVoices.pending, (state, { payload }) => {
        state.isLoadingVoices = true;
      })
      .addCase(listVoices.fulfilled, (state, { payload }) => {
        state.voices = payload;
        state.isLoadingVoices = false;
      })
      .addCase(listVoices.rejected, (state, { payload }) => {
        state.isLoadingVoices = false;
        toast.error("Something went wrong!");
      })

      .addCase(processFaqUrl.pending, (state, { payload }) => {
        state.isLoadingFaq = true;
      })
      .addCase(processFaqUrl.fulfilled, (state, { payload }) => {
        state.faqId = payload.id;
        state.isLoadingFaq = false;
      });
  },
  reducers: {},
});

export default upsertAgent.reducer;
