import { Chip, useTheme } from "@mui/material";
import { StatusChipVariants } from "@/types/data-import";

type Props = {
  status: string;
};

const DataImportChip = ({ status }: Props) => {
  const theme = useTheme();

  const defaultStyles = {
    textTransform: "capitalize",
    borderRadius: "16px",
    padding: "4px 8px",
    color: "#fff",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0.2px",
    "& .MuiChip-label": {
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  };

  const styles = () => {
    switch (status) {
      case StatusChipVariants.prospects:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.mint.p,
          color: theme.palette.txt.default,
        };
      case StatusChipVariants.company:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.midnight[80],
        };
      case StatusChipVariants.product:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.commonColors.disabled,
          color: theme.palette.txt.default,
        };

      default:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        };
    }
  };

  return <Chip label={status} sx={styles} />;
};

export default DataImportChip;
