import { createSlice } from "@reduxjs/toolkit";
import { getbrandRegistration } from "./get";
import { BrandRegistrationFormValues, mapBrandRegistrationResponse } from "./mapper";
import toast from "@/lib/toast";
import { createBrandRegistration } from "./create";

type State = {
  brandRegistration?: BrandRegistrationFormValues;
  loading: boolean;
  error: string | null;
};

const initialState: State = {
  brandRegistration: undefined,
  loading: false,
  error: null,
};

const brandRegistration = createSlice({
  name: "brandRegistration",
  initialState,
  reducers: {
    set: (state, action) => {
      state.brandRegistration = {
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getbrandRegistration.pending, (state) => {
        state.loading = true;
      })
      .addCase(getbrandRegistration.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.brandRegistration = mapBrandRegistrationResponse(payload.data);
        }
        state.loading = false;
      })
      .addCase(getbrandRegistration.rejected, (state, { payload }) => {
        state.error = "Failed to get brand registration";
        state.loading = false;
      })
      .addCase(createBrandRegistration.rejected, (state, { payload }) => {
        state.error = "Failed to create brand registration";
        state.loading = false;
        toast.error("Failed to create brand registration");
      })
      .addCase(createBrandRegistration.fulfilled, (state, { payload }) => {
        state.loading = false;
        toast.success("Brand registration created successfully");
      });
  },
});

export const { set } = brandRegistration.actions;

export default brandRegistration.reducer;
