import toast from "@/lib/toast";
import Services from "@/services";
import { KnAddress, KnBqContact, KnCompany, KnLookupRequestSortDirectionEnum } from "@/services/generated";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProspectFinder } from "./types";

type State = {
  contacts?: ProspectFinder[];
  currentContact?: ProspectFinder | null;
  viewDrawerIsOpen?: boolean;
  editDrawerIsOpen?: boolean;
  totalCount?: number;
  loading: boolean;
  error: string | null;
  isCompaniesLoading?: boolean;
  companies: KnCompany[];
  jobTitles: string[];
  addresses: KnAddress[];
};

const initialState: State = {
  loading: false,
  error: null,
  isCompaniesLoading: false,
  companies: [],
  jobTitles: [],
  addresses: [],
};

type Filters = {
  prospectName?: string;
  corporateCity?: string;
  corporateState?: string;
  companyDomain?: string;
  jobTitle?: string;
  companyIndustry?: string;
  firstName?: string;
  lastName?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: KnLookupRequestSortDirectionEnum;
};

export const getProspectFinderContacts = createAsyncThunk(
  "prospects/finder/getProspectFinderContacts",
  async (filters: Filters) => {
    try {
      let filtersToRequest = Object.keys(filters)
        .filter((field: string) => !["page", "pageSize", "sortBy", "sortDirection"].includes(field))
        .map((field: string) => {
          const values = filters[field as keyof Filters];
          if (!values) return null;

          return {
            property: field,
            options: [
              {
                operator: "equal",
                values: [`${filters[field as keyof Filters]}`],
              },
            ],
          };
        })
        .filter((filter) => filter !== null);
      if (!filtersToRequest.length) {
        console.log("Add google domain! We must have one indexed filter!");
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        filtersToRequest = [
          {
            property: "companyDomain",
            options: [
              {
                operator: "equal",
                values: ["google.com"],
              },
            ],
          },
        ];
      }
      if (!filtersToRequest.length) {
        console.log("filtersToRequest is empty");
        return;
      }
      const response = await Services.ProspectFinder.lookupKnProspects({
        filters: filtersToRequest as any,
        page: filters?.page ?? 0,
        pageSize: filters?.pageSize ?? 10,
        sortBy: filters?.sortBy ?? "id",
        sortDirection: filters?.sortDirection ?? KnLookupRequestSortDirectionEnum.Desc,
      });
      return response;
    } catch (error: any) {
      return error;
    }
  },
);

export const createContactFromProspectFinder = createAsyncThunk(
  "prospects/finder/createContactFromProspectFinder",
  async (prospect: KnBqContact) => {
    try {
      const response = await Services.ProspectFinder.createContactFromProspectFinder({
        contact: prospect,
      });
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const getProspectFinderCompanies = createAsyncThunk(
  "prospects/finder/getProspectFinderCompanies",
  async (searchTerm: string) => {
    try {
      const response = await Services.ProspectFinder.keywordSearchKnCompaniesByName({
        pageSize: 10,
        searchTerm,
      });
      return response;
    } catch (error: any) {
      return error;
    }
  },
);

export const getProspectFinderJobTitles = createAsyncThunk(
  "prospects/finder/getProspectFinderJobTitles",
  async (searchTerm?: string) => {
    try {
      const response = await Services.ProspectFinder.keywordSearchMostCommonKnBqContactJobTitles({
        pageSize: 10,
        searchTerm,
      });
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const getProspectFinderAddresses = createAsyncThunk(
  "prospects/finder/getProspectFinderAddresses",
  async (searchTerm?: string) => {
    try {
      const response = await Services.ProspectFinder.keywordSearchMostCommonKnBqContactAddresses({
        pageSize: 10,
        searchTerm,
      });

      return response;
    } catch (error) {
      return error;
    }
  },
);

const listProspectFinderSlice = createSlice({
  name: "listProspectFinder",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getProspectFinderContacts.fulfilled, (state, { payload }) => {
      if (payload?.data?.results) {
        state.contacts = payload.data.results as ProspectFinder[];
        state.loading = false;
        state.totalCount = payload.data.results.length + 100; // TODO: hardcoded value until we have a proper count
      }
    });

    builder.addCase(createContactFromProspectFinder.fulfilled, (state) => {
      toast.success("Contact created successfully");
    });

    builder.addCase(createContactFromProspectFinder.rejected, (state) => {
      toast.error("Error creating contact");
    });

    builder.addCase(getProspectFinderContacts.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getProspectFinderContacts.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getProspectFinderCompanies.pending, (state) => {
      state.isCompaniesLoading = true;
    });
    builder.addCase(getProspectFinderCompanies.rejected, (state) => {
      state.isCompaniesLoading = false;
    });
    builder.addCase(getProspectFinderCompanies.fulfilled, (state, { payload }) => {
      if (payload?.data?.results) {
        state.companies = payload.data.results;
        state.isCompaniesLoading = false;
      }
    });
    builder.addCase(getProspectFinderJobTitles.fulfilled, (state, { payload }) => {
      if (payload?.data?.results) {
        state.jobTitles = payload.data.results;
      }
    });
    builder.addCase(getProspectFinderAddresses.fulfilled, (state, { payload }) => {
      if (payload?.data?.results) {
        state.addresses = payload.data.results;
      }
    });
  },
  reducers: {
    openViewDrawer: (state) => {
      state.viewDrawerIsOpen = true;
    },
    closeViewDrawer: (state) => {
      state.viewDrawerIsOpen = false;
    },
    openEditDrawer: (state) => {
      state.editDrawerIsOpen = true;
    },
    closeEditDrawer: (state) => {
      state.editDrawerIsOpen = false;
    },
  },
});

export const { openViewDrawer, closeViewDrawer, openEditDrawer, closeEditDrawer } = listProspectFinderSlice.actions;

export default listProspectFinderSlice.reducer;
