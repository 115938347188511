import FormControl from "@mui/material/FormControl";
import { closeEditDrawer } from "@/redux/reducers/prospects/companies/slices/listCompanies";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SyntheticEvent, useEffect } from "react";
import { SubmitHandler } from "react-hook-form";
import DrawerLayout from "@/layouts/DrawerLayout";
import Stack from "@mui/material/Stack";
import Button from "@/common/components/button";
import Dropdown from "@/common/components/dropdown/Dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";

import { Box } from "@mui/material";
import Input from "@/common/components/input";

import { editCompany } from "@/redux/reducers/prospects/companies/slices/listCompanies";

import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  CompanyHeadcountOptions,
  CompanyIndustryOptions,
  CompanyRevenueOptions,
} from "@/redux/reducers/prospects/companies/slices/helpers";
export default function EditCompanyForm() {
  const dispatch = useAppDispatch();
  const currentCompany = useAppSelector(({ companies }) => companies.listCompanies.currentCompany);

  const { control, clearErrors, getValues, setValue, reset } = useForm();
  const { t } = useTranslation();

  const toggleDrawer = () => dispatch(closeEditDrawer());

  useEffect(() => {
    reset({
      name: currentCompany?.name,
      industry: currentCompany?.industry,
      revenue: currentCompany?.revenue,
      headcount: currentCompany?.headcount,
    });
  }, [currentCompany, reset]);

  const onSubmit: SubmitHandler<any> = (data, event) => {
    if (!data) return;

    const company = {
      ...data,
      industry: data.industry?.value || data?.industry,
      revenue: data.revenue?.value || data?.revenue,
      headcount: data.headcount?.value || data?.headcount,
      id: currentCompany?.id,
    };
    try {
      dispatch(editCompany(company));
      toggleDrawer();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <form
      onSubmit={(event: SyntheticEvent) => {
        event.preventDefault();
        clearErrors();
        onSubmit(getValues());
      }}
    >
      <Box sx={{ height: "calc(100vh - 80px)", overflow: "scroll" }}>
        <DrawerLayout customWidth="500px" title="Edit Company" testid="newcompanyForm" closeDrawer={toggleDrawer}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <FormControl sx={{ width: "100%" }}>
              <Controller
                name="name"
                control={control}
                defaultValue={currentCompany?.name}
                render={({ field }) => (
                  <>
                    <FormLabel aria-required label="Company Name" />
                    <Input {...field} type="text" placeholder="Acme Company" />
                  </>
                )}
              />
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              <Controller
                name="industry"
                control={control}
                render={({ field }) => (
                  <>
                    <FormLabel aria-required label="Industry" />
                    <Dropdown
                      isSearchable
                      label="Industry"
                      {...field}
                      defaultValue={currentCompany?.industry}
                      options={CompanyIndustryOptions}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                    />
                  </>
                )}
              />
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              <Controller
                name="revenue"
                control={control}
                render={({ field }) => (
                  <>
                    <FormLabel aria-required label="Revenue" />
                    <Dropdown
                      isSearchable
                      {...field}
                      label="Revenue"
                      defaultValue={currentCompany?.revenue}
                      options={CompanyRevenueOptions}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                    />
                  </>
                )}
              />
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <Controller
                name="headcount"
                control={control}
                render={({ field }) => (
                  <>
                    <FormLabel aria-required label="Size HeadCounts" />
                    <Dropdown
                      isSearchable
                      label="Size HeadCounts"
                      {...field}
                      defaultValue={currentCompany?.headcount}
                      options={CompanyHeadcountOptions}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                    />
                  </>
                )}
              />
            </FormControl>
          </Box>
        </DrawerLayout>
      </Box>
      <Stack direction={{ xs: "column", md: "row" }} gap={3} padding={3} position="absolute" bottom={0} width="100%">
        <Button
          data-testid="closeTaskDrawer"
          variant="secondary"
          onClick={toggleDrawer}
          type="button"
          sx={{ width: "100%" }}
        >
          {t("tasks.cancel")}
        </Button>

        <Button type="submit" variant="primary" sx={{ width: "100%" }}>
          Save Company
        </Button>
      </Stack>
    </form>
  );
}
