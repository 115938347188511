import Checkbox from "@/common/components/checkbox/Checkbox";
import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input/Input";
import Switch from "@/common/components/switch";
import { getCurrentSequence } from "@/redux/selectors/sequences";
import { useAppSelector } from "@/redux/store";
import { Box, FormControl, Typography } from "@mui/material";
import { weekdays } from "moment";
import { useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, UseFormReturn } from "react-hook-form";
import { SequenceFormType } from "./useSequenceForm";
import moment from "moment-timezone";

type SequenceFormProps = {
  formMethods: UseFormReturn<SequenceFormType>;
  canUpdateSequence: boolean;
};

function generateTimeValues() {
  const timeValues = [];

  for (let i = 0; i < 24; i++) {
    const formattedHour = i.toString().padStart(2, "0");

    const value = `${formattedHour}:00`;

    const label = moment(value, ["HH:mm"]).format("hh:mm A");

    timeValues.push({ label, value });
  }

  return timeValues;
}

const DEFAULT_START_TIME = "08:00";
const DEFAULT_END_TIME = "17:00";
const DEFAULT_TIMEZONE = "GMT-04:00";

export default function SequenceForm({ formMethods, canUpdateSequence }: SequenceFormProps) {
  const [schedule, setSchedule] = useState(false);

  const { control, setValue } = formMethods;

  const currentSequence = useAppSelector(getCurrentSequence);

  useEffect(() => {
    const setDefaultScheduleValues = () => {
      setValue("schedule", false);
      setValue("daysOfWeek", []);
      setValue("businessHoursStart", DEFAULT_START_TIME);
      setValue("businessHoursEnd", DEFAULT_END_TIME);
      setValue("businessHoursTimezone", DEFAULT_TIMEZONE);
    };

    if (!currentSequence) {
      setDefaultScheduleValues();
      return;
    }

    setSchedule(!!currentSequence.schedule);

    setValue("schedule", !!currentSequence.schedule);
    setValue("daysOfWeek", []);
    setValue("name", currentSequence.name ?? "");

    if (currentSequence.schedule) {
      const start = moment(currentSequence.schedule.businessHoursStart, ["HH:mm:ss"]).format("HH:mm");
      const end = moment(currentSequence.schedule.businessHoursEnd, ["HH:mm:ss"]).format("HH:mm");

      setValue("businessHoursStart", start);
      setValue("businessHoursEnd", end);
      setValue("daysOfWeek", currentSequence.schedule.daysOfWeek);
      setValue("businessHoursTimezone", currentSequence.schedule.businessHoursTimezone);
    } else {
      setDefaultScheduleValues();
    }
  }, [currentSequence, setValue]);

  const timeOptions = useMemo(() => generateTimeValues(), []);

  const timezones = useMemo(() => {
    const timezones = [
      { name: "Eastern", zone: "US/Eastern", offset: "" },
      { name: "Central", zone: "US/Central", offset: "" },
      { name: "Mountain", zone: "US/Mountain", offset: "" },
      { name: "Pacific", zone: "US/Pacific", offset: "" },
      { name: "Alaska", zone: "US/Alaska", offset: "" },
      { name: "Hawaii-Aleutian", zone: "US/Hawaii", offset: "" },
      { name: "American Samoa", zone: "US/Samoa", offset: "" },
    ];

    const now = moment();

    timezones.forEach((timezone) => {
      const offset = moment.tz(now, timezone.zone).format("Z");
      timezone.offset = `GMT${offset}`;
    });

    const sortedOffsets = timezones
      .sort((a, b) => {
        const offsetA = parseInt(a.offset.replace("GMT", "").replace(":", ""));
        const offsetB = parseInt(b.offset.replace("GMT", "").replace(":", ""));

        return offsetA - offsetB;
      })
      .map((timezone) => ({
        label: timezone.name,
        value: timezone.offset,
      }));

    return sortedOffsets;
  }, []);

  return (
    <FormProvider {...formMethods}>
      <Box sx={{ width: "100%", maxWidth: "654px", marginTop: 3 }}>
        <FormControl sx={{ width: "100%" }}>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <FormLabel aria-required label="Title" />
                <Input
                  {...field}
                  placeholder="Type here your sequence name"
                  disabled={!canUpdateSequence}
                  error={fieldState?.error?.message}
                />
              </>
            )}
          />
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          maxWidth: "654px",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 500,
            marginBottom: "24px",
          }}
        >
          Business Hours
        </Typography>
        <Box>
          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="schedule"
              control={control}
              render={({ field }) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Switch
                      {...field}
                      disabled={!canUpdateSequence}
                      onChange={(_, checked) => {
                        setSchedule(!!checked);
                        field.onChange(checked);
                      }}
                    />
                    <Typography>Execute steps on business days only</Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    If enabled sequence steps will be sent on specified business days only.
                  </Typography>
                </Box>
              )}
            />
          </FormControl>
        </Box>
        {schedule && (
          <Box>
            <Box>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  name="daysOfWeek"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Box
                      sx={{
                        paddingTop: "16px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        Business Days
                      </Typography>
                      {fieldState.error?.message && (
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "red",
                          }}
                        >
                          {fieldState.error?.message}
                        </Typography>
                      )}
                      <Box
                        sx={{
                          paddingY: "16px",
                          display: "flex",
                          gap: "16px",
                        }}
                      >
                        {weekdays().map((weekday, i) => {
                          const value = weekday.toLocaleUpperCase();

                          return (
                            <Checkbox
                              {...field}
                              disabled={!canUpdateSequence}
                              key={`${i}-weekday`}
                              checked={field.value?.includes(value)}
                              label={weekday.substring(0, 2)}
                              onChange={() => {
                                if (!field.value) {
                                  return;
                                }

                                const index = field.value.indexOf(value);

                                if (index >= 0) {
                                  field.onChange(field.value.filter((_, i) => i !== index));
                                } else {
                                  field.onChange([...field.value, value]);
                                }
                              }}
                            />
                          );
                        })}
                      </Box>
                    </Box>
                  )}
                />
              </FormControl>
            </Box>
            <Box
              sx={{
                width: "480px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gap: "32px",
                  gridTemplateColumns: "repeat(2, 1fr)",
                }}
              >
                <FormControl>
                  <Controller
                    name="businessHoursStart"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Box>
                        <FormLabel aria-required label="Start Time" infoIcon />
                        <Dropdown
                          {...field}
                          isDisabled={!canUpdateSequence}
                          options={timeOptions}
                          error={fieldState.error?.message}
                          onChange={(value) => {
                            field.onChange(value.value);
                          }}
                          menuMaxHeight="200px"
                        />
                      </Box>
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Controller
                    name="businessHoursEnd"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Box>
                        <FormLabel aria-required label="End Time" infoIcon />
                        <Dropdown
                          {...field}
                          isDisabled={!canUpdateSequence}
                          options={timeOptions}
                          error={fieldState.error?.message}
                          onChange={(value) => {
                            field.onChange(value.value);
                          }}
                          menuMaxHeight="200px"
                        />
                      </Box>
                    )}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gap: "32px",
                  gridTemplateColumns: "repeat(2, 1fr)",
                }}
              >
                <FormControl>
                  <Controller
                    name="businessHoursTimezone"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Box>
                        <FormLabel aria-required label="Timezone" infoIcon />
                        <Dropdown
                          {...field}
                          isDisabled={!canUpdateSequence}
                          options={timezones}
                          error={fieldState.error?.message}
                          onChange={(value) => {
                            field.onChange(value.value);
                          }}
                          menuMaxHeight="200px"
                        />
                      </Box>
                    )}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </FormProvider>
  );
}
