import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { ChevronDown } from "heroicons-react";

type Sublink = {
  label: string;
  route: string;
  initial?: boolean;
};

type SidebarItemProps = {
  sidebarOpen: boolean;
  handleToggle: () => void;
  item: {
    label: string;
    icon: React.ReactNode;
    route: string;
    sublinks?: Sublink[];
  };
};

export default function SidebarItem(props: SidebarItemProps) {
  const { item } = props;
  const theme = useTheme();
  const location = useLocation();
  const selected = location.pathname.toLowerCase();
  const isActive = item.route === selected || (item.route !== "/" && selected.startsWith(item.route));
  const activeSublinkIndex = item.sublinks?.findIndex((sublink) => selected === sublink.route);
  const isFirstSublinkActive = activeSublinkIndex === undefined || activeSublinkIndex === -1;

  return (
    <Box
      sx={{
        borderRadius: "8px",
        "&:hover": {
          background: theme.palette.midnight[80],
        },
        "&:active": {
          background: theme.palette.midnight[80],
        },
        background: isActive ? theme.palette.midnight[80] : "transparent",
      }}
    >
      <Link key={item.label} to={item.route} style={{ textDecoration: "none", color: "white" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "8px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Box
              sx={{
                color: isActive ? theme.palette.mint.p : theme.palette.common.white,
                width: "24px",
                height: "24px",
              }}
            >
              {item.icon}
            </Box>

            {props.sidebarOpen && (
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  color: theme.palette.common.white,
                }}
              >
                {item.label}
              </Typography>
            )}
          </Box>

          {item.sublinks && props.sidebarOpen && (
            <IconButton
              sx={{
                color: theme.palette.common.white,
              }}
            >
              <ChevronDown
                style={{
                  width: "16px",
                  height: "16px",
                  color: theme.palette.common.white,
                  rotate: isActive ? "180deg" : "0deg",
                  transition: "all 0.2s ease-in-out",
                }}
              />
            </IconButton>
          )}
        </Box>
      </Link>
      {isActive && item.sublinks && (
        <Box sx={{ gap: 1, display: "flex", flexDirection: "column", marginX: "8px", paddingBottom: "8px" }}>
          {item.sublinks.map((sublink, index) => (
            <Link key={index} to={sublink.route} style={{ textDecoration: "none", color: "white" }}>
              <Box
                sx={{
                  borderRadius: "8px",
                  background:
                    selected === sublink.route || (index === 0 && isFirstSublinkActive)
                      ? theme.palette.midnight[60]
                      : "transparent",
                  borderLeft: `4px solid ${
                    selected === sublink.route || (index === 0 && isFirstSublinkActive)
                      ? theme.palette.mint.p
                      : "transparent"
                  }`,
                  marginBottom: index + 1 === item.sublinks?.length ? "8px" : "12px",
                  "&:hover": {
                    background: theme.palette.midnight[60],
                  },
                  "&:active": {
                    background: theme.palette.midnight[60],
                  },
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.common.white,
                    padding: "8px",
                    borderRadius: "8px",
                    display: "block",
                    paddingLeft: "32px",
                  }}
                >
                  {sublink.label}
                </Typography>
              </Box>
            </Link>
          ))}
        </Box>
      )}
    </Box>
  );
}
