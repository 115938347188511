import { Box, Divider } from "@mui/material";
import RolesBox from "./roles/RolesBox";
import { TitleAndSubtitleBox } from "../items";
import BasicInformation from "@/pages/settings/company/basic-information/BasicInformation";
import CrmBlock from "./crm";
import UserManagement from "./users/UserManagement";
import { useAuth0 } from "@auth0/auth0-react";
import { KnTeamUserRoleUserRoleEnum } from "@/services/generated";

export default function TeamSettings() {
  const { user } = useAuth0();
  const role = user?.team_user_role;

  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "16px",
      })}
    >
      <BasicInformation />
      <TitleAndSubtitleBox title="Roles" subtitle="Existing roles and description" />
      <RolesBox />
      <Divider />
      <TitleAndSubtitleBox title="Users" subtitle="Add or remove users to your team and assign roles" />
      <UserManagement />
      {role === KnTeamUserRoleUserRoleEnum.SalesManager && (
        <>
          <Divider />
          <TitleAndSubtitleBox
            title="Company Integrations"
            subtitle="Manage your current integrated accounts and applications"
          />
          <CrmBlock />
        </>
      )}
    </Box>
  );
}
