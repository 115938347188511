import { components } from "react-select";
import { Box } from "@mui/material";
import Checkbox from "../checkbox/Checkbox";

export const Option = (props: any) => {
  const { icon: Icon, isMulti } = props;

  return (
    <components.Option {...props}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "6px",
        }}
      >
        {isMulti ? (
          <Checkbox checked={props.isSelected} label={props.label} />
        ) : (
          <>
            {Icon && <Icon />}
            {props.label}
          </>
        )}
      </Box>
    </components.Option>
  );
};
