import { Auth0Provider } from "@auth0/auth0-react";
import config from "@/common/constants/config";

const AuthenticationProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
      authorizationParams={{
        scope: "openid profile email",
        redirect_uri: window.location.origin,
        audience: config.auth0Audience,
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthenticationProvider;
