import React from "react";
import { Button } from "@mui/material";
import { SortAscending, SortDescending } from "heroicons-react";
import { Bars4Icon } from "@heroicons/react/24/solid";

interface TableSortByProps {
  sortDirection?: string;
  onClick: () => void;
}

const TableSortBy: React.FC<TableSortByProps> = ({ sortDirection, onClick }) => (
  <Button variant="text" onClick={onClick}>
    {sortDirection === "asc" ? (
      <SortAscending width={24} color="gray" />
    ) : sortDirection === "desc" && sortDirection ? (
      <SortDescending width={24} color="gray" />
    ) : (
      <Bars4Icon width={24} color="gray" />
    )}
  </Button>
);

export default TableSortBy;
