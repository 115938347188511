/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";
import { AxiosResponse } from "axios";

export const getbrandRegistration = createAsyncThunk(
  "settings/getbrandRegistration",
  async (teamId: string, { rejectWithValue, dispatch }): Promise<AxiosResponse<any>> => {
    try {
      const response = await Services.A2p.getBrand(teamId);
      return response;
    } catch (error: any) {
      return error;
    }
  },
);
