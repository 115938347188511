import { Box } from "@mui/system";
import React, { CSSProperties } from "react";
import SidePanelHeader from "@/common/components/side-header/SidePanelHeader";
import { TaskRequestStatusEnum } from "@/services/generated";

type DrawerTaskLayoutProps = {
  children: React.ReactNode;
  title: string;
  testid?: string;
  customWidth?: string;
  closeDrawer: () => void;
  status?: TaskRequestStatusEnum;
  sx?: CSSProperties;
  isLoading?: boolean;
};

const TOTAL_DRAWER_WIDTH = "650px";

export default function DrawerLayout(props: DrawerTaskLayoutProps) {
  const { children, title, testid, customWidth, status, closeDrawer, sx, isLoading = false } = props;

  return (
    <Box
      data-testid={testid}
      sx={{
        width: customWidth ?? TOTAL_DRAWER_WIDTH,
        padding: "15px",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        gap: "16px",
        justifyContent: "space-between",
        ...sx,
      }}
    >
      <SidePanelHeader title={title} closeAction={closeDrawer} status={status} isLoading={isLoading} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflowY: "auto",
          justifyContent: "space-between",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
