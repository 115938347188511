import { Box, Theme, Typography } from "@mui/material";
import { Control, Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Input from "@/common/components/input";
import Button from "@/common/components/button";
import FormControl from "@mui/material/FormControl";
import { ReactComponent as TrashIcon } from "@/common/assets/svg/trash_icon.svg";
import Dropdown from "@/common/components/dropdown";
import React from "react";
import { ObjectionTypeOptionList } from "@/pages/ai-learning/sales-process/model/objection-type";

function ColumnHeader(props: { value: string }) {
  const value = props.value;
  return (
    <Typography
      sx={{
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "20px",
        letterSpacing: "0.2px",
      }}
    >
      {value}
    </Typography>
  );
}

export default function AiLearningSalesProcessPage(props: {
  control: Control<any>;
  onRemove?: (index: number) => void;
}) {
  const control = props.control;
  const onRemove = props.onRemove;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "salesProcess.objections",
  });
  const { t } = useTranslation();

  const handleRemove = (index: number) => {
    onRemove && onRemove(index);
    remove(index);
  };
  return (
    <>
      <Box
        sx={(theme: Theme) => ({
          borderRadius: "6px",
          padding: "16px",
          backgroundColor: theme.palette.midnight[20],
          display: "grid",
          gap: "12px",
        })}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "12fr 12fr 1fr",
            gap: "16px",
          }}
        >
          <ColumnHeader value={t("aiLearning.salesProcess.table.objectionType.title")} />
          <ColumnHeader value={t("aiLearning.salesProcess.table.objectionHandlingStrategy.title")} />
        </Box>
        {fields.map((objection, index) => (
          <Box
            key={objection.id}
            sx={(theme) => ({
              borderRadius: "6px",
              padding: "16px",
              backgroundColor: "white",
              display: "grid",
              gridTemplateColumns: "12fr 12fr 1fr",
              gap: "16px",
            })}
          >
            <Controller
              name={`salesProcess.objections.${index}.objectionType`}
              control={control}
              render={({ field, fieldState }) => (
                <FormControl>
                  <Dropdown
                    options={ObjectionTypeOptionList}
                    value={field.value}
                    onChange={(o) => field.onChange(o.value)}
                    error={fieldState.error?.message}
                  />
                </FormControl>
              )}
            />
            <Controller
              name={`salesProcess.objections.${index}.objectionHandle`}
              control={control}
              render={({ field, fieldState }) => (
                <FormControl>
                  <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} multiline />
                </FormControl>
              )}
            />
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <TrashIcon onClick={() => handleRemove(index)} width={24} />
            </Box>
          </Box>
        ))}
        <Button variant="secondary" onClick={() => append({ objection: "", objectionHandle: "" })}>
          Add Objection and Handling Strategy
        </Button>
      </Box>
    </>
  );
}
