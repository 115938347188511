import { Box } from "@mui/material";
import { SMS } from "@/types/inbox";
import { formatTime12HourFormat } from "@/utils/date";
import Typography from "@mui/material/Typography";

interface OutboundMessageProps extends SMS {
  name?: string;
}
const OutboundMessage = ({ body, occurredAt, name }: OutboundMessageProps) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "5px",
          justifyContent: "flex-start",
        }}
      >
        <Typography
          variant="body2"
          style={{
            fontWeight: 500,
          }}
        >
          {name}
        </Typography>
        <Box
          style={{
            color: "#6B747B",
            fontSize: "12px",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          {formatTime12HourFormat(occurredAt)}
        </Box>
      </Box>
      <Box
        style={{
          padding: 12,
          background: "#EAFEF3",
          borderRadius: 6,
          gap: 10,
          border: "1px solid #E7EBED",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: "#09131D",
            fontWeight: "400",
            lineHeight: "20px",
            letterSpacing: 0.2,
            wordWrap: "break-word",
          }}
        >
          {body}
        </Typography>
      </Box>
    </Box>
  );
};

export default OutboundMessage;
