import { combineReducers } from "@reduxjs/toolkit";
import listReducer from "./list";
import productReducer from "../product/slices";
import companyReducer from "../company/slices";
import prospectsReducer from "../prospect/slices";

const dataImportReducer = combineReducers({
  list: listReducer,
  product: productReducer,
  company: companyReducer,
  prospect: prospectsReducer,
});

export default dataImportReducer;
