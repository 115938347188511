import { RootState } from "@/redux/store";
import { createSelector } from "@reduxjs/toolkit";

const getRoot = (state: RootState) => state.sequences;

export const getSequences = createSelector(getRoot, (state) => state.listSequences.sequences);

export const getCurrentSequence = createSelector(getRoot, (state) => state.listSequences?.currentSequence);

export const getSequencesFilters = createSelector(getRoot, (state) => state.listSequences.filters);

export const getSequencesCount = createSelector(getRoot, (state) => state.listSequences.sequences?.length || 0);
