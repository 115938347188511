import Button from "@/common/components/button";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router";
import routes from "@/common/constants/routes";

export default function NoLeadFlowPerformanceBySource() {
  const navigate = useNavigate();

  const navigateLeadFlow = () => {
    navigate(routes.leadFlow.setup.path);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        height: "100%",
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <path
          d="M24 18V25.5M42 24C42 33.9411 33.9411 42 24 42C14.0589 42 6 33.9411 6 24C6 14.0589 14.0589 6 24 6C33.9411 6 42 14.0589 42 24ZM24 31.5H24.015V31.515H24V31.5Z"
          stroke="#54658D"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <Typography
        variant="body1"
        sx={{
          color: "#6B747B",
          textAlign: "center",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0.2px",
        }}
      >
        No lead flow configured
      </Typography>

      <Button variant="secondary" size="sm" onClick={navigateLeadFlow}>
        Configure
      </Button>
    </div>
  );
}
