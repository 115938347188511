import { Components, Theme } from "@mui/material";

declare module "@mui/material/Button" {
  // interface ButtonOwnProps {
  //   rounded?: boolean;
  //   shadowed?: boolean;
  // } this is not needed for now

  interface ButtonPropsVariantOverrides {
    primary?: true;
    secondary?: true;
  }
}

export const ButtonStyles: Components<Theme>["MuiButton"] = {
  defaultProps: {
    variant: "primary",
    size: "medium",
  },

  variants: [
    {
      props: { size: "small" },
      style: {
        padding: "8px 16px",
        fontSize: "14px",
        lineHeight: "16px",
      },
    },
    {
      props: { size: "medium" },
      style: {
        padding: "12px 24px",
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
    {
      props: { size: "large" },
      style: {
        padding: "16px 32px",
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
    {
      props: { fullWidth: true },
      style: {
        width: "100%",
      },
    },
    // {
    //   props: { shadowed: true },
    //   style: {
    //     boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    //   },
    // },
    // {
    //   props: { rounded: true },
    //   style: {
    //     borderRadius: "30px",
    //   },
    // },

    {
      props: { variant: "primary" },
      style: ({ theme }) => ({
        borderRadius: "8px",
        fontWeight: 600,
        color: theme.palette.commonColors.text,
        backgroundColor: theme.palette.btnPrimary.default,
        "&:hover": {
          backgroundColor: theme.palette.btnPrimary.hover,
        },
        "&:active": {
          backgroundColor: theme.palette.btnPrimary.pressed,
        },
        "&:disabled": {
          backgroundColor: theme.palette.btnPrimary.disabled,
        },
        "&:focus": {
          backgroundColor: theme.palette.btnPrimary.focus,
        },
      }),
    },

    {
      props: { variant: "secondary" },
      style: ({ theme }) => ({
        borderRadius: "8px",
        fontWeight: 600,
        border: `2px solid ${theme.palette.btnSecondary.default}`,
        color: theme.palette.btnSecondary.default,
        backgroundColor: "transparent",
        "&:hover": {
          borderColor: theme.palette.btnSecondary.hover,
        },
        "&:active": {
          borderColor: theme.palette.btnSecondary.pressed,
        },
        "&:disabled": {
          borderColor: theme.palette.btnSecondary.disabled,
        },
        "&:focus": {
          borderColor: theme.palette.btnSecondary.focus,
        },
      }),
    },
  ],
};
