import { Slider, styled, Typography, useTheme } from "@mui/material";
import Button from "../button/Button";
import Box from "@mui/material/Box";

const sliderWidth = 500;

export const KnSlider = styled(Slider)(({ theme }) => ({
  marginTop: 40,

  "& .MuiSlider-markLabel": {
    top: -36,
  },
  "& .MuiSlider-rail": {
    backgroundColor: theme.palette.midnight[40],
    border: "none",
    height: 2,
  },
  "& .MuiSlider-track": {
    height: 2,
  },
  "& .MuiSlider-thumb": {
    border: `2px solid ${theme.palette.mint.p}`,
    backgroundColor: theme.palette.midnight.p,
    height: 24,
    width: 24,
  },
  "& .MuiSlider-mark": {
    backgroundColor: theme.palette.midnight[40],
    borderRadius: "50%",
    height: 8,
    width: 8,
  },
  "& .MuiSlider-markActive": {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function KnSliderAiPersona({
  showRange = false,
  firstLabel,
  endLabel,
  selectedValue = 0,
  setSelectedValue,
}: {
  firstLabel: string;
  endLabel: string;
  showRange?: boolean;
  selectedValue: number;
  setSelectedValue: (value: number) => void;
}) {
  const SLIDER_MARKS = [
    { value: -5, label: 5 },
    { value: -4, label: 4 },
    { value: -3, label: 3 },
    { value: -2, label: 2 },
    { value: -1, label: 1 },
    { value: 0, label: 0 },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
  ];
  const theme = useTheme();

  const toVal = (v: number | number[]) => {
    if (Array.isArray(v)) {
      return v[0];
    }
    return v;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ flex: "1" }}>
        <KnSlider
          value={selectedValue}
          onChange={(_, value) => setSelectedValue(toVal(value))}
          min={-5}
          max={5}
          step={1}
          marks={SLIDER_MARKS}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            py: 0.5,
            px: 1,
            bgcolor: theme.palette.midnight[20],
            borderRadius: 2,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              color: theme.palette.commonColors.text,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
            onClick={() => setSelectedValue(-5)}
          >
            {firstLabel}
          </Typography>
        </Box>
        <Box
          sx={{
            py: 0.5,
            px: 1,
            bgcolor: theme.palette.midnight[20],
            borderRadius: 2,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              color: theme.palette.commonColors.text,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
            onClick={() => setSelectedValue(5)}
          >
            {endLabel}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
