import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import * as React from "react";

import { StepTemplateRequestCreateDelayTypeEnum } from "@/services/generated";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormControl } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "../button";
import Dropdown from "../dropdown";
import FormLabel from "../form-label/FormLabel";
import Input from "../input";
import ToolTip from "../tool-tip";

interface ActionSetDelayProps {
  icon?: JSX.Element;
  description: string;
  id: string;
  delay?: DelayForm;
  onSetDelay?: (delay: DelayForm) => void;
}

const schema = Yup.object({
  delayAmount: Yup.number().typeError("Invalid value").integer("Must be an integer").required("This field is required"),
  delayType: Yup.mixed<StepTemplateRequestCreateDelayTypeEnum>().required("This field is required"),
});

export type DelayForm = Yup.InferType<typeof schema>;

export default function ActionSetDelay({ icon, description, id, delay, onSetDelay }: ActionSetDelayProps) {
  const methods = useForm<DelayForm>({
    resolver: yupResolver(schema),
  });

  const { control, setValue } = methods;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSave: SubmitHandler<DelayForm> = (values) => {
    onSetDelay?.(values);
    handleClose();
  };

  useEffect(() => {
    if (delay) {
      setValue("delayAmount", delay.delayAmount);
      setValue("delayType", delay.delayType);
    }
  }, [delay, setValue]);

  const intervalUnits = useMemo(() => {
    return Object.values(StepTemplateRequestCreateDelayTypeEnum).map((value) => ({
      label: value,
      value,
    }));
  }, []);

  return (
    <>
      <ToolTip key={id} title={description} placement={"right"}>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          {icon}
        </IconButton>
      </ToolTip>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              ml: 11.23,
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 100,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "10px",
            fontSize: "30px",
          },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", padding: "4px 14px", width: "316px" }}>
          <Box sx={{ display: "flex", gap: "9px", justifyContent: "center", alignItems: "center" }}>
            <FormProvider {...methods}>
              <form>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "16px",
                  }}
                >
                  <FormControl>
                    <Controller
                      name="delayType"
                      control={control}
                      render={({ field, fieldState }) => (
                        <>
                          <FormLabel label="Length" />
                          <Dropdown
                            {...field}
                            options={intervalUnits}
                            error={fieldState.error?.message}
                            isClearable={false}
                            onChange={(value) => {
                              field.onChange(value.value);
                            }}
                            menuMaxHeight="200px"
                          />
                        </>
                      )}
                    />
                  </FormControl>
                  <FormControl>
                    <Controller
                      name="delayAmount"
                      control={control}
                      render={({ field, fieldState }) => (
                        <>
                          <FormLabel label="Duration" />
                          <Input {...field} placeholder="Duration" error={fieldState.error?.message} />
                        </>
                      )}
                    />
                  </FormControl>
                </Box>
              </form>
            </FormProvider>
          </Box>
          <Box sx={{ display: "flex", gap: "8px", justifyContent: "end", alignItems: "center" }}>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={methods.handleSubmit(handleSave)}>
              Save
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
}
