import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from "@mui/material";

export interface TooltipProps extends MuiTooltipProps {
  children: React.ReactElement<any, any>;
}

export default function Tooltip(props: TooltipProps) {
  const { children, ...rest } = props;
  return <MuiTooltip {...rest}>{children}</MuiTooltip>;
}
