import { KnDeal } from "@/services/generated";
import { DealCard } from "./types";

export const getFormattedDeal = (deal: KnDeal): DealCard => ({
  id: deal.id!,
  assignee: { id: deal.assignee!.id!, name: deal.assignee!.name!, avatar: deal.assignee!.imageUrl! },
  pipelineId: deal.pipelineId!,
  name: deal.name!,
  // TODO: Add heat score
  heatScore: 0,
  stage: { id: deal.pipelineStage!.id!, name: deal.pipelineStage!.name! },
  lineItems: deal.lineItems!.map(({ id, quantity, name, price }) => ({
    id: id!,
    quantity: parseInt(quantity!),
    price: parseFloat(price!),
    name: name!,
  })),
  price: deal.amount!,
  // TODO: remove any
  contactList: (deal as any).contactList || [],
  updatedAt: new Date(deal.updatedAt!),
});
