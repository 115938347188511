import { Box, Typography } from "@mui/material";
import { SMS } from "@/types/inbox";
import { formatTime12HourFormat } from "@/utils/date";

const InboundMessage = ({ body, occurredAt, from }: SMS) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "5px",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          variant="body2"
          style={{
            fontWeight: 500,
          }}
        >
          {from}
        </Typography>
        <Box
          style={{
            color: "#6B747B",
            fontSize: "12px",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          {formatTime12HourFormat(occurredAt)}
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          alignSelf: "stretch",
          padding: "12px",
          borderRadius: "6px",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "10",
          background: "#F4F7F9",
          display: "inline-flex",
          border: "1px solid #E7EBED",
        })}
      >
        <Typography
          sx={{
            fontWeight: "400",
            lineHeight: "20px",
            letterSpacing: 0.2,
            wordWrap: "break-word",
          }}
        >
          {body}
        </Typography>
      </Box>
    </Box>
  );
};

export default InboundMessage;
