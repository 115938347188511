import { Box, Typography, useTheme } from "@mui/material";
import Button from "@/common/components/button";
import React, { useRef, useState } from "react";
import PhotoIcon from "@heroicons/react/24/outline/PhotoIcon";

export type FileUploadProps = {
  label: string;
  iconPlaceholderUrl?: string;
  onFileSelect: (file: File) => void; // New prop to handle file selection
  prevFile?: File | null;
};

export default function FileUpload({ label, iconPlaceholderUrl, prevFile, onFileSelect }: FileUploadProps) {
  const theme = useTheme();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(iconPlaceholderUrl || null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const newFileUrl = getFileUrl(file);
      setFileUrl(newFileUrl);
      onFileSelect(file);
    }
  };

  const getFileUrl = (file: File) => {
    const newFileUrl = URL.createObjectURL(file);
    return newFileUrl;
  };

  const IconPlaceholder = () => {
    return fileUrl ? (
      <Box
        component="img"
        src={fileUrl}
        alt="Uploaded icon"
        sx={{
          width: 48,
          height: 48,
          borderRadius: 24,
          objectFit: "cover",
        }}
      />
    ) : prevFile ? (
      <Box
        component="img"
        src={getFileUrl(prevFile)}
        alt="Uploaded icon"
        sx={{
          width: 48,
          height: 48,
          borderRadius: 24,
          objectFit: "cover",
        }}
      />
    ) : (
      <Box
        sx={{
          width: 48,
          height: 48,
          borderRadius: 24,
          backgroundColor: theme.palette.midnight[20],
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PhotoIcon width={24} />
      </Box>
    );
  };

  return (
    <Box sx={{ display: "flex", gap: "8px", flexDirection: "column" }}>
      {label && (
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
          }}
        >
          {label}
        </Typography>
      )}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {IconPlaceholder()}
          <Typography>PNG, JPEG under 5MB</Typography>
        </Box>
        <Button variant="secondary" onClick={handleButtonClick}>
          Select an image
        </Button>
      </Box>
      <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
    </Box>
  );
}
