import Services from "@/services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { TaskRequest, TaskDto } from "@/services/generated";
import { AxiosResponse } from "axios";
import { uploadAttachments } from "../../uploadAttachments";

export const updateTask = createAsyncThunk(
  "tasks/updateTask",
  async ({ taskId, updatedTaskData }: { taskId: string; updatedTaskData: TaskRequest }, { rejectWithValue }) => {
    try {
      if (!taskId) return;

      const uploadedAttachments = await uploadAttachments(updatedTaskData);
      if (uploadedAttachments.length > 0) updatedTaskData.attachments = uploadedAttachments;

      const response: AxiosResponse<TaskDto> = await Services.Tasks.updateTask(taskId, updatedTaskData);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
