import Services from "@/services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { TaskRequest } from "@/services/generated";
import { uploadAttachments } from "../../uploadAttachments";

export const createTask = createAsyncThunk(
  "tasks/createTask",
  async ({ newTask }: { newTask: TaskRequest }, { rejectWithValue }) => {
    try {
      const uploadedAttachments = await uploadAttachments(newTask);
      newTask.attachments = uploadedAttachments;

      const createdTask = await Services.Tasks.createTask(newTask);

      return createdTask.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
