import { Box } from "@mui/system";
import { Typography, useTheme } from "@mui/material";
import { Icon } from "@/pages/tasks/helpers";
import { TaskRequestTypeEnum } from "@/services/generated";

export default function TaskTableType(props: { action: string; prospectName: string }) {
  const { action, prospectName } = props;
  const theme = useTheme();

  const typeAction = (action: string) => {
    switch (action) {
      case TaskRequestTypeEnum.Call:
        return "Call";
      case TaskRequestTypeEnum.ApproveAiMessage:
        return "Approve message to";
      case TaskRequestTypeEnum.AttendMeeting:
        return "Meet with";
      case TaskRequestTypeEnum.Other:
        return "Other with";
      default:
        return "Other with";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: "12px",
        alignItems: "center",
      }}
    >
      <Icon action={action} />
      <Box>
        <Box
          sx={{
            display: "flex",
            gap: "4px",
          }}
        >
          <Typography variant="body2">{typeAction(action)}</Typography>

          <Typography variant="body2">{prospectName}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
