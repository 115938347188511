import FormControl from "@mui/material/FormControl";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect, useMemo } from "react";
import FormLabel from "@/common/components/form-label/FormLabel";
import Dropdown from "@/common/components/dropdown/Dropdown";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import Input from "@/common/components/input";
import Avatar from "@/common/components/avatar/Avatar";
import { getPipelineDetails, getPipelines } from "@/redux/reducers/dealFlow/slices/listPipelines";
import { getTeamUsers } from "@/redux/reducers/team/slices/listUsers";

interface Option {
  label: string;
  value: string;
}

export default function CreateDealForm() {
  const dispatch = useAppDispatch();
  const { control, watch } = useFormContext();
  const pipelines = useAppSelector(({ dealFlow }) => dealFlow.listPipelines.pipelines) || [];
  const pipelineId = watch("action.dealFlowId");
  const users = useAppSelector(({ team }) => team.listUsers.users);

  useEffect(() => {
    dispatch(getPipelines());
  }, [dispatch]);

  const pipelineOptions: Option[] = useMemo(() => {
    return pipelines.map((p) => {
      return {
        label: p.name,
        value: p.id,
      };
    });
  }, [pipelines]);

  useEffect(() => {
    if (pipelineId) {
      dispatch(getPipelineDetails(pipelineId));
    }
  }, [dispatch, pipelineId]);

  const pipelineStageOptions: Option[] = useMemo(() => {
    const pipelineStages = pipelines.find((p) => p.id === pipelineId)?.stages || [];
    return pipelineStages.map((s) => {
      return {
        label: s.name,
        value: s.id,
      };
    });
  }, [pipelines, pipelineId]);

  useEffect(() => {
    if (users === undefined) {
      dispatch(getTeamUsers());
    }
  }, [users, dispatch]);

  const assigneesOptions = useMemo(
    () =>
      (users || []).map((assignee) => ({
        icon: () => <Avatar src={assignee.imgUrl} size="small" />,
        value: assignee.user_id!,
        label: assignee.firstName! + " " + assignee.lastName!,
      })),
    [users],
  );

  // const handleInputChangeContact = useMemo(
  //   () =>
  //     debounce((value) => {
  //       if (value.length < 1) return;
  //       dispatch(getProspectsByLastName({ lastName: value }));
  //     }, 500),
  //   [dispatch],
  // );

  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Controller
        name="action.name"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Deal Name" aria-required />
            <Input
              placeholder="Deal Name"
              value={field.value}
              onChange={field.onChange}
              error={fieldState.error?.message}
            />
          </FormControl>
        )}
      />
      <Controller
        name="action.dealFlowId"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <FormControl>
              <FormLabel label="Pipeline" aria-required />
              <Dropdown
                placeholder="Pipeline"
                options={pipelineOptions}
                value={field.value}
                onChange={(option) => field.onChange(option.value)}
                error={fieldState.error?.message}
              />
            </FormControl>
          );
        }}
      />
      <Controller
        name="action.stageId"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Deal Stage" aria-required />
            <Dropdown
              placeholder="Select Deal Stage"
              options={pipelineStageOptions}
              value={field.value}
              onChange={(option) => field.onChange(option.value)}
              error={fieldState.error?.message}
            />
          </FormControl>
        )}
      />
      <Controller
        name="action.assigneeId"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <FormControl>
              <FormLabel label="Deal Assignee" aria-required />
              <Dropdown
                placeholder="Select Deal Assignee"
                options={assigneesOptions}
                value={field.value}
                onChange={(option) => field.onChange(option.value)}
                error={fieldState.error?.message}
              />
            </FormControl>
          );
        }}
      />

      {/*<FormControl sx={{ width: "100%" }}>*/}
      {/*  <Controller*/}
      {/*    name="lineItems"*/}
      {/*    control={control}*/}
      {/*    render={({ field }) => (*/}
      {/*      <LineItemForm*/}
      {/*        lineItems={currentDeal?.lineItems}*/}
      {/*        onChangeLineItems={(lineItems) => field.onChange(lineItems)}*/}
      {/*        errors={formState.errors.lineItems}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  />*/}
      {/*</FormControl>*/}
    </Box>
  );
}
