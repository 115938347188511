import { DealCard, DealFiltersInput } from "@/redux/reducers/dealFlow/slices/types";
import { RootState } from "@/redux/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectAllCards = (state: RootState) => state.dealFlow.listDeals.cards;

export const selectDealCardsByFilters = createSelector(
  [selectAllCards, (state, filters) => filters],
  (cards: DealCard[], filters: DealFiltersInput) => {
    if (cards === undefined) return [];
    const { selectedStage, assigneeId } = filters;

    let filteredData = cards;

    if (selectedStage) {
      filteredData = filteredData.filter((card: DealCard) => card.stage.id && selectedStage.includes(card.stage.id));
    }

    if (assigneeId) {
      filteredData = filteredData.filter((card: DealCard) => card.assignee?.id === assigneeId);
    }

    return filteredData;
  },
);
