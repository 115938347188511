import FormControl from "@mui/material/FormControl";
import { closeEditTaskDrawer } from "@/redux/reducers/tasks/slices/listTask";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SyntheticEvent, useCallback, useEffect } from "react";
import { SubmitHandler } from "react-hook-form";
import { updateTask } from "@/redux/reducers/tasks/slices/updateTask";
import DrawerLayout from "@/layouts/DrawerLayout";
import Button from "@/common/components/button";
import Dropdown from "@/common/components/dropdown/Dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import { Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getTeamUsers } from "@/redux/reducers/team/slices/listUsers";
import { closeAssigneeDrawer } from "@/redux/reducers/tasks/slices/listTask";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import DrawerButtonsLayout from "@/layouts/DrawerButtonsLayout";
import Box from "@mui/material/Box";

export default function AssigneeTaskForm() {
  const dispatch = useAppDispatch();
  const currentTask = useAppSelector(({ tasks }) => tasks.listTask.currentTask);
  const users = useAppSelector((selector) => selector.team.listUsers.users);
  const methods = useForm({
    defaultValues: {
      assignee: currentTask?.assignee?.id,
    },
  });
  const { control, handleSubmit, reset } = methods;

  const { t } = useTranslation();

  const toggleDrawer = () => dispatch(closeEditTaskDrawer());

  useEffect(() => {
    if (currentTask) {
      reset({
        assignee: currentTask.assignee?.id,
      });
    }
  }, [currentTask, reset]);

  useEffect(() => {
    if (users === undefined) {
      dispatch(getTeamUsers());
    }
  }, [users, dispatch]);

  const onSubmit: SubmitHandler<any> = useCallback(
    (data) => {
      if (!currentTask) return;
      if (!data?.assignee) {
        methods.setError("assignee", { type: "required", message: "Assignee is required" });
        return;
      }

      const assigneeId = data.assignee.value;

      try {
        const updateData = {
          taskId: currentTask.id!,
          updatedTaskData: {
            assigneeId,
          },
        } as any;

        dispatch(updateTask(updateData));

        dispatch(closeAssigneeDrawer());
      } catch (e) {
        console.error(e);
      }
    },
    [users, currentTask, dispatch],
  );

  return (
    <form>
      <DrawerLayout title="Update Assignee" closeDrawer={toggleDrawer}>
        <>
          <Box>
            {!currentTask ? (
              <LoadingSpinner loading={true} />
            ) : (
              <>
                <FormControl sx={{ width: "100%" }}>
                  <Controller
                    name="assignee"
                    control={control}
                    render={({ field, fieldState }: { field: any; fieldState: any }) => {
                      return (
                        <>
                          <FormLabel aria-required label="Assignee" />
                          <Dropdown
                            label="Assignee"
                            options={(users || []).map((user) => ({
                              label: `${user.firstName!} ${user.lastName!}`,
                              value: user.user_id!,
                            }))}
                            error={fieldState.error?.label?.message}
                            {...field}
                          />
                        </>
                      );
                    }}
                  />
                </FormControl>

                <Typography variant="body2">New assignee will be applied to all tasks selected</Typography>
              </>
            )}
          </Box>

          <DrawerButtonsLayout>
            <Button data-testid="closeTaskDrawer" variant="secondary" fullWidth onClick={toggleDrawer}>
              {t("tasks.cancel")}
            </Button>

            <Button
              type="button"
              variant="primary"
              fullWidth
              onClick={(e: SyntheticEvent) => {
                e.preventDefault();
                handleSubmit(onSubmit)();
              }}
            >
              Update Assignee
            </Button>
          </DrawerButtonsLayout>
        </>
      </DrawerLayout>
    </form>
  );
}
