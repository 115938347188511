import Services from "@/services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type State = {
  deals?: any;
  dealsByContactId?: any;
};

const initialState: State = {};

export const getDealsList = createAsyncThunk("deals/getDealsList", async () => {
  try {
    const response = await Services.Deals.getDealsList({
      sortBy: "CREATED_AT",
      sortDirection: "ASC",
      pageSize: 10,
      filters: [],
    });

    return response;
  } catch (error: any) {
    return error;
  }
});

export const getDealsListByContactId = createAsyncThunk("deals/getDealsListByContactId", async (contactId: string) => {
  try {
    const response = await Services.Deals.getDealsList({
      filters: [
        {
          property: "CONTACT_ID",
          options: [
            {
              operator: "contains",
              values: [contactId],
            },
          ],
        },
      ],
    });

    return response;
  } catch (error: any) {
    return error;
  }
});

const listDealsSlice = createSlice({
  name: "listDeals",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDealsList.fulfilled, (state, { payload }) => {
      state.deals = payload.data;
    });

    builder.addCase(getDealsListByContactId.fulfilled, (state, { payload }) => {
      state.dealsByContactId = payload.data;
    });
  },
  reducers: {},
});

export default listDealsSlice.reducer;
