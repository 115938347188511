import Services from "@/services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Trigger, TriggerRequest } from "@/services/generated";
import { AxiosResponse } from "axios";

export const updateTrigger = createAsyncThunk(
  "triggers/updateTrigger",
  async ({ id, data }: { id: string; data: TriggerRequest }, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<Trigger> = await Services.Triggers.updateTrigger(id, data);
      return response.data;
    } catch (error: any) {
      console.error(error);
    }
  },
);
