// team
import { GuardProps } from "@/common/components/route-guard/types";
import routes from "@/common/constants/routes";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import { useAppSelector } from "@/redux/store";

/**
 * Enforce user has a team
 */
export const TeamGuard = ({ children }: GuardProps) => {
  const { user, isLoading } = useAuth0();
  const { team } = useAppSelector(({ settings }) => settings.team);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (user && !user?.team_id && !team) {
    return <Navigate to={routes.onboard.team.path} />;
  }

  return children;
};
