import { Box, Typography } from "@mui/material";

export default function NoData({ message }: { message: string }) {
  return (
    <Box
      sx={{
        border: "1px solid #9FA9C0",
        background: "#fff",
        borderRadius: "8px",
        padding: "32px 16px",
        width: "316px",
        margin: "auto",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          color: "#1A2135",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0.2px",
        }}
      >
        {message}
      </Typography>
    </Box>
  );
}
