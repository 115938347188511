import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { v4 as uuidv4 } from "uuid";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { DragIndicator as DragIndicatorIcon } from "@mui/icons-material";
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import Button from "@/common/components/button";
import { ColorPicker } from "@/common/components/color-picker";
import useConfirmModal from "@/common/hooks/useConfirmModal";
import { PipelineStage, PipelineStageItem } from "@/redux/reducers/dealFlow/slices/types";
import Input from "@/common/components/input";
import { useTranslation } from "react-i18next";
import { ReactComponent as TrashIcon } from "@/common/assets/svg/trash_icon.svg";
import { PlusIcon } from "@heroicons/react/24/outline";
import ViewAll from "@/common/components/view-all/ViewAll";
import Switch from "@/common/components/switch";

type DraggableItemProps = {
  item: PipelineStageItem;
  index: number;
  onChange: (id: string, item: PipelineStageItem) => void;
  removeItem: (id: string) => void;
  moveItem: (fromIndex: number, toIndex: number) => void;
};

const DraggableItem = ({ item, onChange, index, moveItem }: DraggableItemProps) => {
  const { id, color = "#000", name, description, close } = item;
  const { deleteConfirm } = useConfirmModal();

  const theme = useTheme();
  const [, drag] = useDrag({
    type: "ITEM",
    item: { id, index },
  });

  const [, drop] = useDrop({
    accept: "ITEM",
    drop: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{
        backgroundColor: theme.palette.common.white,
        alignItems: "center",
        borderRadius: 2,
        display: "flex",
        padding: "8px",
        margin: "4px",
        gap: 1,
      }}
    >
      <DragIndicatorIcon sx={{ color: theme.palette.icon.default, cursor: "move" }} />
      <Box sx={{ gap: 2, display: "flex", width: "100%", alignItems: "center" }}>
        <Input
          sx={{ color: theme.palette.txt.default }}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(id, { ...item, name: e.target.value, updated: true })
          }
          value={name}
        />
        <Input
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(id, { ...item, description: e.target.value, updated: true })
          }
          value={description}
        />
        <Switch value={!!close} onChange={(e, checked) => onChange(id, { ...item, close: checked, updated: true })} />
        <ColorPicker initialColor={color} onColorChange={(color) => onChange(id, { ...item, color, updated: true })} />
        <Box
          sx={{ display: "flex", cursor: "pointer", m: 0, p: 0 }}
          onClick={() => {
            deleteConfirm({})
              .then(() => {
                onChange(id, { ...item, deleted: true });
              })
              .catch(() => {
                return;
              });
          }}
        >
          <TrashIcon width={28} height={28} color={theme.palette.icon.danger} />
        </Box>
      </Box>
    </div>
  );
};

type EditStagesProps = {
  stages: PipelineStageItem[];
  onEditStages: (stages: PipelineStageItem[]) => void;
  onCancel: () => void;
  canSaveOrCreate?: boolean;
};

export default function EditStages({ stages, onEditStages, onCancel, canSaveOrCreate }: EditStagesProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [items, setItems] = useState<PipelineStageItem[]>(stages);

  useEffect(() => {
    setItems(stages);
  }, [stages]);

  const moveItem = (fromIndex: number, toIndex: number) => {
    const newItems = [...items];
    const [movedItem] = newItems.splice(fromIndex, 1);
    newItems.splice(toIndex, 0, movedItem);
    setItems(newItems);
  };

  const removeItem = (id: string) => {
    const newItems = [...items];
    const index = items.findIndex((item) => item.id === id);
    if (index === undefined) return;
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const onChangeItem = (id: string, item: PipelineStage) => {
    const newItems = [...items];
    const index = items.findIndex((item) => item.id === id);
    if (index === undefined) return;
    newItems.splice(index, 1, item);
    setItems(newItems);
  };

  return (
    <Box sx={{ py: 2, px: 3, gap: 2, display: "flex", flexDirection: "column" }}>
      <Box>
        <Typography sx={{ fontSize: "18px", fontWeight: "bold", color: "#000000" }}>Board customization</Typography>
        <Typography sx={{ fontSize: "16px", color: theme.palette.grey[700], fontWeight: 400 }}>
          Customize what users can see when viewing the pipeline board
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: theme.palette.bg.default, p: 2, borderRadius: 1 }}>
        <DndProvider backend={HTML5Backend}>
          <Box
            sx={{
              color: theme.palette.grey[700],
              display: "flex",
              textTransform: "uppercase",
              alignItems: "center",
              width: "100%",
              mx: "4px",
              mb: "12px",
            }}
          >
            <Box sx={{ display: "flex", flex: 1, color: "#000000", fontWeight: 500 }}>Stage Name</Box>
            <Box sx={{ display: "flex", flex: 1, color: "#000000", mr: -5, fontWeight: 500 }}>
              Optional Stage Description
            </Box>
            <Box sx={{ display: "flex", flex: "none", width: 135, color: "#000000", fontWeight: 500 }}>Close</Box>
          </Box>
          {items
            .filter((item) => !item.deleted)
            .map((item, index) => (
              <DraggableItem
                key={item.id}
                item={item}
                onChange={onChangeItem}
                index={index}
                moveItem={moveItem}
                removeItem={removeItem}
              />
            ))}
        </DndProvider>
        <ViewAll
          sx={{ mt: 2, mx: "4px" }}
          onClick={() => {
            setItems((items) => [
              ...items,
              {
                name: "New stage",
                description: "Description",
                color: "#000",
                id: uuidv4(),
                new: true,
              },
            ]);
          }}
          leftIcon={<PlusIcon height={16} color="#fff" />}
        >
          Add Stage
        </ViewAll>
      </Box>
      <Stack direction={{ xs: "column", md: "row" }} justifyContent="end" gap={1} my={1}>
        <Button
          variant="secondary"
          size="sm"
          onClick={(e: SyntheticEvent) => {
            e.preventDefault();
            onCancel();
          }}
        >
          {t("dealFlow.actions.editPipeline.cancel")}
        </Button>

        <Button
          size="sm"
          type="button"
          variant="primary"
          disabled={!canSaveOrCreate}
          onClick={(e: SyntheticEvent) => {
            e.preventDefault();
            onEditStages(items);
          }}
        >
          {t("dealFlow.actions.editPipeline.save")}
        </Button>
      </Stack>
    </Box>
  );
}
