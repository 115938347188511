import toast from "@/lib/toast";
import Services from "@/services";
import { LeadQualifying } from "@/types/lead-flow";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type State = {
  loading: boolean;
  leadReports?: LeadQualifying[];
};

const initialState: State = { loading: false };

export const getLeadFlowReports = createAsyncThunk("getReports/getLeadFlowReports", async (_, { rejectWithValue }) => {
  try {
    const response = await Services.LeadFlow.reportLeadFlow();
    if (!response?.data?.data || response.status !== 200) {
      return [];
    }
    return response.data.data.map((report) => ({
      id: report.id!,
      total: report.total!,
      updatedAt: report.updated_at!,
      valid: report.valid!,
    }));
  } catch (error) {
    toast.error("Something went wrong!");
    return rejectWithValue(error);
  }
});

const leadDataSlice = createSlice({
  name: "getReports",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getLeadFlowReports.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLeadFlowReports.fulfilled, (state, action: PayloadAction<LeadQualifying[]>) => {
      const { payload } = action;
      state.leadReports = payload;
      state.loading = false;
    });
    builder.addCase(getLeadFlowReports.rejected, (state) => {
      state.leadReports = [];
      state.loading = false;
    });
  },
  reducers: {},
});

export default leadDataSlice.reducer;
