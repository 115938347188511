import { Box, Typography, useTheme } from "@mui/material";
import { X } from "heroicons-react";
import { isEqual } from "lodash";
import { useRef } from "react";
import Select, { ClearIndicatorProps, GroupBase } from "react-select";
import { SelectComponents } from "react-select/dist/declarations/src/components";
import { Control } from "./Control";
import { DropdownIndicator } from "./DropdownIndicator";
import { Option } from "./Option";
import { SingleValue } from "./SingleValue";
import { getDropdownStyles } from "./styles";
import useDropdownState from "./useDropdownState";

type DropdownOptions = {
  label?: string;
  value?: object | string | number | boolean;
  icon?: any;
  isDisabled?: boolean;
}[];

export type DropdownProps = {
  ref?: any;
  label?: string;
  required?: boolean;
  options: DropdownOptions;
  isMulti?: boolean;
  isSearchable?: boolean;
  onChange?: (value: any) => void;
  defaultValue?: string | number | boolean;
  value?: string | number | boolean | string[];
  placeholder?: string;
  isDisabled?: boolean;
  error?: string | undefined | null;
  isPending?: boolean;
  isClearable?: boolean;
  placeholderIcon?: JSX.Element;
  onInputChange?: (inputValue: string) => void | undefined;
  menuPlacement?: "auto" | "top" | "bottom";
  menuMinWidth?: string;
  hasBottomBorders?: boolean;
  hideDropDownIcon?: boolean;
  hideBorder?: boolean;
  openLeft?: boolean;
  menuMaxHeight?: string;
  hideSelectedValue?: boolean;
};

const Dropdown = ({
  placeholder = "Select",
  placeholderIcon,
  options,
  isMulti = false,
  isSearchable = false,
  isDisabled = false,
  onChange = () => null,
  menuPlacement = "auto",
  value,
  error,
  isPending,
  isClearable = true,
  onInputChange = undefined,
  menuMinWidth = "172px",
  hasBottomBorders = true,
  hideDropDownIcon = false,
  hideBorder = false,
  openLeft = false,
  menuMaxHeight,
  hideSelectedValue = false,
}: DropdownProps) => {
  const theme = useTheme();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { menuIsOpen, handleMenuOpen, setMenuIsOpen } = useDropdownState({ dropdownRef, isMulti });

  const handleOnChange = (option: DropdownOptions) => {
    if (option) {
      onChange(option);
    } else {
      onChange({ value: "" });
    }
  };

  const ClearIndicator = (props: ClearIndicatorProps) => {
    const {
      children = (
        <X
          size={16}
          style={{
            color: theme.palette.text.secondary,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        />
      ),

      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div {...restInnerProps} ref={ref}>
        <div style={{ padding: "0px 5px " }}>{children}</div>
      </div>
    );
  };

  const selectValue = isMulti
    ? options.filter((opt) => Array.isArray(value) && value.includes(opt?.value?.toString() ?? ""))
    : options.find((opt) => {
        if (typeof opt.value === "object") {
          return isEqual(opt.value, value);
        }

        return opt.value === value;
      });

  const components: Partial<SelectComponents<unknown, boolean, GroupBase<unknown>>> = {
    Option,
    DropdownIndicator: hideDropDownIcon ? null : DropdownIndicator,
    ClearIndicator,
    ...(hideSelectedValue && {
      SingleValue,
    }),
    ...(!isSearchable && {
      Control: (props) => {
        return <Control {...props} placeholderIcon={placeholderIcon} />;
      },
    }),
  };

  return (
    <Box ref={dropdownRef}>
      <Select
        menuIsOpen={menuIsOpen}
        onMenuOpen={handleMenuOpen}
        onMenuClose={() => setMenuIsOpen(false)}
        isClearable={isClearable}
        value={selectValue}
        onChange={handleOnChange}
        onInputChange={(isSearchable && !!onInputChange && onInputChange) || undefined}
        options={options}
        isSearchable={isSearchable}
        isLoading={isPending}
        isDisabled={isDisabled}
        placeholder={placeholder}
        closeMenuOnSelect={!isMulti}
        isMulti={isMulti}
        hideSelectedOptions={!isMulti}
        backspaceRemovesValue={!isMulti}
        menuPlacement={menuPlacement}
        styles={getDropdownStyles(
          theme,
          error,
          menuMinWidth,
          isMulti,
          hasBottomBorders,
          hideBorder,
          openLeft,
          menuMaxHeight,
        )}
        components={components}
      />
      {error && (
        <Typography variant="body2" style={{ color: theme.palette.commonColors.danger, fontSize: "12px" }}>
          This field is required
        </Typography>
      )}
    </Box>
  );
};

export default Dropdown;
