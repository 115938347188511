import GenericDialog from "@/common/components/genericDialog/GenericDialog";
import routes from "@/common/constants/routes";
import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router";

interface MissingTwilioContextInterface {
  TwilioWarn: () => JSX.Element;
  triggerTwilioWarn: (sequenceName: string) => void;
  closeTwilioWarn: () => void;
}

export const MissingTwilioContext = createContext<MissingTwilioContextInterface | null>(null);

interface MissingTwilioProviderProps {
  children: JSX.Element;
}

export const MissingTwilioProvider = (props: MissingTwilioProviderProps) => {
  const [sequenceMissingTwilioCredentials, setSequenceMissingTwilioCredentials] = useState<string | null>(null);
  const navigate = useNavigate();

  const TwilioWarn = () => (
    <GenericDialog
      title="Unable To Resume Sequence"
      content={
        <span>
          The sequence <strong>{sequenceMissingTwilioCredentials}</strong> cannot be resumed. You need to connect a
          Twilio account in order to resume sequences for your team.
        </span>
      }
      open={!!sequenceMissingTwilioCredentials}
      cancelButton={{
        action: () => setSequenceMissingTwilioCredentials(null),
      }}
      confirmButton={{
        action: () => navigate(routes.settings.path, { state: "companySettings" }),
        text: "Go To Company Settings",
      }}
    />
  );

  return (
    <MissingTwilioContext.Provider
      value={{
        TwilioWarn,
        triggerTwilioWarn: (sequenceName: string) => setSequenceMissingTwilioCredentials(sequenceName),
        closeTwilioWarn: () => setSequenceMissingTwilioCredentials(null),
      }}
    >
      {props.children}
    </MissingTwilioContext.Provider>
  );
};

export function useMissingTwilioCredentialsWarn() {
  const context = useContext(MissingTwilioContext);

  return {
    ...context,
  };
}
