import { Control, Controller } from "react-hook-form";
import Input from "@/common/components/input";
import { Box, FormControl, Theme, Typography } from "@mui/material";
import FormLabel from "@/common/components/form-label/FormLabel";
import { BrandRegistrationFormValues } from "@/redux/reducers/settings/brand-registration/mapper";
import PhoneInput from "@/common/components/phone-input/PhoneInput";
interface FormProps {
  control: Control<BrandRegistrationFormValues>;
}
export default function Contact({ control }: FormProps) {
  return (
    <Box
      sx={(theme: Theme) => ({
        borderRadius: "6px",
        padding: "16px",
        backgroundColor: theme.palette.midnight[20],
        display: "grid",
        gap: "16px",
      })}
    >
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "28px",
          letterSpacing: "0.2px",
        }}
      >
        Contact
      </Typography>
      <Box
        sx={{
          display: "grid",
          gap: "32px",
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        <Controller
          name="contactFirstName"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="First Name" aria-required />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="contactLastName"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Last Name" aria-required />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="contactEmail"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Email" aria-required />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="contactPhone"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Phone" aria-required />
              <PhoneInput {...field} error={fieldState?.error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="contactJobTitle"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Job Title" aria-required />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
      </Box>
    </Box>
  );
}
