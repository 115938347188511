import Box from "@mui/material/Box";
import Searchbar from "@/common/components/searchbar/Searchbar";

import { DatePicker } from "@/common/components/date-picker/DatePicker";

const OutreachTypeOptions = [
  { label: "SMS", value: "sms" },
  { label: "Email", value: "email" },
  { label: "Call", value: "call" },
];

export type InboxFiltersInput = {
  text: string;
  updatedAtS: Date | null;
  updatedAtE: Date | null;
};

type InboxFiltersProps = {
  filters: InboxFiltersInput;
  setFilters: (filters: InboxFiltersInput) => void;
};

export default function InboxFilters({ filters, setFilters }: InboxFiltersProps) {
  return (
    <form>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Box>
          <Searchbar
            placeholder="Search"
            value={filters.text}
            onChange={(event) => setFilters({ ...filters, text: event.target.value })}
          />
        </Box>

        <Box />

        <Box>
          {/*<Dropdown*/}
          {/*  label="Outreach Type"*/}
          {/*  isMulti={true}*/}
          {/*  placeholder="Outreach Type"*/}
          {/*  options={OutreachTypeOptions}*/}
          {/*  value={filters.outreachType as any}*/}
          {/*  onChange={(option) => setFilters({ ...filters, outreachType: option.value })}*/}
          {/*/>*/}
        </Box>

        <Box>
          <DatePicker
            range={true}
            value={[filters.updatedAtS, filters.updatedAtE]}
            onChange={(range: any) => {
              if (range.dateStart && range.dateEnd) {
                setFilters({
                  ...filters,
                  updatedAtS: new Date(range.dateStart),
                  updatedAtE: new Date(range.dateEnd),
                });
              } else {
                setFilters({
                  ...filters,
                  updatedAtS: null,
                  updatedAtE: null,
                });
              }
            }}
          />
        </Box>
      </Box>
    </form>
  );
}
