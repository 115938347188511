import { ReactComponent as DealTriggers } from "@/common/assets/svg/triggers/deal_triggers.svg";
import { ReactComponent as LeadTriggers } from "@/common/assets/svg/triggers/lead_triggers.svg";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input/Input";
import { TriggerOption, TriggersType } from "@/pages/triggers/edit/types";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { Controller, useFormContext } from "react-hook-form";
import ObjectCard from "../ObjectCard";

const items: TriggerOption[] = [
  {
    image: <LeadTriggers />,
    title: "Lead Triggers",
    type: TriggersType.LEAD,
    description: "Object linked to new contact, meeting state changes and prospect engaging events.",
  },
  {
    image: <DealTriggers />,
    title: "Deal Triggers",
    type: TriggersType.DEAL,
    description: "Object linked to the deal stage changed and deal assignee change events.",
  },
];

export const ObjectStep = () => {
  const { control } = useFormContext();

  return (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        flexDirection: "column",
        justifyContent: "center",
        margin: "0 auto",
        maxWidth: "800px",
        width: "100%",
      }}
    >
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Add a Trigger Name" aria-required />
            <Input
              type="text"
              placeholder="Type here your trigger name"
              value={field.value}
              onChange={field.onChange}
              error={fieldState.error?.message}
            />
          </FormControl>
        )}
      />
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          justifyContent: "center",
        }}
      >
        <ObjectCard items={items} />
      </Box>
    </Box>
  );
};
