import { Box, Typography, useTheme } from "@mui/material";
import InfoIcon from "@/common/assets/svg/info_icon.svg";

export type FormLabelProps = {
  label: string;
  "aria-required"?: boolean;
  suggested?: boolean;
  sx?: any;
  infoIcon?: boolean;
};

export default function FormLabel(props: FormLabelProps) {
  const { sx, label, suggested, infoIcon } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "8px",
        ...sx,
      }}
    >
      <Box sx={{ display: "flex", gap: "2px" }}>
        <Typography variant="body2">{label}</Typography>
        {infoIcon && (
          <img
            src={InfoIcon}
            alt="info icon"
            style={{
              width: "20px",
              height: "20px",
            }}
          />
        )}
      </Box>

      {props["aria-required"] ? (
        <Typography sx={{ fontSize: "12px", lineHeight: "16px", color: theme.palette.txt.placeholder }}>
          Required
        </Typography>
      ) : suggested ? (
        <Typography sx={{ fontSize: "12px", lineHeight: "16px", color: theme.palette.txt.placeholder }}>
          Suggested
        </Typography>
      ) : null}
    </Box>
  );
}
