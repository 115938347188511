import { Components, Theme } from "@mui/material";
export const AccordionSummaryStyles: Components<Theme>["MuiAccordionSummary"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      "&:hover": {
        backgroundColor: theme.palette.midnight[20],
      },
      padding: "0px",
      minHeight: "32px",
    }),
  },
};
