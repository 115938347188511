import { KnCompanyHeadcountEnum, KnCompanyIndustryEnum, KnCompanyRevenueEnum } from "@/types/enums";

export const CompanyHeadcountOptions = Object.values(KnCompanyHeadcountEnum).map((action) => ({
  label: action,
  value: action,
}));

export const CompanyIndustryOptions = Object.values(KnCompanyIndustryEnum).map((action) => ({
  label: action,
  value: action,
})) as [{ label: string; value: string }];

// Note: Values may be categories for Lead Flow but are not legitimate options. Lead Flow groups industries. Our enum is based on 5x5 industry options which is what Prospect Finder searches.
// CompanyIndustryOptions.push(
//   { label: "Engineering", value: "Engineering" },
//   { label: "Sales", value: "Sales" },
//   { label: "Marketing", value: "Marketing" },
//   { label: "Product", value: "Product" },
//   { label: "Finance", value: "Finance" },
//   { label: "HR", value: "HR" },
//   { label: "Operations", value: "Operations" },
//   { label: "Legal", value: "Legal" },
//   { label: "Other", value: "Other" },
// );

export const CompanyRevenueOptions = Object.values(KnCompanyRevenueEnum).map((action) => ({
  label: action,
  value: action,
}));
