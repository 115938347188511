import { Box, Typography, useTheme } from "@mui/material";

type OnboardCardProps = {
  title: string;
  subtitle?: string;
  img: JSX.Element;
  value: string;
  selected?: boolean;
  onChange: () => void;
};

export default function OnboardCard({ value, title, img, selected, subtitle, onChange }: OnboardCardProps) {
  const theme = useTheme();
  return (
    <label htmlFor={value} style={{ cursor: "pointer", width: "100%" }}>
      <Box
        sx={{
          borderRadius: "8px",
          padding: "10px",
          border: `${selected ? `2px solid #1C73C1` : "1px solid #E0E0E0"}`,
        }}
      >
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Box
            sx={{
              background: theme.palette.mint.p,
              padding: "4px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {img}
          </Box>

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "8px",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Box sx={{ minWidth: "30vw" }}>
              <Typography variant="body2" sx={{ fontWeight: "700" }}>
                {title}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: "400" }}>
                {subtitle}
              </Typography>
            </Box>

            <input id={value} type="radio" checked={selected} onChange={onChange} />
          </Box>
        </Box>
      </Box>
    </label>
  );
}
