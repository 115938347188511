import { Box, Divider } from "@mui/material";
import OutreachCampaign from "@/pages/settings/campaign-registration/OutreachCampaign";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useForm, FormProvider } from "react-hook-form";
import Button from "@/common/components/button";
import {
  CampaignRegistrationFormValues,
  mapCampaignRegistrationRequest,
} from "@/redux/reducers/settings/campaign-registration/mapper";
import { createCampaignRegistration } from "@/redux/reducers/settings/campaign-registration/create";
import { useAuth0 } from "@auth0/auth0-react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = Yup.object({
  outreachUseCase: Yup.string().required("Use case is required"),
  outreachConsent: Yup.string().required("Consent is required"),
  outreachUseCaseDesc: Yup.string()
    .required("Use case description is required")
    .min(40, "Description must be at least 40 characters"),
  outreachOptInKeywords: Yup.string().required("Opt-in keywords are required"),
  outreachSampleMessage1: Yup.string().required("Sample message 1 is required"),
  outreachOptInMessage: Yup.string().required("Opt-in message is required"),
  outreachSampleMessage2: Yup.string().required("Sample message 2 is required"),
}).required();

export default function CampaignRegistrationPage() {
  const dispatch = useAppDispatch();
  const brandRegistration = useAppSelector(({ settings }) => settings.brandRegistration.brandRegistration);
  const campaignRegistration = useAppSelector(({ settings }) => settings.campaignRegistration.campaignRegistration);
  const { user } = useAuth0();

  const methods = useForm<CampaignRegistrationFormValues>({
    resolver: yupResolver(schema),
    defaultValues: campaignRegistration,
  });

  const onSubmit = (values: CampaignRegistrationFormValues) => {
    const mappedValues = mapCampaignRegistrationRequest(values);

    dispatch(
      createCampaignRegistration({
        ...mappedValues,
        owner_team_id: user?.team_id,
        brand_id: brandRegistration?.brand_id,
      }),
    );
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box sx={{ display: "grid", gap: "32px" }}>
          <OutreachCampaign control={methods.control} />
          <Divider />
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
            <Button type="submit" variant="secondary">
              Submit Outreach Campaign for Review
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}
