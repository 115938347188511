import Button from "@/common/components/button";
import { Box } from "@mui/material";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useNavigate } from "react-router";
import { SequenceColumns } from "./table/columns";

import PageHeader from "@/common/components/page-header/PageHeader";
import { usePagination } from "@/common/components/pagination/usePagination";
import routes from "@/common/constants/routes";
import {
  getSequencesTemplates,
  setCurrentSequence,
  setSequenceFilters,
} from "@/redux/reducers/sequences/slices/listSequences";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { SequenceTemplate, SequenceTemplateStatusType } from "@/types/sequence";
import { useEffect, useState } from "react";
import SequenceFilters from "./filters";
import SequenceTable from "./table";
import { MissingTwilioProvider, useMissingTwilioCredentialsWarn } from "./utils/useMissingTwilioCredentials";

export type SequenceFiltersInput = {
  name?: string;
  status?: SequenceTemplateStatusType[];
};

export default function SequenceBuilderPage() {
  const { sequences, total } = useAppSelector((selector) => selector.sequences.listSequences);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { TwilioWarn } = useMissingTwilioCredentialsWarn();
  const { onPaginationChange, pagination } = usePagination();
  const [filters, setFilters] = useState<SequenceFiltersInput>({});

  const table = useReactTable<SequenceTemplate>({
    data: sequences || [],
    columns: SequenceColumns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: total,
    manualPagination: true,
    state: {
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },
    onPaginationChange,
  });

  useEffect(() => {
    dispatch(setSequenceFilters({ ...filters, pageNumber: pagination.pageIndex, pageSize: pagination.pageSize }));
    dispatch(getSequencesTemplates());
  }, [dispatch, filters, pagination.pageIndex, pagination.pageSize]);

  const handleNavigate = () => {
    dispatch(setCurrentSequence({ sequence: null }));
    navigate(routes.sequence.create.path);
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <PageHeader
        title="Sequences"
        actions={
          <Button variant="primary" onClick={handleNavigate}>
            Create Sequence
          </Button>
        }
        filters={<SequenceFilters filters={filters} setFilters={setFilters} />}
      />
      <MissingTwilioProvider>
        <SequenceTable table={table} filters={filters} />
      </MissingTwilioProvider>
    </Box>
  );
}
