import { Box, Typography } from "@mui/material";
import { Message } from "@/types/inbox";

const MailMessageDetail = ({ content }: Message) => {
  return (
    <Box sx={{}}>
      <div style={{ display: "flex", gap: "4px" }}>
        <Typography
          variant="body2"
          style={{
            fontWeight: 500,
          }}
        >
          Andrew Ruge
        </Typography>
        <Typography
          variant="body2"
          style={{
            fontWeight: 400,
            color: "#6B747B",
          }}
        >
          Jul 09, 2023 - 12:00 PM
        </Typography>
      </div>
      <Box
        sx={(theme) => ({
          background: "#EAFEF3",
          padding: "12px 16px",
          border: "1px solid #E7EBED",
          borderRadius: "8px",
        })}
      >
        <Box
          style={{
            flex: "1 1 0",
            color: "#09131D",
            fontSize: 14,
            fontWeight: "400",
            lineHeight: "20px",
            letterSpacing: 0.2,
            wordWrap: "break-word",
          }}
        >
          <Typography
            sx={{
              color: "#09131D",
              fontSize: "14px",
              fontWeight: "400",
              letterSpacing: "0.2px",
              wordWrap: "break-word",
              whiteSpace: "pre-wrap",
            }}
            component="pre"
          >
            {content}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MailMessageDetail;
