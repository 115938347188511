import { Components, Theme } from "@mui/material";

const TextFieldStyles: Components<Theme>["MuiTextField"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: "100%",

      "& .MuiInputBase-root": {
        "& > fieldset": {
          borderColor: "#E7EBED",
        },
      },

      "& .MuiOutlinedInput-input": {
        padding: "12px 16px",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.2px",

        "&::placeholder": {
          color: "#6B747B!important",
          opacity: 0.5,
        },
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "8px",
        background: "#fff",
        color: "#6B747B",

        "& .MuiInputBase-inputMultiline": {
          padding: "0px",
          minHeight: "50px",
        },

        "&:hover": {
          background: "#F8F8F8",
        },

        "&.Mui-focused fieldset": {
          border: `1px solid #1C73C1`,
          outline: "none",
        },
      },

      "&:hover fieldset": {
        borderColor: "#E7EBED!important",
      },

      defaultProps: {
        inputProps: {
          style: {
            fontFamily: theme.typography.fontFamily,
            transition: "all 0.2s ease-in-out",
          },
        },
      },
    }),
  },
};

export default TextFieldStyles;
