import { Box } from "@mui/material";
import { Message } from "@/types/inbox";
import OutboundMessage from "./sms-messages/OutboundMessage";
import InboundMessage from "./sms-messages/InboundMessage";

const SmsMessageDetail = ({ name, SMSs }: Message) => {
  return (
    <Box
      sx={{
        background: "white",
        overflow: "hidden",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "10px",
        display: "inline-flex",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          alignSelf: "stretch",
          flex: "1 1 0",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "24px",
          display: "flex",
          overflowY: "scroll",
          minHeight: "40vh",
        }}
      >
        {SMSs?.map((sms) =>
          sms.direction === "outbound" ? (
            <OutboundMessage {...sms} name={name} key={sms.body + sms.direction} />
          ) : (
            <InboundMessage {...sms} key={sms.body + sms.direction} />
          ),
        )}
      </Box>
    </Box>
  );
};

export default SmsMessageDetail;
