import { Box, Typography } from "@mui/material";
import Dropdown from "../dropdown/Dropdown";
import { ChevronLeft, ChevronRight } from "heroicons-react";

export default function Pagination(props: any) {
  const { tableLib } = props;
  const totalCount = tableLib.getPageCount();
  const pageSize = tableLib.getState().pagination.pageSize;
  const pageCount = Math.ceil(totalCount / pageSize);
  const isLastPage = pageCount === tableLib.getState().pagination.pageIndex + 1;

  const handlePageSizeChange = (value: any) => {
    tableLib.setPageSize(parseInt(value, 10));
  };

  const handlePageIndexChange = (value: any) => {
    tableLib.setPageIndex(parseInt(value, 10) - 1);
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "5px",
        background: "#fff",
        padding: "8px 16px",
        borderRadius: "8px",
        border: `1px solid ${theme.palette.border.default}`,
      })}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <Typography variant="body2">Display</Typography>

        <Dropdown
          label=""
          isClearable={false}
          options={[10, 25].map((pageSize) => ({
            label: pageSize.toString(),
            value: pageSize.toString(),
          }))}
          menuMinWidth="auto"
          onChange={({ value }) => handlePageSizeChange(value)}
          value={tableLib.getState().pagination.pageSize?.toString()}
          defaultValue={tableLib.getState().pagination.pageSize?.toString()}
        />

        <Typography variant="body2">of {tableLib.getPageCount()} items</Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <ChevronLeft
          onClick={() => {
            if (!tableLib.getCanPreviousPage()) return;
            tableLib.previousPage();
          }}
          style={{
            cursor: tableLib.getCanPreviousPage() ? "pointer" : "not-allowed",
            fill: tableLib.getCanPreviousPage() ? "#1A2135" : "#D3D3D3",
          }}
        />

        <Dropdown
          label=""
          isClearable={false}
          options={Array.from({ length: pageCount }, (_, i) => i + 1).map((pageIndex) => ({
            label: pageIndex.toString(),
            value: pageIndex.toString(),
          }))}
          onChange={({ value }) => handlePageIndexChange(value)}
          value={
            (tableLib.getState().pagination.pageIndex + 1).toString() ||
            (tableLib.getState().pagination.pageIndex + 1).toString()
          }
        />

        <ChevronRight
          onClick={() => {
            if (!tableLib.getCanNextPage() || isLastPage) return;
            tableLib.nextPage();
          }}
          style={{
            cursor: tableLib.getCanNextPage() && !isLastPage ? "pointer" : "not-allowed",
            fill: tableLib.getCanNextPage() && !isLastPage ? "#1A2135" : "#D3D3D3",
          }}
        />
      </Box>
    </Box>
  );
}
