import { useMemo, useRef, useState } from "react";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import FormLabel from "@/common/components/form-label/FormLabel";
import SearchBar from "@/common/components/searchbar/Searchbar";
import AudioPlayer from "@/pages/inbox/AudioPlayer";
import { useAppSelector } from "@/redux/store";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/16/solid";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Grid as SwiperGrid } from "swiper/modules";

type VoicesGalleryProps = {
  onSelect?: (voiceId: string) => void;
  voiceId?: string;
  error?: string;
};

export default function VoicesGallery({ voiceId, onSelect, error }: VoicesGalleryProps) {
  const theme = useTheme();
  const swiperRef = useRef<SwiperCore | null>(null);
  const voices = useAppSelector(({ aiVoice }) => aiVoice.upsertAgent.voices);
  const [search, setSearch] = useState("");
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  const selectedVoice = voices?.find((voice) => voice.id === voiceId);

  const filteredVoices = useMemo(() => {
    return (voices || []).filter((voice) => (search ? voice.name?.toLowerCase().includes(search) : true));
  }, [search, voices]);

  if (!voices?.length) {
    return null;
  }

  return (
    <Stack gap={2}>
      <SearchBar placeholder="Search Voice Agents" value={search} onChange={(e) => setSearch(e.target.value)} />

      {filteredVoices.length === 0 && "No agents with search"}
      {filteredVoices?.length > 0 && (
        <Box sx={{ position: "relative" }}>
          <Box
            onClick={() => swiperRef.current?.slidePrev()}
            sx={{
              cursor: "pointer",
              position: "absolute",
              left: -70,
              zIndex: 10,
              top: "50%",
              transform: "translateY(-50%)",
              borderRadius: 24,
            }}
          >
            <ChevronLeftIcon width={24} />
          </Box>
          <Box
            onClick={() => swiperRef.current?.slideNext()}
            sx={{
              cursor: "pointer",
              position: "absolute",
              right: -70,
              zIndex: 10,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <ChevronRightIcon width={24} />
          </Box>
          <Swiper
            style={{ height: "300px", width: "100%" }}
            slidesPerView={4}
            grid={{ rows: 2 }}
            navigation
            spaceBetween={8}
            modules={[SwiperGrid]}
            onSwiper={(swiper: SwiperCore) => (swiperRef.current = swiper)}
            onRealIndexChange={(swiper: SwiperCore) => {
              setActiveSlideIndex(swiper.realIndex);
            }}
          >
            {filteredVoices.map((voice) => (
              <SwiperSlide
                key={voice.id}
                onClick={() => voice.id && onSelect?.(voice.id)}
                style={{ cursor: "pointer" }}
              >
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                    height: "96px",
                    borderRadius: 3,
                    objectFit: "cover",
                    border: `1.5px solid ${voiceId === voice.id ? theme.palette.info.main : "transparent"}`,
                    boxSizing: "border-box",
                    "&:hover": {
                      border: `1.5px solid ${theme.palette.info.main}`,
                    },
                  }}
                  src={voice.image_url}
                  alt={voice.name}
                />

                <Typography textAlign="center">{voice.name}</Typography>
              </SwiperSlide>
            ))}
          </Swiper>

          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {new Array(filteredVoices.length >= 3 ? Math.ceil(filteredVoices.length / 2 - 3) : 1)
              .fill(null)
              .map((_, index) => (
                <Box
                  key={index}
                  sx={
                    activeSlideIndex === index
                      ? {
                          border: `2px solid ${theme.palette.mint.p}`,
                          backgroundColor: theme.palette.midnight.p,
                          height: 16,
                          width: 16,
                          borderRadius: "50%",
                        }
                      : {
                          backgroundColor: theme.palette.midnight[40],
                          borderRadius: "50%",
                          height: 8,
                          width: 8,
                        }
                  }
                />
              ))}
          </Box>
          {error && (
            <Typography
              textAlign="center"
              variant="body2"
              sx={{ color: theme.palette.commonColors.danger, fontSize: "12px", mt: 2 }}
            >
              {error}
            </Typography>
          )}
        </Box>
      )}

      <FormLabel label="Voice Sample" />
      <AudioPlayer url={selectedVoice?.voice_sample_url || ""} />
    </Stack>
  );
}
