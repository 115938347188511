import { createSlice } from "@reduxjs/toolkit";
import { getSalesProcess } from "@/redux/reducers/ai-learning/sales-process/get";
import { AiSalesProcessObjection } from "@/services/generated";
import { persistSalesProcess } from "@/redux/reducers/ai-learning/sales-process/persist";

type State = {
  objections: AiSalesProcessObjection[];
  loading: boolean;
  error: string | null;
};

const initialState: State = {
  objections: [],
  loading: false,
  error: null,
};

const salesProcessSlice = createSlice({
  name: "salesProcess",
  initialState,
  // Triggered by external action AsyncThunk
  extraReducers: (builder) => {
    builder
      .addCase(getSalesProcess.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.objections = payload.data.objections || [];
        }
        state.loading = false;
      })
      .addCase(persistSalesProcess.fulfilled, (state, { payload }) => {
        console.log("persistSalesProcess.fulfilled");
      });
  },
  // Dispatch by Component
  reducers: {
    addObjection: (state) => {
      state.objections = [...state.objections, {}];
    },
  },
});

export const { addObjection } = salesProcessSlice.actions;

export default salesProcessSlice.reducer;
