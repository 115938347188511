import Button from "@/common/components/button";
import BrandRegistrationPage from "@/pages/settings/brand-registration";
import CampaignRegistrationPage from "@/pages/settings/campaign-registration/index";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CompanySettings from "./company/TeamSettings";
import UserSettings from "./user";

import StatusChip from "@/common/components/status-chip";
import TabList from "@/common/components/tab-list";
import { BrandRegistrationEnum } from "@/common/constants/brand-registration";
import config from "@/common/constants/config";
import toast from "@/lib/toast";
import {
  getIsConnectedUserGmail,
  getIsConnectedUserOutlook,
  getIsCRMHubspotAuthorized,
  getIsGoogleCalendarAuthorized,
  getIsOutlookCalendarAuthorized,
  listConnectedTeamShared,
} from "@/redux/reducers/oauth/get";
import { getbrandRegistration } from "@/redux/reducers/settings/brand-registration/get";
import { getCampaignRegistration } from "@/redux/reducers/settings/campaign-registration/get";
import { listInvites, updateTeam } from "@/redux/reducers/settings/team";
import { updateTeamAiVoice } from "@/redux/reducers/settings/team-ai-voice";
import { getTeamAiVoiceSettings } from "@/redux/reducers/settings/team-ai-voice/get";
import { getTeam, getTeamUsers } from "@/redux/reducers/settings/team/get";
import { updateUser } from "@/redux/reducers/settings/user";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useAuth0 } from "@auth0/auth0-react";
import {
  BriefcaseIcon,
  CurrencyDollarIcon,
  MegaphoneIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Tab } from "@mui/material";
import { AnyAsyncThunk, RejectedWithValueActionFromAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { useLocation } from "react-router";
import BillingSettings from "./billing";

export default function SettingsPage() {
  const location = useLocation();
  const [tabValue, setTabValue] = useState(location.state || "userSettings");
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  const brand = useAppSelector(({ settings }) => settings.brandRegistration.brandRegistration);

  // Can Update Forms
  const canSaveAiVoice = useAppSelector(({ settings }) => settings.teamAiVoice.canSave);
  const canUpdateUserInfo = useAppSelector(({ settings }) => settings.user.canUpdateUserInfo);

  useEffect(() => {
    dispatch(getIsConnectedUserGmail());
    dispatch(getIsConnectedUserOutlook());
    dispatch(listConnectedTeamShared());
    dispatch(getIsGoogleCalendarAuthorized());
    dispatch(getIsOutlookCalendarAuthorized());
    dispatch(getIsCRMHubspotAuthorized());
    dispatch(getTeam());
    dispatch(getTeamAiVoiceSettings());
    dispatch(getTeamUsers());
    dispatch(listInvites());
    if (config.flagOutreach) {
      dispatch(getbrandRegistration(user?.team_id || ""));
      dispatch(getCampaignRegistration(user?.team_id || ""));
    }
  }, [dispatch, user?.team_id]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleSave = async () => {
    try {
      let responses: RejectedWithValueActionFromAsyncThunk<AnyAsyncThunk>[] = [];

      if (tabValue === "userSettings" && canUpdateUserInfo) {
        responses.push(await dispatch(updateUser()));
      } else if (tabValue === "companySettings" && canSaveAiVoice) {
        responses = await Promise.all([dispatch(updateTeam()), dispatch(updateTeamAiVoice())]);
      } else {
        // TODO: Other forms
        toast.error("Please review errors in the form");
      }

      if (responses.some((response) => response?.error)) {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            fontSize: "28px",
            fontWeight: "700",
            lineHeight: "36px",
            letterSpacing: "0.2px",
          }}
        >
          {t("settings.title")}
        </Box>
        <Button onClick={handleSave} variant="primary">
          {t("settings.save")}
        </Button>
      </Box>
      <Box
        sx={(theme) => ({
          background: theme.palette.common.white,
          borderRadius: "8px",
        })}
      >
        <TabContext value={tabValue}>
          <TabList onChange={handleTabChange} indicatorColor="primary" variant="fullWidth">
            <Tab
              iconPosition="start"
              icon={<UserCircleIcon width={20} />}
              label={t("settings.user")}
              value={"userSettings"}
              sx={{ textTransform: "capitalize" }}
            />
            <Tab
              iconPosition="start"
              icon={<UserGroupIcon width={20} />}
              label={t("settings.company")}
              value={"companySettings"}
              sx={{ textTransform: "capitalize" }}
            />
            {config.flagOutreach && (
              <Tab
                iconPosition="start"
                icon={<BriefcaseIcon width={28} />}
                value={"brandRegistration"}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      gap: "16px",
                      justifyContent: "center",
                      alignItems: "center",
                      textTransform: "none",
                    }}
                  >
                    {t("settings.brandRegistration")}
                    <StatusChip
                      type="brandRegistration"
                      status={
                        brand?.status === "OK"
                          ? BrandRegistrationEnum.Approved
                          : brand?.status === "REGISTRATION_PENDING"
                          ? BrandRegistrationEnum.Pending
                          : brand?.status === "REGISTRATION_FAILED"
                          ? BrandRegistrationEnum.Rejected
                          : BrandRegistrationEnum.Incomplete
                      }
                    />
                  </Box>
                }
                sx={{ textTransform: "capitalize" }}
              />
            )}
            {config.flagOutreach && (
              <Tab
                iconPosition="start"
                disabled={brand?.status !== "OK"}
                content="dss"
                icon={<MegaphoneIcon width={24} />}
                label={t("settings.campaignRegistration")}
                value={"campaignRegistration"}
                sx={{ textTransform: "capitalize" }}
              />
            )}
            <Tab
              iconPosition="start"
              icon={<CurrencyDollarIcon width={20} />}
              label={t("settings.billing")}
              value={"billing"}
              sx={{ textTransform: "capitalize" }}
            />
          </TabList>

          <TabPanel value="userSettings">
            <UserSettings />
          </TabPanel>
          <TabPanel value="companySettings">
            <CompanySettings />
          </TabPanel>
          <TabPanel value="brandRegistration">
            <BrandRegistrationPage />
          </TabPanel>
          <TabPanel value="campaignRegistration">
            <CampaignRegistrationPage />
          </TabPanel>
          <TabPanel value="billing">
            <BillingSettings />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}
