import { Control, Controller } from "react-hook-form";
import Input from "@/common/components/input";
import { Box, FormControl, Theme, Typography } from "@mui/material";
import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import { BrandRegistrationFormValues } from "@/redux/reducers/settings/brand-registration/mapper";
import { CreateBrandRequestDtoEntityTypeEnum, CreateBrandRequestDtoVerticalEnum } from "@/services/generated";
import { capitalize } from "@/utils/capitalize";

export const BusinessTypeSelectOptions = [
  {
    label: "Private Profit",
    value: CreateBrandRequestDtoEntityTypeEnum.PrivateProfit,
  },
  {
    label: "Public Profit",
    value: CreateBrandRequestDtoEntityTypeEnum.PublicProfit,
  },
  {
    label: "Non Profit",
    value: CreateBrandRequestDtoEntityTypeEnum.NonProfit,
  },
  {
    label: "Sole Proprietor",
    value: CreateBrandRequestDtoEntityTypeEnum.SoleProprietor,
  },
  {
    label: "Government",
    value: CreateBrandRequestDtoEntityTypeEnum.Government,
  },
];

const IndustrySelectOptions = Object.values(CreateBrandRequestDtoVerticalEnum).map((value) => ({
  label: capitalize(value.replace(/_/g, " ")),
  value,
}));

interface FormProps {
  control: Control<BrandRegistrationFormValues>;
}

export default function BasicInformation({ control }: FormProps) {
  return (
    <Box
      sx={(theme: Theme) => ({
        borderRadius: "6px",
        padding: "16px",
        backgroundColor: theme.palette.midnight[20],
        display: "grid",
        gap: "16px",
      })}
    >
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "28px",
          letterSpacing: "0.2px",
        }}
      >
        Basic Information
      </Typography>
      <Box
        sx={{
          display: "grid",
          gap: "32px",
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        <Controller
          name="businessName"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Business Name" aria-required />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name={"businessType"}
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Business Type" aria-required />
              <Dropdown
                required
                options={BusinessTypeSelectOptions}
                value={field.value?.value}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          name="businessUrl"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Business URL" suggested />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="businessSupportEmail"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Business Support Email" aria-required />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="taxId"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Tax ID (Ex. US EIN, Canada, BN9, etc)" aria-required />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name={"businessIndustry"}
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Business Industry" aria-required />
              <Dropdown
                required
                options={IndustrySelectOptions}
                value={field.value?.value}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            </FormControl>
          )}
        />
      </Box>
    </Box>
  );
}
