import routes from "@/common/constants/routes";
import DrawerLayout from "@/layouts/DrawerLayout";
import CallSummary from "@/pages/ai-voice/call-logs/call-summary/CallSummary";
import { Drawer } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router";

type CallSummarySidePanelProps = {
  open?: boolean;
};

export default function CallSummarySidePanel({ open = false }: CallSummarySidePanelProps) {
  const navigate = useNavigate();

  const handleCloseSidePanel = useCallback(() => {
    navigate(routes.aiVoice.callLogs.path);
  }, [navigate]);

  return (
    <Drawer open={open} onClose={handleCloseSidePanel} anchor="right">
      <DrawerLayout title="Call Summary" closeDrawer={handleCloseSidePanel} customWidth="980px">
        <CallSummary />
      </DrawerLayout>
    </Drawer>
  );
}
