import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompanyDetailsRequest } from "@/services/generated";
import Services from "@/services";

export const persistCompanyDetails = createAsyncThunk(
  "aiLearning/persistCompanyDetails",
  async ({ id, request }: { id?: string; request: CompanyDetailsRequest }, { rejectWithValue, dispatch }) => {
    try {
      return !id ? await Services.Ai.companyDetailsCreate(request) : await Services.Ai.companyDetailsUpdate(request);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
