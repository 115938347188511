import Dropdown from "@/common/components/dropdown/Dropdown";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { CallDirectionSelectOptions } from "@/redux/reducers/ai-voice/helpers";
import { AiVoiceCallFlowType } from "@/types/ai-voice";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { AiAgentFiltersInput } from "..";
import { CALL_FLOW_TYPE_OPTIONS } from "../../constants";

type AiAgentFiltersProps = {
  filters: AiAgentFiltersInput;
  setFilters: (filters: AiAgentFiltersInput | ((prevFilters: AiAgentFiltersInput) => AiAgentFiltersInput)) => void;
};

export default function AiAgentFilters({ filters, setFilters }: AiAgentFiltersProps) {
  useEffect(() => {
    return () => setFilters((filters) => ({ ...filters, text: "" }));
  }, [setFilters]);

  return (
    <form>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Box>
          <Searchbar
            placeholder="Search by Language or Phone Number"
            value={filters.text}
            onChange={(event) => setFilters({ ...filters, text: event.target.value })}
          />
        </Box>
        <Box />
        <Box>
          <Dropdown
            label="Direction"
            placeholder="Direction"
            value={filters.callDirection}
            options={CallDirectionSelectOptions}
            onChange={(option) => {
              setFilters({ ...filters, callDirection: option.value });
            }}
          />
        </Box>
        <Box>
          <Dropdown
            label="Call Flow"
            placeholder="Call Flow"
            value={filters.callFlow}
            options={[
              ...Object.keys(CALL_FLOW_TYPE_OPTIONS).map((callFlowType: AiVoiceCallFlowType) => ({
                value: callFlowType,
                label: CALL_FLOW_TYPE_OPTIONS[callFlowType].label,
              })),
            ]}
            onChange={(option) => {
              setFilters({ ...filters, callFlow: option.value });
            }}
          />
        </Box>
      </Box>
    </form>
  );
}
