import Services from "@/services";
import { KnLookupRequest } from "@/services/generated";
import { Team } from "@/types/admin";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type State = {
  teams: Team[];
  total: number;
  loading: boolean;
  error?: string;
};

const initialState: State = {
  total: 0,
  teams: [],
  loading: false,
};

export const getTeams = createAsyncThunk("admin/getTeams", async (filters: KnLookupRequest, { rejectWithValue }) => {
  try {
    const response = await Services.Admin.getTeams(filters);

    return response;
  } catch (error) {
    rejectWithValue(error.message);
  }
});

const getTeamsSlice = createSlice({
  name: "getTeams",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getTeams.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTeams.fulfilled, (state, { payload }) => {
      state.loading = false;

      if (payload?.data.results) {
        state.teams = payload.data.results.map((team) => ({ ...team, id: team.id ?? "" }));
      } else {
        state.teams = [];
      }

      state.total = payload?.data.total ?? 0;
    });

    builder.addCase(getTeams.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });
  },
  reducers: {},
});

export const {} = getTeamsSlice.actions;

export default getTeamsSlice.reducer;
