import FormLabel from "@/common/components/form-label/FormLabel";
import React, { ChangeEvent, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { ButtonBase, FormControl } from "@mui/material";
import Input from "@/common/components/input";
import { MinusCircleOutline, PlusCircleOutline } from "heroicons-react";
import { Controller } from "react-hook-form";

type Prop = {
  label: string;
  features: string[];
  onChangeFeature: (featureList: string[]) => void;
};

export default function Features({ label, features, onChangeFeature }: Prop) {
  const [feature, setFeature] = useState<string>();
  const [featureList, setFeatureList] = useState<string[]>(features || []);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFeature(event.target.value);
  };

  const handleFeatureAdd = () => {
    if (!feature) {
      return;
    }
    const update = [feature, ...featureList];
    setFeatureList(update);
    onChangeFeature(update);
    setFeature("");
  };

  const handleFeatureRemove = (index: number) => {
    const update = [...featureList];
    update.splice(index, 1);
    setFeatureList(update);
    onChangeFeature(update);
  };

  return (
    <Box>
      <FormLabel label={label} />
      <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
        <FormControl sx={{ width: "100%" }}>
          <Input placeholder={`Type a ${label.toLowerCase()}`} value={feature} onChange={handleInputChange} />
        </FormControl>
        <FormControl sx={{ height: "min-content", display: "flex", alignSelf: "end", p: 1 }}>
          <ButtonBase onClick={() => handleFeatureAdd()}>
            <PlusCircleOutline size={36} />
          </ButtonBase>
        </FormControl>
      </Stack>
      {featureList.map((f, index) => {
        return (
          <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
            <FormControl sx={{ width: "100%" }}>
              <Input value={f} disabled />
            </FormControl>
            <FormControl sx={{ height: "min-content", display: "flex", alignSelf: "end", p: 1 }}>
              <ButtonBase onClick={() => handleFeatureRemove(index)}>
                <MinusCircleOutline size={36} />
              </ButtonBase>
            </FormControl>
          </Stack>
        );
      })}
    </Box>
  );
}
