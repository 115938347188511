import { AiSalesProcessObjectionObjectionTypeEnum } from "@/services/generated";

export type ObjectionTypeOption = {
  label: string;
  value: AiSalesProcessObjectionObjectionTypeEnum;
};

export const ObjectionTypeOptionList: ObjectionTypeOption[] = [
  { label: "Price", value: AiSalesProcessObjectionObjectionTypeEnum.Price },
  { label: "Timing", value: AiSalesProcessObjectionObjectionTypeEnum.Timing },
  { label: "Competition", value: AiSalesProcessObjectionObjectionTypeEnum.Competition },
  { label: "Authority", value: AiSalesProcessObjectionObjectionTypeEnum.Authority },
  { label: "Need", value: AiSalesProcessObjectionObjectionTypeEnum.Need },
  { label: "Other", value: AiSalesProcessObjectionObjectionTypeEnum.Other },
];
