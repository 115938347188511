import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";
import { EmailDTO } from "@/services/generated";

export const sendEmail = createAsyncThunk("inbox/sendEmail", async (data: EmailDTO, { rejectWithValue }) => {
  try {
    const emailSent = await Services.Inbox.createEmail(data);

    return emailSent;
  } catch (error) {
    return error;
  }
});
