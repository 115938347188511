import Drawer from "@mui/material/Drawer";
import { useAppDispatch } from "@/redux/store";
import DrawerLayout from "@/layouts/DrawerLayout";
import { Box } from "@mui/material";
import { useAppSelector } from "@/redux/store";
import { closeViewTaskDrawer } from "@/redux/reducers/tasks/slices/listTask";
import { smsEmailViewDrawerToggle } from "@/redux/reducers/tasks/slices/listTask";
import ViewMessagesDetails from "./smsEmailDrawer/ViewMessagesDetail";
import { ViewTaskDrawerForm } from "./ViewTaskDrawerForm";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";

export default function ViewTaskDrawer() {
  const dispatch = useAppDispatch();
  const currentTask = useAppSelector(({ tasks }) => tasks.listTask.currentTask);

  const viewDrawerIsOpen = useAppSelector(({ tasks }) => tasks.listTask.viewDrawerIsOpen);

  const smsEmailDrawerIsOpen = useAppSelector(({ tasks }) => tasks.listTask.smsEmailDrawerIsOpen);
  const toggleDrawerSmsEmail = () => dispatch(smsEmailViewDrawerToggle());

  return (
    <>
      <Drawer open={smsEmailDrawerIsOpen} onClose={() => dispatch(smsEmailViewDrawerToggle())} anchor="right">
        <DrawerLayout title="SMS/Email" closeDrawer={toggleDrawerSmsEmail} customWidth="800px">
          <ViewMessagesDetails />
        </DrawerLayout>
      </Drawer>

      <Drawer open={viewDrawerIsOpen} onClose={() => dispatch(closeViewTaskDrawer())} anchor="right">
        {!currentTask ? (
          <Box sx={{ minWidth: "600px" }}>
            <LoadingSpinner loading />
          </Box>
        ) : (
          <>
            <ViewTaskDrawerForm />
          </>
        )}
      </Drawer>
    </>
  );
}
