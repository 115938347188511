import Services from "@/services";
import { TeamConfigRequest, LeadFlowTeamConfig } from "@/services/generated";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "@/lib/toast";

type State = {
  loading: boolean;
  configuration?: null | LeadFlowTeamConfig;
};

const initialState: State = { loading: false };

export const updateLeadConfig = createAsyncThunk(
  "configLeadFlow/updateLeadConfig",
  async (body: TeamConfigRequest, { dispatch, rejectWithValue }) => {
    try {
      // TODO: Fix return type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await Services.LeadFlow.leadFlowTeamConfigCreateOrUpdate(body);
      // TODO return update result
      return body;
    } catch (error) {
      toast.error("Something went wrong!");
      return rejectWithValue(error.message);
    }
  },
);

export const getLeadFlowConfig = createAsyncThunk(
  "configLeadFlow/getLeadFlowConfig",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await Services.LeadFlow.leadFlowTeamConfigGet();
      if (!response.data) {
        return null;
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        return null;
      }
      toast.error("Something went wrong!");
      return rejectWithValue(error.message);
    }
  },
);

const configLeadFlow = createSlice({
  name: "configLeadFlow",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(updateLeadConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateLeadConfig.fulfilled, (state, action: PayloadAction<TeamConfigRequest | null>) => {
      if (!action.payload) {
        return;
      }
      // TODO: Fix inconsistencies
      state.configuration = {
        ...action.payload,
        timeframe_unit: action.payload.timeframe_unit as LeadFlowTeamConfig["timeframe_unit"],
      };
      state.loading = false;
    });
    builder.addCase(updateLeadConfig.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getLeadFlowConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLeadFlowConfig.fulfilled, (state, action: PayloadAction<LeadFlowTeamConfig | null>) => {
      state.configuration = action.payload;
      state.loading = false;
    });
    builder.addCase(getLeadFlowConfig.rejected, (state) => {
      state.configuration = null;
      state.loading = false;
    });
  },
  reducers: {
    updateLeadFlowConfiguration: (state, action) => {
      state.configuration = action.payload;
    },
  },
});

export const { updateLeadFlowConfiguration } = configLeadFlow.actions;

export default configLeadFlow.reducer;
