import { Box, Typography, useTheme } from "@mui/material";

export type SummaryProps = {
  title: string;
  data: {
    title: string;
    value: string;
  }[];
};

export default function Summary({ data }: SummaryProps) {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(2),
        }}
      >
        {data?.map((item) => (
          <Box
            key={item.title}
            sx={{
              border: `1px solid ${theme.palette.commonColors.light}`,
              padding: "16px 32px",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px",
              backgroundColor: "white",
              width: "100%",
              minWidth: "207px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                color: "#6B747B",
              }}
            >
              {item.title}
            </Typography>
            <Typography
              sx={{
                color: "#1A2135",
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "28px",
              }}
            >
              {item.value}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
