import Services from "@/services";
import { DripTemplateRequest } from "@/services/generated";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createStepTemplateDrip = createAsyncThunk(
  "sequenceBuilder/createStepTemplateDrip",
  async (data: DripTemplateRequest, { rejectWithValue }) => {
    try {
      const response = await Services.DripTemplateController.createDripTemplate(data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateStepTemplateDrip = createAsyncThunk(
  "sequenceBuilder/updateStepTemplateDrip",
  async (data: DripTemplateRequest, { rejectWithValue }) => {
    try {
      const response = await Services.DripTemplateController.updateDripTemplate(data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const removeStepTemplateDrip = createAsyncThunk(
  "sequenceBuilder/removeStepTemplateDrip",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await Services.DripTemplateController.deleteDripTemplate(id);

      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
