import Checkbox, { CheckboxProps } from "./Checkbox";
import { useEffect, useRef } from "react";

type IndeterminateCheckboxProps = {
  indeterminate?: boolean;
} & CheckboxProps;

function IndeterminateCheckbox({ indeterminate, ...rest }: IndeterminateCheckboxProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current && typeof indeterminate === "boolean") {
      inputRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return <Checkbox {...rest} inputRef={inputRef} />;
}

export default IndeterminateCheckbox;
