import { Box } from "@mui/material";
import IndeterminateCheckbox from "../components/checkbox/IntermediateCheckbox";
import { ColumnHelper, Row } from "@tanstack/react-table";

const createCheckboxCol = <T,>(columnHelper: ColumnHelper<T>) => {
  return columnHelper.display({
    id: "checkbox",
    header: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "17px",
          alignItems: "center",
          margin: "0 auto",
          maxWidth: "0px",
        }}
      >
        <IndeterminateCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      </Box>
    ),
    cell: ({ row }: { row: Row<any> }) => {
      return (
        <Box
          sx={{
            display: "flex",
            gap: "17px",
            alignItems: "center",
            margin: "0 auto",
            width: "0px",
            maxWidth: "0px",
          }}
        >
          <IndeterminateCheckbox
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        </Box>
      );
    },
  });
};

export default createCheckboxCol;
