import { ReactComponent as Home } from "@/common/assets/svg/sidebar/home.svg";
import { ReactComponent as QueueListIcon } from "@/common/assets/svg/sidebar/queue-list.svg";
import { ReactComponent as RocketLaunch } from "@/common/assets/svg/sidebar/rocket-launch.svg";
import { ReactComponent as Identification } from "@/common/assets/svg/sidebar/identification.svg";
import { ReactComponent as TableCells } from "@/common/assets/svg/sidebar/table-cells.svg";
import { ReactComponent as SquaresPlus } from "@/common/assets/svg/sidebar/squares-plus.svg";
import { ReactComponent as CpuChip } from "@/common/assets/svg/sidebar/cpu-chip.svg";
import { ReactComponent as DocumentArrowUp } from "@/common/assets/svg/sidebar/document_arrow_up.svg";
import { ReactComponent as Envelope } from "@/common/assets/svg/envelope_outline.svg";
import { FunnelIcon } from "@heroicons/react/24/outline";
import routes from "@/common/constants/routes";
import config from "@/common/constants/config";

export type DashboardMenuItem = {
  icon: JSX.Element;
  label: string;
  route: string;
  sublinks?: { label: string; route: string; hide?: boolean }[];
  hide?: boolean;
};

export const dashboardMenuItems: DashboardMenuItem[] = [
  {
    icon: <Home />,
    label: "Dashboard",
    route: routes.root.path,
  },
  {
    icon: <QueueListIcon />,
    label: "Tasks",
    route: routes.tasks.path,
    hide: !config.flagAiVoice,
  },
  {
    icon: <RocketLaunch />,
    label: "Triggers",
    route: routes.triggers.path,
    hide: !config.flagAiVoice,
  },
  {
    icon: <Identification />,
    label: "Prospects",
    route: routes.prospects.path,
    hide: !config.flagAiVoice,
    sublinks: [
      {
        label: "Prospects",
        route: routes.prospects.table.path,
      },
      {
        label: "Companies",
        route: routes.prospects.companies.path,
      },
      {
        label: "Prospect Finder",
        route: routes.prospects.prospectFinder.path,
        hide: !config.flagProspectFinder,
      },
    ],
  },
  {
    icon: <TableCells />,
    label: "Deal Flow",
    route: routes.dealFlow.path,
    hide: !config.flagAiVoice,
    sublinks: [
      {
        label: "Deal Flow",
        route: routes.dealFlow.table.path,
      },
      {
        label: "Deal Flow Setup",
        route: routes.dealFlow.create.path,
      },
      {
        label: "Product Library",
        route: routes.dealFlow.productLibrary.path,
      },
    ],
  },
  {
    icon: <FunnelIcon width={24} />,
    label: "Lead Flow Setup",
    route: routes.leadFlow.setup.path,
    hide: !config.flagOutreach,
  },
  {
    icon: <SquaresPlus />,
    label: "Sequences",
    route: routes.sequence.path,
    hide: !config.flagAiVoice,
  },
  {
    icon: <Envelope />,
    label: "Inbox",
    route: routes.inbox.path,
    hide: !config.flagAiVoice,
  },
  {
    icon: <DocumentArrowUp />,
    label: "Data Import",
    route: routes.dataImport.path,
    hide: !config.flagAiVoice,
  },
  {
    icon: <CpuChip />,
    label: "AI Learning",
    route: routes.aiLearning.path,
  },
  {
    icon: <FunnelIcon width={24} />,
    label: "AI Voice",
    route: routes.aiVoice.agents.table.path,
    hide: !config.flagAiVoice,
    sublinks: [
      {
        label: "Agents",
        route: routes.aiVoice.agents.table.path,
      },
      {
        label: "Call Logs",
        route: routes.aiVoice.callLogs.path,
      },
    ],
  },
];
