export interface CampaignRegistrationFormValues {
  outreachUseCase: string;
  outreachConsent: string;
  outreachUseCaseDesc: string;
  outreachOptInKeywords: string;
  outreachSampleMessage1: string;
  outreachOptInMessage: string;
  outreachSampleMessage2: string;
  brandId?: string;
  teamId?: string;
}

export interface CampaignRegistrationResponse {
  description: string;
  mock: boolean;
  sample1: string | null;
  sample2: string | null;
  sample3: string | null;
  sample4: string | null;
  sample5: string | null;
  status: string | null;
  usecase: string;
  age_gated: boolean;
  auto_renewal: boolean;
  billed_date: string | null;
  brand_id: string;
  campaign_id: string;
  tcr_brand_id: string | null;
  tcr_campaign_id: string | null;
  create_date: string | null;
  csp_id: string | null;
  direct_lending: boolean;
  embedded_link: boolean;
  embedded_phone: boolean;
  help_keywords: string | null;
  help_message: string | null;
  message_flow: string | null;
  next_renewal_or_expiration_date: string | null;
  number_pool: boolean;
  optin_keywords: string | null;
  optin_message: string | null;
  optout_keywords: string | null;
  optout_message: string | null;
  reference_id: string | null;
  reseller_id: string | null;
  sub_usecases: string | null;
  subscriber_help: boolean;
  subscriber_optin: boolean;
  subscriber_optout: boolean;
  terms_and_conditions: boolean;
  webhook_url: string | null;
  webhook_failover_url: string | null;
  is_tmobile_registered: boolean;
  is_tmobile_suspended: boolean;
  is_tmobile_number_pooling_enabled: boolean;
}

export const mapCampaignRegistrationResponse = (response: CampaignRegistrationResponse) => {
  return {
    outreachUseCase: response.usecase || "",
    outreachUseCaseDesc: response.description || "",
    outreachConsent: "",
    outreachOptInKeywords: response.optin_keywords || "",
    outreachOptInMessage: response.optin_message || "",
    outreachSampleMessage1: response.sample1 || "",
    outreachSampleMessage2: response.sample2 || "",
  };
};

export const mapCampaignRegistrationRequest = (state: CampaignRegistrationFormValues) => {
  return {
    brand_id: state.brandId,
    use_case: state.outreachUseCase,
    description: state.outreachUseCaseDesc,
    message_flow: state.outreachUseCaseDesc,
    optin_keywords: state.outreachOptInKeywords,
    optin_message: state.outreachOptInMessage,
    sample1: state.outreachSampleMessage1,
    sample2: state.outreachSampleMessage2,
    owner_team_id: state.teamId,
  };
};
