import { CallLogCallOutcomeEnum } from "@/services/generated";
import { AiVoiceCallFlowType } from "@/types/ai-voice";

type Option = {
  label: string;
  description: string;
  bgcolor?: string;
  color?: string;
};

export const CALL_FLOW_TYPE_OPTIONS: Record<AiVoiceCallFlowType, Option> = {
  QUALIFY_AND_BOOK_A_MEETING: {
    label: "Qualify and Book a Prospect",
    description:
      "The agent will introduce itself, qualify the prospect by assessing their needs and suitability, and if qualified, book and confirm an appointment.",
  },
  BOOK_A_MEETING: {
    label: "Book a Meeting with a Prospect",
    description:
      "The agent will greet the prospect, clarify the purpose of the meeting, ask for the prospect’s availability, offer available time slots, confirm the chosen slot, and send the meeting invite.",
  },
  QUALIFY_AND_LIVE_TRANSFER: {
    label: "Qualify and Live Transfer a Prospect",
    description:
      "The agent will greet the prospect, introduce itself, determine if the prospect is qualified, and if so, inform the prospect about the transfer process and facilitate the transfer.",
  },
  ANSWER_FAQS: {
    label: "Answer FAQs for Customers",
    description:
      "The agent greets the customer, asks how it can assist, listens to the customer’s query, searches its knowledge base for relevant information, provides an answer, and offers additional resources if needed.",
  },
  CONFIRM_A_MEETING: {
    label: "Confirm a Meeting with a Prospect",
    description:
      "The agent will greet the prospect, explain the purpose of the call, confirm meeting call-summary, offer to resend the invite if needed, and provide reminders as requested.",
  },
  PROMPT_AND_QUALIFY: {
    label: "Prompt & Qualify",
    description:
      "For most calls, SalesAi agents use prompts to qualify prospects and adapt dynamically, either booking a meeting or transferring to a live agent. This flexible approach ensures personalized conversations.",
  },
  PROMPT: {
    label: "Prompt Based",
    description:
      "For most calls, SalesAi agents use prompts to qualify prospects and adapt dynamically, either booking a meeting or transferring to a live agent. This flexible approach ensures personalized conversations.",
  },
  SCRIPT: {
    label: "Script Based",
    description:
      "Ideal for regulated industries, SalesAi agents follow a strict script to introduce the company and answer questions, maintaining compliance before either booking a meeting or transferring to a live agent.",
  },
  TIER_ONE_SUPPORT: {
    label: "Tier One Support",
    description:
      "For most support calls, AI assistants use prompts to diagnose customer issues and adapt dynamically, either resolving the problem or connecting the caller to a live Tier 1 support agent. This flexible approach ensures efficient and personalized customer service.",
  },
};

export const CALL_FLOW_OUTCOME_OPTIONS: Record<CallLogCallOutcomeEnum, Option> = {
  [CallLogCallOutcomeEnum.Unspecified]: {
    label: "Not Completed",
    description: "",
    bgcolor: "#D3D3D3",
  },
  [CallLogCallOutcomeEnum.Error]: {
    label: "Error",
    description: "",
    bgcolor: "#FF0000",
  },
  [CallLogCallOutcomeEnum.Inbound]: {
    label: "Inbound",
    description: "",
    bgcolor: "#b4dfff",
  },
  [CallLogCallOutcomeEnum.Dna]: {
    label: "DNA",
    description: "",
    bgcolor: "#121a29",
    color: "#ffe49a",
  },
  [CallLogCallOutcomeEnum.OutboundDnc]: {
    label: "DNC",
    description: "",
    bgcolor: "#121a29",
    color: "#ffffff",
  },
  [CallLogCallOutcomeEnum.Dnc]: {
    label: "DNC",
    description: "",
    bgcolor: "#121a29",
    color: "#ffffff",
  },
  [CallLogCallOutcomeEnum.OutboundVoicemail]: {
    label: "Voicemail",
    description: "",
    bgcolor: "#ffc29a",
  },
  [CallLogCallOutcomeEnum.Voicemail]: {
    label: "Voicemail",
    description: "",
    bgcolor: "#ffc29a",
  },
  [CallLogCallOutcomeEnum.OutboundAnswered]: {
    label: "Answered",
    description: "",
    bgcolor: "#3373c2",
    color: "#FFFFFF",
  },
  [CallLogCallOutcomeEnum.Answered]: {
    label: "Answered",
    description: "",
    bgcolor: "#3373c2",
    color: "#FFFFFF",
  },
  [CallLogCallOutcomeEnum.Booked]: {
    label: "Booked",
    description: "",
    bgcolor: "#80f5c1",
  },
  [CallLogCallOutcomeEnum.BookedAMeeting]: {
    label: "Booked",
    description: "",
    bgcolor: "#80f5c1",
  },
  [CallLogCallOutcomeEnum.Transfer]: {
    label: "Transferred",
    description: "",
    bgcolor: "#d9c4fd",
  },
  [CallLogCallOutcomeEnum.Transferred]: {
    label: "Transferred",
    description: "",
    bgcolor: "#d9c4fd",
  },
  [CallLogCallOutcomeEnum.TransferSales]: {
    label: "Transferred",
    description: "",
    bgcolor: "#d9c4fd",
  },
  [CallLogCallOutcomeEnum.TransferBilling]: {
    label: "Transferred",
    description: "",
    bgcolor: "#d9c4fd",
  },
  [CallLogCallOutcomeEnum.TransferSupport]: {
    label: "Transferred",
    description: "",
    bgcolor: "#d9c4fd",
  },
};
