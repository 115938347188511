import BillingInfo from "@/common/components/billing-info/BillingInfo";
import { Box } from "@mui/material";

export default function BillingSettings() {
  return (
    <Box sx={{ width: "75%" }}>
      <BillingInfo showNextPayment />
    </Box>
  );
}
