import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";
import { AiCompanyDetails } from "@/services/generated";
import { AxiosResponse } from "axios";

export const getCompanyDetails = createAsyncThunk(
  "aiLearning/getCompanyDetails",
  async (d, { rejectWithValue, dispatch }): Promise<AxiosResponse<AiCompanyDetails>> => {
    try {
      return await Services.Ai.companyDetailsGet();
    } catch (error: any) {
      return error;
    }
  },
);
