import Typography from "@mui/material/Typography";
import { useFormContext, Controller } from "react-hook-form";
import { TriggerOption } from "./types";

type ObjectCardProps = {
  items: TriggerOption[];
};

export default function ObjectCard({ items }: ObjectCardProps) {
  const methods = useFormContext();

  return (
    <>
      {items?.map((item) => (
        <Controller
          key={item.type}
          name="type"
          control={methods.control}
          render={({ field, fieldState: { error } }) => {
            return (
              <label htmlFor={item.type} style={{ cursor: "pointer" }}>
                <div style={{ width: "280px" }}>
                  <div
                    style={{
                      padding: "16px",
                      borderRadius: "8px",
                      textAlign: "center",
                      gap: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      borderStyle: "solid",
                      borderColor: field.value === item.type ? "#2C72E2" : "#E7EBED",
                      borderWidth: field.value === item.type ? "2px" : "1px",
                    }}
                  >
                    <div style={{ width: "100%", textAlign: "right" }}>
                      <input
                        id={item.type}
                        type="radio"
                        className="radio"
                        {...field}
                        onChange={() => {
                          field.onChange(item.type);
                        }}
                        checked={items.find((item) => item.type === field.value)?.type === item.type}
                      />
                    </div>
                    {item.image}
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "28px",
                        letterSpacing: "0.2px",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#6B747B",
                        fontWeight: 400,
                        lineHeight: "20px",
                        letterSpacing: "0.2px",
                      }}
                    >
                      {item.description}
                    </Typography>
                  </div>
                </div>
              </label>
            );
          }}
        />
      ))}
    </>
  );
}
