import Switch from "@/common/components/switch";
import { CheckCircleIcon } from "@heroicons/react/16/solid";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import BuildingOffice2Icon from "@heroicons/react/24/outline/BuildingOffice2Icon";
import EnvelopeIcon from "@heroicons/react/24/outline/EnvelopeIcon";
import IdentificationIcon from "@heroicons/react/24/outline/IdentificationIcon";
import MapPinIcon from "@heroicons/react/24/solid/MapPinIcon";
import PhoneIcon from "@heroicons/react/24/outline/PhoneIcon";
import { Box, FormControl, Typography, useTheme } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { TeamConfigRequest } from "@/services/generated";

type ConfigureStep = {
  formName?: keyof TeamConfigRequest;
  label: string;
  icon: JSX.Element;
  optional?: boolean;
};

const STEPS: ConfigureStep[] = [
  { label: "Name", icon: <IdentificationIcon width={24} /> },
  {
    label: "Phone #",
    icon: <PhoneIcon width={24} />,
  },
  {
    label: "Email",
    icon: <EnvelopeIcon width={24} />,
  },
  {
    formName: "is_require_company",
    label: "Company",
    icon: <BuildingOffice2Icon width={24} />,
  },
  {
    formName: "is_require_job_title",
    label: "Job Title",
    icon: <UserCircleIcon width={24} />,
  },
  {
    formName: "is_require_address",
    label: "Address",
    icon: <MapPinIcon width={24} />,
  },
];

export default function ConfigurationSteps({ control }: { control: Control<TeamConfigRequest> }) {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, width: "max-content" }}>
      <Typography fontWeight={400} color={"#1A2135"} fontSize="14px">
        Basic Info
      </Typography>
      {STEPS.map(({ formName, label, icon }) => (
        <Box
          key={label}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            border: `1px solid ${theme.palette.border.default}`,
            borderRadius: "8px",
            padding: "10px",
            width: 322,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Box sx={{ bgcolor: theme.palette.primary.main, p: "4px", borderRadius: "4px", lineHeight: 0 }}>{icon}</Box>
            <Typography fontWeight={700} color={"#1A2135"}>
              {label}
            </Typography>
          </Box>
          {!formName && <CheckCircleIcon color={theme.palette.commonColors.disabled} width={16} />}

          {formName && (
            <FormControl>
              <Controller
                name={formName}
                control={control}
                render={({ field }) => (
                  <Switch value={!!field.value} onChange={(event) => field.onChange(event?.target.checked)} />
                )}
              />
            </FormControl>
          )}
        </Box>
      ))}
    </Box>
  );
}
