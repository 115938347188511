import { openViewDrawer, setCurrentProspect } from "@/redux/reducers/prospects/slices/listProspects";
import { IconButton, Typography } from "@mui/material";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { ReactComponent as NoProspectsSVG } from "@/common/assets/svg/Prospects/zero-prospect-graph.svg";
import { ReactComponent as ArrowDownOnSquare } from "@/common/assets/svg/arrow_down_on_square.svg";
import TsTable from "@/common/components/ts-table";
import { ProspectFinder } from "@/redux/reducers/prospects/finder/slices/types";
import { useAppDispatch } from "@/redux/store";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import { useAppSelector } from "@/redux/store";
import { createContactFromProspectFinder } from "@/redux/reducers/prospects/finder/slices/listProspectFinder";

const columnHelper = createColumnHelper<ProspectFinder>();

const useColumns = () => {
  const dispatch = useAppDispatch();
  return [
    columnHelper.accessor("prospect", {
      header: () => (
        <div style={{ marginLeft: "16px" }}>
          <Typography variant="body3" fontWeight="600">
            Prospect
          </Typography>
        </div>
      ),

      cell: (info) => {
        const original = info.row.original;
        const fullName = `${original?.firstName} ${original?.lastName}`.trim();
        return (
          <div style={{ marginLeft: "16px" }}>
            <Typography variant="body2">{fullName}</Typography>
          </div>
        );
      },
    }),

    columnHelper.accessor("jobTitle", {
      header: () => (
        <Typography variant="body3" fontWeight="600">
          Job Title
        </Typography>
      ),
      cell: ({ row }) => {
        return <Typography variant="body2">{row.original?.jobTitle}</Typography>;
      },
    }),

    columnHelper.accessor("location", {
      header: () => (
        <Typography variant="body3" fontWeight="600">
          Location
        </Typography>
      ),
      cell: ({ row }) => {
        return <Typography variant="body2">{row.original.personalCity}</Typography>;
      },
    }),

    columnHelper.accessor("industry", {
      header: () => (
        <Typography variant="body3" fontWeight="600">
          Department
        </Typography>
      ),
      cell: ({ row }) => {
        return <Typography variant="body2">{row.original.department}</Typography>;
      },
    }),
    columnHelper.accessor("company", {
      id: "Company",
      header: () => (
        <Typography variant="body3" fontWeight="600">
          Company
        </Typography>
      ),
      cell: ({ row }) => {
        return <Typography variant="body2">{row.original.companyDomain || ""}</Typography>;
      },
    }),

    columnHelper.display({
      id: "actions",
      header: () => (
        <Typography variant="body3" fontWeight="600">
          Actions
        </Typography>
      ),
      cell: ({ row }) => {
        return (
          <IconButton>
            <ArrowDownOnSquare
              height="24px"
              width="24px"
              color="#1A2135"
              onClick={() => {
                if (!row.original.id) return;
                dispatch(createContactFromProspectFinder(row.original));
              }}
            />
          </IconButton>
        );
      },
    }),
  ];
};

export default function ProspectFinderTable({
  contacts,
  totalCount,
  pageSize,
  pageIndex,
  onPaginationChange,
}: {
  contacts: ProspectFinder[];
  totalCount: number;
  pageSize: number;
  pageIndex: number;
  onPaginationChange: any;
}) {
  const dispatch = useAppDispatch();

  const handleCellClick = (params: any) => {
    dispatch(setCurrentProspect(params));
    dispatch(openViewDrawer());
  };

  const loading = useAppSelector((state) => state.prospectFinder.listProspectsFinder?.loading);

  const columns = useColumns();
  const table = useReactTable<any>({
    data: contacts,

    columns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: totalCount,
    manualPagination: true,
    state: {
      pagination: {
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    },
    onPaginationChange,
  });

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <TsTable
          table={table}
          handleCellClick={handleCellClick}
          notFoundProps={{
            title: "No prospects found",
            description: (
              <span>
                Begin adding or importing prospects by clicking the <br /> <b>"Import Prospects"</b> and{" "}
                <b>"Add Prospect"</b> buttons above.
              </span>
            ),

            Icon: NoProspectsSVG,
          }}
        />
      )}
    </>
  );
}
