import Avatar from "@/common/components/avatar";
import { AiAgent } from "@/types/ai-voice";
import { ArrowUpRightIcon } from "@heroicons/react/16/solid";
import { ArrowDownLeftIcon } from "@heroicons/react/24/outline";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { CALL_FLOW_TYPE_OPTIONS } from "../constants";
import AiAgentActionButton from "./action-button";

interface AIAgentCardProps {
  aiAgent: AiAgent;
  showCallTest: boolean;
  openCallTest: () => void;
}

export default function AIAgentCard({ aiAgent, showCallTest, openCallTest }: AIAgentCardProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        overflow: "hidden",
        gap: 2,
        display: "flex",
        flexDirection: "column",
        bgcolor: theme.palette.common.white,
        px: 2,
        py: 1.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Avatar src={aiAgent.voice_image_url} size="xlarge" label={aiAgent.voice_name} />
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6" sx={{ fontSize: 16, fontWeight: 500, color: theme.palette.commonColors.text }}>
            {aiAgent?.agent_label || aiAgent?.voice_name}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 14, color: theme.palette.commonColors.text }}>
            {aiAgent.phone_number}
          </Typography>
        </Box>
        <AiAgentActionButton
          agent={{ id: aiAgent.agent_id! }}
          showCallTest={showCallTest}
          openCallTest={openCallTest}
        />
      </Box>

      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {aiAgent.call_flow && (
          <>
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                fontWeight: "bold",
                gap: 1,
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  bgcolor: theme.palette.midnight[20],
                  borderRadius: "100%",
                  width: 28,
                  height: 28,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {aiAgent.call_direction === "OUTBOUND" ? (
                  <ArrowUpRightIcon height={20} />
                ) : (
                  <ArrowDownLeftIcon height={20} />
                )}
              </Box>
              {CALL_FLOW_TYPE_OPTIONS[aiAgent.call_flow]?.label}
            </Typography>
            <Box
              sx={{
                p: 1,
                bgcolor: theme.palette.midnight[20],
                borderRadius: 2,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: "14px",
                  color: theme.palette.commonColors.text,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {CALL_FLOW_TYPE_OPTIONS[aiAgent.call_flow]?.description}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
