import Dropzone, { Accept } from "react-dropzone";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Line } from "rc-progress";
import { useUploadPercent } from "./useUploadPercent";

type DragAndDropProps = {
  onSetFile?: (file: File) => void;
  file?: File;
  accept?: Accept;
};

export default function DragAndDrop({ file: defaultFile, onSetFile, accept }: DragAndDropProps) {
  const [file, setFile] = useState<File | null>(defaultFile ?? null);
  const { percent, handleDropInterval, isUploading } = useUploadPercent();
  const theme = useTheme();

  const UploadText = () => {
    switch (file) {
      case null:
        return (
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.txt.default,
              textAlign: "center",
            }}
          >
            Drag and drop an CSV file here with the prospects <br /> or{" "}
            <Box
              sx={{
                color: theme.palette.commonColors.informative,
                cursor: "pointer",
                display: "inline",
              }}
            >
              click here
            </Box>{" "}
            to search in your computer
          </Typography>
        );
      default:
        if (isUploading) {
          return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Typography variant="body2">
                Wait while your document <span style={{ fontWeight: "600" }}>{file.name} </span> is uploaded
              </Typography>
              <Typography variant="body2">{percent}%</Typography>
            </Box>
          );
        } else
          return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Typography variant="body2">
                The file <span style={{ fontWeight: "600" }}>{file.name}</span> has been uploaded successfully
              </Typography>
              <Typography variant="body2">{percent}%</Typography>
            </Box>
          );
    }
  };

  return (
    <Dropzone
      accept={accept}
      onDrop={(acceptedFiles) => {
        onSetFile?.(acceptedFiles[0]);
        setFile(acceptedFiles[0]);
        handleDropInterval();
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <Box
          sx={{
            border: `1px dashed ${theme.palette.commonColors.light}`,
            borderColor:
              file?.name && !isUploading
                ? theme.palette.btnPrimary.pressed
                : isUploading
                ? theme.palette.border.focus
                : theme.palette.border.default,
            background: file?.name && !isUploading ? theme.palette.mint[20] : theme.palette.bg.default,
            borderRadius: "10px",
            height: "180px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            cursor: "pointer",
          }}
          {...getRootProps()}
        >
          <Box>
            {isUploading ? <UploadingIcon /> : file?.name ? <SuccessIcon /> : <UploadIcon />}
            <input {...getInputProps()} id="dragAndDrop" />
          </Box>
          <Box>
            <UploadText />
          </Box>
          <Box>
            {file?.name && (
              <Line
                percent={percent}
                strokeColor={percent === 100 ? "#05CF74" : "#2C72E2"}
                strokeLinecap="round"
                style={{ width: "150px", height: "5px", borderRadius: "5px", margin: "0 auto" }}
              />
            )}
          </Box>
        </Box>
      )}
    </Dropzone>
  );
}

const SuccessIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49" fill="none">
    <path
      d="M20.25 5H11.25C10.0074 5 9 6.00736 9 7.25V41.75C9 42.9926 10.0074 44 11.25 44H36.75C37.9926 44 39 42.9926 39 41.75V23.75M20.25 5H21C30.9411 5 39 13.0589 39 23V23.75M20.25 5C23.9779 5 27 8.02208 27 11.75V14.75C27 15.9926 28.0074 17 29.25 17H32.25C35.9779 17 39 20.0221 39 23.75M18 30.5L22.5 35L30 24.5"
      stroke="#55BF75"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const ErrorIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path
      d="M24 18V25.5M42 24C42 33.9411 33.9411 42 24 42C14.0589 42 6 33.9411 6 24C6 14.0589 14.0589 6 24 6C33.9411 6 42 14.0589 42 24ZM24 31.5H24.015V31.515H24V31.5Z"
      stroke="#FB2D67"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const UploadingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49" fill="none">
    <path
      d="M39 29V23.75C39 20.0221 35.9779 17 32.25 17H29.25C28.0074 17 27 15.9926 27 14.75V11.75C27 8.02208 23.9779 5 20.25 5H16.5M30 29L24 23M24 23L18 29M24 23L24 35M21 5H11.25C10.0074 5 9 6.00736 9 7.25V41.75C9 42.9926 10.0074 44 11.25 44H36.75C37.9926 44 39 42.9926 39 41.75V23C39 13.0589 30.9411 5 21 5Z"
      stroke="#2C72E2"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const UploadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    style={{
      width: "40px",
      height: "40px",
      color: "#2C72E2",
    }}
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
    />
  </svg>
);
