import { selectInboxViewDrawerIsOpen } from "@/redux/selectors";
import { closeViewDrawer } from "@/redux/reducers/inbox/slices/getInboxData";
import DrawerLayout from "@/layouts/DrawerLayout";
import Drawer from "@mui/material/Drawer";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { sendEmail } from "@/redux/reducers/inbox/slices/sendEmail";
import { ViewInboxMessages } from "./ViewInboxMessages";
import { useAppSelector, useAppDispatch } from "@/redux/store";
import { useAuth0 } from "@auth0/auth0-react";
import { EmailDTO } from "@/services/generated";

const ViewInboxMessagesDrawer = () => {
  const dispatch = useAppDispatch();
  const viewDrawerIsOpen = useAppSelector(selectInboxViewDrawerIsOpen);
  const currentProspect = useAppSelector(({ prospects }) => prospects.listProspects.currentProspect);
  const methods = useForm();
  const { handleSubmit } = methods;
  // TODO: Fix
  const fullProspectName = `${currentProspect?.firstName} ${currentProspect?.lastName}` || "Unknown";
  const { user } = useAuth0();

  const onSubmit = (data: EmailDTO) => {
    dispatch(
      sendEmail({
        team_id: user?.team_id,
        from_user_id: user?.sub,
        from: data?.from,
        prospect_id: currentProspect?.id,
        action: "SEND",
        subject: data?.subject,
        body: data?.body,
        source: "OUTBOUND",
      }),
    );

    toast.success("Message sent successfully");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Drawer open={viewDrawerIsOpen} onClose={() => dispatch(closeViewDrawer())} anchor="right">
          <DrawerLayout title={fullProspectName} closeDrawer={() => dispatch(closeViewDrawer())} customWidth="980px">
            <ViewInboxMessages />
          </DrawerLayout>
        </Drawer>
      </FormProvider>
    </form>
  );
};

export default ViewInboxMessagesDrawer;
