import Button from "@/common/components/button";
import { Box, Divider, Typography } from "@mui/material";
import { acceptTermsOfService } from "@/redux/reducers/settings/user";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";

const Title = ({ children }: { children: string }) => (
  <Typography
    variant="body1"
    sx={{
      fontWeight: "600",
      lineHeight: "24px",
    }}
  >
    {children}
  </Typography>
);

const Text = ({ children }: { children: string }) => (
  <Typography
    variant="body2"
    sx={{
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "16px",
    }}
  >
    {children}
  </Typography>
);

type TermsOfServicePageProps = {
  onAccept?: () => void;
};

export default function TermsOfServicePage({ onAccept }: TermsOfServicePageProps) {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((selector) => selector.settings.user.loading);

  const handleAccept = () => {
    dispatch(acceptTermsOfService())
      .unwrap()
      .then(() => {
        onAccept?.();
      });
  };

  return (
    <>
      <FullLoadingOverlay loading={loading} overlay />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          paddingTop: "32px",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            lineHeight: "28px",
          }}
        >
          KENNECTED TERMS OF SERVICE
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Title>Acceptance of the Terms</Title>
          <Text>
            These Kennected Terms of Service (these “Terms”) are entered into by and between Kennected, Inc., the owner
            and operator of www.kennected.io and any services offered via the Platform (as defined below) (“Kennected,”
            “us,” “we,” or “our”), and you, a user of the Platform (“you,” “your,” or “user(s)”). The Kennected website,
            extension, app, and/or any public facing piece of technology of Kennected shall be referred to collectively
            as the “Platform.” These Terms, together with all documents, policies, and agreements referenced herein,
            govern your access to and use of the Platform, including any content, functionality, products, and services
            offered on or through the Platform, so please read them carefully before you access or use the Platform. 
            Your access to and use of the Platform is conditioned on your acceptance of and compliance with these Terms.
            These Terms apply to all visitors, users, and others who access or use the Platform. By accessing or using
            the Platform or by clicking to accept or agree to these Terms when that option is made available to you, you
            accept and agree to be bound and abide by these Terms and our Privacy Policy, found at [Insert hyperlink to
            KN’s Privacy Policy] (“Privacy Policy”), incorporated herein by reference. If you disagree with any part of
            these Terms or our Privacy Policy, then you must not access or use the Platform.  The Platform is offered
            and available only to users who are over the age of eighteen (18) and have the legal capacity to enter into
            a binding agreement. By using the Platform, you represent that you meet all the foregoing eligibility
            requirements. If you do not meet all of these requirements, you must not access or use the Platform. 
          </Text>
          <Title>Changes to Terms</Title>
          <Text>
            We may revise and update these Terms from time to time in our sole discretion. All changes are effective
            immediately when we post them and apply to all access to and use of the Platform thereafter. Your continued
            use of the Platform following the posting of revised Terms means that you accept and agree to the changes.
            You are expected to check this page each time you access the Platform so you are aware of any changes, as
            they are binding on you.
          </Text>
          <Title>Login, Sign-up, and Account Security</Title>
          <Text>
            Portions of the Platform may require you to create a login or sign-up for an account. You must fully
            complete the registration process by providing us with your current, complete, truthful, and accurate
            information as prompted by the applicable registration form. In order to complete your sign-up, we may
            require you to agree to Additional Agreements (as defined below). Upon sign-up Kennected may assign you a
            username and password where required. You are entirely responsible for maintaining the confidentiality of
            your username, password, and account and for all activities that occur under your account. You agree to
            notify Kennected immediately of any unauthorized use of your account or any other breach of security.
            Kennected will not be liable for any losses you incur as a result of someone else using your username,
            password, or account, either with or without your knowledge. However, you could be held liable for losses
            incurred by Kennected or another party due to someone else using your username, account, or password. You
            may not use anyone else’s account at any time without the permission of the account holder. We reserve the
            right to restrict or refuse sign-up or use of the Platform for any users of the Platform for any reason
            whatsoever and at any time. You also agree to ensure that you exit from your account at the end of each
            session. You should use particular caution when accessing your account from a public or shared computer so
            that others are not able to view or record your password or other personal information. We have the right to
            disable any username, password, or other identifier, whether chosen by you or provided by us, at any time
            if, in our opinion, you have violated any provision of these Terms.
          </Text>
          <Title>Accessing the Platform</Title>
          <Text>
            From time to time, we may restrict access to some parts of the Platform, or the entire Platform. You are
            responsible for both (a) making all arrangements necessary for you to have access to the Platform and (b)
            ensuring that all individuals or entities who access the Platform through your internet connection are aware
            of these Terms and comply with them. It is a condition of your use of the Platform that all the information
            you provide on the Platform is correct, current, and complete. You agree that all information you provide to
            access or register with the Platform or otherwise, including, without limitation, through the use of any
            interactive features on the Platform, is governed by our Privacy Policy, and you consent to all actions we
            take with respect to your information consistent with our Privacy Policy.
          </Text>
          <Title>Platform Availability and Modification</Title>
          <Text>
            Although we will attempt to provide continuous Platform availability to you, we do not guarantee that the
            Platform will always be available, work, or be accessible at any particular time. We will not be liable if
            for any reason all or any part of the Platform is unavailable at any time or for any period. We reserve the
            right to alter, modify, update, suspend, discontinue, or remove the Platform, or portions thereof, and any
            service or material we provide on the Platform, in our sole discretion without notice at any time. We may
            conduct such modifications to the Platform and/or our services for security reasons, intellectual property,
            legal reasons, or various other reasons at our discretion, and we are not required to explain such
            modifications. For example, we may provide updates to fix security flaws or to respond to legal demands.
            Please note that this is a non-binding illustration of how we might exercise our rights under this section,
            and nothing in this section obligates us to take measures to update the Platform or our services for
            security, legal, or other reasons.
          </Text>
          <Title>Additional Agreements</Title>
          <Text>
            In order to access some portions of the Platform or some services offered via the Platform, you may be
            required to agree and execute a services agreement or other relevant agreements (collectively, “Additional
            Agreements”) as required by Kennected. The Additional Agreements should be read in conjunction with these
            Terms and may obligate you to additional responsibilities when using the Platform. If you wish to learn more
            about any Kennected services, please contact us at support@kennected.io.
          </Text>{" "}
          <Title>Reliance on Information Posted; Third-Party Links</Title>
          <Text>
            The information presented on or through the Platform is made available solely for general information
            purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you
            place on such information is strictly at your own risk. We disclaim all liability and responsibility arising
            from any reliance placed on such materials by you or any other visitor to the Platform, or by anyone who may
            be informed of any of its contents.
          </Text>
          <br />
          <Text>
            The Platform may contain content from or links to third-party websites that are not owned or controlled by
            Kennected. If the Platform contains links to other websites and/or resources provided by third parties,
            these links are provided for your convenience only. Kennected has no control over, and assumes no
            responsibility for, the content, privacy policies, or practices of any third-party websites. All statements
            and/or opinions expressed in those materials, and all articles and responses to questions and other content,
            other than the content provided by us, are solely the opinions and the responsibility of the individual or
            entity providing those materials. Those materials do not necessarily reflect the opinion of Kennected. We
            are not responsible, or liable to you or any third party, for the content or accuracy of any materials
            provided by any third parties. In addition, Kennected will not and cannot censor or edit the content of any
            third-party website. If you decide to access any of the third-party websites linked to the Platform, you do
            so entirely at your own risk and subject to the terms and conditions of use for such websites. By using the
            Platform, you expressly relieve Kennected from all liability arising from your use of any third-party
            website.
          </Text>
          <Title>Social Media Features</Title>
          <Text>
            The Platform may provide certain social media features that enable you to: (a) link from your own or certain
            third-party websites to certain content on the Platform, (b) send communications with certain content, or
            links to certain content, on the Platform, and/or (c) cause limited portions of content on the Platform to
            be displayed or appear to be displayed on your own or certain third-party websites.
          </Text>
          <br />
          <Text>
            You may use these features solely as they are provided by us, solely with respect to the content they are
            displayed with, and otherwise in accordance with any additional terms and conditions we provide with respect
            to such features. The website from which you are linking, or on which you make certain content accessible,
            must comply in all respects with the content standards set out in these Terms. You agree to cooperate with
            us in causing any unauthorized framing or linking immediately to stop. We reserve the right to withdraw
            linking permission without notice. We may disable all or any social media features and any links at any time
            without notice in our discretion and without any liability to you.
          </Text>
          <Title>Your License</Title>
          <Text>
            By accepting these Terms, we grant you a personal, non-exclusive, revocable, non-transferable, limited
            license to access the Platform as provided by us. This license is subject to your compliance with these
            Terms and all Additional Agreements, as applicable. Please be aware that this license does not grant you any
            ownership interest in any portions of the Platform or to any copyrighted content found on the Platform. Such
            copyrights and intellectual property rights are owned solely by Kennected or its third-party licensors.
            Notwithstanding anything to the contrary in these Terms or any Additional Agreements, we may revoke your
            license to access the Platform at any time and without notice.
          </Text>{" "}
          <Title>Your Conduct While Using the Platform; Prohibited Uses</Title>
          <Text>
            When accessing or using the Platform, you are solely responsible for your use and for any use of the
            Platform made using your account. You may use the Platform only for lawful purposes and in accordance with
            these Terms.
          </Text>
          <br />
          <Typography
            variant="body2"
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "16px",
            }}
          >
            You agree to abide by the following rules of conduct:
            <ul>
              <li>
                You will not copy, distribute, reverse engineer, or disclose any part of the Platform in any medium,
                including, without limitation, by any automated or non-automated “scraping.”
              </li>
              <li>
                You will not attempt to interfere with, compromise the system integrity or security, or decipher any
                transmissions to or from the servers running the Platform.{" "}
              </li>
              <li>
                You will not use any robot, spider, crawler, scraper or other automated means or interface not provided
                by us to access the Platform or to extract or export data collected through the Platform. 
              </li>
              <li>
                You will not take any action that imposes, or may impose at our sole discretion, an unreasonable or
                disproportionately large load on our infrastructure. 
              </li>
              <li>You will not use the Platform to stalk, harass, bully, or harm another individual.</li>
              <li>You will not hold Kennected responsible for your use of the Platform. </li>
              <li>
                You will not violate any requirements, procedures, policies, or regulations of networks connected to
                Kennected.
              </li>
              <li>You will not interfere with or disrupt the Platform.</li>{" "}
              <li>
                You will not use the Platform in any way that violates any applicable federal, state, local, or
                international law or regulation (including, without limitation, any laws regarding the export of data or
                software to and from the US or other jurisdictions, the CAN-SPAM Act, and the Telephone Consumer
                Protection Act).
              </li>{" "}
              <li>
                You will not use the Platform in any way that is misleading, unlawful, defamatory, obscene, invasive,
                threatening, or harassing. 
              </li>{" "}
              <li>
                You will not use the Platform for the purpose of exploiting, harming, or attempting to exploit or harm
                minors in any way by exposing them to inappropriate content, asking for personally identifiable
                information, or otherwise.
              </li>{" "}
              <li>
                You will not use the Platform to send, knowingly receive, upload, download, use, or re-use any material
                that does not comply with the content standards set out in these Terms.
              </li>{" "}
              <li>
                You agree not to use the Platform to transmit, or procure the sending of, any advertising or promotional
                material without our prior written consent, including any “junk mail,” “chain letter,” “spam,” or any
                other similar solicitation.
              </li>{" "}
              <li>
                You will not use the Platform to impersonate or attempt to impersonate us, our employees, another user,
                or any other individual or entity (including, without limitation, by using email addresses or screen
                names associated with any of the foregoing).
              </li>{" "}
              <li>
                You will not use the Platform to engage in any other conduct that restricts or inhibits anyone’s use or
                enjoyment of the Platform, or which, as determined by us, may harm us or users of the Platform, or
                expose them to liability.
              </li>{" "}
              <li>
                You will not introduce any viruses, Trojan horses, worms, logic bombs, or other material that is
                malicious or technologically harmful to the Platform.
              </li>{" "}
              <li>
                You will not attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the
                Platform, the server on which the Platform is stored, or any server, computer, or database connected to
                the Platform. 
              </li>{" "}
              <li>You will not otherwise attempt to interfere with the proper working of the Platform.</li>
            </ul>
          </Typography>
          <Text>
            If you are discovered to be undertaking any of the aforementioned actions your privileges to use the
            Platform may at our discretion be terminated or suspended without liability to you or any third party.
            Generally, we will provide an explanation for any suspension or termination of your use of the Platform, but
            Kennected reserves the right to suspend or terminate any account at any time without notice or explanation.
          </Text>
          <Title>Your Content</Title>
          <Text>
            Your ability to submit or transmit any information through the Platform, including, without limitation,
            text, audio messages, videos, photos, images, or any other information will be referred to as “User
            Contributions” throughout these Terms. All User Contributions must comply with the content standards set out
            in these Terms. You are solely responsible for any User Contributions you submit or contribute to the
            Platform, and you, not Kennected, have full responsibility for such content, including its legality,
            reliability, accuracy, and appropriateness. We are not responsible or liable to any third party for the
            content or accuracy of any User Contributions posted by you or any other user of the Platform.
          </Text>
          <Text>
            Any User Contribution you submit to the Platform will be considered non-confidential and non-proprietary.
            When you submit any User Contributions to us, you grant Kennected, its partners, affiliates, users,
            representatives, and assigns a non-exclusive, fully-paid, royalty-free, irrevocable, worldwide, universal,
            transferable, assignable license to display, publicly perform, distribute, store, broadcast, transmit,
            reproduce, modify, prepare derivative works, and otherwise use and reuse all or part of your User
            Contributions. Additionally, you agree that Kennected may use User Contributions, in an aggregated form from
            which all personally identifiable information has been removed, for purposes of benchmarking system
            performance, preparing statistics and system metrics, marketing, and other purposes. You represent and
            warrant that (a) you own or control all rights in and to your User Contributions and have the right to grant
            the license granted above to us and our affiliates and service providers, and each of their and our
            respective licensees, successors, and assigns and (b) all your User Contributions do and will comply with
            these Terms.
          </Text>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "32px",
          }}
        >
          <Box
            sx={{
              width: "35%",
            }}
          >
            <Button fullWidth onClick={handleAccept}>
              Accept
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
