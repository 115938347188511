import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { UserGroupIcon, CogIcon, ArrowRightStartOnRectangleIcon } from "@heroicons/react/24/outline";
import routes from "@/common/constants/routes";

const useHeaderMenuItems = () => {
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const handleLogout = () => {
    return logout({
      logoutParams: {
        returnTo: `${window.location.origin}`,
      },
    });
  };

  const headerMenuItems = [
    {
      label: "Community",
      icon: <UserGroupIcon width={28} color="#1A2135" />,
      onClick: () => window.open("https://salesai-community.mn.co/", "_blank", "noreferrer"),
    },
    {
      label: "Settings",
      icon: <CogIcon width={28} color="#1A2135" />,
      onClick: () => navigate(routes.settings.path),
    },
    {
      label: "Log Out",
      icon: <ArrowRightStartOnRectangleIcon width={28} color="#1A2135" />,
      onClick: () => handleLogout(),
    },
  ];

  return { headerMenuItems };
};

export default useHeaderMenuItems;
