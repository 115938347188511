import Button from "@/common/components/button";
import Box from "@mui/material/Box";
import { PaperAirplaneOutline } from "heroicons-react";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { useFormContext } from "react-hook-form";

export const ActionButtons = ({
  sendText = "Send",
  showGenerate = true,
  onSubmit,
  onError,
  isPending,
  disabled,
  setIsGenerating,
  aiMessage = "Respond with AI",
}: {
  onSubmit: any;
  onError?: any;
  sendText?: string;
  showGenerate?: boolean;
  isPending?: boolean;
  disabled?: boolean;
  aiMessage?: string;
  setIsGenerating?: (isGenerating: boolean) => void;
}) => {
  const methods = useFormContext();

  return (
    <Box
      sx={{
        display: "flex",

        justifyContent: sendText === "Make a Call" ? "end" : "space-between",
        alignItems: "center",
      }}
    >
      {showGenerate ? (
        <Button
          fullWidth
          size="sm"
          variant="secondary"
          leftIcon={<SparklesIcon />}
          sx={{ width: "fit-content" }}
          type="button"
          onClick={() => {
            setIsGenerating && setIsGenerating(true);
          }}
        >
          {aiMessage}
        </Button>
      ) : null}
      <Button
        sx={{ width: "fit-content" }}
        onClick={methods.handleSubmit(onSubmit, onError)}
        size="sm"
        fullWidth
        type="button"
        loading={isPending}
        disabled={isPending || disabled}
        rightIcon={
          <PaperAirplaneOutline
            style={{
              transform: "rotate(90deg)",
            }}
          />
        }
        variant="primary"
      >
        {sendText}
      </Button>
    </Box>
  );
};
