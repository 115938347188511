import { Typography } from "@mui/material";
import { useFormContext, Controller, ControllerRenderProps, FieldValues } from "react-hook-form";

const MEETING_STATE_OPTIONS = [
  {
    label: "Created",
    value: "CREATED",
  },
  // {
  //   label: "Updated",
  //   value: "UPDATED",
  // },
  // {
  //   label: "Completed",
  //   value: "COMPLETED",
  // },
  // {
  //   label: "Canceled",
  //   value: "CANCELED",
  // },
];

export const MeetingStateChangesType = () => {
  const methods = useFormContext();
  const { control } = methods;

  const renderOption = (
    field: ControllerRenderProps<FieldValues, "event.meetingState">,
    option: { label: string; value: string },
  ) => {
    return (
      <label key={option.value} style={{ cursor: "pointer", width: "100%" }}>
        <div style={{ display: "flex", gap: "5px" }}>
          <input type="radio" checked={option.value === field.value} onChange={() => field.onChange(option.value)} />
          <Typography variant="body2">{option.label}</Typography>
        </div>
      </label>
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="body2" sx={{ fontWeight: 500 }}>
        Meeting State
      </Typography>
      <Controller
        name="event.meetingState"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <div style={{ display: "flex", flexDirection: "column", gap: "16px", paddingTop: "8px" }}>
            {MEETING_STATE_OPTIONS.map((option) => renderOption(field, option))}
          </div>
        )}
      />
    </div>
  );
};
