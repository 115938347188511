import { PhoneOutline, ViewListOutline } from "heroicons-react";
import { Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@/common/components/tab-list";
import { ChatBubbleBottomCenterTextIcon, EnvelopeIcon } from "@heroicons/react/24/outline";

export enum TabValue {
  SMS = "SMS",
  EMAIL = "EMAIL",
  CALLS = "CALLS",
  ACTIVITY = "ACTIVITY",
}

type Props = {
  showTabs?: boolean;
  setTabValue: (value: TabValue) => void;
  tabValue: string;
  onTabChange?: (event: React.SyntheticEvent, newValue: TabValue) => void;
  isNewMessage?: boolean;
};

export const InboxTabSelector = (props: Props) => {
  const { setTabValue, tabValue = "email", onTabChange, isNewMessage = false } = props;

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    if (!newValue) return;
    setTabValue(newValue);
    onTabChange?.(event, newValue);
  };

  return (
    <TabContext value={tabValue}>
      <TabList
        onChange={handleTabChange}
        aria-label="Tabs"
        variant="fullWidth"
        sx={{ padding: "0px", width: "100%", border: "1px solid #E7EBED", borderRadius: "8px", minHeight: "42px" }}
      >
        <Tab
          iconPosition="start"
          icon={<ChatBubbleBottomCenterTextIcon width="24" height="24" />}
          label="SMS"
          value={TabValue.SMS}
        />
        <Tab iconPosition="start" icon={<EnvelopeIcon width={24} height={24} />} label="Email" value={TabValue.EMAIL} />
        <Tab iconPosition="start" icon={<PhoneOutline />} label="Calls" value={TabValue.CALLS} />
        {!isNewMessage ? (
          <Tab iconPosition="start" icon={<ViewListOutline />} label="Activity" value={TabValue.ACTIVITY} />
        ) : null}
      </TabList>
    </TabContext>
  );
};
