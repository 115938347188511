import { Box, SxProps, Theme, useTheme } from "@mui/material";
import React from "react";

export interface IconButtonProps {
  children?: React.ReactNode;
  loading?: boolean;
  rounded?: boolean;
  sx?: SxProps<Theme>;
  size?: "sm" | "md" | "lg";
  variant?: "primary" | "secondary" | "danger";
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  fullWidth?: boolean;
  onClick?: (e: any) => void;
}

const IconButton: React.FC<IconButtonProps> = ({
  children,
  loading,
  rounded = true,
  size = "md",
  variant = "primary",
  disabled,
  fullWidth,
  sx,
  ...rest
}) => {
  const theme = useTheme();
  const buttonStyle = {
    transition: "all 0.3s ease",
    borderRadius: rounded ? "8px" : "0",
    padding: size === "lg" ? "16px 24px" : size === "md" ? "12px 16px" : "8px 16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: size === "sm" ? "14px" : "16px",
    border: "2px solid transparent",
    width: fullWidth ? "100%" : "auto",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0.2px",
    cursor: "pointer",
  };

  const iconStyle = {
    height: size === "sm" ? "16px" : size === "md" ? "24px" : "32px",
    width: size === "sm" ? "16px" : size === "md" ? "24px" : "32px",
    display: "flex",
    alignItems: "center",

    "&:disabled": {
      color: variant === "secondary" ? "#D5D5D5" : "",
    },
  };

  const primaryVariant = {
    ...buttonStyle,
    backgroundColor: "#5ECF81",

    color: "#1A2135",
    "&:hover": {
      backgroundColor: "#70F4B8",
    },
    "&:active": {
      backgroundColor: "#55BF75",
    },
    "&:disabled": {
      backgroundColor: "#D5D5D5",
    },
    "&:focus-visible": {
      border: "2px solid #54658D",
      outline: "none",
    },
  };

  const secondaryVariant = {
    ...buttonStyle,
    backgroundColor: "transparent",
    color: "#1A2135",
    borderWidth: "2px",
    border: "2px solid #1A2135",

    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "#54658D",
    },
    "&:active": {
      backgroundColor: "transparent",
      borderColor: "#54658D",
      color: "#54658D",
    },
    "&:disabled": {
      color: "#D5D5D5",
      borderColor: "#D5D5D5",
    },
    "&:focus": {
      border: "2px solid #54658D",
    },
  };

  const dangerVariant = {
    ...buttonStyle,
    backgroundColor: "transparent",
    color: theme.palette.commonColors.danger,
    borderWidth: "2px",
    border: `2px solid ${theme.palette.commonColors.danger}`,

    "&:hover": {
      backgroundColor: "transparent",
      borderColor: theme.palette.commonColors.danger10,
      color: theme.palette.commonColors.danger10,
    },
    "&:active": {
      backgroundColor: "transparent",
      borderColor: theme.palette.commonColors.danger10,
      color: theme.palette.commonColors.danger10,
    },
    "&:disabled": {
      borderColor: theme.palette.commonColors.danger10,
      color: theme.palette.commonColors.danger10,
    },
    "&:focus": {
      // TODO: Add
    },
  };

  const variantStyle =
    {
      danger: dangerVariant,
      primary: primaryVariant,
      secondary: secondaryVariant,
    }[variant] || {};

  return (
    <Box
      component="button"
      disabled={loading || disabled}
      sx={{
        ...variantStyle,
        ...(sx || {}),
      }}
      {...rest}
    >
      <Box component="span" sx={iconStyle}>
        {children}
      </Box>
    </Box>
  );
};

export default IconButton;
