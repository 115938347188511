import Services from "@/services";
import { SmsDTO } from "@/services/generated";
import { createAsyncThunk } from "@reduxjs/toolkit";

type Params = {
  from_user_id: string;
  prospect_id: string;
  team_id: string;
  sequence_id: string;
  action: SmsDTO["action"];
  body: string;
};

export const createInboxSms = createAsyncThunk("inbox/createInboxSms", async (params: Params) => {
  try {
    const { from_user_id, prospect_id, team_id, sequence_id, action, body } = params;

    const response = await Services.Inbox.createSms({
      from_user_id,
      prospect_id,
      team_id,
      sequence_id,
      action,
      body,
    });

    return response;
  } catch (error: any) {
    return error;
  }
});
