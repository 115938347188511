import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useEffect } from "react";
import { useAppDispatch } from "@/redux/store";
import { getActivities } from "@/redux/reducers/dashboard/slices/list";
import { useAppSelector } from "@/redux/store";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import { useFormContext } from "react-hook-form";
import NoAutomatedActivities from "./emptyData/NoAutomatedActivities";
import { EntityActivities } from "@/services/generated";

ChartJS.register(CategoryScale, BarElement, LinearScale, PointElement, LineElement, Title, ChartTooltip, Legend);

export default function AutomatedActivities() {
  const dispatch = useAppDispatch();
  const data = useAppSelector(({ dashboard }) => dashboard.list.activities);
  const activitiesPending = useAppSelector(({ dashboard }) => dashboard.list.activitiesPending);
  const { watch } = useFormContext();
  const dateRangeFilter = watch("dateRangeFilter");

  useEffect(() => {
    dispatch(getActivities(dateRangeFilter.value));
  }, [dateRangeFilter, dispatch]);

  const setFirstAndLastNameLabels = (data: EntityActivities[]) => {
    return data.slice(0, 5).map((item) => {
      if (item?.entity?.entity_type?.toUpperCase() === "SALES_AI") {
        return "SalesAi";
      } else if (item?.entity?.first_name && item?.entity?.last_name) {
        return `${item.entity.first_name} ${item.entity.last_name}`;
      } else {
        return "";
      }
    });
  };

  const getActivityData = (
    data: EntityActivities[],
    activityType: "count_email" | "count_sms" | "count_call" | "count_meet",
  ) => {
    return data.slice(0, 5).map((item) => item?.activities?.[activityType] || 0);
  };

  return (
    <div
      style={{
        height: "270px",
        width: "100%",
      }}
    >
      {activitiesPending ? (
        <LoadingSpinner />
      ) : !data || data.length === 0 ? (
        <NoAutomatedActivities />
      ) : (
        <Bar
          data={{
            labels: setFirstAndLastNameLabels(data),
            datasets: [
              {
                label: "Emails",
                backgroundColor: "#05CF74",
                data: getActivityData(data, "count_email"),
                stack: "Stack 0",
                barThickness: 10,
                borderRadius: 4,
              },
              {
                label: "SMS",
                backgroundColor: "#374667",
                data: getActivityData(data, "count_sms"),
                stack: "Stack 0",
                barThickness: 10,
                borderRadius: 4,
              },
              {
                label: "Calls",
                backgroundColor: "#FB2D67",
                data: getActivityData(data, "count_call"),
                stack: "Stack 0",
                barThickness: 10,
                borderRadius: 4,
              },
              {
                label: "Meetings",
                backgroundColor: "#2C72E2",
                data: getActivityData(data, "count_meet"),
                stack: "Stack 0",
                barThickness: 10,
                borderRadius: 4,
              },
            ],
          }}
          options={{
            indexAxis: "y",
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: false,
              },
              legend: {
                display: true,
                position: "top",
                align: "start",
                labels: {
                  boxHeight: 8,
                  usePointStyle: true,
                  pointStyle: "circle",
                  color: "#54658D",
                  font: {
                    size: 12,
                    weight: 400,
                    lineHeight: 16,
                  },
                },
              },
              tooltip: {
                backgroundColor: "#374667",
              },
            },
            interaction: {
              intersect: true,
            },
            scales: {
              y: {
                grid: {
                  display: false,
                },
                border: {
                  display: false,
                },
              },
              x: {
                grid: {
                  display: false,
                },
                border: {},
              },
            },
            responsive: true,
          }}
        />
      )}
    </div>
  );
}
