import { createSelector } from "@reduxjs/toolkit";

const getRoot = (state: any) => state.companies.listCompanies;

export const getCompanies = createSelector(getRoot, (state) => state.companies);

export const getCompaniesCount = createSelector(getRoot, (state) => state.totalCount);

export const getCompanyById = (companyId: string) =>
  createSelector(getRoot, (state) => state.companies.find((company: any) => company.id === companyId));

const filterByString = (companies: any[], searchString: string) => {
  return companies.filter((company) => {
    const companyString = JSON.stringify(company).toLowerCase();
    return companyString.includes(searchString.toLowerCase());
  });
};

export const getCompaniesByFilters = createSelector([getRoot, (state, filters) => filters], (state, filters) => {
  const { searchString } = filters;

  let filteredData = state.companies;

  if (searchString && searchString.length > 0) {
    filteredData = filterByString(filteredData, searchString);
  }

  return filteredData;
});
