import Box from "@mui/material/Box";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { TaskStatusSelectOptions, TaskTypeSelectOptions } from "@/redux/reducers/tasks/slices/helpers";
import { FormControl } from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { TaskFiltersInput } from "..";
import { TaskRequestStatusEnum, TaskRequestTypeEnum } from "@/services/generated";
import { DatePicker } from "@/common/components/date-picker/DatePicker";
import { capitalize } from "@/utils/capitalize";

export type TaskFiltersProps = {
  filters?: TaskFiltersInput;
  setFilters: (filters: TaskFiltersInput | ((prevFilters: TaskFiltersInput) => TaskFiltersInput)) => void;
};

export default function TaskFilters({ filters, setFilters }: TaskFiltersProps) {
  const [prospectName, setProspectName] = useState("");

  const debouncedSetFilters = useMemo(
    () =>
      debounce((value) => {
        setFilters((prev) => ({ ...prev, prospectName: value }));
      }, 500),
    [setFilters],
  );

  useEffect(() => {
    debouncedSetFilters(prospectName);
  }, [debouncedSetFilters, prospectName]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        gap: "16px",
      }}
    >
      <Searchbar
        placeholder="Search by prospect"
        value={prospectName}
        onChange={({ target: { value } }: any) => {
          setProspectName(value);
        }}
      />

      <Box>
        <Dropdown
          label="Task Type"
          placeholder="Task Type"
          options={TaskTypeSelectOptions.filter((o) => o.show)}
          onChange={({ value }: { value: TaskRequestTypeEnum }) => {
            setFilters((prev) => ({ ...prev, type: value }));
          }}
        />
      </Box>

      <Box>
        <Dropdown
          label="Status"
          placeholder="Status"
          options={[
            ...TaskStatusSelectOptions.map((option) => ({
              label: capitalize(option.label),
              value: option.value,
            })),
          ]}
          onChange={({ value }: { value: string }) => {
            setFilters((prev) => ({ ...prev, status: value as TaskRequestStatusEnum }));
          }}
        />
      </Box>
      <Box>
        <FormControl sx={{ width: "100%" }}>
          <DatePicker
            range={true}
            value={[filters?.dueDateStart, filters?.dueDateEnd] as any}
            onChange={(formattedDates: any) => {
              if (typeof formattedDates === "object") {
                setFilters((prev) => ({
                  ...prev,
                  dueDateStart: formattedDates?.dateStart,
                  dueDateEnd: formattedDates?.dateEnd,
                }));
              }
            }}
          />
        </FormControl>
      </Box>
    </Box>
  );
}
