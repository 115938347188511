import Drawer from "@mui/material/Drawer";
import CreateDealForm from "@/pages/deal-flow/create/CreateDealForm";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { closeCreateDealDrawer } from "@/redux/reducers/dealFlow/slices/listDeals";

export default function CreateDrawer() {
  const isOpen = useAppSelector(({ dealFlow }) => dealFlow.listDeals.createDealDrawerIsOpen);
  const dispatch = useAppDispatch();

  return (
    <Drawer open={isOpen} onClose={() => dispatch(closeCreateDealDrawer())} anchor="right">
      <CreateDealForm />
    </Drawer>
  );
}
