import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useEffect } from "react";
import { useAppDispatch } from "@/redux/store";
import { useAppSelector } from "@/redux/store";
import { getLeadFlowPerformanceBySource } from "@/redux/reducers/dashboard/slices/list";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import { useFormContext } from "react-hook-form";
import NoLeadFlowPerformanceBySource from "./emptyData/NoLeadFlowPerformanceBySource";

ChartJS.register(CategoryScale, BarElement, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function LeadFlowPerformanceBySource() {
  const dispatch = useAppDispatch();
  const data = useAppSelector(({ dashboard }) => dashboard.list.leadFlowPerformanceBySource);
  const dataPending = useAppSelector(({ dashboard }) => dashboard.list.leadFlowPerformanceBySourcePending);

  const { watch } = useFormContext();
  const dateRangeFilter = watch("dateRangeFilter");

  useEffect(() => {
    dispatch(getLeadFlowPerformanceBySource(dateRangeFilter.value));
  }, [dateRangeFilter, dispatch]);

  return (
    <div
      style={{
        height: "270px",
        width: "100%",
      }}
    >
      {dataPending ? (
        <LoadingSpinner />
      ) : !data?.length ? (
        <NoLeadFlowPerformanceBySource />
      ) : (
        <Bar
          data={{
            labels: data?.map((item) => item?.lead_source?.name),
            datasets: [
              {
                label: "Converted",
                backgroundColor: "#05CF74",
                data: [data[0]?.won, data[1]?.won, data[2]?.won, data[3]?.won, data[4]?.won],
                stack: "Stack 0",
                barThickness: 10,
                borderRadius: 15,
              },
              {
                label: "In Process",
                backgroundColor: "#374667",
                data: [
                  data[0]?.in_process,
                  data[1]?.in_process,
                  data[2]?.in_process,
                  data[3]?.in_process,
                  data[4]?.in_process,
                ],
                stack: "Stack 0",
                barThickness: 10,
                borderRadius: 15,
              },
              {
                label: "Lost - STOP",
                backgroundColor: "#FB2D67",
                data: [data[0]?.lost, data[1]?.lost, data[2]?.lost, data[3]?.lost, data[4]?.lost],
                stack: "Stack 0",
                barThickness: 10,
                borderRadius: 15,
              },
              {
                label: "Lost - Unqualified",
                backgroundColor: "#2C72E2",
                data: [data[0]?.unqual, data[1]?.unqual, data[2]?.unqual, data[3]?.unqual, data[4]?.unqual],
                stack: "Stack 0",
                barThickness: 10,
                borderRadius: 15,
              },
            ],
          }}
          options={{
            indexAxis: "x",
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: false,
              },

              filler: {
                propagate: true,
              },

              legend: {
                display: true,
                position: "top",
                align: "start",

                labels: {
                  boxHeight: 8,

                  usePointStyle: true,
                  pointStyle: "circle",
                  textAlign: "left",
                  color: "#54658D",
                  font: {
                    size: 12,
                    weight: 400,
                    lineHeight: 16,
                  },
                },
              },
              tooltip: {
                backgroundColor: "#374667",
              },
            },
            interaction: {
              intersect: true,
            },
            scales: {
              y: {
                grid: {
                  display: false,
                },
              },

              x: {
                stacked: true,
                grid: {
                  display: false,
                },
              },
            },
            responsive: true,
          }}
        />
      )}
    </div>
  );
}
