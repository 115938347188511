import Services from "@/services";

export async function uploadAttachments(object: any) {
  try {
    if (!object.attachments) return [];

    const attachments = object.attachments.filter((attachment: any) => typeof attachment.media !== "string");

    if (attachments.length === 0) return object.attachments;

    const uploadedAttachments = await Promise.all(
      attachments.map(async (attachment: any) => {
        const formData = new FormData();
        formData.append("file", attachment.media);

        const response = await Services.MediaController.uploadFile(attachment.media);

        return {
          media: response.data,
          name: attachment.name,
        };
      }),
    );

    return [
      ...uploadedAttachments,
      ...object.attachments.filter((attachment: any) => typeof attachment.media === "string"),
    ];
  } catch (error) {
    console.error("Error uploading files:", error);
  }
  return [];
}
