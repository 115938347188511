import DragAndDrop from "@/common/components/drag-and-drop/DragAndDrop";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Input from "@/common/components/input/Input";
import { FormControl, useTheme } from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import FormLabel from "@/common/components/form-label/FormLabel";
import { uploadCompanyCsv } from "@/redux/reducers/dataImport/company/slices/import";
import { uploadProductCsv } from "@/redux/reducers/dataImport/product/slices/import";
import { uploadProspectCsv } from "@/redux/reducers/dataImport/prospect/slices/import";
import { useAppDispatch } from "@/redux/store";
import { KnFirestoreImportJobImportJobTypeEnum } from "@/services/generated";
import Box from "@mui/material/Box";
import { DataImportFormFields, DataImportSchema } from "../types";

export default function UploadStep() {
  const methods = useFormContext<DataImportSchema>();
  const { importType } = useWatch<DataImportFormFields>();
  const dispatch = useAppDispatch();

  const handleUploadFile = async (file: File) => {
    let response = null;
    switch (importType) {
      case KnFirestoreImportJobImportJobTypeEnum.Company:
        response = await dispatch(uploadCompanyCsv(file)).unwrap();
        break;
      case KnFirestoreImportJobImportJobTypeEnum.Contact:
        response = await dispatch(uploadProspectCsv(file)).unwrap();
        break;
      case KnFirestoreImportJobImportJobTypeEnum.Product:
        response = await dispatch(uploadProductCsv(file)).unwrap();
        break;
      default:
    }

    if (response?.id) {
      methods.setValue("id", response.id);
      methods.setValue("file", file);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: 700,
        margin: "0 auto",
      }}
    >
      <Controller
        name="importJobName"
        control={methods.control}
        render={({ field, fieldState }) => (
          <FormControl sx={{ width: "100%" }}>
            <FormLabel aria-required label="Import Name" />
            <Input
              {...field}
              type="text"
              placeholder="Add file name here"
              onChange={(e) => field.onChange(e.target.value)}
              error={fieldState.error?.message}
            />
          </FormControl>
        )}
      />

      {importType === KnFirestoreImportJobImportJobTypeEnum.Contact && (
        <Controller
          name="leadSource"
          control={methods.control}
          render={({ field, fieldState }) => (
            <FormControl sx={{ width: "100%" }}>
              <FormLabel aria-required label="Lead Source" />
              <Input
                {...field}
                placeholder="Form Submission"
                onChange={(e) => field.onChange(e.target.value)}
                error={fieldState?.error?.message}
              />
            </FormControl>
          )}
        />
      )}

      <Box sx={{ width: "100%" }}>
        <DragAndDrop accept={{ "text/csv": [".csv"] }} file={methods.watch("file")} onSetFile={handleUploadFile} />
      </Box>
    </Box>
  );
}
