import { Typography } from "@mui/material";
import { ReactComponent as CurrencyDollar } from "@/common/assets/svg/onboarding/currency-dollar.svg";
import { ReactComponent as Qrcode } from "@/common/assets/svg/onboarding/qr-code.svg";
import Box from "@mui/material/Box";
import BillingInfo from "@/common/components/billing-info";
import { useAppSelector } from "@/redux/store";
import { useEffect, useState } from "react";
import OnboardCard from "../../OnboardCard";
import PrepaidForm from "./PrepaidForm";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";

type BillingTypeOption = {
  title: string;
  img: JSX.Element;
  value: "prepaid" | "checkout";
};

const BILLING_OPTIONS: BillingTypeOption[] = [
  {
    title: "Have a prepaid code?",
    img: <Qrcode />,
    value: "prepaid",
  },

  {
    title: "Checkout",
    img: <CurrencyDollar />,
    value: "checkout",
  },
];

export default function BillingSettings() {
  const [showBilling, setShowBilling] = useState(false);
  const [billingType, setBillingType] = useState<BillingTypeOption["value"]>();
  const billingInfo = useAppSelector(({ settings }) => settings.billing.info);
  const loading = useAppSelector(({ settings }) => settings.billing.loading);

  useEffect(() => {
    if (billingInfo) {
      setShowBilling(true);
    }
  }, [billingInfo]);

  return (
    <>
      <FullLoadingOverlay overlay loading={loading} />

      {!showBilling && <PrepaidForm setCanContinue={setShowBilling} />}
      {/**
      {!showBilling && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: "700",
              lineHeight: "20px",
            }}
          >
            Choose a Billing Option
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "16px",
            }}
          >
            {BILLING_OPTIONS.map((option) => (
              <OnboardCard
                {...option}
                key={option.title}
                onChange={() => setBillingType(option.value)}
                selected={option.value === billingType}
              />
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              paddingTop: "16px",
            }}
          >
            {billingType === "prepaid" && <PrepaidForm setCanContinue={setShowBilling} />}
          </Box>
        </Box>
      )}
 */}

      {showBilling && (
        <Box
          sx={{
            width: "75%",
          }}
        >
          <BillingInfo />
        </Box>
      )}
    </>
  );
}
