import { Box, SxProps, Typography, useTheme } from "@mui/material";
import { Plus } from "heroicons-react";

type ViewAllProps = {
  leftIcon?: any;
  rightIcon?: any;
  disabled?: boolean;
  onClick?: () => void;
  children?: JSX.Element | string;
  sx?: SxProps;
};

export default function ViewAll(props: ViewAllProps) {
  const { leftIcon, rightIcon, disabled, onClick, children, sx } = props;
  const theme = useTheme();

  return (
    <Box
      onClick={onClick}
      sx={{
        padding: "4px 8px",
        background: disabled ? theme.palette.commonColors.disabled : theme.palette.midnight.p,
        width: "fit-content",
        display: "flex",
        alignItems: "center",
        color: "white",
        gap: "2px",
        borderRadius: "4px",
        cursor: "pointer",
        transition: "all 0.3s",

        "&:hover": {
          background: theme.palette.midnight[80],
        },
        "&:active": {
          background: theme.palette.midnight[60],
        },

        "&:focus": {
          background: theme.palette.midnight.p,
          border: `2px solid ${theme.palette.txt.default}`,
        },
        ...sx,
      }}
    >
      {leftIcon ? <Plus size={20} /> : null}
      <Typography fontSize="12px">{children || "View All"}</Typography>
      {rightIcon ? <Plus size={20} /> : null}
    </Box>
  );
}
