import Services from "@/services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteProductById } from "./listProducts";
import { ProductRequestCreate } from "@/services/generated";

type State = {
  loading: boolean;
  error: string | null;
};

const initialState: State = {
  loading: false,
  error: null,
};

export const deleteProduct = createAsyncThunk(
  "productLibrary/deleteProduct",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Products.deleteProduct(id);
      if (response.status === 200) {
        dispatch(deleteProductById({ id }));
      }
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const deleteProductSlice = createSlice({
  name: "deleteProduct",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(deleteProduct.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deleteProduct.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(deleteProduct.rejected, (state, { payload }) => {
      state.loading = false;
    });
  },
  reducers: {},
});

export const {} = deleteProductSlice.actions;

export default deleteProductSlice.reducer;
