import toast from "@/lib/toast";
import Services from "@/services";
import { KnProcessProductImportJobRequest, KnCreateProductImportJobRequest } from "@/services/generated";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type State = {
  loading: boolean;
};

const initialState: State = {
  loading: false,
};

export const createProductJob = createAsyncThunk(
  "product/import/createProductJob",
  async (body: KnCreateProductImportJobRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.DataImportProduct.createProductImportJob(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const uploadProductCsv = createAsyncThunk(
  "product/import/uploadProductCsv",
  async (file: File, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.DataImportProduct.uploadProductCsv(file);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const processProductJob = createAsyncThunk(
  "product/import/processProductJob",
  async (body: KnProcessProductImportJobRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.DataImportProduct.processProductImportJob(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const productImportSlice = createSlice({
  name: "product/import",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createProductJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(createProductJob.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createProductJob.rejected, (state, action) => {
        state.loading = false;
        toast.error("Failed to create product job");
      })

      .addCase(uploadProductCsv.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadProductCsv.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(uploadProductCsv.rejected, (state, action) => {
        state.loading = false;
        toast.error("Failed to upload CSV file");
      })

      .addCase(processProductJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(processProductJob.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(processProductJob.rejected, (state, action) => {
        state.loading = false;
        toast.error("Failed to process product job");
      });
  },
});

export default productImportSlice.reducer;
