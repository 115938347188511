import { Typography } from "@mui/material";

export default function NoAutomatedActivities() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        height: "100%",
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <g clipPath="url(#clip0_7344_3666)">
          <path
            d="M30.4713 10.6023H17.5374C17.2427 10.6026 16.9601 10.7206 16.7517 10.9303C16.5433 11.14 16.4261 11.4243 16.4257 11.7209V40.6439L16.2775 40.6894L13.1046 41.667C12.9542 41.7131 12.7918 41.6973 12.653 41.6231C12.5142 41.5489 12.4103 41.4222 12.3642 41.271L2.92626 10.2525C2.88033 10.1012 2.89599 9.93777 2.96979 9.79808C3.04358 9.65839 3.16948 9.55389 3.31982 9.50756L8.20925 8.00119L22.3839 3.63571L27.2733 2.12934C27.3477 2.1063 27.4259 2.09825 27.5034 2.10565C27.5809 2.11304 27.6562 2.13575 27.7249 2.17246C27.7937 2.20917 27.8546 2.25917 27.9042 2.31959C27.9537 2.38001 27.9909 2.44966 28.0137 2.52457L30.4261 10.4531L30.4713 10.6023Z"
            fill="#E7EBED"
          />
          <path
            d="M33.2943 10.4532L30.3867 0.897474C30.3383 0.738258 30.2593 0.590192 30.154 0.461739C30.0487 0.333286 29.9193 0.226963 29.7732 0.148845C29.6271 0.0707269 29.4671 0.0223445 29.3024 0.00646439C29.1377 -0.00941576 28.9714 0.00751749 28.8132 0.0562948L21.939 2.17341L7.76515 6.53964L0.890917 8.6575C0.571577 8.75616 0.304189 8.97828 0.147445 9.2751C-0.00929977 9.57192 -0.0425945 9.91919 0.0548701 10.2407L9.99231 42.8983C10.0715 43.1578 10.2312 43.3849 10.4481 43.5464C10.665 43.7078 10.9276 43.7951 11.1974 43.7954C11.3223 43.7954 11.4465 43.7766 11.5658 43.7394L16.2781 42.2883L16.4263 42.242V42.0862L16.2781 42.1317L11.5221 43.597C11.2402 43.6835 10.9357 43.6539 10.6755 43.5148C10.4153 43.3757 10.2205 43.1384 10.1339 42.855L0.197203 10.1967C0.154296 10.0563 0.139342 9.90869 0.153196 9.76244C0.16705 9.61619 0.209439 9.47413 0.277939 9.34438C0.346438 9.21464 0.439702 9.09977 0.552387 9.00635C0.665072 8.91292 0.794966 8.84278 0.934632 8.79994L7.80886 6.68207L21.9828 2.31659L28.857 0.19873C28.9629 0.166201 29.0731 0.149616 29.1838 0.149512C29.4215 0.150049 29.6528 0.227154 29.8438 0.369533C30.0348 0.511912 30.1754 0.712083 30.2452 0.940727L33.1394 10.4532L33.1853 10.6024H33.3395L33.2943 10.4532Z"
            fill="#374667"
          />
          <path
            d="M9.09184 9.53193C8.94899 9.53183 8.80993 9.48567 8.69507 9.40022C8.5802 9.31478 8.49558 9.19454 8.4536 9.05715L7.49897 5.91975C7.47331 5.83547 7.46441 5.74694 7.47277 5.6592C7.48113 5.57147 7.50658 5.48624 7.54768 5.4084C7.58878 5.33057 7.64471 5.26164 7.71229 5.20555C7.77987 5.14947 7.85776 5.10733 7.94153 5.08154L20.9814 1.06477C21.1505 1.01284 21.3333 1.03055 21.4894 1.11403C21.6456 1.1975 21.7625 1.33992 21.8145 1.51002L22.7691 4.64746C22.8207 4.81767 22.8031 5.00151 22.7201 5.15866C22.6372 5.3158 22.4956 5.43342 22.3266 5.48571L9.28672 9.50248C9.22357 9.52198 9.15789 9.5319 9.09184 9.53193Z"
            fill="#5ECF81"
          />
          <path
            d="M14.0927 3.35238C14.9113 3.35238 15.575 2.68463 15.575 1.86093C15.575 1.03722 14.9113 0.369476 14.0927 0.369476C13.274 0.369476 12.6104 1.03722 12.6104 1.86093C12.6104 2.68463 13.274 3.35238 14.0927 3.35238Z"
            fill="#5ECF81"
          />
          <path
            d="M14.0929 2.80537C14.6113 2.80537 15.0316 2.38253 15.0316 1.86094C15.0316 1.33934 14.6113 0.916504 14.0929 0.916504C13.5745 0.916504 13.1543 1.33934 13.1543 1.86094C13.1543 2.38253 13.5745 2.80537 14.0929 2.80537Z"
            fill="#F8F8F8"
          />
          <path
            d="M44.6645 43.4142H19.6134C19.4464 43.414 19.2862 43.3472 19.1681 43.2284C19.05 43.1095 18.9836 42.9484 18.9834 42.7804V12.5784C18.9836 12.4104 19.05 12.2493 19.1681 12.1304C19.2862 12.0116 19.4464 11.9448 19.6134 11.9446H44.6645C44.8315 11.9448 44.9916 12.0116 45.1097 12.1304C45.2278 12.2493 45.2943 12.4104 45.2945 12.5784V42.7804C45.2943 42.9484 45.2278 43.1095 45.1097 43.2283C44.9916 43.3472 44.8315 43.414 44.6645 43.4142Z"
            fill="#E7EBED"
          />
          <path
            d="M33.1396 10.4531H17.5383C17.2043 10.4536 16.8841 10.5873 16.6479 10.825C16.4117 11.0626 16.2788 11.3848 16.2783 11.7209V42.1316L16.4266 42.0861V11.7209C16.4269 11.4243 16.5442 11.14 16.7526 10.9303C16.961 10.7206 17.2435 10.6026 17.5383 10.6023H33.1856L33.1396 10.4531ZM46.7399 10.4531H17.5383C17.2043 10.4536 16.8841 10.5873 16.6479 10.825C16.4117 11.0626 16.2788 11.3848 16.2783 11.7209V45.8751C16.2788 46.2112 16.4117 46.5334 16.6479 46.771C16.8841 47.0087 17.2043 47.1424 17.5383 47.1429H46.7399C47.0739 47.1424 47.3941 47.0087 47.6303 46.771C47.8665 46.5334 47.9994 46.2112 47.9998 45.8751V11.7209C47.9994 11.3848 47.8665 11.0626 47.6303 10.825C47.3941 10.5873 47.0739 10.4536 46.7399 10.4531ZM47.8516 45.8751C47.8512 46.1717 47.734 46.456 47.5256 46.6657C47.3172 46.8754 47.0346 46.9934 46.7399 46.9937H17.5383C17.2435 46.9934 16.961 46.8754 16.7526 46.6657C16.5442 46.456 16.4269 46.1717 16.4266 45.8751V11.7209C16.4269 11.4243 16.5442 11.14 16.7526 10.9303C16.961 10.7206 17.2435 10.6026 17.5383 10.6023H46.7399C47.0346 10.6026 47.3172 10.7206 47.5256 10.9303C47.734 11.14 47.8512 11.4243 47.8516 11.7209V45.8751Z"
            fill="#374667"
          />
          <path
            d="M38.9576 13.7343H25.3204C25.1435 13.7341 24.974 13.6634 24.8489 13.5375C24.7239 13.4117 24.6535 13.2411 24.6533 13.0632V9.78198C24.6535 9.60404 24.7239 9.43344 24.8489 9.30762C24.974 9.1818 25.1435 9.11103 25.3204 9.11082H38.9576C39.1345 9.11103 39.304 9.1818 39.4291 9.30762C39.5541 9.43344 39.6245 9.60404 39.6247 9.78198V13.0632C39.6245 13.2411 39.5541 13.4117 39.4291 13.5375C39.304 13.6634 39.1345 13.7341 38.9576 13.7343Z"
            fill="#5ECF81"
          />
          <path
            d="M32.1386 9.33454C32.9572 9.33454 33.6209 8.6668 33.6209 7.84309C33.6209 7.01938 32.9572 6.35164 32.1386 6.35164C31.3199 6.35164 30.6562 7.01938 30.6562 7.84309C30.6562 8.6668 31.3199 9.33454 32.1386 9.33454Z"
            fill="#5ECF81"
          />
          <path
            d="M32.1392 8.75153C32.6378 8.75153 33.0421 8.34481 33.0421 7.8431C33.0421 7.34138 32.6378 6.93466 32.1392 6.93466C31.6406 6.93466 31.2363 7.34138 31.2363 7.8431C31.2363 8.34481 31.6406 8.75153 32.1392 8.75153Z"
            fill="#F8F8F8"
          />
        </g>
        <defs>
          <clipPath id="clip0_7344_3666">
            <rect width="48" height="47.1429" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <Typography
        variant="body1"
        sx={{
          color: "#6B747B",
          textAlign: "center",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0.2px",
        }}
      >
        No data has been found for this chart.
      </Typography>
    </div>
  );
}
