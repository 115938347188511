import { Navigate } from "react-router-dom";
import { LoadingSpinner } from "../loading-overlay/LoadingSpinner";
import { useRoles } from "../roles/useRoles";
import { GuardProps } from "./types";

export const AdminGuard = ({ children }: GuardProps) => {
  const {
    isAdministrator,
    auth0: { isLoading },
  } = useRoles();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isAdministrator) {
    return children;
  }

  return <Navigate to="/" />;
};
