import Typography from "@mui/material/Typography";
import { createColumnHelper } from "@tanstack/react-table";
import { DripTableType } from "./DripInModal";
import moment from "moment";
import TableDate from "@/common/components/table-date";

const columnHelper = createColumnHelper<DripTableType>();

export const Columns = [
  columnHelper.accessor("contact.name", {
    header: () => <Typography fontWeight="600">Name</Typography>,
    cell: (info) => {
      return <Typography>{info.getValue()}</Typography>;
    },
  }),

  columnHelper.accessor("contact.email", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Email
      </Typography>
    ),
    cell: (info) => {
      return <Typography variant="body2">{info.getValue()}</Typography>;
    },
  }),

  columnHelper.accessor("contact.phone", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Phone
      </Typography>
    ),
    cell: (info) => {
      return <Typography variant="body2">{info.getValue()}</Typography>;
    },
  }),

  columnHelper.accessor("executionDate", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Next Execution On (DATE)
      </Typography>
    ),
    cell: (info) => {
      return <TableDate date={info.getValue()} />;
    },
  }),
];
