import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import { KnSlider } from "@/common/components/slider/Slider";
import { listPhoneNumbers, listVoices } from "@/redux/reducers/ai-voice/upsertAgent";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Box, Typography, useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiAgentForm } from "..";
import VoicesGallery from "./voices-gallery";
import { KnAiAgentCallDirectionEnum } from "@/services/generated";
import Input from "@/common/components/input";

const LANGUAGE_OPTIONS = [
  { value: "ENGLISH", label: "English" },
  { value: "ENGLISH_AUSTRALIA", label: "English (Australia)" },
  { value: "FRENCH", label: "French" },
  { value: "FRENCH_CANADA", label: "French (Canada)" },
  { value: "DUTCH", label: "Dutch" },
  { value: "ITALIAN", label: "Italian" },
  { value: "CHINESE_MANDARIN", label: "Chinese (Mandarin)" },
  { value: "PORTUGUESE", label: "Portuguese" },
  { value: "PORTUGUESE_BRAZIL", label: "Portuguese (Brazil)" },
  { value: "SPANISH", label: "Spanish" },
  { value: "SPANISH_LATIN_AMERICA", label: "Spanish (Latin America)" },
];

const SLIDER_MARKS = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
];

export default function AgentStep() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { control, watch } = useFormContext<AiAgentForm>();
  const dispatch = useAppDispatch();
  const phones = useAppSelector(({ aiVoice }) => aiVoice.upsertAgent.phones);
  const voices = useAppSelector(({ aiVoice }) => aiVoice.upsertAgent.voices);
  const isLoadingPhones = useAppSelector(({ aiVoice }) => aiVoice.upsertAgent.isLoadingPhones);
  const isLoadingVoices = useAppSelector(({ aiVoice }) => aiVoice.upsertAgent.isLoadingVoices);
  const callDirection = watch("call_direction");

  const phoneNumberOptions = useMemo(() => {
    return (phones || []).map((phone) => {
      const isInbound = callDirection === KnAiAgentCallDirectionEnum.Inbound;
      const isDisabled = isInbound && phone.is_used_inbound;
      return {
        label: phone.number,
        value: phone.number,
        isDisabled: isDisabled,
        subtext: isDisabled && "In Use",
      };
    });
  }, [callDirection, phones]);

  useEffect(() => {
    if (phones === undefined) {
      dispatch(listPhoneNumbers());
    }
  }, [dispatch, phones]);

  useEffect(() => {
    if (voices === undefined) {
      dispatch(listVoices());
    }
  }, [dispatch, voices]);

  if (isLoadingPhones || isLoadingVoices) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Controller
        name="agent_label"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Internal Agent Label" aria-required />
            <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
          </FormControl>
        )}
      />
      <Box
        sx={{
          display: "flex",
          gap: 4,
          my: 4,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Controller
            name="language"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl sx={{ width: "100%" }}>
                <FormLabel label={t("agent.language")} aria-required />
                <Dropdown
                  {...field}
                  options={LANGUAGE_OPTIONS}
                  value={field.value}
                  onChange={(o) => field.onChange(o.value)}
                  error={fieldState.error?.message}
                />
              </FormControl>
            )}
          />
        </Box>

        <Box sx={{ width: "100%" }}>
          <Controller
            name="agent_phone_number"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl sx={{ width: "100%" }}>
                <FormLabel label={t("agent.phoneNumber")} aria-required />
                <Dropdown
                  {...field}
                  options={phoneNumberOptions}
                  value={field.value}
                  onChange={(o) => field.onChange(o.value)}
                  error={fieldState.error?.message}
                />
              </FormControl>
            )}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Controller
          name="voice_id"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={t("agent.voiceAgent")} />
              <VoicesGallery
                error={fieldState.error?.message}
                voiceId={field.value}
                onSelect={(voiceId) => field.onChange(voiceId)}
              />
            </FormControl>
          )}
        />

        <Controller
          name="interrupt"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={t("agent.interruptSensitivity")} aria-required />
              <Box
                sx={{
                  border: `1px solid ${theme.palette.border.default}`,
                  borderRadius: 2,
                  pt: 2,
                  px: 3,
                }}
              >
                <KnSlider
                  value={field.value}
                  onChange={(_, newValue) => field.onChange(newValue)}
                  min={1}
                  max={5}
                  step={1}
                  marks={SLIDER_MARKS}
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    py: 0.5,
                    px: 1,
                    bgcolor: theme.palette.midnight[20],
                    borderRadius: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "14px",
                      color: theme.palette.commonColors.text,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    Less Sensitive
                  </Typography>
                </Box>
                <Box
                  sx={{
                    py: 0.5,
                    px: 1,
                    bgcolor: theme.palette.midnight[20],
                    borderRadius: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "14px",
                      color: theme.palette.commonColors.text,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    More Sensitive
                  </Typography>
                </Box>
              </Box>
              {fieldState.error && <Box sx={{ color: "error.main" }}>{fieldState.error.message}</Box>}
            </FormControl>
          )}
        />

        <Controller
          name="creativity"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={t("agent.aiCreativity")} aria-required />
              <Box
                sx={{
                  border: `1px solid ${theme.palette.border.default}`,
                  borderRadius: 2,
                  pt: 2,
                  px: 3,
                }}
              >
                <KnSlider
                  value={field.value}
                  onChange={(_, newValue) => field.onChange(newValue)}
                  min={1}
                  max={5}
                  step={1}
                  marks={SLIDER_MARKS}
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    py: 0.5,
                    px: 1,
                    bgcolor: theme.palette.midnight[20],
                    borderRadius: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "14px",
                      color: theme.palette.commonColors.text,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    Less Creative
                  </Typography>
                </Box>
                <Box
                  sx={{
                    py: 0.5,
                    px: 1,
                    bgcolor: theme.palette.midnight[20],
                    borderRadius: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "14px",
                      color: theme.palette.commonColors.text,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    More Creative
                  </Typography>
                </Box>
              </Box>

              {fieldState.error && <Box sx={{ color: "error.main" }}>{fieldState.error.message}</Box>}
            </FormControl>
          )}
        />
      </Box>
    </>
  );
}
