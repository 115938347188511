/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";
import { CreateBrandRequestDto } from "@/services/generated";

export const createBrandRegistration = createAsyncThunk(
  "settings/createBrandRegistration",
  async (body: CreateBrandRequestDto, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.A2p.createBrand(body);
      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
