import { AxiosResponse } from "axios";

export const downloadFileFromBlobResponse = (response: AxiosResponse<void, any>, fileName?: string) => {
  const contentDisposition = response.headers["content-disposition"];
  const name = contentDisposition ? contentDisposition.split("filename=")[1]?.replace(/['"]/g, "") : fileName;

  const blob = new Blob([response.data as unknown as BlobPart], {
    type: response.headers["content-type"] || "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = name;
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
