import Services from "@/services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { TriggerRequest } from "@/services/generated";

export const createTrigger = createAsyncThunk(
  "triggers/createTrigger",
  async (newTrigger: TriggerRequest, { rejectWithValue }) => {
    try {
      // const uploadedAttachments = await uploadAttachments(newTrigger);
      // newTrigger.attachments = uploadedAttachments;

      // create trigger example
      // {
      //     "name": "name",
      //     "event" : {
      //         "eventType": "DEAL_ASSIGNEE_CHANGE",
      //         "dealFlowId": "deal flow id"
      //     },
      //     "deleted": false,
      //     "action": {
      //         "actionType" : "SEND_SMS",
      //         "receiver" : ["reiceiver 1"],
      //         "message": "sms message"
      //         }

      // }

      const createdTrigger = await Services.Triggers.createTrigger(newTrigger);

      return createdTrigger.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
