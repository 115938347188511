import { Box } from "@mui/material";
import DealsCard from "@/common/components/deals-card/DealsCard";
import { useAppDispatch } from "@/redux/store";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import { openCreateDealDrawer, updateDealStage } from "@/redux/reducers/dealFlow/slices/listDeals";
import { DealCard } from "@/redux/reducers/dealFlow/slices/types";

const DRAGGABLE_ITEM_TYPE = "deal-card";

type ColumnProps = {
  header: JSX.Element;
  items: DealCard[];
  stage: { name: string; id: string };
};

export default function Column({ stage, header, items }: ColumnProps) {
  const dispatch = useAppDispatch();
  const [{ isOver }, drop] = useDrop(() => ({
    accept: DRAGGABLE_ITEM_TYPE,
    drop: (item: { id: string }) => {
      const { id } = item;
      dispatch(updateDealStage({ id, stage }));
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        gap: 1,
        backgroundColor: theme.palette.midnight[20],
        border: isOver ? `1px solid ${theme.palette.primary.dark}` : "1px solid #E7EBED",
        transition: "all .3s",
        padding: "4px",
        borderRadius: 2,
      })}
    >
      {header}
      <Box
        ref={drop}
        sx={(theme) => ({
          height: 764,
          flex: "none",
          flexDirection: "column",
          display: "flex",
          width: 204,
          overflowY: "hidden",
          ":hover": {
            overflowY: "auto",
          },
          "&::-webkit-scrollbar": {
            width: 0,
          },
          gap: "4px",
        })}
      >
        {items.map((item) => (
          <DraggableCard key={item.id} item={item} />
        ))}
      </Box>
    </Box>
  );
}

function DraggableCard({ item }: { item: DealCard }) {
  const dispatch = useAppDispatch();
  const [, drag] = useDrag(() => ({
    type: DRAGGABLE_ITEM_TYPE,
    item: { id: item.id },
  }));

  const contactList = item.contactList;
  const contactName = contactList && contactList.length > 0 ? contactList[0].name : "";
  return (
    <Box
      ref={drag}
      onClick={() => {
        dispatch(openCreateDealDrawer({ deal: item }));
      }}
    >
      <DealsCard
        dealName={item.name}
        contactName={contactName}
        callDaysAgo={3}
        price={String(item.price)}
        heatScore={item.heatScore}
        campaign={item.name}
        assigneeAvatar={item.assignee?.avatar}
        assigneeName={item.assignee?.name}
      />
    </Box>
  );
}
