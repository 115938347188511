import Services from "@/services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addNewDeal, updateDealById } from "./listDeals";
import { DealRequestCreate, DealRequestUpdate, KnDealLineItem } from "@/services/generated";
import { getFormattedDeal } from "./helpers";

type State = {
  loading: boolean;
  error: string | null;
};

const initialState: State = {
  loading: false,
  error: null,
};

export const createDeal = createAsyncThunk(
  "dealFlow/createDeal",
  async (newDeal: DealRequestCreate, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Deals.createDeal(newDeal);

      dispatch(addNewDeal(getFormattedDeal(response.data)));

      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateDeal = createAsyncThunk(
  "dealFlow/updateDeal",
  async (body: DealRequestUpdate, { rejectWithValue, dispatch }) => {
    const { id } = body;
    if (!id) {
      return;
    }

    try {
      const response = await Services.Deals.updateDeal(body);

      dispatch(
        updateDealById({
          id: id,
          data: getFormattedDeal({
            ...response.data,
            lineItems: body.lineItems as KnDealLineItem[],
          }),
        }),
      );

      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const createDealSlice = createSlice({
  name: "createDeal",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(createDeal.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(createDeal.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(createDeal.rejected, (state, { payload }) => {
      state.loading = false;
    });
  },
  reducers: {},
});

export const {} = createDealSlice.actions;

export default createDealSlice.reducer;
