import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material";

export interface ColorPickerProps {
  initialColor?: string;
  onColorChange?: (color: string) => void;
}

export default function ColorPicker(props: ColorPickerProps) {
  const { initialColor, onColorChange } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const {
    palette: { icon, midnight, link, decorative, commonColors, mint, border, bg },
  } = useTheme();

  const colors = [
    [icon.default, midnight.p, midnight[80], midnight[60], link.pressed, link.default, link.hover],
    [decorative.success, commonColors.mintP, mint[80], decorative.warning, border.danger, link.disabled, bg.midnight],
  ];

  const [selectedColor, setSelectedColor] = useState(initialColor || colors[0][0]);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (color: string) => {
    setSelectedColor(color);
    onColorChange && onColorChange(color);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton aria-controls="color-menu" aria-haspopup="true" onClick={handleClick} sx={{ padding: "0px" }}>
        <Box sx={{ background: selectedColor, width: "24px", height: "24px", borderRadius: "100px" }} />
      </IconButton>
      <Menu
        id="color-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        sx={{
          padding: "0px",
          paddingTop: "0px",
          paddingBottom: "0px",
          border: "1px solid green",
          "& .MuiMenu-list": {
            padding: "0px",
          },
          "& .MuiMenu-paper": {
            borderRadius: "8px",
          },
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              minWidth: 300,
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",

              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 9,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box
          sx={{
            display: "flex",
            width: "264px",
            padding: "16px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
          }}
        >
          {colors.map((row, index) => (
            <Box key={`color-row-${index}`} sx={{ display: "flex", gap: "16px" }}>
              {row.map((color) => (
                <MenuItem
                  key={color}
                  onClick={() => handleMenuItemClick(color)}
                  sx={{
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  <Box
                    sx={{
                      background: color,
                      width: "24px",
                      height: "24px",
                      borderRadius: "100px",
                      border: selectedColor === color ? "2px solid white" : "none",
                      boxShadow: selectedColor === color ? "0px 0px 0px 2px rgba(0, 0, 0, 0.25)" : "none",
                    }}
                  />
                </MenuItem>
              ))}
            </Box>
          ))}
        </Box>
      </Menu>
    </Box>
  );
}
