import { InputBaseComponentProps, TextField, Typography } from "@mui/material";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import { forwardRef } from "react";

export interface InputProps {
  label?: string;
  disabled?: boolean;
  isOptional?: boolean;
  multiline?: boolean;
  placeholder?: string;
  type?: string;
  value?: string | number;
  error?: string | undefined | null;
  onChange?: (e: any) => void;
  sx?: SxProps<Theme>;
  rows?: number;
  inputProps?: InputBaseComponentProps;
  hasRoundedBottomBorders?: boolean;
  hasRoundedTopBorders?: boolean;
  leftPadding?: number;
  InputProps?: InputBaseComponentProps;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      onChange,
      value,
      multiline = false,
      placeholder = "",
      error,
      disabled,
      type = "text",
      sx,
      rows = 1,
      inputProps = {},
      hasRoundedBottomBorders = true,
      hasRoundedTopBorders = true,
      leftPadding = 16,
    }: InputProps,
    ref,
  ) => {
    const theme = useTheme();

    const baseStyles: SxProps<Theme> = {
      "& .MuiOutlinedInput-root": {
        paddingLeft: `${leftPadding}px`,
        borderBottomRightRadius: hasRoundedBottomBorders ? "8px" : "0px",
        borderBottomLeftRadius: hasRoundedBottomBorders ? "8px" : "0px",
        borderTopRightRadius: hasRoundedTopBorders ? "8px" : "0px",
        borderTopLeftRadius: hasRoundedTopBorders ? "8px" : "0px",
      },

      "& fieldset": {
        borderBottom: hasRoundedBottomBorders ? "1px solid" : "none",
      },
    };

    const errorStyles: SxProps<Theme> = error
      ? {
          "& .MuiInputBase-root": {
            "& > fieldset": {
              borderColor: theme.palette.commonColors.danger,
            },
          },
        }
      : {};

    return (
      <>
        <TextField
          ref={ref}
          multiline={multiline}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          onChange={onChange}
          rows={rows}
          value={value}
          inputProps={inputProps}
          sx={{
            ...baseStyles,
            ...errorStyles,
            ...sx,
            "& .MuiInputBase-root": {
              "& > fieldset": {
                border: `1px solid ${error ? theme.palette.border.danger : theme.palette.commonColors.light}`,
              },
            },
          }}
        />

        {error && (
          <Typography variant="body2" style={{ color: theme.palette.commonColors.danger, fontSize: "12px" }}>
            {error || "This field is required"}
          </Typography>
        )}
      </>
    );
  },
);

export default Input;
