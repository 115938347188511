import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { closeEditTaskDrawer } from "@/redux/reducers/tasks/slices/listTask";
import EditTaskFormValues from "./EditTaskFormValues";
import * as Yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TaskRequestStatusEnum, TaskRequestTypeEnum } from "@/services/generated";
import { AttachmentDto } from "@/services/generated";
import { updateTask } from "@/redux/reducers/tasks/slices/updateTask";
import dayjs from "dayjs";
import { useEffect } from "react";

type TaskFormProps = {
  type: TaskRequestTypeEnum;
  status: TaskRequestStatusEnum;
  dueDate?: Date | undefined;
  dueTime?: Date | undefined;
  description?: string | undefined;
  attachment?: AttachmentDto[] | undefined;
  prospect?: string;
  deal?: string;
  assignee?: string;
};

const schema = Yup.object({
  assignee: Yup.string().required(),
  deal: Yup.string().required(),
  prospect: Yup.string().required(),
  status: Yup.string().nullable(),
  dueDate: Yup.string(),
  dueTime: Yup.string(),
  type: Yup.string().required(),
  description: Yup.string().nullable(),
  attachments: Yup.array().of(
    Yup.object().shape({
      media: Yup.mixed().required(),
      name: Yup.string().required(),
    }),
  ),
}).required();

export default function EditTaskForm() {
  const dispatch = useAppDispatch();
  const currentTask = useAppSelector(({ tasks }) => tasks.listTask.currentTask);
  const isLoading = useAppSelector(({ tasks }) => tasks.listTask.pendingUpdateTask);
  const { t } = useTranslation();
  const toggleDrawer = () => dispatch(closeEditTaskDrawer());

  const methods = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      type: currentTask?.type,
      status: currentTask?.status,
      assignee: currentTask?.assignee?.id,
      description: currentTask?.description,
      dueDate: currentTask?.dueDate,
      dueTime: currentTask?.dueDate,
      deal: currentTask?.deal?.id,
      prospect: currentTask?.prospect?.id,
      attachments: currentTask?.attachments || [],
    },
  });

  useEffect(() => {
    if (currentTask) {
      methods.reset({
        type: currentTask?.type,
        status: currentTask?.status,
        assignee: currentTask?.assignee?.id,
        description: currentTask?.description,
        dueDate: currentTask?.dueDate,
        dueTime: currentTask?.dueDate,
        deal: currentTask?.deal?.id,
        prospect: currentTask?.prospect?.id,
        attachments: currentTask?.attachments || [],
      });
    }
  }, [currentTask, methods]);

  const onSubmit = (data: TaskFormProps) => {
    if (!data) return;

    if (data.type !== TaskRequestTypeEnum.Other) delete data.description;
    if (data.type === TaskRequestTypeEnum.Other && !data.description) {
      methods.setError("description", { type: "required", message: "Description is required" });
      return;
    }

    if (!data?.dueDate) {
      methods.setError("dueDate", { type: "required", message: "Due date is required" });
      return;
    }
    if (!data?.dueTime) {
      methods.setError("dueTime", { type: "required", message: "Due time is required" });
      return;
    }

    const date = dayjs(data?.dueDate).format("YYYY-MM-DD");
    const time = dayjs(data?.dueTime).format("HH:mm:ss");
    delete data.dueTime;
    delete data.dueDate;

    const newDate = `${date.split("T")[0]}T${time}`;

    const updateData = {
      ...data,
      dueDate: newDate,
      assigneeId: data.assignee,
      prospectId: data.prospect,
      dealId: data.deal,
    } as any;

    if (!currentTask?.id) {
      return;
    }

    try {
      dispatch(updateTask({ taskId: currentTask?.id, updatedTaskData: updateData }));
    } catch (e) {}
  };

  return (
    <FormProvider {...methods}>
      <EditTaskFormValues
        drawerTitle={t("tasks.editTaskTitle")}
        onSubmit={onSubmit}
        toggleDrawer={toggleDrawer}
        actionButtonLabel="Update Task"
        isLoading={isLoading}
      />
    </FormProvider>
  );
}
