import Box from "@mui/material/Box";

import Dropdown from "@/common/components/dropdown/Dropdown";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { DataImportFiltersInput } from "@/pages/data-import";
import { KnCompanyImportJobImportJobTypeEnum } from "@/services/generated";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";

export const ImportObjectTypeOptions = [
  { label: "Contact", value: KnCompanyImportJobImportJobTypeEnum.Contact },
  { label: "Company", value: KnCompanyImportJobImportJobTypeEnum.Company },
  { label: "Product", value: KnCompanyImportJobImportJobTypeEnum.Product },
];

export type DataImportFiltersProps = {
  filters: DataImportFiltersInput;
  setFilters: (
    filters: DataImportFiltersInput | ((prevFilters: DataImportFiltersInput) => DataImportFiltersInput),
  ) => void;
};

export default function DataImportFilters({ filters, setFilters }: DataImportFiltersProps) {
  const [search, setSearch] = useState<string>("");

  const debouncedSetFilters = useMemo(
    () =>
      debounce((value: string) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          search: value,
        }));
      }, 500),
    [setFilters],
  );

  useEffect(() => {
    debouncedSetFilters(search);

    return () => debouncedSetFilters.cancel();
  }, [debouncedSetFilters, search]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <Box>
        <Searchbar
          placeholder="Search by Name"
          value={search}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(value);
          }}
        />
      </Box>
      <Box sx={{ minWidth: 300 }}>
        <Dropdown
          label="Object Type"
          options={ImportObjectTypeOptions}
          placeholder="Object Type"
          value={filters.objectType}
          onChange={(option) => setFilters((prev) => ({ ...prev, objectType: option.value || undefined }))}
        />
      </Box>
    </Box>
  );
}
