import { Box, Typography, useTheme } from "@mui/material";
import { useReactTable, getCoreRowModel, getPaginationRowModel } from "@tanstack/react-table";
import useMapStepColumns from "./useMapStepColumns";
import { InformationCircle } from "heroicons-react";
import TsTable from "@/common/components/ts-table";
import { DataImportFormFields, DataImportSchema } from "../types";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useMemo } from "react";
import Checkbox from "@/common/components/checkbox/Checkbox";
import { KnFirestoreImportJobImportJobTypeEnum } from "@/services/generated";

export default function MapStep() {
  const theme = useTheme();
  const methods = useFormContext<DataImportSchema>();
  const { importType } = useWatch<DataImportFormFields>();
  const columns = useMapStepColumns();
  const columnMappingStrategyList = methods.watch("columnMappingStrategyList");
  const data = useMemo(
    () => (columnMappingStrategyList || []).map((column) => ({ id: column.originalFileColumnName, ...column })),
    [columnMappingStrategyList],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box>
        <Typography
          style={{
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "28px",
            letterSpacing: "0.2px",
          }}
        >
          Map columns in your file to contact properties
        </Typography>

        <Typography
          style={{
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20px",
            letterSpacing: "0.2px",
          }}
        >
          Each column header below should be mapped to a contact properly in Kennected Reach. Some of these have already
          been mapped based on their names. Anything that hasn’t been mapped yet can be mapped to a note properly with
          the dropdown menu. You can always choose “Don’t import column”.
        </Typography>
      </Box>

      <TsTable table={table} />

      {importType === KnFirestoreImportJobImportJobTypeEnum.Contact && (
        <>
          <Box sx={{ display: "flex", gap: "8px" }}>
            <InformationCircle color={theme.palette.decorative.informative} size={20} />
            <Typography sx={{ fontSize: 14 }}>Unmapped data will not be imported.</Typography>
          </Box>
          <Controller
            name="unmappedDataConsent"
            control={methods.control}
            render={({ field, fieldState }) => (
              <Checkbox
                {...field}
                error={fieldState.error?.message}
                checked={!!field.value}
                label="By checking this box, I acknowledge that I have received the required consent for communication to these contacts."
              />
            )}
          />
        </>
      )}
    </Box>
  );
}
