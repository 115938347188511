import { useConfirm } from "material-ui-confirm";
import { InformationCircleOutline, CheckCircleOutline } from "heroicons-react";
import { Typography } from "@mui/material";

export default function useConfirmModal() {
  const confirm = useConfirm();

  type DeleteConfirmProps = {
    title?: string;
    textDiv?: React.ReactNode;
    confirmationText?: string;
    cancellationText?: string;
  };

  const deleteConfirm = ({ title = "", confirmationText, cancellationText, textDiv }: DeleteConfirmProps = {}) =>
    confirm({
      title,
      content: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            gap: "10px",
            margin: "0 auto",
            justifyContent: "center",
            paddingTop: "40px",
          }}
        >
          <InformationCircleOutline style={{ width: "42px", height: "42px", color: "#FB2D67", margin: "0 auto" }} />
          <div>
            {textDiv || (
              <Typography variant="h6" style={{ color: "#1A2135" }}>
                Are you sure you want to delete this?
              </Typography>
            )}
          </div>
        </div>
      ),

      confirmationText: confirmationText ?? "Yes, continue",
      cancellationText: cancellationText ?? "No, cancel",
      cancellationButtonProps: {
        variant: "secondary",
      },
      dialogActionsProps: {
        sx: {
          display: "flex",
          justifyContent: "center",
          width: "100%",
          paddingBottom: "40px",
        },
      },
    });
  const informativeConfirm = (props: DeleteConfirmProps = {}) =>
    confirm({
      title: "",
      content: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            gap: "10px",
            margin: "0 auto",
            justifyContent: "center",
            paddingTop: "40px",
          }}
        >
          <CheckCircleOutline color="#2C72E2" width="42px" height="42px" style={{ margin: "0 auto" }} />
          <div>
            {props?.textDiv || (
              <Typography variant="h6" style={{ color: "#1A2135" }}>
                Succesfully!
              </Typography>
            )}
          </div>
        </div>
      ),

      confirmationText: props.confirmationText ?? "Yes, continue",
      hideCancelButton: true,
      dialogActionsProps: {
        sx: {
          display: "flex",
          justifyContent: "center",
          width: "100%",
          paddingBottom: "40px",
        },
      },
    });
  return {
    deleteConfirm,
    informativeConfirm,
  };
}
