import { Controller } from "react-hook-form";
import Input from "@/common/components/input";
import { Box, FormControl, Theme, Typography } from "@mui/material";
import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import { Control } from "react-hook-form";
import { CampaignRegistrationFormValues } from "@/redux/reducers/settings/campaign-registration/mapper";

export const UseCaseSelectOptions = [
  { label: "Low Volume Mixed (Recommended)", value: "LOW_VOLUME_MIXED" },
  { label: "Two factor Authentication", value: "TWO_FACTOR_AUTHENTICATION" },
  { label: "Account Notification", value: "ACCOUNT_NOTIFICATION" },
  { label: "Customer Care", value: "CUSTOMER_CARE" },
  { label: "Delivery Notification", value: "DELIVERY_NOTIFICATION" },
  { label: "Fraud Alert", value: "FRAUD_ALERT" },
  { label: "Messaging", value: "MESSAGING" },
  { label: "Higher Education", value: "HIGHER_EDUCATION" },
  { label: "Marketing", value: "MARKETING" },
  { label: "Mixed", value: "MIXED" },
  { label: "Polling and Voting", value: "POLLING_AND_VOTING" },
  { label: "Public Service Announcement", value: "PUBLIC_SERVICE_ANNOUNCEMENT" },
  { label: "Security Alert", value: "SECURITY_ALERT" },
];
interface OutreachCampaignProps {
  control: Control<CampaignRegistrationFormValues>;
}

export default function OutreachCampaign({ control }: OutreachCampaignProps) {
  return (
    <Box
      sx={(theme: Theme) => ({
        borderRadius: "6px",
        padding: "16px",
        backgroundColor: theme.palette.midnight[20],
        display: "grid",
        gap: "16px",
      })}
    >
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "28px",
          letterSpacing: "0.2px",
        }}
      >
        Outreach Campaign
      </Typography>
      <Box
        sx={{
          display: "grid",
          gap: "32px",
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        <Controller
          name={"outreachUseCase"}
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Use Case" aria-required />
              <Dropdown
                required
                options={UseCaseSelectOptions}
                value={field.value}
                onChange={(option) => field.onChange(option.value)}
                // error={fieldState.error?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          name="outreachConsent"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="How do lead/contacts consent to receive messages?" aria-required />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="outreachUseCaseDesc"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Use Case Description" aria-required />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="outreachOptInKeywords"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Opt-In Keywords" aria-required />
              <Input
                label="Opt-In Keywords"
                value={field.value}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          name="outreachSampleMessage1"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Sample Message 1" aria-required />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} multiline />
            </FormControl>
          )}
        />
        <Controller
          name="outreachOptInMessage"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Opt-In Message" aria-required />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} multiline />
            </FormControl>
          )}
        />
        <Controller
          name="outreachSampleMessage2"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Sample Message 2" aria-required />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} multiline />
            </FormControl>
          )}
        />
      </Box>
    </Box>
  );
}
