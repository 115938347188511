import "./colors.d";

const commonColors = {
  danger: "#FB2D67",
  success: "#05CE74",
  warning: "#FFC935",
  informative: "#2C72E2",
  mintP: "#5ECF81",
  disabled: "#D5D5D5",
  black: "#000000",
  white: "#ffffff",
  text: "#1A2135",
  light: "#E7EBED",
  inverse: "#FFFFFF",
  focus: "#54658D",
  informative10: "rgba(44, 114, 226, 0.1)",
  warning20: "rgba(255, 201, 53, 0.2)",
  danger10: "rgba(255, 53, 110, 0.1)",
};

export const customColors = {
  mint: {
    20: "#E6FDEE",
    40: "#D6F5DF",
    60: "#B0E9C2",
    80: "#87DCA1",
    p: commonColors.mintP,
  },
  midnight: {
    20: "#F2F4F8",
    40: "#9FA9C0",
    60: "#6E7EA5",
    80: "#54658D",
    p: "#374667",
  },

  bg: {
    default: "#F8F9FA",
    inverse: commonColors.inverse,
    midnight: "#121A29",
  },

  border: {
    default: commonColors.light,
    hover: commonColors.light,
    pressed: commonColors.light,
    disabled: commonColors.light,
    inverse: commonColors.inverse,
    focus: "#1C73C1",
    danger: commonColors.danger,
  },

  txt: {
    default: commonColors.text,
    hover: commonColors.text,
    pressed: commonColors.text,
    disabled: commonColors.disabled,
    inverse: commonColors.inverse,
    placeholder: "#A9A9A9",
  },

  icon: {
    default: commonColors.text,
    hover: commonColors.text,
    pressed: commonColors.text,
    disabled: commonColors.disabled,
    focus: commonColors.focus,
    informative: commonColors.informative,
    success: commonColors.success,
    warning: commonColors.warning,
    danger: commonColors.danger,
    inverse: commonColors.inverse,
  },

  btnPrimary: {
    default: commonColors.mintP,
    hover: "#70F4B8",
    pressed: "#55BF75",
    disabled: commonColors.disabled,
    focus: commonColors.focus,
  },
  btnSecondary: {
    default: commonColors.text,
    hover: commonColors.focus,
    pressed: "#374667",
    disabled: commonColors.disabled,
    focus: commonColors.focus,
  },

  link: {
    default: commonColors.informative,
    hover: "#357DF0",
    pressed: "#1857BB",
    disabled: commonColors.disabled,
    focus: commonColors.informative,
  },

  decorative: {
    informative: commonColors.informative,
    success: commonColors.success,
    warning: commonColors.warning,
    danger: commonColors.danger,
  },

  input: {
    default: commonColors.white,
    hover: "#F8F8F8",
  },

  tableRow: {
    heading: "#F0F0F0",
    odd: "#EBEBEB",
    pair: commonColors.white,
  },

  commonColors: {
    ...commonColors,
  },
};
