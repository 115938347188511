import { Box, Typography } from "@mui/material";
import Input from "@/common/components/input/Input";
import { useEffect } from "react";
import { useAppSelector } from "@/redux/store";
import { lookupAgentsOutbound } from "@/redux/reducers/ai-voice/listAgents";
import { useAppDispatch } from "@/redux/store";
import { useState } from "react";
import { KnAiAgent } from "@/services/generated";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import AiAgent from "@/pages/inbox/tabs/AiAgent";

export default function AiAgentList({
  selectedAgentId,
  setSelectedAgentId,
}: {
  selectedAgentId: string | null;
  setSelectedAgentId: (agentId: string | null) => void;
}) {
  const agentsList = useAppSelector(({ aiVoice }) => aiVoice.listAgents.outboundData);
  const agentsIsLoading = useAppSelector(({ aiVoice }) => aiVoice.listAgents.outboundLoading);
  const dispatch = useAppDispatch();
  const [filterAgentList, setFilterAgentList] = useState(agentsList);

  useEffect(() => {
    if (agentsList === undefined) {
      dispatch(lookupAgentsOutbound());
    } else {
      setFilterAgentList(agentsList);
    }
  }, [dispatch, agentsList, filterAgentList]);

  if (agentsIsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Box sx={{ height: "100%", overflowY: "auto", marginTop: "20px" }}>
        <Typography variant="h6" sx={{ marginTop: "24px", fontSize: "16px" }}>
          Choose Voice Agent
        </Typography>

        <Input
          label="Search"
          placeholder="Search"
          onChange={(e) => {
            const value = e.target.value;
            if (value) {
              setFilterAgentList(
                agentsList?.filter((agent: KnAiAgent) => {
                  return agent.voice_name?.toLowerCase().includes(value.toLowerCase());
                }),
              );
            } else {
              setFilterAgentList(agentsList);
            }
          }}
        />

        {filterAgentList?.map((agent: KnAiAgent) => (
          <AiAgent
            key={agent.agent_id}
            agent={agent}
            selected={agent.agent_id === selectedAgentId}
            onClick={() => setSelectedAgentId(agent.agent_id || null)}
          />
        ))}
      </Box>
    </>
  );
}
