import IconButton from "@mui/material/IconButton";
import { DashboardMenuItem, dashboardMenuItems } from "@/common/constants/menuItems";
import { Box } from "@mui/material";
import SalesAi from "@/common/assets/svg/salesai.svg";
import SalesAiFull from "@/common/assets/svg/salesaifull.svg";
import SidebarItem from "./SidebarItem";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { drawerWidth, drawerWidthClosed } from "@/common/constants/drawer";
import Kennected from "@/common/assets/svg/kennected.svg";
import KennectedFull from "@/common/assets/svg/kennectedfull.svg";
import PoweredBy from "@/common/assets/svg/powered_by.svg";
import { useMemo } from "react";
import { WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import routes from "@/common/constants/routes";
import { useRoles } from "@/common/components/roles/useRoles";

const adminRoute = {
  icon: <WrenchScrewdriverIcon width={24} />,
  label: "Admin",
  route: routes.admin.path,
};

export default function SidebarDrawer({ setOpen, open }: { setOpen: (open: boolean) => void; open: boolean }) {
  const handleToggle = () => setOpen(!open);
  const { isAdministrator } = useRoles();

  const showDashboardMenuItems: DashboardMenuItem[] = useMemo(() => {
    const routes = [...dashboardMenuItems];

    if (isAdministrator) {
      routes.push(adminRoute);
    }

    return routes
      .filter((i) => !i.hide)
      .map((i) => {
        return {
          ...i,
          sublinks: i.sublinks?.filter((l) => !l.hide),
        };
      });
  }, [isAdministrator]);

  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.midnight.p,
        color: theme.palette.commonColors.light,
        width: open ? drawerWidth : drawerWidthClosed,
        padding: "20px 16px",
        transition: "all 0.2s ease-in-out",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      })}
    >
      <div>
        <Box sx={{ paddingLeft: "4px" }}>
          {open ? <img src={SalesAi} alt="SalesAI Logo" /> : <img src={SalesAiFull} alt="SalesAI Logo" />}
        </Box>

        <Box width="100%" onClick={handleToggle} sx={{ marginTop: "32px" }}>
          <IconButton>
            <KeyboardDoubleArrowRightIcon
              sx={{
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all 0.2s ease-in-out",
                color: "#fff",
              }}
            />
          </IconButton>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          {showDashboardMenuItems.map((item) => (
            <SidebarItem key={item.label} item={item} handleToggle={handleToggle} sidebarOpen={open} />
          ))}
        </Box>
      </div>
      <Box sx={{ displyay: "flex", width: "100%" }}>
        {open ? (
          <Box
            sx={{
              display: "flex",
              gap: "4px",
              justifyContent: "center",
            }}
          >
            <img src={PoweredBy} alt="Powered By Logo" />
            <img src={KennectedFull} alt="Kenneected Logo" />
          </Box>
        ) : (
          <img src={Kennected} alt="Kennected Logo" />
        )}
      </Box>
    </Box>
  );
}
