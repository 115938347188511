import { Box, Typography } from "@mui/material";

export const TitleAndSubtitleBox = ({ title, subtitle }: any) => {
  return (
    <Box sx={{ display: "flex", gap: "5px", flexDirection: "column" }}>
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "28px",
          letterSpacing: "0.2px",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "20px",
          letterSpacing: "0.2px",
        }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};
