import Services from "@/services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addNewPipeline, updatePipelineById } from "./listPipelines";
import { PipelineStageItem } from "./types";

type State = {
  loading: boolean;
  error: string | null;
};

const initialState: State = {
  loading: false,
  error: null,
};

export const createPipeline = createAsyncThunk(
  "dealFlow/createPipeline",
  async ({ name, stages }: { name: string; stages: PipelineStageItem[] }, { rejectWithValue, dispatch }) => {
    try {
      const stagesWithOrder = stages.map((stage, order) => ({ ...stage, order }));
      const { data } = await Services.Pipelines.createPipeline({ name, stages: stagesWithOrder });
      const pipeline = {
        id: data.id!,
        name: data.name!,
        stages: data.stagesList!.map((stage) => ({
          id: stage.id!,
          name: stage.name!,
          color: stage.color!,
          description: stage.description!,
          close: stage.close!,
        })),
      };
      dispatch(addNewPipeline({ pipeline }));
      return pipeline;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updatePipeline = createAsyncThunk(
  "dealFlow/updatePipeline",
  async (
    {
      id,
      name,
      stages,
    }: {
      id: string;
      name: string;
      stages: PipelineStageItem[];
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const pipelineResponse = await Services.Pipelines.updatePipeline({ id, name });
      if (pipelineResponse.status === 200) {
        await Promise.all(
          stages
            .filter((stage) => !stage.new && (stage.updated || stage.deleted))
            .map((stage) => {
              if (stage.updated) {
                return Services.Stages.updateStage({
                  id: stage.id,
                  name: stage.name,
                  color: stage.color,
                  close: stage.close,
                });
              }
              if (stage.deleted) {
                return Services.Stages.deleteStage(stage.id);
              }
              return null;
            }),
        );
        const newStages = await Promise.all(
          stages
            .filter((stage) => stage.new)
            .map((stage) =>
              Services.Stages.createStage({
                name: stage.name,
                color: stage.color,
                close: stage.close,
                pipelineId: id,
              }).then((res) => res.data),
            ),
        );
        const stagesUpdated = stages
          .filter((stage) => !stage.deleted)
          .map((stage) => {
            if (stage.new) {
              return newStages.find((st) => st.name === stage.name);
            }
            return stage;
          });

        await Services.Pipelines.updatePipelineStagesOrder({
          pipelineId: id,
          stagesIdList: stagesUpdated.map(({ id }: PipelineStageItem) => id),
        });
        dispatch(updatePipelineById({ id, data: { id, name, stages: stagesUpdated } }));
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const createPipelineSlice = createSlice({
  name: "createPipeline",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createPipeline.pending, (state, { payload }) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPipeline.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createPipeline.rejected, (state, { payload }) => {
        state.loading = false;
      });
  },
  reducers: {},
});

export const {} = createPipelineSlice.actions;

export default createPipelineSlice.reducer;
