import { Components, Theme } from "@mui/material";

const MuiListItemText: Components<Theme>["MuiListItemText"] = {
  defaultProps: {},
  styleOverrides: {
    primary: ({ theme }) => ({
      ...theme.typography.body1,
      fontWeight: 500,
    }),
  },
};

export default MuiListItemText;
