import DropdownMenu from "./dropdown-menu/DropdownMenu";
import Stack from "@mui/material/Stack";
import useTheme from "@mui/material/styles/useTheme";

export default function NewHeader({ open }: { open: boolean }) {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        background: "#fff",
        borderBottom: `1px solid ${theme.palette.commonColors.light}`,
        height: "80px",
        padding: "20px 32px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <DropdownMenu />
    </Stack>
  );
}
