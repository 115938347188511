import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import Input from "@/common/components/input";
import { Controller, useFormContext } from "react-hook-form";
import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { CompanyHeadcountOptionList } from "@/pages/ai-learning/company-details/model/headcount";
import { AiLearningFormType } from "@/pages/ai-learning";
import toast from "@/lib/toast";
const MAX_PROMPT_LENGTH = 1000;
export default function AiLearningCompanyDetailsPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { control } = useFormContext<AiLearningFormType>();

  return (
    <Box
      sx={{
        display: "grid",
        gap: "32px",
        gridTemplateColumns: "repeat(2, 1fr)",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gap: "32px",
        }}
      >
        <Controller
          name="companyDetails.companyName"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={t("aiLearning.companyDetails.companyName")} aria-required />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name={"companyDetails.companyHeadcount"}
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={t("aiLearning.companyDetails.companyHeadcount")} aria-required />
              <Dropdown
                options={CompanyHeadcountOptionList}
                value={field.value}
                onChange={(o) => field.onChange(o.value)}
                error={fieldState.error?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          name="companyDetails.companyValueProposition"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={t("aiLearning.companyDetails.companyValueProposition")} aria-required />
              <Input
                multiline
                rows={5}
                value={field.value}
                onChange={(e) => {
                  if (e.target.value.length <= MAX_PROMPT_LENGTH) {
                    field.onChange(e);
                  } else {
                    toast.error("Prompt cannot exceed 1,000 characters", {
                      style: {
                        width: "350px",
                      },
                    });
                  }
                }}
                error={fieldState.error?.message}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-18px",
                  right: "10px",
                  color:
                    field.value?.length === MAX_PROMPT_LENGTH ? theme.palette.error.main : theme.palette.text.secondary,
                }}
              >
                <Typography variant="caption">
                  {`${field.value?.length}/${MAX_PROMPT_LENGTH}`} characters used
                </Typography>
              </Box>
            </FormControl>
          )}
        />
        <Controller
          name="companyDetails.linkToFaq"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={t("aiLearning.companyDetails.linkToFaq")} />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="companyDetails.linkToKnowledgeBase"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={t("aiLearning.companyDetails.linkToKnowledgeBase")} />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
      </Box>
    </Box>
  );
}
