import * as React from "react";
import { ReactComponent as PencilSquare } from "@/common/assets/svg/tasks/edit.svg";
import { ReactComponent as Trash } from "@/common/assets/svg/tasks/trash.svg";
import { useNavigate } from "react-router-dom";
import { deleteAgent } from "@/redux/reducers/ai-voice/listAgents";
import { useAppDispatch } from "@/redux/store";
import toast from "@/lib/toast";
import useConfirmModal from "@/common/hooks/useConfirmModal";
import routes from "@/common/constants/routes";
import { PhoneOutline } from "heroicons-react";
import MenuOptions2, { MenuOption2 } from "@/common/components/menu-options/MenuOptions2";

const iconStyles = {
  width: "24px",
  height: "24px",
  stroke: "#1A2135",
};

export default function AiAgentActionButton(props: {
  agent: {
    id: string;
  };
  showCallTest: boolean;
  openCallTest: () => void;
}) {
  const { agent, showCallTest, openCallTest } = props;
  const { deleteConfirm } = useConfirmModal();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickCallTest = () => {
    openCallTest();
    handleClose();
  };

  const handleClickEdit = () => {
    navigate(routes.aiVoice.agents.edit.with({ id: agent.id }));
    handleClose();
  };

  const handleClickDelete = () => {
    deleteConfirm({})
      .then(() => {
        dispatch(deleteAgent(agent.id))
          .unwrap()
          .then(() => {
            toast.success("Agent deleted successfully!");
          });
      })
      .catch(() => {
        return;
      });
    handleClose();
  };

  const options: MenuOption2[] = [
    {
      label: "Test Call",
      icon: <PhoneOutline style={iconStyles} />,
      show: showCallTest,
      onClick: handleClickCallTest,
    },
    {
      label: "Edit",
      icon: <PencilSquare style={iconStyles} />,
      show: true,
      onClick: handleClickEdit,
    },
    {
      label: "Delete",
      icon: <Trash style={iconStyles} />,
      show: true,
      onClick: handleClickDelete,
    },
  ];

  return (
    <MenuOptions2
      open={open}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      options={options}
    />
  );
}
