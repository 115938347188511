import React from "react";
import { Box, SxProps, Theme, Typography } from "@mui/material";

interface TableHeaderProps {
  children: React.ReactNode;
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  sx?: SxProps<Theme>;
}

const TableHeader: React.FC<TableHeaderProps> = ({ sx, children, rightIcon, leftIcon }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        ...sx,
      }}
    >
      {leftIcon && <Box>{leftIcon}</Box>}
      <Typography style={{ cursor: "pointer" }} variant="body3">
        {children}
      </Typography>
      {rightIcon && <Box>{rightIcon}</Box>}
    </Box>
  );
};

export default TableHeader;
