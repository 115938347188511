import React from "react";
import Typography from "@mui/material/Typography";
import { SxProps, Theme } from "@mui/material";

interface TableCellProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

const TableCell: React.FC<TableCellProps> = ({ sx, children }) => {
  return (
    <Typography sx={sx} variant="body2">
      {children}
    </Typography>
  );
};

export default TableCell;
