/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";
import { AxiosResponse } from "axios";

export const getTeam = createAsyncThunk(
  "settings/getTeam",
  async (_, { rejectWithValue, dispatch }): Promise<AxiosResponse<any>> => {
    try {
      const response = await Services.Teams.getCurrentTeam();
      return response;
    } catch (error: any) {
      return error;
    }
  },
);

export const getTeamUsers = createAsyncThunk(
  "settings/getTeamUsers",
  async (_, { rejectWithValue, dispatch }): Promise<AxiosResponse<any>> => {
    try {
      const response = await Services.TeamUserRoles.getTeamUsers();
      return response;
    } catch (error: any) {
      return error;
    }
  },
);

export const getTeamPhoneSMS = createAsyncThunk(
  "settings/getTeamPhoneSMS",
  async (ownerTeamId: string, { rejectWithValue, dispatch }): Promise<AxiosResponse<any>> => {
    try {
      const response = await Services.A2p.getPhoneNumber(ownerTeamId);
      return response;
    } catch (error: any) {
      return error;
    }
  },
);
