import { Box } from "@kennected/components";
import Spinner from "@/common/assets/png/spinner.png";

type Props = {
  loading?: boolean | undefined;
  overlay?: boolean;
  children?: React.ReactNode;
};

export const FullLoadingOverlay = ({ children, loading = false, overlay = false }: Props) => {
  const styles = {
    overlay: {
      display: "flex",
      width: "100%",
      height: "100%",
      position: overlay ? "absolute" : "fixed",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 9999,
      backgroundColor: overlay ? "rgba(55, 70, 103, 0.6)" : "transparent",
      justifyContent: "center",
      alignItems: "center",
    },
    spinner: {
      width: 80,
      height: 80,
      animation: "spin 2s linear infinite",
      marginBottom: 100,
    },
    keyframe: `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `,
  };

  if (!loading) {
    return <>{children}</>;
  }

  return (
    <>
      <Box sx={styles.overlay}>
        <style>{styles.keyframe}</style>
        <img src={Spinner} alt="Loading..." style={styles.spinner} />
      </Box>
      <Box>{children}</Box>
    </>
  );
};
