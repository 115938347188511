import Drawer from "@mui/material/Drawer";
import { useAppDispatch } from "@/redux/store";
import DrawerLayout from "@/layouts/DrawerLayout";
import { Box } from "@mui/material";
import { useAppSelector } from "@/redux/store";
import LeadSourceInfo from "./lead-source-info/LeadSourceInfo";
import { closeViewDrawer } from "@/redux/reducers/dataImport/slices/list";

export default function ViewLeadSourceDrawer() {
  const dispatch = useAppDispatch();
  const viewDrawerIsOpen = useAppSelector(({ dataImport }) => dataImport.list.viewDrawerIsOpen);
  const toggleDrawer = () => dispatch(closeViewDrawer({}));

  return (
    <Drawer open={viewDrawerIsOpen} onClose={toggleDrawer} anchor="right">
      <DrawerLayout customWidth="420px" title="Data Import Details" testid="viewLeadSource" closeDrawer={toggleDrawer}>
        <Box>
          <LeadSourceInfo />
        </Box>
      </DrawerLayout>
    </Drawer>
  );
}
