import { useTheme } from "@mui/material";
import { ReactComponent as Other } from "@/common/assets/svg/tasks/other.svg";
import { ReactComponent as Phone } from "@/common/assets/svg/tasks/phone.svg";
import { ReactComponent as VideoCamera } from "@/common/assets/svg/tasks/video.svg";
import { TaskRequestTypeEnum } from "@/services/generated";

export const Icon = ({ action }: { action: TaskRequestTypeEnum | string | undefined }) => {
  const theme = useTheme();

  const imageStyles = {
    background: theme.palette.commonColors.light,

    padding: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
  };
  switch (action) {
    case TaskRequestTypeEnum.AttendMeeting:
      return (
        <div style={imageStyles}>
          <VideoCamera />
        </div>
      );

    case TaskRequestTypeEnum.Call:
      return (
        <div style={imageStyles}>
          <Phone />
        </div>
      );

    default:
      return (
        <div style={imageStyles}>
          <Other />
        </div>
      );
  }
};
