import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";
import { AxiosResponse } from "axios/index";
import { AiAgentCallOutboundRequest } from "@/services/generated";

export const sendCall = createAsyncThunk(
  "aiVoice/sendCall",
  async (
    { ai_agent_id, contact_id }: AiAgentCallOutboundRequest,
    { rejectWithValue, dispatch },
  ): Promise<AxiosResponse<void>> => {
    try {
      const body: AiAgentCallOutboundRequest = {
        ai_agent_id,
        contact_id,
      };
      return await Services.AiVoice.sendCall(body);
    } catch (error: any) {
      return error;
    }
  },
);
