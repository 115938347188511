import Services from "@/services";
import { Lead } from "@/types/lead-flow";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type State = {
  loading: boolean;
  leads?: Lead[];
  hasPrev?: boolean;
  hasNext?: boolean;
};

const initialState: State = { loading: false };

export const getLeadsList = createAsyncThunk("listLeads/getLeadsList", async () => {
  try {
    const {
      data: { results, hasNext, hasPrev },
      status,
    } = await Services.LeadFlow.leadFlowLeadList("LEAD_FLOW_STATE_BOOKING");
    if (status !== 200) {
      return [];
    }
    const leads = results?.map((result) => ({
      id: result.id!,
      contact: result.contact,
      createdAt: result.created_at!,
      state: result.lead_state,
      lostReason: result.lead_state_lost_reason,
      updatedAt: result.updated_at,
    }));
    return {
      leads,
      hasNext,
      hasPrev,
    };
  } catch (error) {
    return error;
  }
});

const listLeadsSlice = createSlice({
  name: "listLeads",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getLeadsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getLeadsList.fulfilled,
      (state, action: PayloadAction<{ leads: Lead[]; hasPrev: boolean; hasNext: boolean }>) => {
        const { payload } = action;
        state.leads = payload.leads;
        state.hasNext = payload.hasNext;
        state.hasPrev = payload.hasPrev;
        state.loading = false;
      },
    );
  },
  reducers: {},
});

export default listLeadsSlice.reducer;
