import { createSelector } from "@reduxjs/toolkit";

const getRoot = (state: any) => state.prospects.listProspects;

export const getProspects = createSelector(getRoot, (state) => state.prospects);

export const getProspectsCount = createSelector(getRoot, (state) => state.totalCount);

const filterByString = (prospects: any[], searchString: string) => {
  return prospects.filter((prospect) => {
    const prospectString = JSON.stringify(prospect).toLowerCase();
    return prospectString.includes(searchString.toLowerCase());
  });
};

export const getProspectsByFilters = createSelector([getRoot, (state, filters) => filters], (state, filters) => {
  const { searchString } = filters;

  let filteredData = state.prospects;

  if (searchString && searchString.length > 0) {
    filteredData = filterByString(filteredData, searchString);
  }

  return filteredData;
});
