import Services from "@/services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TeamUser } from "./types";

type State = {
  users?: TeamUser[];
  loading: boolean;
};

const initialState: State = { loading: false };

export const getTeamUsers = createAsyncThunk("team/getUsers", async (_, { rejectWithValue }) => {
  try {
    return await Services.TeamUserRoles.getTeamUsers();
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const listUsersSlice = createSlice({
  name: "listUsers",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getTeamUsers.pending, (state, { payload }) => {
      state.loading = true;
    });

    builder.addCase(getTeamUsers.fulfilled, (state, { payload }) => {
      state.users = payload.data;
      state.loading = false;
    });
  },
  reducers: {},
});

export const {} = listUsersSlice.actions;

export default listUsersSlice.reducer;
