import { SidePanelItemDetail, SidePanelPaper, SidePanelNumberDescription } from "./MainDashboardPage";
import { Typography } from "@mui/material";
import { useAppSelector } from "@/redux/store";

const sidePanelDescription = (num: number) => {
  if (num > -1) return `+${num} in last 24hs`;
  if (num < 0) return `${num} in last 24hs`;
  return "No change in last 24hs";
};

export default function LeadsLost() {
  const data = useAppSelector(({ dashboard }) => dashboard.list.leadFlowPerformance) as any;

  const items = [
    {
      name: "Contacts created",
      count: data?.created,
      days: `+${data?.created_today}/day`,
      good: true,
    },
  ];

  return (
    <SidePanelPaper title="Leads Won">
      <div style={{ display: "flex", gap: "6px", justifyContent: "space-between", alignItems: "center" }}>
        <Typography
          variant="body1"
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "28px",
          }}
        >
          {data?.won}
        </Typography>
        <SidePanelNumberDescription type={"success"} description={sidePanelDescription(data?.won_today)} />
      </div>

      <SidePanelItemDetail items={items} />
    </SidePanelPaper>
  );
}

// tasks set deals as optional y mostrarlo en disabled hasta que elijo un prospect.

// {
//   name: "Meetings booked",
//   count: data?.won_today,
//   days: `+${data?.won_today}/day`,
//   good: true,
// },
// replace this for
