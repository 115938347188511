import TableActionButton from "./ActionButton";
import { Company } from "@/redux/reducers/prospects/companies/slices/types";
import { createColumnHelper } from "@tanstack/react-table";
import TableDate from "@/common/components/table-date";
import TableHeader from "@/common/components/table-header";
import TableCell from "@/common/components/table-cell";

const columnHelper = createColumnHelper<Company>();

export const CompaniesTableColumns = [
  columnHelper.accessor("name", {
    header: () => <TableHeader>Company Name</TableHeader>,

    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("industry", {
    header: () => <TableHeader>Industry</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("revenue", {
    header: () => <TableHeader>Revenue</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("headcount", {
    header: () => <TableHeader>Head Count</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("createdAt", {
    header: () => <TableHeader>Created Date</TableHeader>,
    cell: (info) => <TableDate nanos={info.getValue()} />,
  }),

  columnHelper.display({
    id: "actions",
    header: () => <TableHeader>Actions</TableHeader>,
    cell: (props) => <TableActionButton params={props} />,
  }),
];
