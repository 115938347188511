import dayjs from "dayjs";
import { useAppSelector } from "@/redux/store";
import { Box } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import parse from "html-react-parser";
import NoData from "./NoData";

const formatDate = (date: string) => {
  return dayjs(date).format("MMM DD, YYYY - hh:mm A");
};

export default function EmailMessages({ activityTab = false }: { activityTab?: boolean }) {
  const { prospects } = useAppSelector(({ prospects }) => prospects.listProspects);
  const pendingEmailCurrentInbox = useAppSelector((state) => state.inbox.getInboxData.pendingEmailCurrentInbox);
  const emailCurrentInbox = useAppSelector((state) => state.inbox.getInboxData.emailCurrentInbox);
  const { user } = useAuth0();

  const getProspectName = (to_user_id: string) => {
    const prospect = prospects?.find((prospect) => prospect.id === to_user_id);
    return `${prospect?.firstName} ${prospect?.lastName}`;
  };

  const Subject = ({ subject }: { subject: string }) => (
    <Box
      sx={{
        color: "#1A2135",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0.2px",
      }}
    >
      {subject}
    </Box>
  );

  const NameAndDate = ({
    to_user_id,
    createdAt,
    isSender,
  }: {
    to_user_id: string;
    createdAt: string;
    isSender: boolean;
  }) => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          color: "#1A2135",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "20px",
          letterSpacing: "0.2px",
        }}
      >
        {isSender ? "You" : getProspectName(to_user_id)}
      </Box>
      <Box
        sx={{
          color: "#6B747B",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0.2px",
        }}
      >
        {formatDate(createdAt)}
      </Box>
    </Box>
  );

  const Body = ({ body }: { body: string }) => (
    <Box
      sx={{
        background: "#F2F4F8",
        padding: "12px 16px",
        border: "1px solid #E7EBED",
        borderRadius: "8px",
        color: "#1A2135",
        textAlign: "left",
      }}
    >
      {parse(body)}
    </Box>
  );

  if (pendingEmailCurrentInbox) {
    return <LoadingSpinner />;
  }

  if (emailCurrentInbox?.length === 0 && activityTab) {
    return null;
  }

  return (
    <Box sx={{ overflowY: activityTab ? "" : "auto" }}>
      {activityTab ? (
        <Box sx={{ display: "flex", gap: "8px", alignItems: "center", marginBottom: "16px" }}>
          <EnvelopeIcon width={24} height={24} />
          <Box
            sx={{
              color: "#1A2135",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              letterSpacing: "0.2px",
            }}
          >
            Email
          </Box>
        </Box>
      ) : null}
      {emailCurrentInbox?.length > 0 ? (
        emailCurrentInbox?.map((message: any) => {
          const isSender = message?.prospect_id === user?.sub;

          return (
            <Box
              key={message?.id}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "16px",
              }}
            >
              <Subject subject={message?.subject} />
              <Box>
                <NameAndDate to_user_id={message?.to_user_id} createdAt={message?.createdAt} isSender={isSender} />
                <Body body={message?.body} />
              </Box>
            </Box>
          );
        })
      ) : (
        <NoData message="There are no new emails at the moment. Feel free to take a breather or continue with your tasks." />
      )}
    </Box>
  );
}
