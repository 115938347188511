export const TriggerEventType = {
  DealFlowStateChange: "DEAL_FLOW_STATE_CHANGE",
  MeetingStateChange: "MEETING_STATE_CHANGE",
  ProspectEngagement: "PROSPECT_ENGAGEMENT",
  DealAssigneeChange: "DEAL_ASSIGNEE_CHANGE",
  NewContact: "NEW_CONTACT",
  ProspectNoResponse: "PROSPECT_NO_RESPONSE",
};
export type TriggerEventType = (typeof TriggerEventType)[keyof typeof TriggerEventType];

export const TriggerActionType = {
  CancelSequence: "CANCEL_SEQUENCE",
  CreateDeal: "CREATE_DEAL",
  CreateTask: "CREATE_TASK",
  EnrollSequence: "ENROLL_SEQUENCE",
  SendEmail: "SEND_EMAIL",
  SetDealFlowStage: "SET_DEAL_FLOW_STAGE",
  EnrollLeadFlow: "ENROLL_LEAD_FLOW",
};
export type TriggerActionType = (typeof TriggerActionType)[keyof typeof TriggerActionType];
