import { createAsyncThunk } from "@reduxjs/toolkit";
import { AiPersonaRequest } from "@/services/generated";
import Services from "@/services";

export const persistAiPersona = createAsyncThunk(
  "aiLearning/persistAiPersona",
  async ({ id, request }: { id?: string; request: AiPersonaRequest }, { rejectWithValue, dispatch }) => {
    try {
      return !id ? await Services.Ai.aiPersonaCreate(request) : await Services.Ai.aiPersonaUpdate(request);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
