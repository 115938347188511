import { createSlice } from "@reduxjs/toolkit";
import { ConsentDTO } from "@/services/generated";
import { getConsentsForCurrentUser } from "./getConsentsForCurrentUser";

type InitialStateType = {
  consents: ConsentDTO[];
};

const initialState: InitialStateType = {
  consents: [] as ConsentDTO[],
};

const consentSlice = createSlice({
  name: "consent",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getConsentsForCurrentUser.fulfilled, (state, { payload }) => {
      state.consents = payload;
    });
  },
  reducers: {},
});

export const {} = consentSlice.actions;

export default consentSlice.reducer;
