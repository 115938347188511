import { ReactComponent as Fire } from "../../assets/svg/deals/fire.svg";
import { ReactComponent as Warm } from "../../assets/svg/deals/warm.svg";
import { ReactComponent as Cold } from "../../assets/svg/deals/cold.svg";
import { Stack, Typography } from "@mui/material";

export type HeatScoreProps = {
  score: number;
  hideLabel?: boolean;
};

const Label = ({ type }: { type: string }) => (
  <Typography component="span" sx={{ fontSize: "14px", lineHeight: "2px" }}>
    {type}
  </Typography>
);

export default function HeatScore({ score, hideLabel = false }: HeatScoreProps) {
  if (score >= 3.5) {
    return (
      <Stack direction="row" spacing="3px" alignItems={"center"}>
        <Fire />
        {!hideLabel && <Label type="Hot" />}
      </Stack>
    );
  }
  if (score >= 2.5) {
    return (
      <Stack direction="row" spacing="3px" alignItems={"center"}>
        <Warm />
        {!hideLabel && <Label type="Warm" />}
      </Stack>
    );
  }
  return (
    <Stack direction="row" spacing="3px" alignItems={"center"}>
      <Cold />
      {!hideLabel && <Label type="Cold" />}
    </Stack>
  );
}
