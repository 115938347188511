import Services from "@/services";
import { DripStepResponse, LookupDripStepRequest } from "@/services/generated";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type State = {
  drips: DripStepResponse[];
  total: number;
  filters: Partial<LookupDripStepRequest>;
  loading: boolean;
  error: string | null;
};

const initialState: State = {
  drips: [],
  total: 0,
  loading: false,
  error: null,
  filters: {
    contactName: "",
  },
};

export const getAllDrips = createAsyncThunk(
  "drips/getAllDrips",
  async (filters: LookupDripStepRequest, { rejectWithValue }) => {
    try {
      const response = await Services.DripTemplateController.getAllDrips(filters);

      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

const listDripsSlice = createSlice({
  name: "getAllDrips",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllDrips.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getAllDrips.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.drips = payload?.results ?? [];
      state.total = payload?.total ?? 0;
    });
  },
  reducers: {
    setDripFilters: (state, action: PayloadAction<LookupDripStepRequest>) => {
      const newFilters = action.payload;
      state.filters = { ...state.filters, ...newFilters };
    },
  },
});

export const { setDripFilters } = listDripsSlice.actions;

export default listDripsSlice.reducer;
