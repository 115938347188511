import dayjs from "dayjs";
import { useAppSelector } from "@/redux/store";
import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { Message } from "@/types/inbox";
import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import parse from "html-react-parser";
import NoData from "./NoData";

export default function SmsMessages({ activityTab = false }: { activityTab?: boolean }) {
  const { user } = useAuth0();
  const smsCurrentInbox = useAppSelector(({ inbox }) => inbox.getInboxData.smsCurrentInbox);
  const pendingSmsCurrentInbox = useAppSelector(({ inbox }) => inbox.getInboxData.pendingSmsCurrentInbox);

  const formatDate = (date: string) => {
    return dayjs(date).format("MMM DD, YYYY - hh:mm A");
  };

  const prospects = useAppSelector(({ prospects }) => prospects.listProspects.prospects);

  const getProspectName = (to_user_id: string) => {
    const prospect = prospects?.find((prospect) => prospect.id === to_user_id);
    return `${prospect?.firstName} ${prospect?.lastName}`;
  };

  const MessageBox = ({ body, isSender }: { body: string; isSender: boolean }) => (
    <Box
      sx={{
        background: isSender ? "#E6FDEE" : "#F2F4F8",
        padding: "12px 16px",
        border: "1px solid #E7EBED",
        borderRadius: "8px",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.2px",
        color: "#1A2135",
        textAlign: "left",
      }}
    >
      {parse(body)}
    </Box>
  );

  if (pendingSmsCurrentInbox) {
    return <LoadingSpinner />;
  }

  if (smsCurrentInbox?.length === 0 && activityTab) {
    return null;
  }
  return (
    <Box sx={{ overflowY: activityTab ? "" : "auto" }}>
      {activityTab ? (
        <Box sx={{ display: "flex", gap: "8px", alignItems: "center", marginBottom: "16px" }}>
          <ChatBubbleBottomCenterTextIcon width="24" height="24" />
          <Box
            sx={{
              color: "#1A2135",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              letterSpacing: "0.2px",
            }}
          >
            SMS
          </Box>
        </Box>
      ) : null}
      {smsCurrentInbox?.length > 0 ? (
        smsCurrentInbox?.map((message: Message) => {
          const isSender = message?.from_user_id === user?.sub;

          return (
            <>
              <Box
                key={message?.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: isSender ? "flex-end" : "flex-start",
                  marginBottom: "16px",
                  textAlign: isSender ? "right" : "left",
                }}
              >
                <Box sx={{ width: "328px", display: "flex", flexDirection: "column", gap: "8px" }}>
                  <Box
                    sx={{
                      color: "#1A2135",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "16px",
                      letterSpacing: "0.2px",
                    }}
                  >
                    {isSender ? "You" : getProspectName(message?.to_user_id)}
                  </Box>

                  <Box
                    sx={{
                      color: "#6B747B",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      letterSpacing: "0.2px",
                    }}
                  >
                    {formatDate(message?.createdAt)}
                  </Box>

                  <MessageBox body={message?.body} isSender={isSender} />
                </Box>
              </Box>
              {activityTab && (
                <Box sx={{ width: "100%", height: "1px", backgroundColor: "#ccc", margin: "16px 0px" }}></Box>
              )}
            </>
          );
        })
      ) : (
        <NoData message="There aren’t any messages right now. You can start a new conversation or check back later." />
      )}
    </Box>
  );
}
