export const ConsentSourceLevelEnum = {
  None: "none",
  Lvl1: "lvl_1",
  Lvl2: "lvl_2",
} as const;
export type ConsentSourceLevelEnum = (typeof ConsentSourceLevelEnum)[keyof typeof ConsentSourceLevelEnum];

export const KnContactLeadTypeEnum = {
  Unspecified: "unspecified",
  Inbound: "inbound",
  Outbound: "outbound",
} as const;

export const KnContactSeniorityLevelEnum = {
  Cxo: "CXO",
  Vp: "VP",
  Director: "Director",
  Manager: "Manager",
  Staff: "Staff",
} as const;

export const KnContactLeadSourceEnum = {
  Unspecified: "unspecified",
  ProspectFinder: "prospect_finder",
  CrmHubspot: "crm_hubspot",
  CrmSalesforce: "crm_salesforce",
  InboundGoogleForms: "inbound_google_forms",
  InboundFacebookLeadAds: "inbound_facebook_lead_ads",
  InboundWebhook: "inbound_webhook",
  InboundWebhookZapier: "inbound_webhook_zapier",
  DataImportCsv: "data_import_csv",
  DataImportIdk: "data_import_idk",
  Ui: "ui",
} as const;
export type KnContactLeadSourceEnum = (typeof KnContactLeadSourceEnum)[keyof typeof KnContactLeadSourceEnum];

export const KnContactDepartmentEnum = {
  Unspecified: "unspecified",
  Administrative: "Administrative",
  CommunityAndSocialServices: "Community And Social Services",
  Education: "Education",
  Engineering: "Engineering",
  Executive: "Executive",
  Finance: "Finance",
  Government: "Government",
  HealthServices: "Health Services",
  HumanResources: "Human Resources",
  InformationTechnology: "Information Technology",
  Legal: "Legal",
  Marketing: "Marketing",
  MediaAndCommunications: "Media And Communications",
  MilitaryAndProtectiveServices: "Military And Protective Services",
  Operations: "Operations",
  ProductManagement: "Product Management",
  RealEstate: "Real Estate",
  Sales: "Sales",
} as const;

export const KnCompanyIndustryEnum = {
  Unspecified: "unspecified",
  Accounting: "Accounting",
  AirlinesAviation: "Airlines/Aviation",
  AlternativeDisputeResolution: "Alternative Dispute Resolution",
  AlternativeMedicine: "Alternative Medicine",
  Animation: "Animation",
  ApparelFashion: "Apparel & Fashion",
  ArchitecturePlanning: "Architecture & Planning",
  ArtsAndCrafts: "Arts And Crafts",
  Automotive: "Automotive",
  AviationAerospace: "Aviation & Aerospace",
  Banking: "Banking",
  Biotechnology: "Biotechnology",
  BroadcastMedia: "Broadcast Media",
  BuildingMaterials: "Building Materials",
  BusinessSuppliesAndEquipment: "Business Supplies And Equipment",
  CapitalMarkets: "Capital Markets",
  Chemicals: "Chemicals",
  CivicSocialOrganization: "Civic & Social Organization",
  CivilEngineering: "Civil Engineering",
  CommercialRealEstate: "Commercial Real Estate",
  ComputerNetworkSecurity: "Computer & Network Security",
  ComputerGames: "Computer Games",
  ComputerHardware: "Computer Hardware",
  ComputerNetworking: "Computer Networking",
  ComputerSoftware: "Computer Software",
  Construction: "Construction",
  ConsumerElectronics: "Consumer Electronics",
  ConsumerGoods: "Consumer Goods",
  ConsumerServices: "Consumer Services",
  Cosmetics: "Cosmetics",
  Dairy: "Dairy",
  DefenseSpace: "Defense & Space",
  Design: "Design",
  ELearning: "E-Learning",
  EducationManagement: "Education Management",
  ElectricalElectronicManufacturing: "Electrical/Electronic Manufacturing",
  Entertainment: "Entertainment",
  EnvironmentalServices: "Environmental Services",
  EventsServices: "Events Services",
  ExecutiveOffice: "Executive Office",
  FacilitiesServices: "Facilities Services",
  Farming: "Farming",
  FinancialServices: "Financial Services",
  FineArt: "Fine Art",
  Fishery: "Fishery",
  FoodBeverages: "Food & Beverages",
  FoodProduction: "Food Production",
  FundRaising: "Fund-Raising",
  Furniture: "Furniture",
  GamblingCasinos: "Gambling & Casinos",
  GlassCeramicsConcrete: "Glass, Ceramics, & Concrete",
  GovernmentAdministration: "Government Administration",
  GovernmentRelations: "Government Relations",
  GraphicDesign: "Graphic Design",
  HealthWellnessAndFitness: "Health, Wellness And Fitness",
  HigherEducation: "Higher Education",
  HospitalHealthCare: "Hospital & Health Care",
  Hospitality: "Hospitality",
  HumanResources: "Human Resources",
  ImportAndExport: "Import And Export",
  IndividualFamilyServices: "Individual & Family Services",
  IndustrialAutomation: "Industrial Automation",
  InformationServices: "Information Services",
  InformationTechnologyAndServices: "Information Technology And Services",
  Insurance: "Insurance",
  InternationalAffairs: "International Affairs",
  InternationalTradeAndDevelopment: "International Trade And Development",
  Internet: "Internet",
  InvestmentBanking: "Investment Banking",
  InvestmentManagement: "Investment Management",
  Judiciary: "Judiciary",
  LawEnforcement: "Law Enforcement",
  LawPractice: "Law Practice",
  LegalServices: "Legal Services",
  LegislativeOffice: "Legislative Office",
  LeisureTravelTourism: "Leisure, Travel, & Tourism",
  Libraries: "Libraries",
  LogisticsAndSupplyChain: "Logistics And Supply Chain",
  LuxuryGoodsJewelry: "Luxury Goods & Jewelry",
  Machinery: "Machinery",
  ManagementConsulting: "Management Consulting",
  Maritime: "Maritime",
  MarketResearch: "Market Research",
  MarketingAndAdvertising: "Marketing And Advertising",
  MechanicalOrIndustrialEngineering: "Mechanical Or Industrial Engineering",
  MediaProduction: "Media Production",
  MedicalDevices: "Medical Devices",
  MedicalPractice: "Medical Practice",
  MentalHealthCare: "Mental Health Care",
  Military: "Military",
  MiningMetals: "Mining & Metals",
  MotionPicturesAndFilm: "Motion Pictures And Film",
  MuseumsAndInstitutions: "Museums And Institutions",
  Music: "Music",
  Nanotechnology: "Nanotechnology",
  Newspapers: "Newspapers",
  NonProfitOrganizationManagement: "Non-Profit Organization Management",
  OilEnergy: "Oil & Energy",
  OnlineMedia: "Online Media",
  OutsourcingOffshoring: "Outsourcing/Offshoring",
  PackageFreightDelivery: "Package/Freight Delivery",
  PackagingAndContainers: "Packaging And Containers",
  PaperForestProducts: "Paper & Forest Products",
  PerformingArts: "Performing Arts",
  Pharmaceuticals: "Pharmaceuticals",
  Philanthropy: "Philanthropy",
  Photography: "Photography",
  Plastics: "Plastics",
  PoliticalOrganization: "Political Organization",
  PrimarySecondaryEducation: "Primary/Secondary Education",
  Printing: "Printing",
  ProfessionalTrainingCoaching: "Professional Training & Coaching",
  ProgramDevelopment: "Program Development",
  PublicPolicy: "Public Policy",
  PublicRelationsAndCommunications: "Public Relations And Communications",
  PublicSafety: "Public Safety",
  Publishing: "Publishing",
  RailroadManufacture: "Railroad Manufacture",
  Ranching: "Ranching",
  RealEstate: "Real Estate",
  RecreationalFacilitiesAndServices: "Recreational Facilities And Services",
  ReligiousInstitutions: "Religious Institutions",
  RenewablesEnvironment: "Renewables & Environment",
  Research: "Research",
  Restaurants: "Restaurants",
  Retail: "Retail",
  SecurityAndInvestigations: "Security And Investigations",
  Semiconductors: "Semiconductors",
  Shipbuilding: "Shipbuilding",
  SportingGoods: "Sporting Goods",
  Sports: "Sports",
  StaffingAndRecruiting: "Staffing And Recruiting",
  Supermarkets: "Supermarkets",
  Telecommunications: "Telecommunications",
  Textiles: "Textiles",
  ThinkTanks: "Think Tanks",
  Tobacco: "Tobacco",
  TranslationAndLocalization: "Translation And Localization",
  TransportationTruckingRailroad: "Transportation/Trucking/Railroad",
  Utilities: "Utilities",
  VentureCapitalPrivateEquity: "Venture Capital & Private Equity",
  Veterinary: "Veterinary",
  Warehousing: "Warehousing",
  Wholesale: "Wholesale",
  WineAndSpirits: "Wine And Spirits",
  Wireless: "Wireless",
  WritingAndEditing: "Writing And Editing",
};

export const KnCompanyHeadcountEnum = {
  _1To10: "1 to 10",
  _11To25: "11 to 25",
  _26To50: "26 to 50",
  _51To100: "51 to 100",
  _101To250: "101 to 250",
  _251To500: "251 to 500",
  _501To1000: "501 to 1000",
  _1001To5000: "1001 to 5000",
  _5001To10000: "5001 to 10000",
  _10000: "10000+",
} as const;

export const KnCompanyRevenueEnum = {
  Under1Million: "Under 1 Million",
  _1MillionTo5Million: "1 Million to 5 Million",
  _5MillionTo10Million: "5 Million to 10 Million",
  _10MillionTo25Million: "10 Million to 25 Million",
  _25MillionTo50Million: "25 Million to 50 Million",
  _50MillionTo100Million: "50 Million to 100 Million",
  _100MillionTo250Million: "100 Million to 250 Million",
  _250MillionTo500Million: "250 Million to 500 Million",
  _500MillionTo1Billion: "500 Million to 1 Billion",
  _1BillionAndOver: "1 Billion and Over",
} as const;
