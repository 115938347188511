import toast from "@/lib/toast";
import { useAppDispatch } from "@/redux/store";
import { Autocomplete as MuiAutocomplete } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { debounce } from "@mui/material/utils";
import React, { useCallback, useEffect, useMemo, useState } from "react";

export interface OptionType<T> {
  label: string;
  value: T;
}

type GenericAutocompleteProps<T> = {
  disabled?: boolean;
  onChange: (value?: T) => void;
  state: T[];
  fetchData: (input: string) => Promise<any>;
  mapData: (data: T) => OptionType<T>;
  placeholder?: string;
};

export default function GenericAutocomplete<T>({
  disabled,
  onChange,
  state,
  fetchData,
  mapData,
  placeholder = "Select",
}: GenericAutocompleteProps<T>) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<OptionType<T>[]>([]);

  const fetchOptionsData = useCallback(
    async (input?: string) => {
      try {
        setLoading(true);

        await fetchData(input ?? "");
      } catch (error) {
        toast.error("Error while fetching options");
      } finally {
        setLoading(false);
      }
    },
    [fetchData],
  );

  const fetchWithDebounce = useMemo(() => debounce(fetchOptionsData, 1000), [fetchOptionsData]);

  useEffect(() => {
    const optionsUpdate = state.map(mapData);

    setOptions(optionsUpdate);
  }, [mapData, state]);

  useEffect(() => {
    fetchOptionsData();
  }, []);

  return (
    <MuiAutocomplete
      disabled={disabled}
      id="async-job-titles-autocomplete"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      getOptionLabel={(option) => option.label}
      loading={loading}
      onChange={(_, option) => {
        onChange(option?.value);
      }}
      placeholder={placeholder}
      onInputChange={(event, newInputValue, reason) => {
        if (event?.type !== "click") {
          setLoading(true);
          fetchWithDebounce(newInputValue);
        }
      }}
      sx={{
        "& .MuiInputBase-root": {
          padding: "0px 9px",
        },
        "& .MuiFormLabel-root": {
          fontSize: "14px",
        },
        "& .MuiAutocomplete-inputRoot": {
          height: "44px",
        },
        "& .MuiAutocomplete-listbox": {
          "& .MuiAutocomplete-option": {
            padding: "10px 12px",
            "&[data-focus='true']": {
              backgroundColor: "#E7EBED",
            },
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
