import { FormControl, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import Dropdown from "@/common/components/dropdown/Dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import { useFormContext } from "react-hook-form";
import { SequenceTemplate } from "@/types/sequence";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useEffect, useMemo } from "react";
import { getSequencesTemplates } from "@/redux/reducers/sequences/slices/listSequences";

export default function EnrollSequenceForm() {
  const dispatch = useAppDispatch();
  const { control } = useFormContext();
  const { sequences } = useAppSelector(({ sequences }) => sequences.listSequences);

  useEffect(() => {
    dispatch(getSequencesTemplates());
  }, [dispatch]);

  const getVersionActive = (sequence: SequenceTemplate) => {
    return sequence.versions?.find((v) => v.status === "ACTIVE") || null;
  };

  const activeSequenceOptions = useMemo(() => {
    const activeSequences = sequences?.map(getVersionActive).filter((s) => s != null) || [];
    return activeSequences.map((sequence: SequenceTemplate) => ({
      label: sequence.name,
      value: sequence.id,
    }));
  }, [sequences]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Typography variant="body3">Enroll in Sequence</Typography>
      <Controller
        name="action.sequenceId"
        control={control}
        render={({ field }) => {
          return (
            <FormControl>
              <FormLabel label="Select Sequence" aria-required />
              <Dropdown
                label="Select Sequence"
                options={activeSequenceOptions}
                value={field.value}
                onChange={(option) => field.onChange(option.value)}
              />
            </FormControl>
          );
        }}
      />
    </div>
  );
}
