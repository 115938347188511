import { Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

export default function OverviewStep() {
  const { watch } = useFormContext();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: "100%",
        maxWidth: "800px",
        margin: "0 auto",
      }}
    >
      <Typography variant="body1">
        <b>Object: </b>
        <span>{watch("type")}</span>
      </Typography>
      <Typography variant="body1">
        <b>Event: </b>
        <span>{watch("event.eventType")}</span>
      </Typography>
      <Typography variant="body1">
        <b>Action: </b>
        <span>{watch("action.actionType")}</span>
      </Typography>
    </div>
  );
}
