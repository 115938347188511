import { Navigate } from "react-router-dom";
import { GuardProps } from "./types";
import routes from "@/common/constants/routes";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useEffect } from "react";
import { getOrCreateUser } from "@/redux/reducers/settings/user/getOrCreate";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";

/**
 * Enforce user has accepted Terms of Service.
 */
export const TermsOfServiceGuard = ({ children }: GuardProps) => {
  const dispatch = useAppDispatch();
  const { user, loading } = useAppSelector(({ settings }) => settings.user);

  useEffect(() => {
    console.log("TermsOfServiceGuard getUser()");
    dispatch(getOrCreateUser());
  }, [dispatch]);

  if (loading || !user) {
    return <LoadingSpinner />;
  }

  if (!user.tosAccepted) {
    return <Navigate to={routes.onboard.termsOfService.path} />;
  }

  return children;
};
