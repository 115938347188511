import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useEffect, useState } from "react";
import DrawerLayout from "@/layouts/DrawerLayout";
import { FormProvider, useForm } from "react-hook-form";
import AiAgentList from "@/pages/inbox/tabs/AiAgentList";
import { ActionButtons } from "@/pages/inbox/tabs/ActionButtons";
import { StepTemplate } from "@/types/sequence";

interface CreateAiVoiceProps {
  closeDrawer: (discard?: boolean) => void;
  isOpen: boolean;
  onAddAction: (data: StepTemplate) => void;
}

export default function CreateAiVoice({ closeDrawer, isOpen, onAddAction }: CreateAiVoiceProps) {
  const [selectedAgentId, setSelectedAgentId] = useState<string | null>(null);
  const { getValues, setValue } = useForm<StepTemplate>();

  useEffect(() => {
    setValue("aiAgentId", selectedAgentId || undefined);
  }, [setValue, selectedAgentId]);

  const handleAddAction = () => {
    onAddAction(getValues());
  };

  const methods = useForm();

  const Content = () => (
    <>
      <FormProvider {...methods}>
        <AiAgentList setSelectedAgentId={setSelectedAgentId} selectedAgentId={selectedAgentId} />
        <Box sx={{ marginTop: "16px" }}>
          <ActionButtons
            sendText="Add to Sequence"
            disabled={!selectedAgentId}
            showGenerate={false}
            onSubmit={handleAddAction}
          />
        </Box>
      </FormProvider>
    </>
  );

  return (
    <Drawer open={isOpen} onClose={() => closeDrawer(!selectedAgentId)} anchor="right">
      <DrawerLayout title="AI Call" closeDrawer={() => closeDrawer(!selectedAgentId)}>
        <Content />
      </DrawerLayout>
    </Drawer>
  );
}
