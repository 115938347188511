import { Box, IconButton } from "@mui/material";
import { ReactComponent as ArrowPath } from "../../assets/svg/sequence/arrow-path.svg";
import { ReactComponent as ArrowUp } from "../../assets/svg/sequence/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../assets/svg/sequence/arrow-down.svg";
import { ReactComponent as Duplicate } from "../../assets/svg/sequence/document-duplicate.svg";
import { ReactComponent as Clock } from "../../assets/svg/sequence/clock.svg";
import { ReactComponent as Trash } from "../../assets/svg/sequence/trash.svg";
import ToolTip from "../tool-tip";
import ActionSetDelay, { DelayForm } from "./ActionSetDelay";

interface SequenceOptionsProps {
  delay?: DelayForm;
  onUpdateAction?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onDuplicateAction?: () => void;
  onSetDelay?: (delay: DelayForm) => void;
  onRemoveAction?: () => void;
}

const sequenceOptions = (props: SequenceOptionsProps) => [
  { description: "Update Action", id: "update", icon: <ArrowPath />, onClick: props.onUpdateAction },
  { description: "Move Up", id: "moveUp", icon: <ArrowUp />, onClick: props.onMoveUp },
  { description: "Move Down", id: "moveDown", icon: <ArrowDown />, onClick: props.onMoveDown },
  { description: "Duplicate action", id: "duplicate", icon: <Duplicate />, onClick: props.onDuplicateAction },
  { description: "Set Delay", id: "setDelay", icon: <Clock />, onClick: () => null },
  { description: "Remove Action", id: "remove", icon: <Trash />, onClick: props.onRemoveAction },
];

export const SequenceOptions = (props: SequenceOptionsProps) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", padding: "12px 8px", background: "white", borderRadius: "8px" }}
    >
      {sequenceOptions(props).map((option, index) => (
        <ToolTip key={option.id} title={option.description} placement={index % 2 === 0 ? "left" : "right"}>
          {option.id === "setDelay" ? (
            <ActionSetDelay {...option} delay={props.delay} onSetDelay={props.onSetDelay} />
          ) : (
            <IconButton onClick={option.onClick}>{option.icon}</IconButton>
          )}
        </ToolTip>
      ))}
    </Box>
  );
};
