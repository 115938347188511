type Option = {
  label: string;
  value: any;
};

export const labelAscComparator = (optionA: Option, optionB: Option): number => {
  const a = optionA.label.toUpperCase();
  const b = optionB.label.toUpperCase();
  if (a < b) return -1;
  if (b < a) return 1;
  return 0;
};
