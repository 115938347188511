import Button from "@/common/components/button";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Input from "@/common/components/input";
import PageHeader from "@/common/components/page-header/PageHeader";
import routes from "@/common/constants/routes";
import toast from "@/lib/toast";
import { createPipeline, updatePipeline } from "@/redux/reducers/dealFlow/slices/createPipeline";
import { getPipelineDetails, getPipelines } from "@/redux/reducers/dealFlow/slices/listPipelines";
import { Pipeline, PipelineStage, PipelineStageItem } from "@/redux/reducers/dealFlow/slices/types";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Box, Theme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import EditStages from "./edit-stages/EditStages";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";

const DEFAULT_STAGES = [
  {
    id: uuidv4(),
    name: "New",
    color: "#05CE74",
  },
  {
    id: uuidv4(),
    name: "Responded",
    color: "#2C72E2",
  },
  {
    id: uuidv4(),
    name: "Scheduled",
    color: "#FFC935",
  },
  {
    id: uuidv4(),
    name: "Not Scheduled",
    color: "#FB2D67",
  },
];

export default function DealFlowActionsPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const pipelines = useAppSelector(({ dealFlow }) => dealFlow.listPipelines.pipelines);
  const createPipelineLoading = useAppSelector(({ dealFlow }) => dealFlow.createPipeline.loading);
  const { id: currentPipelineId } = useParams<{ id?: string }>();
  const navigate = useNavigate();

  // TODO: Add form validation and use form controllers instead of state
  const [newPipeline, setNewPipeline] = useState<{ name: string; stages: PipelineStage[] }>({
    name: "",
    stages: DEFAULT_STAGES,
  });

  useEffect(() => {
    if (pipelines === undefined) {
      dispatch(getPipelines());
    }
  }, [pipelines, dispatch]);

  const currentPipeline = useMemo(() => {
    if (currentPipelineId) {
      return pipelines?.find((pipeline) => pipeline.id === currentPipelineId);
    }
  }, [pipelines, currentPipelineId]);

  const pipelineStages = useMemo(() => {
    if (!currentPipelineId) {
      return newPipeline.stages;
    }
    if (currentPipelineId) {
      const currentPipeline = pipelines?.find((pipeline: Pipeline) => pipeline.id === currentPipelineId);
      if (currentPipeline && currentPipeline.stages === undefined) {
        dispatch(getPipelineDetails(currentPipelineId));
        return [];
      }
      if (currentPipeline?.stages) {
        return currentPipeline.stages;
      }
    }
    return [];
  }, [dispatch, pipelines, newPipeline, currentPipelineId]);

  const pipelineOptions = useMemo(
    () => (pipelines || []).map((pipeline) => ({ value: pipeline.id, label: pipeline.name })),
    [pipelines],
  );

  const handleEditPipeline = (stages: PipelineStageItem[]) => {
    if (!currentPipelineId) {
      const request = {
        ...newPipeline,
        stages,
      };
      dispatch(createPipeline(request))
        .unwrap()
        .then((newPipeline) => {
          navigate(routes.dealFlow.details.with({ id: newPipeline.id }));
          toast.success("Pipeline created successfully!");
        })
        .catch(() => {
          toast.error("Something went wrong!");
        });
    } else if (currentPipeline) {
      dispatch(updatePipeline({ id: currentPipeline.id, name: currentPipeline.name, stages }))
        .unwrap()
        .then(() => {
          navigate(routes.dealFlow.details.with({ id: currentPipelineId }));
          toast.success("Board updates saved!");
        })
        .catch(() => {
          toast.error("Something went wrong!");
        });
    }
  };

  const handleCancelEdit = () => {
    if (!currentPipelineId) {
      navigate(routes.dealFlow.table.path);
    } else {
      navigate(routes.dealFlow.details.with({ id: currentPipelineId }));
    }
  };

  const handleNewPipeline = () => {
    setNewPipeline((pipeline) => ({ ...pipeline, name: "", stages: DEFAULT_STAGES }));
    navigate(routes.dealFlow.create.path);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <FullLoadingOverlay loading={createPipelineLoading} overlay={true} />
      <PageHeader
        title={!currentPipelineId ? t("dealFlow.actions.create.title") : t("dealFlow.actions.title")}
        actions={
          currentPipelineId && (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Dropdown
                label="Select Pipeline"
                placeholder="Select Pipeline"
                value={currentPipelineId}
                options={pipelineOptions}
                defaultValue={currentPipelineId}
                onChange={(option) => {
                  navigate(routes.dealFlow.update.with({ id: option.value }));
                }}
              />

              <Button size="sm" onClick={handleNewPipeline}>
                {t("dealFlow.actions.create.title")}
              </Button>
            </Box>
          )
        }
        filters={
          !currentPipelineId && (
            <Input
              onChange={(event) => setNewPipeline((pipeline) => ({ ...pipeline, name: event.target.value }))}
              placeholder="New Pipeline Name"
              sx={{ maxWidth: "500px" }}
            />
          )
        }
      />

      <Box sx={(theme: Theme) => ({ w: "100%", borderRadius: 3, backgroundColor: theme.palette.common.white })}>
        <EditStages
          stages={pipelineStages}
          onEditStages={handleEditPipeline}
          onCancel={handleCancelEdit}
          canSaveOrCreate={!!currentPipelineId || !!newPipeline?.name}
        />
      </Box>
    </Box>
  );
}
