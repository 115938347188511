import Dropdown from "@/common/components/dropdown";
import React from "react";
import { useRoles } from "./useRoles";
import { KnTeamUserRoleUserRoleEnum } from "@/services/generated";

interface UserRoleSelectorProps {
  value?: string;
  onChange: (value: { label: string; value: KnTeamUserRoleUserRoleEnum }) => void;
}

export default function UserRoleSelector({ value, onChange }: UserRoleSelectorProps) {
  const { userRoles, canUpdateUsers } = useRoles();

  return (
    <Dropdown
      label="Role"
      placeholder="Role"
      isDisabled={!canUpdateUsers}
      options={userRoles.map((role) => ({
        label: role.roleName,
        value: role.roleCode,
      }))}
      value={value}
      onChange={onChange}
    />
  );
}
