import { AiCompanyDetailsCompanyHeadcountEnum } from "@/services/generated";

export type CompanyHeadcountOption = {
  label: string;
  value: AiCompanyDetailsCompanyHeadcountEnum;
};

export const CompanyHeadcountOptionList: CompanyHeadcountOption[] = [
  { label: "1 to 10", value: AiCompanyDetailsCompanyHeadcountEnum._1To10 },
  { label: "11 to 25", value: AiCompanyDetailsCompanyHeadcountEnum._11To25 },
  { label: "26 to 50", value: AiCompanyDetailsCompanyHeadcountEnum._26To50 },
  { label: "51 to 100", value: AiCompanyDetailsCompanyHeadcountEnum._51To100 },
  { label: "100 to 250", value: AiCompanyDetailsCompanyHeadcountEnum._101To250 },
  { label: "251 to 500", value: AiCompanyDetailsCompanyHeadcountEnum._251To500 },
  { label: "501 to 1000", value: AiCompanyDetailsCompanyHeadcountEnum._501To1000 },
  { label: "1001 to 5000", value: AiCompanyDetailsCompanyHeadcountEnum._1001To5000 },
  { label: "5001 to 10000", value: AiCompanyDetailsCompanyHeadcountEnum._5001To10000 },
  { label: "10000+", value: AiCompanyDetailsCompanyHeadcountEnum._10000 },
];
