import { Box, Loading, alpha } from "@kennected/components";

type Props = {
  loading?: boolean;
};

const LoadingOverlay = ({ loading = true }: Props) => {
  if (!loading) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: 200,
        backgroundColor: alpha("#FFF", 0.6),
      }}
    >
      <Loading />
    </Box>
  );
};

export default LoadingOverlay;
