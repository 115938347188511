import { AiPersonaResponsivenessEnum } from "@/services/generated";

export type ResponsivenessOption = {
  label: string;
  value: AiPersonaResponsivenessEnum;
};

export const ResponsivenessOptionList: ResponsivenessOption[] = [
  { label: "Immediate", value: AiPersonaResponsivenessEnum.Immediate },
  { label: "Delay (30s)", value: AiPersonaResponsivenessEnum.DelaySec30 },
  { label: "Delay (60s)", value: AiPersonaResponsivenessEnum.DelaySec60 },
];
