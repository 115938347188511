import { UsersIcon } from "@heroicons/react/24/solid";
import { Box, Typography } from "@mui/material";

export type DealsHeaderProps = {
  title: string;
  color: string;
  total?: number;
  count: number;
};

const moneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export default function DealsHeader({ title, total, color, count }: DealsHeaderProps) {
  return (
    <Box
      sx={{
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box p={1}>
        <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box gap={0.5} display="flex" alignItems="center">
            <UsersIcon width={16} />
            <Typography fontWeight="bold">{count}</Typography>
          </Box>

          {total !== undefined && (
            <Typography display="inline-flex" gap={0.3}>
              Total: <Typography fontWeight="bold">{moneyFormatter.format(total)}</Typography>
            </Typography>
          )}
        </Box>
      </Box>
      <Box borderRadius={2} height={4} width="100%" bgcolor={color} />
    </Box>
  );
}
