import Button from "@/common/components/button";
import Box from "@mui/material/Box";
import { useFormContext } from "react-hook-form";

export const ActionButtons = ({
  sendText = "Send",
  onSubmit,
  isPending,
  disabled,
}: {
  onSubmit: any;
  sendText?: string;
  isPending?: boolean;
  disabled?: boolean;
}) => {
  const methods = useFormContext();
  return (
    <Box
      sx={{
        display: "flex",
        gap: "8px",
        justifyContent: "right",
      }}
    >
      <Button
        onClick={methods.handleSubmit(onSubmit)}
        fullWidth
        type="button"
        loading={isPending}
        disabled={isPending || disabled}
        variant="primary"
      >
        {sendText}
      </Button>
    </Box>
  );
};
