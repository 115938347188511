import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import Button from "@/common/components/button";
export default function UnauthorizedPage() {
  const { isLoading, loginWithRedirect } = useAuth0();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        {isLoading ? (
          <Box>Loading...</Box>
        ) : (
          <>
            <h2>Please login to continue</h2>
            <Button
              onClick={() =>
                loginWithRedirect({
                  authorizationParams: {
                    redirect_uri: `${window.location.origin}`,
                  },
                })
              }
            >
              Log In
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
}
