import { KnAiAgentCallDirectionEnum, KnAiAgentCallFlowEnum } from "@/services/generated";

export const CallFlowSelectOptions = [
  { label: "Book a Meeting", value: KnAiAgentCallFlowEnum.BookAMeeting },
  { label: "Qualify and Book a Meeting", value: KnAiAgentCallFlowEnum.QualifyAndBookAMeeting },
];

export const CallDirectionSelectOptions = [
  { label: "Inbound", value: KnAiAgentCallDirectionEnum.Inbound },
  { label: "Outbound", value: KnAiAgentCallDirectionEnum.Outbound },
];
