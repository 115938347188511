import React from "react";
import SidebarDrawer from "@/layouts/main/sidebar/SidebarDrawer";
import Header from "@/layouts/main/header";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function MainLayout({ children }: { children: React.ReactNode }) {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: 0 }}>
          <SidebarDrawer open={open} setOpen={setOpen} />
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            background: theme.palette.bg.default,
            height: "100vh",
            overflowX: "hidden",
          }}
        >
          <Header open={open} />
          <Box sx={{ margin: "24px" }}>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
}
