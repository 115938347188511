import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import { usePagination } from "@/common/components/pagination/usePagination";
import Searchbar from "@/common/components/searchbar/Searchbar";
import TsTable from "@/common/components/ts-table";
import { getTeams } from "@/redux/reducers/admin/teams/slices/getTeams";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Team } from "@/types/admin";
import { SquaresPlusIcon } from "@heroicons/react/24/outline";
import { Box } from "@mui/material";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { TeamsColumns } from "./columns";

export default function TeamsTable() {
  const { teams, total, loading } = useAppSelector((selector) => selector.teams.getTeams);

  const { onPaginationChange, pagination } = usePagination();

  const [nameFilter, setNameFilter] = useState<string>("");
  const [debouncedNameFilter, setDebouncedNameFilter] = useState<string>("");

  const handleCellClick = async (id: string) => {
    // TODO: Show team info page
  };

  const dispatch = useAppDispatch();

  const setDebounced = useMemo(
    () =>
      debounce((value) => {
        setDebouncedNameFilter(value);
      }, 500),
    [],
  );

  useEffect(() => {
    dispatch(
      getTeams({
        pageSize: pagination.pageSize,
        page: pagination.pageIndex,
        filters: [
          {
            options: [{ operator: "equal", values: [debouncedNameFilter] }],
            property: "name",
          },
        ],
      }),
    );
  }, [dispatch, debouncedNameFilter, pagination.pageIndex, pagination.pageSize]);

  const table = useReactTable<Team>({
    data: teams,
    columns: TeamsColumns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: total,
    manualPagination: true,
    state: {
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },
    onPaginationChange,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Searchbar
        value={nameFilter || ""}
        onChange={(event) => {
          setDebounced(event.target.value);
          setNameFilter(event.target.value);
        }}
        placeholder="Search by Name"
      />
      <FullLoadingOverlay loading={loading} />
      {teams && (
        <TsTable
          table={table}
          handleCellClick={handleCellClick}
          notFoundProps={{
            title: teams.length === 0 && nameFilter ? "No teams found that match your criteria" : "No teams found",
            description:
              teams.length === 0 && nameFilter ? (
                <span>Try a different search parameter.</span>
              ) : (
                <span>
                  Begin creating an agent by clicking the <b>"Create Sequence"</b> button above.
                </span>
              ),
            Icon: SquaresPlusIcon,
          }}
        />
      )}
    </Box>
  );
}
