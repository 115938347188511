import IndeterminateCheckbox from "@/common/components/checkbox/IntermediateCheckbox";
import TableHeader from "@/common/components/table-header";
import TableCell from "@/common/components/table-cell";
import { createColumnHelper } from "@tanstack/react-table";
import HeatScore from "@/common/components/heat-score/HeatScore";
import { DealCard } from "@/redux/reducers/dealFlow/slices/types";
import TableDate from "@/common/components/table-date";

const columnHelper = createColumnHelper<DealCard>();

export const DealColumns = [
  columnHelper.accessor("name", {
    header: ({ table }) => (
      <div style={{ display: "flex", gap: "18px", alignItems: "center", marginLeft: "16px" }}>
        <IndeterminateCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
        <TableHeader>Deal Name</TableHeader>
      </div>
    ),

    cell: ({ row }) => {
      return (
        <div style={{ display: "flex", gap: "10px", alignItems: "center", marginLeft: "16px" }}>
          <IndeterminateCheckbox
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
          <TableCell>{row.original.name}</TableCell>
        </div>
      );
    },
  }),

  columnHelper.accessor("price", {
    header: () => <TableHeader>Value $</TableHeader>,
    cell: (info) => {
      return <TableCell>${info.getValue().toFixed(2)}</TableCell>;
    },
  }),

  columnHelper.accessor("assignee", {
    header: () => <TableHeader>Prospect</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()?.name}</TableCell>;
    },
  }),

  columnHelper.accessor("assignee", {
    header: () => <TableHeader>Assignee</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()?.name}</TableCell>;
    },
  }),

  columnHelper.accessor("stage", {
    header: () => <TableHeader>Stage</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()?.name}</TableCell>;
    },
  }),

  columnHelper.accessor("heatScore", {
    header: () => <TableHeader>Heat Score</TableHeader>,
    cell: (info) => {
      return <HeatScore score={info.getValue()} />;
    },
  }),

  columnHelper.accessor("updatedAt", {
    header: () => <TableHeader>Last Activity</TableHeader>,
    cell: (info) => {
      return <TableDate date={info.getValue()?.toString()} />;
    },
  }),
];
