import { useAppSelector } from "@/redux/store";
import React, { useEffect, useMemo, useState } from "react";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { getProspectsCount } from "@/redux/selectors/prospects";
import Button from "@/common/components/button";
import { ProspectsFilterInput } from "..";
import { debounce } from "lodash";

type ProspectFiltersProps = {
  setFilters: (filters: ProspectsFilterInput | ((prevFilters: ProspectsFilterInput) => ProspectsFilterInput)) => void;
};

export default function ProspectFilters({ setFilters }: ProspectFiltersProps) {
  const hasProspect = useAppSelector(getProspectsCount);

  const [search, setSearch] = useState<string>("");

  const debouncedSetSearch = useMemo(
    () =>
      debounce((searchString: string) => {
        setFilters({
          searchString,
        });
      }, 500),
    [setFilters],
  );

  useEffect(() => {
    debouncedSetSearch(search);
  }, [debouncedSetSearch, search]);
  return (
    <>
      <Searchbar
        sx={{ width: "450px" }}
        value={search}
        placeholder="Search for a prospect"
        onChange={(e) => {
          const newValue = e.target.value;
          setSearch(newValue);
          // TODO: Check the usage of this event
          // onSetFilters?.({ searchString: newValue });
        }}
      />

      {hasProspect === 0 && search !== "" && (
        <Button
          variant="secondary"
          onClick={() => {
            setSearch("");
          }}
        >
          Reset Search
        </Button>
      )}
    </>
  );
}
