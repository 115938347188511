import { createSlice } from "@reduxjs/toolkit";
import {
  getIsCRMHubspotAuthorized,
  getIsOutlookCalendarAuthorized,
  getIsGoogleCalendarAuthorized,
  getIsConnectedUserGmail,
  getIsConnectedUserOutlook,
  listConnectedTeamShared,
} from "./get";
import {
  deauthorizeCRMUrl,
  deauthorizeUserGmail,
  deauthorizeUserOutlook,
  deauthorizeGoogleCalendarUrl,
  deauthorizeGraphUrl,
  deauthorizeEmailConsent,
} from "./deauthorize";
import { ConsentDTO } from "@/services/generated";

export type TeamConsent = {
  consent: ConsentDTO;
  consentLoading: boolean;
};

type State = {
  // Mail
  isConnectedUserGmail: boolean;
  isConnectedUserGmailLoading: boolean;
  isConnectedUserOutlook: boolean;
  isConnectedUserOutlookLoading: boolean;
  teamConsentList: TeamConsent[];
  isTeamConsentLoading: boolean;
  // Calendar
  isGoogleCalendarAuthorized: boolean | null;
  isGoogleCalendarAuthorizedLoading: boolean | null;
  isOutlookCalendarAuthorized: boolean | null;
  isOutlookCalendarAuthorizedLoading: boolean | null;
  // CRM
  isCRMHubspotAuthorized: boolean | null;
  isCRMHubspotAuthorizedLoading: boolean | null;
};

const initialState: State = {
  isConnectedUserGmail: false,
  isConnectedUserGmailLoading: false,
  isConnectedUserOutlook: false,
  isConnectedUserOutlookLoading: false,
  teamConsentList: [],
  isTeamConsentLoading: false,
  isCRMHubspotAuthorized: null,
  isCRMHubspotAuthorizedLoading: null,
  isGoogleCalendarAuthorized: null,
  isGoogleCalendarAuthorizedLoading: null,
  isOutlookCalendarAuthorized: null,
  isOutlookCalendarAuthorizedLoading: null,
};

const oauthSlice = createSlice({
  name: "oauth",
  initialState,
  // Triggered by external action AsyncThunk
  extraReducers: (builder) => {
    builder
      .addCase(getIsConnectedUserGmail.pending, (state, { payload }) => {
        state.isConnectedUserGmailLoading = true;
      })
      .addCase(getIsConnectedUserGmail.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.isConnectedUserGmail = payload.data;
        }
        state.isConnectedUserGmailLoading = false;
      })

      .addCase(getIsConnectedUserOutlook.pending, (state, { payload }) => {
        state.isConnectedUserOutlookLoading = true;
      })
      .addCase(getIsConnectedUserOutlook.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.isConnectedUserOutlook = payload.data;
        }
        state.isConnectedUserOutlookLoading = false;
      })

      .addCase(listConnectedTeamShared.pending, (state, { payload }) => {
        state.isTeamConsentLoading = true;
      })
      .addCase(listConnectedTeamShared.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.teamConsentList = payload.data.map((d) => ({ consent: d, consentLoading: false }));
        }
        state.isTeamConsentLoading = false;
      })

      .addCase(getIsCRMHubspotAuthorized.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.isCRMHubspotAuthorized = payload.data;
        }
        state.isCRMHubspotAuthorizedLoading = false;
      })
      .addCase(getIsOutlookCalendarAuthorized.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.isOutlookCalendarAuthorized = payload.data;
        }
        state.isOutlookCalendarAuthorizedLoading = false;
      })
      .addCase(getIsGoogleCalendarAuthorized.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.isGoogleCalendarAuthorized = payload.data;
        }
        state.isGoogleCalendarAuthorizedLoading = false;
      })
      .addCase(getIsGoogleCalendarAuthorized.pending, (state, { payload }) => {
        state.isGoogleCalendarAuthorizedLoading = true;
      })
      .addCase(getIsOutlookCalendarAuthorized.pending, (state, { payload }) => {
        state.isOutlookCalendarAuthorizedLoading = true;
      })
      .addCase(getIsCRMHubspotAuthorized.pending, (state, { payload }) => {
        state.isCRMHubspotAuthorizedLoading = true;
      });
    builder
      .addCase(deauthorizeUserGmail.pending, (state, { payload }) => {
        state.isConnectedUserGmailLoading = true;
      })
      .addCase(deauthorizeUserGmail.fulfilled, (state, { payload }) => {
        state.isConnectedUserGmail = false;
        state.isConnectedUserGmailLoading = false;
      })
      .addCase(deauthorizeUserOutlook.pending, (state, { payload }) => {
        state.isConnectedUserOutlookLoading = true;
      })
      .addCase(deauthorizeUserOutlook.fulfilled, (state, { payload }) => {
        state.isConnectedUserOutlook = false;
        state.isConnectedUserOutlookLoading = false;
      })
      .addCase(deauthorizeEmailConsent.pending, (state, data) => {
        const p = data.payload;
        console.log(p);
        // find index
        // const i = state.teamConsentList.findIndex()
        // loading
        // state.isConnectedUserOutlookLoading = true;
      })
      .addCase(deauthorizeEmailConsent.fulfilled, (state, { payload }) => {
        const d = payload.data;
        const index = state.teamConsentList.findIndex((c) => c.consent.id === d.id);
        state.teamConsentList.splice(index, 1);
      })
      .addCase(deauthorizeCRMUrl.fulfilled, (state, { payload }) => {
        state.isCRMHubspotAuthorized = false;
      })
      .addCase(deauthorizeGoogleCalendarUrl.fulfilled, (state, { payload }) => {
        state.isGoogleCalendarAuthorized = false;
      })
      .addCase(deauthorizeGraphUrl.fulfilled, (state, { payload }) => {
        state.isOutlookCalendarAuthorized = false;
      });
  },
  reducers: {},
});

export default oauthSlice.reducer;
