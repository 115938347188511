import { combineReducers } from "@reduxjs/toolkit";
import listSequencesReducer from "./listSequences";
import createSequenceReducer from "./createSequence";

const sequencesReducer = combineReducers({
  listSequences: listSequencesReducer,
  createSequence: createSequenceReducer,
});

export default sequencesReducer;
