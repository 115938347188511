import GenericAutocomplete, { OptionType } from "@/common/components/autocomplete/GenericAutocomplete";
import Dropdown from "@/common/components/dropdown/Dropdown";
import { CompanyIndustryOptions } from "@/redux/reducers/prospects/companies/slices/helpers";
import {
  getProspectFinderCompanies,
  getProspectFinderAddresses,
  getProspectFinderJobTitles,
} from "@/redux/reducers/prospects/finder/slices/listProspectFinder";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import Box from "@mui/material/Box";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { ProspectFinderFiltersType } from "./ProspectFinder";
import { KnAddress, KnCompany } from "@/services/generated";
import Searchbar from "@/common/components/searchbar/Searchbar";

interface ProspectFinderFiltersProps {
  // toggleAdvancedFilters: (value: boolean) => (event: React.MouseEvent<HTMLButtonElement>) => void;
  // setSearchString?: (value: string) => void;
  filters: ProspectFinderFiltersType;
  setFilters: (
    filters: ProspectFinderFiltersType | ((prevFilters: ProspectFinderFiltersType) => ProspectFinderFiltersType),
  ) => void;
}

export default function ProspectFinderFilters({ filters, setFilters }: ProspectFinderFiltersProps) {
  const [prospectName, setProspectName] = useState<string>("");

  const dispatch = useAppDispatch();

  const { companies } = useAppSelector(({ prospectFinder }) => prospectFinder.listProspectsFinder);

  const fetchCompanyList = async (input: string) => await dispatch(getProspectFinderCompanies(input));

  const toOptionCompany = (company: KnCompany): OptionType<KnCompany> => {
    return {
      label: company.name || "",
      value: company,
    };
  };

  const { addresses } = useAppSelector((state) => state.prospectFinder.listProspectsFinder);

  const fetchAddresses = async (input: string) => await dispatch(getProspectFinderAddresses(input));

  const mapAddresses = (address: KnAddress) => {
    return {
      label: `${address.city}, ${address.state}, ${address.country}`,
      value: address,
    };
  };

  const { jobTitles } = useAppSelector((state) => state.prospectFinder.listProspectsFinder);

  const fetchJobTitles = async (input: string) => await dispatch(getProspectFinderJobTitles(input));

  const mapJobTitles = (jobTitle: string) => ({
    label: jobTitle,
    value: jobTitle,
  });

  const setFiltersDebounced = useMemo(
    () =>
      debounce((value: string) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          prospectName: value,
        }));
      }, 500),
    [setFilters],
  );

  useEffect(() => {
    setFiltersDebounced(prospectName);

    return () => setFiltersDebounced.cancel();
  }, [setFiltersDebounced, prospectName]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        gap: "16px",
      }}
    >
      <Searchbar
        label="Search for a prospect"
        placeholder="Search for a prospect"
        value={prospectName}
        onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
          setProspectName(value);
        }}
      />

      <GenericAutocomplete
        onChange={(value) => {
          setFilters((prev) => ({ ...prev, company: value }));
        }}
        state={companies}
        fetchData={fetchCompanyList}
        mapData={toOptionCompany}
        placeholder="Company"
      />

      <GenericAutocomplete
        onChange={(value) => {
          setFilters((prev) => ({ ...prev, location: value }));
        }}
        state={addresses}
        fetchData={fetchAddresses}
        mapData={mapAddresses}
        placeholder="Location"
      />

      <GenericAutocomplete
        onChange={(value) => {
          setFilters((prev) => ({ ...prev, jobTitle: value }));
        }}
        state={jobTitles}
        fetchData={fetchJobTitles}
        mapData={mapJobTitles}
        placeholder="Job Title"
      />

      <Dropdown
        isSearchable
        label="Industry"
        placeholder="Industry"
        options={CompanyIndustryOptions}
        onChange={(option) => {
          setFilters((prev) => ({ ...prev, industry: option.value }));
        }}
        isClearable
      />

      {/*{filterFields.map(({ label, name, options }) => (*/}
      {/*  <Dropdown*/}
      {/*    key={name}*/}
      {/*    isSearchable*/}
      {/*    label={label}*/}
      {/*    placeholder={label}*/}
      {/*    options={[...(options || [])]}*/}
      {/*    isPending={name === "company" && isCompaniesLoading}*/}
      {/*    onChange={(selectedOption) => {*/}
      {/*      console.log("Changing", name, selectedOption?.value);*/}

      {/*      setFilters({*/}
      {/*        [name]: selectedOption?.value,*/}
      {/*        ...filters,*/}
      {/*      });*/}
      {/*    }}*/}
      {/*    isClearable*/}
      {/*  />*/}
      {/*))}*/}
    </Box>
  );
}
