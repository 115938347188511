import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Pipeline } from "./types";
import Services from "@/services";

type State = {
  pipelines?: Pipeline[];
  loading: boolean;
  error: string | null;
};

const initialState: State = {
  loading: false,
  error: null,
  pipelines: undefined,
};

export const getPipelines = createAsyncThunk("listPipelines/getPipelines", async (_, { rejectWithValue }) => {
  try {
    const response = await Services.Pipelines.getPipelinesForUser();

    const pipelines: Pipeline[] = response.data.map(({ id, name }) => ({ id: id!, name: name! }));
    return pipelines;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getPipelineDetails = createAsyncThunk(
  "listPipelines/getPipelineDetails",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Pipelines.getPipeline(id);
      const stages = response.data.stagesList?.sort((a, b) => a.order! - b.order!) || [];
      const pipelineWithDetails = { ...response.data, stages };

      dispatch(updatePipelineById({ id, data: pipelineWithDetails }));
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const listPipelines = createSlice({
  name: "listPipelines",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getPipelines.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getPipelines.fulfilled, (state, { payload }) => {
      state.pipelines = payload;
      state.loading = false;
    });
  },
  reducers: {
    addNewPipeline: (state, action: PayloadAction<{ pipeline: Pipeline }>) => {
      const { pipeline } = action.payload;
      if (state.pipelines) {
        state.pipelines.push(pipeline);
      } else {
        state.pipelines = [pipeline];
      }
    },
    updatePipelineById: (state, action) => {
      const { id, data } = action.payload;
      state.pipelines = state.pipelines?.map((pipeline) => {
        if (pipeline.id === id) {
          return data;
        } else {
          return pipeline;
        }
      });
    },
  },
});

export const { addNewPipeline, updatePipelineById } = listPipelines.actions;

export default listPipelines.reducer;
