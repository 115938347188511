import { useAppDispatch } from "@/redux/store";
import { getCoreRowModel, useReactTable, getPaginationRowModel } from "@tanstack/react-table";
import { openCreateDealDrawer } from "@/redux/reducers/dealFlow/slices/listDeals";
import { DealCard } from "@/redux/reducers/dealFlow/slices/types";
import TsTable from "@/common/components/ts-table";
import { TableCellsIcon } from "@heroicons/react/24/outline";
import { DealColumns } from "./columns";

export default function Table({ cards }: { cards: DealCard[] }) {
  const dispatch = useAppDispatch();

  const handleCellClick = (deal?: DealCard) => {
    dispatch(openCreateDealDrawer({ deal }));
  };

  const table = useReactTable({
    data: cards || [],
    columns: DealColumns,
    pageCount: cards.length,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <TsTable
      table={table}
      handleCellClick={(_, data) => {
        handleCellClick(data);
      }}
      notFoundProps={{
        title: "No deals found",
        description: "Begin creating an agent by clicking the Create New Deal button above.",
        Icon: TableCellsIcon,
      }}
    />
  );
}
