import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import { Box, Typography } from "@mui/material";
import { ReactNode, MouseEvent } from "react";
import { MoreHoriz } from "@mui/icons-material";

export type MenuOption = {
  label: string;
  value: string;
  icon: ReactNode;
  disabled?: boolean;
};

type MenuOptionsProps = {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClick: (event: MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
  options: MenuOption[];
  onClick: (option: MenuOption) => void;
  horizontalIcon?: boolean;
};

export default function MenuOptions({
  open,
  anchorEl,
  handleClick,
  handleClose,
  options,
  onClick,
  horizontalIcon = false,
}: MenuOptionsProps) {
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {horizontalIcon ? <MoreHoriz /> : <MoreVertIcon />}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
          },
        }}
        sx={(theme) => ({
          "& .MuiPaper-root": {
            boxShadow: "0px 6px 15px 0px rgba(55, 70, 103, 0.15)",
            fontSize: "30px",
            boxShados: "none",
            borderRadius: "8px",
          },
          "& .MuiMenu-list": {
            padding: "6px ",
            border: "1px solid #E7EBED",
            borderRadius: "8px",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            letterSpacing: "0.2px",
            maxWidth: "264px",
          },

          "& .MuiDivider-root": {
            margin: "6px 0px",
            borderRadius: "8px",
          },
          "& .MuiMenuItem-root": {
            padding: "10px 12px",
            borderRadius: "8px",
            paddingRight: "22px",

            "&:hover": {
              background: "#F8F8F8",
            },
          },
        })}
      >
        {options.map((option, index) => (
          <Box key={option.value}>
            <MenuItem disableRipple key={option.value} onClick={() => onClick(option)}>
              {option.icon && (
                <ListItemIcon
                  sx={{
                    width: "24px",
                    height: "24px",
                  }}
                >
                  {option.icon}
                </ListItemIcon>
              )}
              <Typography variant="body2">{option.label}</Typography>
            </MenuItem>
            {index + 1 < options.length && (
              <Divider variant="fullWidth" sx={{ color: "#E7EBED", margin: "0px !important" }} />
            )}
          </Box>
        ))}
      </Menu>
    </div>
  );
}
