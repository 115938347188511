import React from "react";
import { TabList as MuiTabList, TabListProps as MuiTabListProps } from "@mui/lab";
import { useTheme } from "@mui/material";

export interface TabListProps extends MuiTabListProps {
  children: React.ReactNode;
}

export default function TabList(props: TabListProps) {
  const { children, ...rest } = props;
  const theme = useTheme();
  return (
    <MuiTabList
      sx={(theme) => ({
        padding: "8px 8px 0 8px",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: theme.palette.border.default,
        "& .MuiTab-root": {
          height: "42px",
          minHeight: "42px",
          maxHeight: "42px",
        },
      })}
      TabIndicatorProps={{
        style: { backgroundColor: theme.palette.midnight.p },
      }}
      {...rest}
    >
      {children}
    </MuiTabList>
  );
}
