import Button from "@/common/components/button";
import FormLabel from "@/common/components/form-label/FormLabel";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import DrawerLayout from "@/layouts/DrawerLayout";
import { ActionButtons } from "@/pages/inbox/send-new/ActionButtons";
import {
  closeSendNewMessageDrawer,
  openSendNewMessageDrawer,
  openViewDrawer,
} from "@/redux/reducers/inbox/slices/getInboxData";
import { getProspects } from "@/redux/reducers/prospects/slices/listProspects";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { setCurrentProspect } from "@/redux/reducers/prospects/slices/listProspects";
import { useEffect } from "react";
import ProspectsAutocomplete from "@/common/components/autocomplete/ProspectsAutocomplete";
import Services from "@/services";

const schema = Yup.object({
  prospectId: Yup.string(),
});

export type Form = Yup.InferType<typeof schema>;

export default function SendNewMessage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const createSmsPending = useAppSelector(({ inbox }) => inbox.getInboxData.createSmsPending);
  const sendNewMessageDrawerIsOpen = useAppSelector(({ inbox }) => inbox.getInboxData.sendNewMessageDrawerIsOpen);
  const { prospects, loading } = useAppSelector(({ prospects }) => prospects.listProspects);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      prospectId: "",
    },
  });
  const { control, handleSubmit } = methods;

  const onSubmit: SubmitHandler<Form> = async (data) => {
    // On select a prospect to send new message
    // 1. Set the current prospect
    // 2. Open the view drawer
    // 3. Close the send new message drawer
    if (!data || !prospects) return;
    // Get prospect by ID (value of the Autocomplete)
    const id = data.prospectId as string;
    const response = await Services.Prospects.getContactById(id);
    if (response.data?.result) {
      dispatch(setCurrentProspect(response.data?.result));
      dispatch(openViewDrawer());
      dispatch(closeSendNewMessageDrawer());
    }
  };

  useEffect(() => {
    if (!prospects || !prospects.length) {
      dispatch(getProspects());
    }
  }, [dispatch, prospects]);

  const MessageComposer = () => {
    return (
      <Box sx={{ height: "90vh", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <Controller
          name="prospectId"
          control={control}
          render={({ field }) => {
            return (
              <FormControl>
                <FormLabel label="Select Prospect" aria-required />
                <ProspectsAutocomplete />
              </FormControl>
            );
          }}
        />
        <ActionButtons sendText="Select" onSubmit={onSubmit} />
      </Box>
    );
  };

  return (
    <>
      {createSmsPending && <FullLoadingOverlay loading={true} overlay={true} />}

      <Button
        variant="primary"
        onClick={() => {
          dispatch(openSendNewMessageDrawer());
          dispatch(setCurrentProspect(null));
        }}
      >
        {t("inbox.newMessage")}
      </Button>
      <Drawer open={sendNewMessageDrawerIsOpen} onClose={() => dispatch(closeSendNewMessageDrawer())} anchor="right">
        <DrawerLayout
          title="Send New Message"
          testid="newProspectForm"
          closeDrawer={() => dispatch(closeSendNewMessageDrawer())}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
              <MessageComposer />
            </FormProvider>
          </form>
        </DrawerLayout>
      </Drawer>
    </>
  );
}
