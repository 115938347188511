import Button from "@/common/components/button";
import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input";
import DrawerLayout from "@/layouts/DrawerLayout";
import { DripTemplateResponse } from "@/services/generated";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography, useTheme } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

interface DraftTemplateDripProps {
  dripData?: DripTemplateResponse;
  closeDrawer: () => void;
  isOpen: boolean;
  onAddAction: (data: DripForm) => void;
}

const schema = Yup.object({
  actionName: Yup.string().required("This field is required"),
  batchSize: Yup.number().typeError("Invalid value").integer("Must be an integer").required("This field is required"),
  days: Yup.number()
    .transform((value, originalValue) => (originalValue === "" ? undefined : value))
    .typeError("Invalid value")
    .integer("Must be an integer")
    .optional(),
  hours: Yup.number()
    .transform((value, originalValue) => (originalValue === "" ? undefined : value))
    .typeError("Invalid value")
    .integer("Must be an integer")
    .optional(),
  minutes: Yup.number()
    .transform((value, originalValue) => (originalValue === "" ? undefined : value))
    .typeError("Invalid value")
    .integer("Must be an integer")
    .optional(),
});

export type DripForm = Yup.InferType<typeof schema>;

const HOURS_OPTIONS = Array.from({ length: 24 }, (_, i) => ({ label: `${i}`, value: i }));
const MINUTES_OPTIONS = Array.from({ length: 60 }, (_, i) => ({ label: `${i}`, value: i }));

export default function DraftTemplateDrip({ closeDrawer, isOpen, onAddAction, dripData }: DraftTemplateDripProps) {
  const methods = useForm<DripForm>({
    resolver: yupResolver(schema),
  });

  const { control, getValues, setValue } = methods;

  const [intervalError, setIntervalError] = useState("");

  const theme = useTheme();

  const onSubmit = () => {
    const data = getValues();

    if (!data.days && !data.hours && !data.minutes) {
      setIntervalError("Please enter a drip interval");

      return;
    }

    onAddAction(data);
  };

  useEffect(() => {
    if (dripData) {
      setValue("actionName", dripData.actionName!);
      setValue("batchSize", dripData.batchSize!);
      setValue("days", dripData.days!);
      setValue("minutes", dripData.minutes!);
      setValue("hours", dripData.hours!);
    }
  }, [dripData, setValue]);

  const DripForm = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        height: "100%",
        justifyContent: "space-between",
        marginLeft: "5px",
        marginRight: "5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          Details
        </Typography>
        <FormControl>
          <Controller
            name="actionName"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <FormLabel aria-required label="Action Name" infoIcon />
                <Input {...field} placeholder="Action Name" error={fieldState?.error?.message} />
              </>
            )}
          />
        </FormControl>
        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(5, 1fr)", height: "48px", gap: "16px" }}>
          <FormControl sx={{ gridColumn: "span 2" }}>
            <Controller
              name="batchSize"
              control={control}
              render={({ field, fieldState }) => (
                <Box>
                  <FormLabel label="Batch Size" infoIcon />
                  <Input {...field} placeholder="Batch Size" error={fieldState.error?.message} />
                </Box>
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name="days"
              control={control}
              render={({ field, fieldState }) => (
                <Box>
                  <FormLabel label="Days" infoIcon />
                  <Input {...field} placeholder="Days" error={fieldState.error?.message} />
                </Box>
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name="hours"
              control={control}
              render={({ field, fieldState }) => (
                <Box>
                  <FormLabel label="Hours" infoIcon />
                  <Dropdown
                    {...field}
                    isClearable={false}
                    options={HOURS_OPTIONS}
                    placeholder="Hours"
                    error={fieldState.error?.message}
                    onChange={(option) => {
                      field.onChange(option.value);
                    }}
                  />
                </Box>
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name="minutes"
              control={control}
              render={({ field, fieldState }) => (
                <Box>
                  <FormLabel label="Minutes" infoIcon />
                  <Dropdown
                    {...field}
                    isClearable={false}
                    options={MINUTES_OPTIONS}
                    placeholder="Minutes"
                    error={fieldState.error?.message}
                    onChange={(option) => {
                      field.onChange(option.value);
                    }}
                  />
                </Box>
              )}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            paddingTop: 2,
            display: "flex",
            justifyContent: "end",
          }}
        >
          {intervalError && (
            <Typography
              variant="body2"
              sx={{ color: theme.palette.commonColors.danger, fontSize: "12px", gridColumn: "span 2" }}
            >
              {intervalError}
            </Typography>
          )}
        </Box>
      </Box>

      <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", height: "48px", gap: "16px" }}>
        <Button fullWidth variant="secondary" type="button" onClick={closeDrawer}>
          Cancel
        </Button>
        <Button onClick={methods.handleSubmit(onSubmit)} fullWidth type="button" variant="primary">
          Save Action
        </Button>
      </Box>
    </div>
  );

  return (
    <Drawer open={isOpen} onClose={closeDrawer} anchor="right">
      <DrawerLayout title="Drip" closeDrawer={closeDrawer}>
        <FormProvider {...methods}>
          <form
            style={{
              height: "100%",
            }}
          >
            <DripForm />
          </form>
        </FormProvider>
      </DrawerLayout>
    </Drawer>
  );
}
