import { SidePanelItemDetail, SidePanelNumberDescription, SidePanelPaper } from "./MainDashboardPage";
import { Typography } from "@mui/material";
import { useAppSelector } from "@/redux/store";

export default function Unqualified() {
  const data = useAppSelector(({ dashboard }) => dashboard.list.leadFlowPerformance);

  const sidePanelDescription = (num: number) => {
    if (num > -1) return `+${num} in last 24hs`;
    if (num < 0) return `${num} in last 24hs`;
    return "No change in last 24hs";
  };

  const items = [
    {
      name: "Budget Ads",
      count: data?.unqual_budget_ads || 0,
      days: `+${data?.unqual_budget_ads_today}/day`,
      good: true,
    },
    {
      name: "Budget Solution",
      count: data?.unqual_budget || 0,
      days: `+${data?.unqual_budget_today}/day`,
      good: true,
    },
    {
      name: "Company Size",
      count: data?.unqual_company_size || 0,
      days: `+${data?.unqual_company_size_today}/day`,
      good: true,
    },
    {
      name: "Industry",
      count: data?.unqual_industry || 0,
      days: `+${data?.unqual_industry_today}/day`,
      good: true,
    },
    {
      name: "Location",
      count: data?.unqual_location || 0,
      days: `+${data?.unqual_location_today}/day`,
      good: true,
    },
    {
      name: "Pain Point",
      count: data?.unqual_pain_point || 0,
      days: `+${data?.unqual_pain_point_today}/day`,
      good: true,
    },
    {
      name: "Seniority",
      count: data?.unqual_seniority || 0,
      days: `+${data?.unqual_seniority_today}/day`,
      good: true,
    },
    {
      name: "Timeframe",
      count: data?.unqual_timeframe || 0,
      days: `+${data?.unqual_timeframe_today}/day`,
      good: true,
    },
  ].filter((item) => item.count > 0);

  return (
    <SidePanelPaper title="Unqualified">
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography
          variant="body1"
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "28px",
          }}
        >
          {data?.unqual}
        </Typography>
        <SidePanelNumberDescription
          type={(data?.unqual || 0) < 0 ? "success" : "danger"}
          description={sidePanelDescription(data?.unqual || 0)}
        />
      </div>

      <SidePanelItemDetail items={items} />
    </SidePanelPaper>
  );
}
