import { usePagination } from "@/common/components/pagination/usePagination";
import SearchBar from "@/common/components/searchbar/Searchbar";
import TsTable from "@/common/components/ts-table";
import { getAllDrips } from "@/redux/reducers/sequences/drips/slices/listDrips";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { DripStepResponse, LookupDripStepRequest } from "@/services/generated";
import { IdentificationIcon } from "@heroicons/react/24/outline";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, DialogTitle, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Columns } from "./Columns";
import { Loading } from "@kennected/components";

interface SeqEnrollDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  dripTemplateId: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export type DripTableType = Required<Pick<DripStepResponse, "id">> & DripStepResponse;

export default function DripInModal({ isOpen, handleClose, dripTemplateId }: SeqEnrollDialogProps) {
  const dispatch = useAppDispatch();
  const { drips, total, loading } = useAppSelector((selector) => selector.drips.listDrips);
  const [search, setSearch] = useState("");
  const { onPaginationChange, pagination } = usePagination();

  const [filters, setFilters] = useState<LookupDripStepRequest>({
    contactName: search,
    dripTemplateId,
  });

  const debouncedSetFilters = useMemo(
    () =>
      debounce((value) => {
        setFilters((prev) => ({ ...prev, contactName: value }));
      }, 500),
    [],
  );

  useEffect(() => {
    debouncedSetFilters(search);
  }, [debouncedSetFilters, search]);

  useEffect(() => {
    dispatch(
      getAllDrips({
        ...filters,
        pageNumber: pagination.pageIndex,
        pageSize: pagination.pageSize,
      }),
    );
  }, [dispatch, filters, pagination.pageIndex, pagination.pageSize]);

  const table = useReactTable<DripTableType>({
    data: (drips as DripTableType[]) || [],
    columns: Columns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: total,
    manualPagination: true,
    state: {
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      },
    },
    onPaginationChange,
  });

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth={false}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "#1A2135",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "28px",
            letterSpacing: "0.2px",
          }}
        >
          Active Contacts In This Drip
        </Typography>
        <Box
          onClick={handleClose}
          sx={{
            display: "flex",
            alignSelf: "center",
          }}
        >
          <CloseIcon
            sx={{
              cursor: "pointer",
              color: "#1A2135",
            }}
          />
        </Box>
      </DialogTitle>
      <Box
        sx={{
          background: "#E0E0E0",
          height: "1px",
          flexShrink: 0,
          alignSelf: "stretch",
        }}
      />
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          minWidth: "600px",
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          <>
            <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}>
              <SearchBar
                sx={{ flexGrow: 1, maxWidth: "1/3" }}
                placeholder="Search by Name"
                value={search}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
              />
            </Box>

            <TsTable
              table={table}
              notFoundProps={{
                title: "No contacts found",
                Icon: IdentificationIcon,
              }}
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
