import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input";
import Switch from "@/common/components/switch";
import { getTeamUsers } from "@/redux/reducers/team/slices/listUsers";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  KnAiAgentBookingModeEnum,
  KnAiAgentCallFlowEnum,
  KnAiAgentCallToActionEnum,
  KnAiAgentRequestTransferEnum,
} from "@/services/generated";
import { Box, Typography, useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React, { useCallback, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  AiAgentForm,
  showAndReqPrompt,
  showAndReqPromptQualify,
  showAndReqHookStatement,
  reqKnowledgeBase,
  showAndReqCallToAction,
  showAndReqBookingMode,
  showAndReqBookingWith,
  showAndReqTransfer,
} from "..";
import { CALL_FLOW_TYPE_OPTIONS } from "../../constants";
import { getTeamAiVoiceSettings } from "@/redux/reducers/settings/team-ai-voice/get";
import config from "@/common/constants/config";
import { Loading } from "@kennected/components";
import { debounce } from "lodash";
import { processFaqUrl } from "@/redux/reducers/ai-voice/upsertAgent";
import toast from "@/lib/toast";
import { teamUserOptionLabel } from "@/common/functions/teamUserOptionLabel";
import { labelAscComparator } from "@/common/functions/option";
import { useAuth0 } from "@auth0/auth0-react";

const TEAM_ID_TIER_ONE_SUPPORT = [
  "3de68cb7-29ff-473f-804f-5992a0c70efe", // STG/PROD White Label
  "78a68b3d-5132-4a42-b454-4ce0e7f3c614", // STG/PROD White Label
  "66016138-7764-45e6-8d86-16f432eca58d", // DEV Internal
];

export const VALUE_ONE_TO_ONE = "ONE_TO_ONE";

const BOOKING_MODE_OPTIONS = [
  {
    label: "Round Robin",
    value: KnAiAgentBookingModeEnum.RoundRobin,
  },
  {
    label: "1:1",
    value: VALUE_ONE_TO_ONE,
  },
];

type BookingWithOption = {
  label: string;
  value: string;
};

const MAX_LENGTH_HOOK = 1000;
const MAX_LENGTH_PROMPT = 10000;

export default function CallFlowStep() {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const theme = useTheme();
  const { control, watch, setValue } = useFormContext<AiAgentForm>();
  const dispatch = useAppDispatch();
  const isLoadingFaq = useAppSelector(({ aiVoice }) => aiVoice.upsertAgent.isLoadingFaq);
  const teamUsers = useAppSelector(({ team }) => team.listUsers.users);
  const teamUsersLoading = useAppSelector(({ team }) => team.listUsers.loading);
  const transferPhoneNumberBilling = useAppSelector(
    ({ settings }) => settings.teamAiVoice.active?.transferPhoneNumberBilling,
  );
  const transferPhoneNumberSales = useAppSelector(
    ({ settings }) => settings.teamAiVoice.active?.transferPhoneNumberSales,
  );
  const transferPhoneNumberSupport = useAppSelector(
    ({ settings }) => settings.teamAiVoice.active?.transferPhoneNumberSupport,
  );
  const callFlow = watch("call_flow") || KnAiAgentCallFlowEnum.QualifyAndBookAMeeting;
  const callToAction = watch("call_to_action");
  const bookingMode = watch("booking_mode");

  const callToActionOptions = useMemo(() => {
    const options: { label: string; value: KnAiAgentCallToActionEnum }[] = [
      {
        label: "Book a Meeting",
        value: KnAiAgentCallToActionEnum.BookMeeting,
      },
      {
        label: "Live Transfer",
        value: KnAiAgentCallToActionEnum.LiveTransfer,
      },
      {
        label: "None",
        value: KnAiAgentCallToActionEnum.None,
      },
    ];

    // if (callFlow !== KnAiAgentCallFlowEnum.Prompt) {
    //   options.push({
    //     label: "Create Ticket",
    //     value: KnAiAgentCallToActionEnum.CreateTicket,
    //   });
    // }

    return options;
  }, [callFlow]);

  const callFlowOptions = useMemo(() => {
    const options = [];
    if (config.flagAiVoiceFlowPromptAndQualify) {
      options.push({
        value: KnAiAgentCallFlowEnum.PromptAndQualify,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.PromptAndQualify].label,
      });
    }
    if (config.flagAiVoiceFlowPrompt) {
      options.push({
        value: KnAiAgentCallFlowEnum.Prompt,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.Prompt].label,
      });
    }
    if (config.flagAiVoiceFlowScript) {
      options.push({
        value: KnAiAgentCallFlowEnum.Script,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.Script].label,
      });
    }
    if (config.flagAiVoiceFlowFaq) {
      options.push({
        value: KnAiAgentCallFlowEnum.AnswerFaqs,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.AnswerFaqs].label,
      });
    }
    if (config.flagAiVoiceFlowQualifyAndBook) {
      options.push({
        value: KnAiAgentCallFlowEnum.QualifyAndBookAMeeting,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.QualifyAndBookAMeeting].label,
      });
    }
    if (config.flagAiVoiceFlowQualifyAndLiveTransfer) {
      options.push({
        value: KnAiAgentCallFlowEnum.QualifyAndLiveTransfer,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.QualifyAndLiveTransfer].label,
      });
    }
    if (config.flagAiVoiceFlowBook) {
      options.push({
        value: KnAiAgentCallFlowEnum.BookAMeeting,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.BookAMeeting].label,
      });
    }
    if (config.flagAiVoiceFlowConfirm) {
      options.push({
        value: KnAiAgentCallFlowEnum.ConfirmAMeeting,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.ConfirmAMeeting].label,
      });
    }
    if (TEAM_ID_TIER_ONE_SUPPORT.includes(user?.team_id)) {
      options.push({
        value: KnAiAgentCallFlowEnum.TierOneSupport,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.TierOneSupport].label,
      });
    }
    return options;
  }, [user, CALL_FLOW_TYPE_OPTIONS]);

  useEffect(() => {
    dispatch(getTeamAiVoiceSettings());
  }, [dispatch]);

  const transferOptions = useMemo(() => {
    const options = [];
    if (transferPhoneNumberSales) {
      options.push({
        label: "Sales",
        value: KnAiAgentRequestTransferEnum.Sales,
      });
    }
    if (transferPhoneNumberBilling) {
      options.push({
        label: "Billing",
        value: KnAiAgentRequestTransferEnum.Billing,
      });
    }
    if (transferPhoneNumberSupport) {
      options.push({
        label: "Support",
        value: KnAiAgentRequestTransferEnum.Support,
      });
    }
    return options;
  }, [transferPhoneNumberBilling, transferPhoneNumberSupport, transferPhoneNumberSales]);

  useEffect(() => {
    if (teamUsers === undefined && !teamUsersLoading) {
      dispatch(getTeamUsers());
    }
  }, [dispatch, teamUsers, teamUsersLoading]);

  const bookingWithOptions: BookingWithOption[] = useMemo(() => {
    return (teamUsers || [])
      .filter((u) => u.calendarConnected)
      .map((u) => {
        return {
          label: teamUserOptionLabel(u),
          value: u.user_id!,
        };
      })
      .sort(labelAscComparator);
  }, [teamUsers]);

  const isValidUrl = (urlString: string): boolean => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$", // validate fragment locator
      "i",
    );
    return urlPattern.test(urlString);
  };

  const debouncedProcessFaqUrl = useMemo(
    () => debounce((url: string) => dispatch(processFaqUrl(url)), 1000),
    [dispatch],
  );

  const handleFaqUrl = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const faqUrl = event.target.value;
      setValue("knowledge_base_url", faqUrl as never);
      if (!isValidUrl(faqUrl)) return;
      debouncedProcessFaqUrl(faqUrl);
    },
    [debouncedProcessFaqUrl],
  );

  return (
    <Box
      sx={{
        display: "grid",
        gap: "32px",
      }}
    >
      <Controller
        name="call_flow"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label={t("callFlow.callFlow")} aria-required />
            <Dropdown
              options={callFlowOptions}
              value={field.value}
              onChange={(o) => field.onChange(o.value)}
              error={fieldState.error?.message}
            />
            {field.value && (
              <Typography
                variant="body2"
                sx={{
                  mt: 2,
                  fontSize: "14px",
                  color: theme.palette.commonColors.text,
                  bgcolor: theme.palette.midnight[20],
                  overflow: "hidden",
                  p: 1,
                  borderRadius: 2,
                }}
              >
                {CALL_FLOW_TYPE_OPTIONS[field.value].description}
              </Typography>
            )}
          </FormControl>
        )}
      />

      {showAndReqPrompt(callFlow) && (
        <Controller
          name="call_flow_prompt"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={"Prompt"} aria-required />
              <Input
                {...field}
                multiline
                rows={6}
                onChange={(e) => {
                  if (e.target.value.length <= MAX_LENGTH_PROMPT) {
                    field.onChange(e);
                  } else {
                    toast.error("Prompt cannot exceed 10,000 characters", {
                      style: {
                        width: "350px",
                      },
                    });
                  }
                }}
                error={fieldState.error?.message}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-18px",
                  right: "10px",
                  color:
                    field.value?.length === MAX_LENGTH_PROMPT ? theme.palette.error.main : theme.palette.text.secondary,
                }}
              >
                <Typography variant="caption">
                  {`${field.value?.length || 0}/${MAX_LENGTH_PROMPT}`} characters used
                </Typography>
              </Box>
            </FormControl>
          )}
        />
      )}

      {showAndReqPromptQualify(callFlow) && (
        <Controller
          name="call_flow_prompt_qualify"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel
                label={
                  callFlow === KnAiAgentCallFlowEnum.PromptAndQualify
                    ? "Required Qualifiers"
                    : "Objections/Question Talk Tracks"
                }
                aria-required
              />
              <Input
                {...field}
                multiline
                rows={6}
                onChange={(e) => {
                  if (e.target.value.length <= MAX_LENGTH_PROMPT) {
                    field.onChange(e);
                  } else {
                    toast.error("Prompt cannot exceed 10,000 characters", {
                      style: {
                        width: "350px",
                      },
                    });
                  }
                }}
                error={fieldState.error?.message}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-18px",
                  right: "10px",
                  color:
                    field.value?.length === MAX_LENGTH_PROMPT ? theme.palette.error.main : theme.palette.text.secondary,
                }}
              >
                <Typography variant="caption">
                  {`${field.value?.length || 0}/${MAX_LENGTH_PROMPT}`} characters used
                </Typography>
              </Box>
            </FormControl>
          )}
        />
      )}

      {showAndReqHookStatement(callFlow) && (
        <Controller
          name="hook_statement"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={t("callFlow.hookStatement")} />
              <Input
                placeholder={t("callFlow.hookStatement")}
                {...field}
                onChange={(e) => {
                  if (e.target.value.length <= MAX_LENGTH_HOOK) {
                    field.onChange(e);
                  } else {
                    toast.error("Prompt cannot exceed 1,000 characters", {
                      style: {
                        width: "350px",
                      },
                    });
                  }
                }}
                error={fieldState.error?.message}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-18px",
                  right: "10px",
                  color:
                    field.value?.length === MAX_LENGTH_PROMPT ? theme.palette.error.main : theme.palette.text.secondary,
                }}
              >
                <Typography variant="caption">
                  {`${field.value?.length || 0}/${MAX_LENGTH_HOOK}`} characters used
                </Typography>
              </Box>
            </FormControl>
          )}
        />
      )}

      <Box sx={{ display: "flex", gap: 3 }}>
        <Controller
          name="knowledge_base_url"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl sx={{ width: "100%" }}>
              <FormLabel label="Knowledge Base URL" aria-required={reqKnowledgeBase(callFlow)} />
              <Input value={field.value} onChange={handleFaqUrl} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
        {isLoadingFaq && <Loading />}
      </Box>

      {showAndReqCallToAction(callFlow) && (
        <Controller
          name="call_to_action"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={"Call to Action"} aria-required />
              <Dropdown
                options={callToActionOptions}
                value={field.value}
                onChange={(o) => field.onChange(o.value)}
                error={fieldState.error?.message}
              />
            </FormControl>
          )}
        />
      )}

      {callToAction === KnAiAgentCallToActionEnum.None && (
        <Typography
          sx={{
            fontSize: 14,
          }}
        >
          Selecting this option will not trigger any follow-up actions.
        </Typography>
      )}

      {showAndReqBookingMode(callFlow, callToAction) && (
        <Controller
          name="booking_mode"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={"Booking Mode"} aria-required />
              <Dropdown
                options={BOOKING_MODE_OPTIONS}
                value={field.value}
                onChange={(o) => field.onChange(o.value)}
                error={fieldState.error?.message}
              />
            </FormControl>
          )}
        />
      )}

      {showAndReqBookingWith(callFlow, callToAction, bookingMode) && (
        <Controller
          name="booking_with"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={"Booking With"} aria-required />
              <Dropdown
                value={field.value as any} // TODO: Fix multi values
                ref={field.ref}
                isMulti
                options={bookingWithOptions}
                onChange={(options) => {
                  field.onChange(options.map((option: any) => option.value));
                }}
                error={fieldState.error?.message}
              />
            </FormControl>
          )}
        />
      )}

      {showAndReqTransfer(callFlow, callToAction) && (
        <Controller
          name="transfer"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={t("callFlow.transfer")} aria-required />
              <Dropdown
                options={transferOptions}
                value={field.value}
                onChange={(o) => field.onChange(o.value)}
                error={fieldState.error?.message}
              />
            </FormControl>
          )}
        />
      )}

      <Box sx={{ display: "flex", gap: 3 }}>
        <Controller
          name="catch_webhook"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl sx={{ width: "100%" }}>
              <FormLabel label={t("callFlow.thirdPartyWebhook")} />
              <Input
                placeholder="https://www.webhook-url-address.com"
                value={field.value}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            </FormControl>
          )}
        />

        <Controller
          name="double_call"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl sx={{ width: "120px" }}>
              <FormLabel label={t("callFlow.doubleCall")} />
              <Switch
                error={fieldState.error?.message}
                value={!!field.value}
                onChange={(value) => field.onChange(value)}
              />
            </FormControl>
          )}
        />
      </Box>
    </Box>
  );
}
