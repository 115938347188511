import { useEffect, useState } from "react";

export default function useDropdownState({
  dropdownRef,
  isMulti,
}: {
  dropdownRef: React.RefObject<HTMLDivElement>;
  isMulti: boolean;
}) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [ignoreFirstClick, setIgnoreFirstClick] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ignoreFirstClick) {
        setIgnoreFirstClick(false);
        return;
      }

      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        if (menuIsOpen) {
          setMenuIsOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuIsOpen, isMulti, ignoreFirstClick, dropdownRef]);

  const handleMenuOpen = () => {
    setMenuIsOpen(true);
    setIgnoreFirstClick(true);
  };

  return {
    menuIsOpen,
    handleMenuOpen,
    setMenuIsOpen,
  };
}
