import { Chip, Theme, useTheme } from "@mui/material";
import { ProspectStatus } from "@/types/prospects/";

import {
  SequenceResponseStatusEnum,
  TaskRequestStatusEnum,
  LeadSourceTriggerResponseStatusEnum,
} from "@/services/generated";
import { ConsentLevel } from "@/common/constants/consent-level";
import { BrandRegistrationEnum } from "@/common/constants/brand-registration";

type Props = {
  status:
    | TaskRequestStatusEnum
    | ProspectStatus
    | ConsentLevel
    | BrandRegistrationEnum
    | SequenceResponseStatusEnum
    | LeadSourceTriggerResponseStatusEnum;

  type: "task" | "prospect" | "consent" | "brandRegistration" | "sequence";
};

const consents = {
  [ConsentLevel.Lvl2.toString()]: "L2",
  [ConsentLevel.Lvl1.toString()]: "L1",
  [ConsentLevel.None.toString()]: "None",
};

const StatusChip = ({ status, type }: Props) => {
  const theme = useTheme();

  const defaultStyles = {
    borderRadius: "16px",
    padding: "4px 8px",
    color: "#fff",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0.2px",
    "& .MuiChip-label": {
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  };

  const getTaskStatusStyles = () => {
    switch (status) {
      case TaskRequestStatusEnum.Complete:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.mint.p,
          color: theme.palette.txt.default,
        };
      case TaskRequestStatusEnum.Due:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.midnight[80],
        };
      case TaskRequestStatusEnum.Upcoming:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.commonColors.disabled,
          color: theme.palette.txt.default,
        };
      case TaskRequestStatusEnum.Overdue:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.commonColors.danger,
        };
      default:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.commonColors.disabled,
          color: theme.palette.txt.default,
        };
    }
  };
  const getBrandRegistrationStatusStyles = () => {
    switch (status) {
      case BrandRegistrationEnum.Approved:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.mint.p,
          color: theme.palette.txt.default,
        };
      case BrandRegistrationEnum.Incomplete:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.icon.warning,
          color: theme.palette.txt.default,
        };
      case BrandRegistrationEnum.Pending:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.midnight[80],
        };
      case BrandRegistrationEnum.Rejected:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.commonColors.danger,
        };
      default:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.commonColors.disabled,
          color: theme.palette.txt.default,
        };
    }
  };
  const getConsentStatusStyles = () => {
    switch (status) {
      case ConsentLevel.Lvl2:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.mint.p,
          color: theme.palette.txt.default,
        };

      case ConsentLevel.Lvl1:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.midnight[80],
        };

      case ConsentLevel.None:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.icon.disabled,
          color: theme.palette.txt.default,
        };
      default:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        };
    }
  };
  const getProspectStatusStyle = (theme: Theme) => {
    switch (status) {
      case ProspectStatus.InProgress:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.commonColors.informative,
          color: theme.palette.common.white,
        };
      case ProspectStatus.Open:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.commonColors.success,
          color: theme.palette.common.white,
        };
      case ProspectStatus.OpenDeal:
        return {
          ...defaultStyles,

          backgroundColor: theme.palette.commonColors.informative,
          color: theme.palette.common.white,
        };
      case ProspectStatus.Unqualified:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.commonColors.danger,
          color: theme.palette.common.white,
        };
      case ProspectStatus.New:
        return {
          ...defaultStyles,

          backgroundColor: "#FBEFC4",
          color: "#59491F",
        };
      case ProspectStatus.Inactive:
        return {
          ...defaultStyles,
          backgroundColor: "#E7EBED",
          color: "##09131D",
        };
      default:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        };
    }
  };

  const getSequenceStatusStyles = () => {
    switch (status) {
      case SequenceResponseStatusEnum.Canceled:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.commonColors.danger,
          color: theme.palette.common.white,
        };
      case SequenceResponseStatusEnum.Completed:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.commonColors.success,
          color: theme.palette.common.white,
        };
      case SequenceResponseStatusEnum.InProgress:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.commonColors.informative,
          color: theme.palette.common.white,
        };
      default:
        return {
          ...defaultStyles,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        };
    }
  };

  const getBadgeColor = () => {
    if (type === "prospect") {
      return getProspectStatusStyle(theme);
    } else if (type === "consent") {
      return getConsentStatusStyles();
    } else if (type === "brandRegistration") {
      return getBrandRegistrationStatusStyles();
    } else if (type === "sequence") {
      return getSequenceStatusStyles();
    } else {
      return getTaskStatusStyles();
    }
  };

  const getSequenceLabel = (status: string) => {
    switch (status) {
      case SequenceResponseStatusEnum.Canceled:
        return "Canceled";
      case SequenceResponseStatusEnum.Completed:
        return "Complete";
      case SequenceResponseStatusEnum.InProgress:
        return "In Progress";
      default:
        return "Unknown";
    }
  };

  const getBadgeText = (status: string) => {
    if (type === "prospect") {
      return status;
    } else if (type === "consent") {
      return consents[status];
    } else if (type === "sequence") {
      return getSequenceLabel(status);
    } else {
      return status;
    }
  };

  return <Chip label={getBadgeText(status)} sx={() => getBadgeColor()} />;
};

export default StatusChip;
