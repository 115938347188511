import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import BasicInformation from "@/pages/settings/user/basic-information/BasicInformation";
import Integrations from "@/pages/settings/user/integrations/Integrations";
import { useAppSelector } from "@/redux/store";
import { Box, Divider } from "@mui/material";

export default function UserSettings() {
  const loading = useAppSelector(({ settings }) => settings.user.loading);

  if (loading) {
    return <FullLoadingOverlay loading={loading} />;
  }
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "16px",
      }}
    >
      <BasicInformation />
      <Divider />
      <Integrations />
    </Box>
  );
}
