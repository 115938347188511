import Drawer from "@mui/material/Drawer";
import CreateProductForm from "@/pages/deal-flow-actions/create/CreateProductForm";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { closeCreateProductDrawer } from "@/redux/reducers/productLibrary/slices/listProducts";

export default function CreateDrawer() {
  const { createProductDrawerOpen } = useAppSelector(({ productLibrary }) => productLibrary.listProducts);
  const dispatch = useAppDispatch();

  return (
    <Drawer open={createProductDrawerOpen} onClose={() => dispatch(closeCreateProductDrawer())} anchor="right">
      <CreateProductForm />
    </Drawer>
  );
}
