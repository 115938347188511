import TaskFilters from "./filters";
import { useEffect, useState } from "react";
import CreateNewTask from "./create/CreateTaskDrawer";
import { useTranslation } from "react-i18next";
import ViewTaskDrawer from "./view/ViewTaskDrawer";
import EditTaskDrawer from "./edit/EditTaskDrawer";
import EditAssigneeDrawer from "./assignee/EditAssigneeDrawer";
import { CalendarOutline, CheckCircleOutline, UserOutline } from "heroicons-react";
import { TaskColumns } from "./table/columns";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getTasks } from "@/redux/reducers/tasks/slices/listTask";
import { ReactComponent as TrashIcon } from "@/common/assets/svg/trash_icon.svg";
import { usePagination } from "@/common/components/pagination/usePagination";
import { TaskRequestStatusEnum, TaskRequestTypeEnum, KnLookupRequest2 } from "@/services/generated";
import { setCurrentTask } from "@/redux/reducers/tasks/slices/listTask";
import { openViewTaskDrawer } from "@/redux/reducers/tasks/slices/listTask";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import { setCurrentProspect } from "@/redux/reducers/prospects/slices/listProspects";
import TsTable from "@/common/components/ts-table";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import PageHeader from "@/common/components/page-header/PageHeader";
import { QueueListIcon } from "@heroicons/react/24/outline";
import { Box } from "@mui/material";

export type TaskFiltersInput = {
  dueDate: string | undefined;
  sortBy: string | undefined;
  sortDirection: KnLookupRequest2["sortDirection"] | undefined;
  type?: TaskRequestTypeEnum;
  status?: TaskRequestStatusEnum;
  dealId?: string;
  dueDateStart?: string;
  dueDateEnd?: string;
  prospectName?: string;
};

export default function Tasks() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { totalCount, tasks, loadingTasks, pendingCreateTask, pendingUpdateTask, currentTask } = useAppSelector(
    ({ tasks }) => tasks.listTask,
  );
  const [filters, setFilters] = useState<TaskFiltersInput>();
  const { onPaginationChange, pagination } = usePagination();

  useEffect(() => {
    dispatch(
      getTasks({
        pageNumber: pagination.pageIndex,
        pageSize: pagination.pageSize,
        ...filters,
      }),
    );
  }, [dispatch, pagination.pageIndex, pagination.pageSize, filters]);

  const handleCellClick = (id: number | string) => {
    const rowId = id?.toString();
    dispatch(setCurrentTask(rowId) as any);
    dispatch(openViewTaskDrawer());
  };

  useEffect(() => {
    if (currentTask?.prospect) {
      dispatch(setCurrentProspect(currentTask.prospect));
    }
  }, [currentTask, dispatch]);

  const table = useReactTable<any>({
    data: tasks || [],
    columns: TaskColumns({ filters, setFilters }),
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: totalCount,
    state: {
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },
    onPaginationChange,
  });

  return (
    <>
      {loadingTasks ? (
        <LoadingSpinner />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <FullLoadingOverlay loading={pendingCreateTask || pendingUpdateTask} overlay={true} />
          <PageHeader
            title={t("tasks.title")}
            actions={<CreateNewTask />}
            filters={<TaskFilters filters={filters} setFilters={setFilters} />}
          />
          {table.getSelectedRowModel().rows.length ? (
            <Box
              sx={{
                border: "1px solid #E7EBED",
                width: "fit-content",
                padding: "4px 12px",
                marginBottom: "10px",
                borderRadius: "8px",
                display: "flex",
                gap: "8px",
                background: "#fff",
              }}
            >
              <CheckCircleOutline size={20} style={{ marginRight: "8px" }} />
              <UserOutline size={20} style={{ marginRight: "8px" }} />
              <CalendarOutline size={20} style={{ marginRight: "8px" }} />
              <TrashIcon width={20} height={20} style={{ marginRight: "8px" }} />
            </Box>
          ) : null}
          <TsTable
            table={table}
            handleCellClick={handleCellClick}
            notFoundProps={{
              title: "No tasks found",
              description: (
                <span>
                  Begin creating an agent by clicking the <br /> <b>"Create Task"</b> button above.
                </span>
              ),
              Icon: QueueListIcon,
            }}
          />

          <ViewTaskDrawer />
          <EditTaskDrawer />
          <EditAssigneeDrawer />
        </Box>
      )}
    </>
  );
}
