import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";
import { AxiosResponse } from "axios";
import { AiSalesProcess } from "@/services/generated";

export const getSalesProcess = createAsyncThunk(
  "aiLearning/getSalesProcess",
  async (): Promise<AxiosResponse<AiSalesProcess>> => {
    try {
      return await Services.Ai.salesProcessGet();
    } catch (error: any) {
      return error;
    }
  },
);
