import Typography from "@mui/material/Typography";
import ProspectsAutomation from "./ProspectsAutomation";
import OutstandingTasks from "./OutstandingTasks";
import React, { CSSProperties, useEffect } from "react";
import Pill from "@/common/components/pill/Pill";
import { ReactComponent as ArrowTrendingUp } from "@/common/assets/svg/arrow_trending_up.svg";
import { ReactComponent as ArrowTrendingDown } from "@/common/assets/svg/arrow_trending_down.svg";
import Dropdown from "@/common/components/dropdown/Dropdown";
import config from "@/common/constants/config";
import { useAuth0 } from "@auth0/auth0-react";
import AutomatedActivities from "./AutomatedActivities";
import LeadFlowPerformanceBySource from "./LeadFlowPerformanceBySource";
import { FormProvider, useForm, Controller } from "react-hook-form";
import LeadFlowPerformance from "./LeadFlowPerformance";
import { useAppSelector } from "@/redux/store";

export enum DashboardDateRange {
  THIS_MONTH = "THIS_MONTH",
  LAST_MONTH = "LAST_MONTH",
  LAST_30_DAYS = "LAST_30_DAYS",
  LAST_90_DAYS = "LAST_90_DAYS",
}

export const SidePanelPaper = ({ children, title }: { children: React.ReactNode; title: string }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      background: "#F8F9FA",
      gap: "12px",
      padding: "8px",
      borderRadius: "6px",
    }}
  >
    <Typography
      variant="body2"
      sx={{
        fontWeight: 500,
      }}
    >
      {title}
    </Typography>
    {children}
  </div>
);

export const SidePanelItemDetail = ({
  items,
}: {
  items: { name: string; count: number; days: string; good: boolean }[];
}) => (
  <>
    {items.map((item, index) => (
      <div
        key={item.name}
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          borderBottom: index === items.length - 1 ? "none" : "1px solid #E7EBED",
          paddingBottom: "5px",
        }}
      >
        <Typography variant="body2" sx={{ color: "#6B747B", lineHeight: "16px", width: "118px" }}>
          {item.name}
        </Typography>
        <div style={{ display: "flex", gap: "5px" }}>
          <Typography
            sx={{
              color: "#1A2135",
              fontSize: "18px",
              fontWeight: 600,
              lineHeight: "28px",
              letterSpacing: "0.2px",
            }}
          >
            {item.count}
          </Typography>
          <div
            style={{
              display: "flex",
              gap: "4px",
              alignItems: "center",
            }}
          >
            {item.good ? <ArrowTrendingUp /> : <ArrowTrendingDown />}

            <div
              style={{
                color: item.good ? "#05CE74" : "#FB2D67",
                fontSize: "10px",
                fontWeight: 500,
                lineHeight: "16px",
                letterSpacing: "0.2px",
              }}
            >
              {item.days}
            </div>
          </div>
        </div>
      </div>
    ))}
  </>
);

export const SidePanelNumberDescription = ({ type, description }: { type: string; description: string }) => (
  <div
    style={{
      display: "flex",
      gap: "4px",
      background: type === "danger" ? "rgba(251, 45, 103, 0.3)" : "#E6FDEE",
      border: type === "danger" ? "1px solid #FB2D67" : "1px solid #D6F5DF",
      borderRadius: "2px",
      width: "fit-content",
      padding: "2px 7px",
      height: "fit-content",
    }}
  >
    <Typography
      variant="body1"
      sx={{
        padding: "0px",
        fontSize: "12px",
        fontWeight: 400,
        color: type === "danger" ? "#FB2D67" : "#05CF74",
        display: "flex",
        alignItems: "center",
      }}
    >
      {description}
    </Typography>
  </div>
);

const Paper = ({
  children,
  title,
  count,
  sx,
}: {
  children: React.ReactNode;
  title: string;
  count?: number;
  sx?: CSSProperties;
}) => (
  <div
    style={{
      background: "white",
      padding: "16px",
      borderRadius: "8px",
      border: "1px solid #E7EBED",
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      ...sx,
    }}
  >
    <div style={{ display: "flex", gap: "8px" }}>
      <Typography variant="body1" style={{ fontWeight: 600 }}>
        {title}
      </Typography>
      {count ? <Pill label={count.toString()} bgColor="success" /> : null}
    </div>
    {children}
  </div>
);

export default function MainDashboardPage() {
  const { getAccessTokenSilently, logout } = useAuth0();
  const { totalCount: tasksTotalCount } = useAppSelector(({ tasks }) => tasks.listTask);

  const methods = useForm({
    defaultValues: {
      dateRangeFilter: DashboardDateRange.THIS_MONTH,
    },
  });

  useEffect(() => {
    const getOrCreateUser = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        const options = {};

        if (!accessToken) return;

        await fetch(`${config.urlApiGateway}/user`, {
          method: "POST",
          ...options,
          headers,
        });
      } catch (e) {
        // If there is an error fetching the user, log the user out
        logout();
        console.log(e);
      }
    };

    getOrCreateUser();
  }, [getAccessTokenSilently]);

  return (
    <form>
      <FormProvider {...methods}>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px", height: "100vh" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            {/* <Title /> */}
            <div></div>
            <div style={{ width: "250px" }}>
              <Controller
                name="dateRangeFilter"
                control={methods.control}
                render={({ field, fieldState: { error } }) => (
                  <Dropdown
                    label="This Month"
                    options={[
                      { label: "This Month", value: DashboardDateRange.THIS_MONTH },
                      { label: "Last Month", value: DashboardDateRange.LAST_MONTH },
                      { label: "Last 30 Days", value: DashboardDateRange.LAST_30_DAYS },
                      { label: "Last 90 Days", value: DashboardDateRange.LAST_90_DAYS },
                    ]}
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column", width: "100%", marginRight: "20px" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                  gap: "20px",
                  maxWidth: "100%",
                }}
              >
                <Paper title="Outstanding Tasks" count={tasksTotalCount || 0}>
                  <OutstandingTasks />
                </Paper>

                <Paper title="Automated activities and time saved">
                  <AutomatedActivities />
                </Paper>

                <Paper title="Lead Flow Performance by Source">
                  <LeadFlowPerformanceBySource />
                </Paper>

                <Paper title="Prospects in automation">
                  <ProspectsAutomation />
                </Paper>
              </div>
            </div>

            <Paper title="Lead Flow performance" sx={{ minWidth: "250px", height: "100%" }}>
              <LeadFlowPerformance />

              {/* <NewContactsCreated /> */}
            </Paper>
          </div>
        </div>
      </FormProvider>
    </form>
  );
}
