import Drawer from "@mui/material/Drawer";
import { closeEditDrawer } from "@/redux/reducers/prospects/companies/slices/listCompanies";
import EditCompanyForm from "@/pages/prospects/companies/edit/EditCompanyForm";
import { useAppDispatch, useAppSelector } from "@/redux/store";

export default function EditCompanyDrawer() {
  const dispatch = useAppDispatch();
  const editDrawerIsOpen = useAppSelector(({ companies }) => companies.listCompanies.editDrawerIsOpen);

  return (
    <div data-testid="editcompanyDrawer">
      <Drawer open={editDrawerIsOpen} onClose={() => dispatch(closeEditDrawer())} anchor="right">
        <EditCompanyForm />
      </Drawer>
    </div>
  );
}
