import { LoadingSpinner } from "../loading-overlay/LoadingSpinner";
import { GuardProps } from "./types";
import { useAuth0 } from "@auth0/auth0-react";

export const AuthenticationGuard = ({ children }: GuardProps) => {
  const { user, isLoading, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!user) {
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}`,
      },
    });
    return null;
  }

  return children;
};
