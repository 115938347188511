import { Link as MuiLink, SvgIcon, Box, Typography, useTheme } from "@mui/material";
import { Link as MuiLinkProps } from "@mui/material";

export type LinkProps = React.ComponentProps<typeof MuiLinkProps> & {
  size: "sm" | "md" | "lg";
  disabled?: boolean;
  iconPosition?: "start" | "end" | "none";
  children: React.ReactElement | string;
};

export default function Link(props: LinkProps) {
  const { size = "lg", disabled, children, iconPosition, ...rest } = props;
  const theme = useTheme();

  const iconSize = {
    sm: "16px",
    md: "20px",
    lg: "24px",
  };

  const Icon = (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={iconSize[size]}
        height={iconSize[size]}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M13.5 6H5.25C4.00736 6 3 7.00736 3 8.25V18.75C3 19.9926 4.00736 21 5.25 21H15.75C16.9926 21 18 19.9926 18 18.75V10.5M7.5 16.5L21 3M21 3L15.75 3M21 3V8.25"
          stroke={disabled ? theme.palette.link.disabled : "#357DF0"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );

  const Svg = () => (
    <SvgIcon
      sx={{
        fill: "none",
        stroke: disabled ? theme.palette.link.disabled : "#357DF0",
        strokeWidth: 1.5,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        width: iconSize[size],
        height: iconSize[size],
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
        />
      </svg>
    </SvgIcon>
  );

  const Text = ({ text }: { text: string | React.ReactElement }) => (
    <Typography
      sx={{
        fontSize: size === "sm" ? "12px" : size === "md" ? "14px" : "16px",
        lineHeight: size === "sm" ? "16px" : size === "md" ? "20px" : "24px",
        letterSpacing: size === "sm" ? "0.2px" : "0.3px",
      }}
    >
      {text}
    </Typography>
  );

  const iconComponent =
    iconPosition === "start" ? (
      <>
        <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
          {<Svg />} <Text text={children} />{" "}
        </Box>
      </>
    ) : iconPosition === "end" ? (
      <>
        <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
          <Text text={children} /> {<Svg />}{" "}
        </Box>
      </>
    ) : (
      <Text text={children} />
    );
  return (
    <>
      <MuiLink
        sx={{
          textDecoration: "none",
          color: disabled ? theme.palette.link.disabled : "#357DF0",
          fontSize: size === "sm" ? "12px" : size === "md" ? "14px" : "16px",
          fontWeight: 400,
          lineHeight: size === "sm" ? "16px" : size === "md" ? "20px" : "24px",
          letterSpacing: size === "sm" ? "0.2px" : "0.3px",
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
            color: disabled ? theme.palette.link.disabled : "rgba(53, 125, 240, 1)",
          },
        }}
        {...rest}
      >
        {iconComponent}
      </MuiLink>
    </>
  );
}
