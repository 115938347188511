import Button from "@/common/components/button";
import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogContent, Divider, IconButton, Typography } from "@mui/material";
import { ReactNode } from "react";

interface ActionButton {
  text?: string;
  action?: () => void;
}

interface DialogProps {
  open: boolean;
  title: string;
  content: string | ReactNode;
  confirmButton?: ActionButton;
  cancelButton?: ActionButton;
}

const GenericDialog = (props: DialogProps) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.cancelButton?.action}
      PaperProps={{ sx: { width: "628px", maxWidth: "none" } }}
    >
      <IconButton
        onClick={props.cancelButton?.action}
        sx={{ padding: "0px", position: "absolute", right: 16, top: 16 }}
      >
        <Close sx={(theme) => ({ color: theme.palette.grey[700] })} />
      </IconButton>

      <Typography
        variant="h2"
        fontWeight="bold"
        color={(theme) => {
          return theme.palette.grey[800];
        }}
        sx={{
          fontFamily: "Roboto",
          fontSize: 18,
          fontWeight: 600,
          textAlign: "left",
          color: "#1a2135",
          padding: "18px 24px",
        }}
      >
        {props.title}
      </Typography>
      <Divider />
      <DialogContent>
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontSize: 14,
            textAlign: "left",
            color: "#1a2135",
          }}
          color={(theme) => {
            return theme.palette.grey[800];
          }}
        >
          {props.content}
        </Typography>
      </DialogContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          gap: 2.5,
          paddingBottom: "16px",
          paddingRight: "24px",
        }}
      >
        {props.cancelButton && (
          <Button variant="secondary" size="sm" onClick={props.cancelButton?.action}>
            {props.cancelButton?.text || "Cancel"}
          </Button>
        )}
        {props.confirmButton && (
          <Button size="sm" onClick={props.confirmButton?.action}>
            {props.confirmButton?.text || "Confirm"}
          </Button>
        )}
      </Box>
    </Dialog>
  );
};

export default GenericDialog;
