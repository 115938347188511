import * as React from "react";
import { useAppDispatch } from "@/redux/store";
import { ReactComponent as Edit } from "@/common/assets/svg/edit.svg";
import { ReactComponent as Duplicated } from "@/common/assets/svg/duplicated.svg";
import { ReactComponent as Delete } from "@/common/assets/svg/delete_trigger.svg";
import { handleDelete } from "@/redux/reducers/triggers/slices/listTriggers";
import { useNavigate } from "react-router-dom";
import { createTrigger } from "@/redux/reducers/triggers/slices/createTrigger";
import routes from "@/common/constants/routes";
import MenuOptions from "@/common/components/menu-options/MenuOptions";

const options = [
  {
    label: "Edit Trigger",
    value: "edit",
    icon: <Edit />,
  },
  {
    label: "Duplicate Trigger",
    value: "duplicateTrigger",
    icon: <Duplicated />,
  },
  {
    label: "Delete Trigger",
    value: "delete",
    icon: <Delete />,
  },
];

export default function TableActionButton(props: any) {
  const { params } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MenuOptions
      open={open}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      options={options}
      onClick={(option) => {
        if (option.value === "delete") {
          dispatch(handleDelete(params.row.original.id));
        } else if (option.value === "edit") {
          navigate(routes.triggers.update.with({ triggerId: params.row.original.id }));
        } else if (option.value === "duplicateTrigger") {
          const duplicateTrigger = {
            ...params.row.original,
            name: `${params.row.original.name} - Copy`,
            isDelayed: false,
          };
          // delete id
          delete duplicateTrigger.id;
          dispatch(createTrigger(duplicateTrigger));
        }
      }}
    />
  );
}
