import Services from "@/services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Company } from "./types";
import { KnLookupRequestSortDirectionEnum } from "@/services/generated";
import { KnCompanyRequest } from "@/services/generated";

type State = {
  companies?: Company[];
  currentCompany?: Company | null;
  viewDrawerIsOpen?: boolean;
  editDrawerIsOpen?: boolean;
  totalCount?: number;
  loading: boolean;
  error: string | null;
  pendingCreateCompany: boolean;
  createCompanyDrawerOpen: boolean;
};

const initialState: State = {
  loading: true,
  error: null,
  pendingCreateCompany: false,
  createCompanyDrawerOpen: false,
};

type Filters = {
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: KnLookupRequestSortDirectionEnum;
  searchTerm?: string;
};

export const getCompanies = createAsyncThunk("prospects/companies/getCompanies", async (filters?: Filters) => {
  try {
    const response = await Services.Companies.pageTeamCompanies({
      page: filters?.page ?? 0,
      pageSize: filters?.pageSize ?? 10,
      sortBy: filters?.sortBy ?? "createdAt",
      sortDirection: filters?.sortDirection ?? KnLookupRequestSortDirectionEnum.Desc,
    });
    return response;
  } catch (error: any) {
    return error;
  }
});

export const searchCompanies = createAsyncThunk("prospects/companies/searchCompanies", async (filters?: Filters) => {
  try {
    const response = await Services.Companies.keywordSearchTeamCompanies({
      page: filters?.page ?? 0,
      pageSize: filters?.pageSize ?? 10,
      sortBy: filters?.sortBy ?? "createdAt",
      sortDirection: filters?.sortDirection ?? KnLookupRequestSortDirectionEnum.Desc,
      searchTermList: filters?.searchTerm?.split(" ") ?? [],
    });
    return response;
  } catch (error: any) {
    return error;
  }
});

export const createCompany = createAsyncThunk(
  "prospects/companies/createCompany",
  async (newCompany: KnCompanyRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Companies.createCompany(newCompany);
      return response.data?.result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const handleEditCompany = async (state: State, companyId: string, updatedCompanyData: Partial<Company>) => {
  if (!state.companies) return;

  const companyIndex = state.companies.findIndex((task) => task.id === companyId);

  if (companyIndex !== -1) {
    state.companies[companyIndex] = { ...state.companies[companyIndex], ...updatedCompanyData };
  }
  await Services.Companies.putCompany({ ...updatedCompanyData, id: companyId });
};

const handleDeleteCompany = async (state: State, companyId: string) => {
  if (!state.companies) return;

  const taskIndex = state.companies.findIndex((company) => company.id === companyId);

  if (taskIndex !== -1) {
    state.companies.splice(taskIndex, 1);
  }
  await Services.Companies.deleteCompany(companyId);
};
const listCompaniesSlice = createSlice({
  name: "listCompanies",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(createCompany.pending, (state, { payload }) => {
      state.pendingCreateCompany = true;
      state.error = null;
    });

    builder.addCase(createCompany.fulfilled, (state, { payload }) => {
      if (payload) {
        state.companies = [payload as Company, ...(state.companies ?? [])];
      }
      state.pendingCreateCompany = false;
      state.error = null;
    });

    builder.addCase(createCompany.rejected, (state, { payload }) => {
      state.pendingCreateCompany = false;
    });

    builder.addCase(getCompanies.fulfilled, (state, { payload }) => {
      if (payload.data.results) {
        state.companies = payload.data.results;
        state.totalCount = payload.data.total ?? 0;
      }
      state.loading = false;
    });

    builder.addCase(getCompanies.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(searchCompanies.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(searchCompanies.fulfilled, (state, { payload }) => {
      if (payload) {
        state.loading = false;
        state.companies = payload.data.results;
        state.totalCount = payload.data.total ?? 0;
      }
    });
  },
  reducers: {
    setCurrentCompany: (state, action) => {
      state.currentCompany = action.payload;
    },

    editCompany: (state, action) => {
      handleEditCompany(state, action.payload.id, action.payload);
    },
    deleteCompany: (state, action) => {
      handleDeleteCompany(state, action.payload);
    },
    openViewDrawer: (state) => {
      state.viewDrawerIsOpen = true;
    },
    closeViewDrawer: (state) => {
      state.viewDrawerIsOpen = false;
    },
    openEditDrawer: (state) => {
      state.editDrawerIsOpen = true;
    },
    closeEditDrawer: (state) => {
      state.editDrawerIsOpen = false;
    },
    createCompanyCloseDrawer: (state) => {
      state.createCompanyDrawerOpen = false;
    },
    createCompanyOpenDrawer: (state) => {
      state.createCompanyDrawerOpen = true;
    },
  },
});

export const {
  setCurrentCompany,
  editCompany,
  deleteCompany,
  openViewDrawer,
  closeViewDrawer,
  openEditDrawer,
  closeEditDrawer,
  createCompanyCloseDrawer,
  createCompanyOpenDrawer,
} = listCompaniesSlice.actions;

export default listCompaniesSlice.reducer;
