import { Auth0Client, createAuth0Client } from "@auth0/auth0-spa-js";
import config from "@/common/constants/config";
import { GetTokenSilentlyOptions } from "@auth0/auth0-react";

let auth0Client: Auth0Client | null = null;

export const getAuth0Client = async (): Promise<Auth0Client> => {
  if (!auth0Client) {
    auth0Client = await createAuth0Client({
      domain: config.auth0Domain,
      clientId: config.auth0ClientId,
    });
  }
  return auth0Client;
};

export const getAuth0AccessToken = async (): Promise<string> => {
  try {
    const auth0Client = await getAuth0Client();
    const options: GetTokenSilentlyOptions = {
      authorizationParams: {
        audience: config.auth0Audience,
      },
    };
    return auth0Client.getTokenSilently(options);
  } catch (error) {
    console.error(error);
    return "";
  }
};
