import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CompanyFilters from "./filters";
import CreateNewCompany from "./create/CreateCompanyDrawer";
import Button from "@/common/components/button/Button";
import EditCompanyDrawer from "./edit";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getCompanies, searchCompanies } from "@/redux/reducers/prospects/companies/slices/listCompanies";
import { usePagination } from "@/common/components/pagination/usePagination";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { CompaniesTableColumns } from "./table/Columns";
import TsTable from "@/common/components/ts-table";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import routes from "@/common/constants/routes";
import PageHeader from "@/common/components/page-header/PageHeader";
import { IdentificationIcon } from "@heroicons/react/24/outline";

type filterType = {
  searchString?: string;
};

export default function ProspectsCompanies() {
  const [filters, setFilters] = useState<filterType>({});
  const { companies, totalCount, loading } = useAppSelector(({ companies }) => companies.listCompanies);
  const { pendingCreateCompany } = useAppSelector(({ companies }) => companies.listCompanies);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { onPaginationChange, pagination } = usePagination();

  useEffect(() => {
    if (filters?.searchString) {
      dispatch(
        searchCompanies({
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
          searchTerm: filters.searchString,
          ...filters,
        }),
      );
    } else {
      dispatch(
        getCompanies({
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
        }),
      );
    }
  }, [dispatch, pagination.pageIndex, pagination.pageSize, filters.searchString, filters]);

  const handleNavigate = () => {
    navigate(routes.dataImport.import.path);
  };

  const table = useReactTable<any>({
    data: companies || [],
    columns: CompaniesTableColumns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: totalCount,
    manualPagination: true,
    state: {
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },
    onPaginationChange,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <>
        <FullLoadingOverlay loading={pendingCreateCompany} overlay={true} />

        <PageHeader
          title="Companies"
          actions={
            <Box sx={{ display: "flex", gap: "16px" }}>
              <Button variant="secondary" onClick={handleNavigate}>
                Import Company
              </Button>
              <CreateNewCompany />
            </Box>
          }
          filters={<CompanyFilters setFilters={setFilters} />}
        />

        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <EditCompanyDrawer />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Box
              sx={{
                borderRadius: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <TsTable
                table={table}
                handleCellClick={() => null}
                notFoundProps={{
                  title: "No companies found",
                  description: (
                    <span>
                      Begin adding or importing companies by clicking the <br /> <b>"Import Company"</b> and{" "}
                      <b>"Add Company"</b> buttons above.
                    </span>
                  ),
                  Icon: IdentificationIcon,
                }}
              />
            </Box>
          )}
        </Box>
      </>
    </Box>
  );
}
