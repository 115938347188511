import MuiListItemText from "./styles/ListItemText";
import AvatarStyles from "./styles/Avatar";
import { customColors } from "./palette/colors";
import { ExtendedPaletteOptions } from "./palette/colors.d";
import typography from "./typography/typography";
import { createTheme } from "@mui/material/styles";
import { ButtonStyles } from "./styles/Button";
import { ToolTipStyles } from "./styles/ToolTip";
import { TabStyles } from "./styles/Tab";
import { AccordionSummaryStyles } from "./styles/AccordionSummary";
import TextFieldStyles from "./styles/TextField";
import { ExtendedTypographyOptions } from "./typography/typography.d";

const theme = createTheme({
  components: {
    MuiButton: ButtonStyles,
    MuiAvatar: AvatarStyles,
    MuiListItemText,
    MuiTooltip: ToolTipStyles,
    MuiTab: TabStyles,
    MuiAccordionSummary: AccordionSummaryStyles,
    MuiTextField: TextFieldStyles,

    MuiDrawer: {
      styleOverrides: {
        root: {
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(55,70,103,0.6)",
          },
        },
      },
    },
  },

  palette: {
    primary: {
      main: customColors.mint.p,
    },
    mint: customColors.mint,
    midnight: customColors.midnight,
    bg: customColors.bg,
    border: customColors.border,
    txt: customColors.txt,
    icon: customColors.icon,
    btnPrimary: customColors.btnPrimary,
    btnSecondary: customColors.btnSecondary,
    link: customColors.link,
    decorative: customColors.decorative,
    input: customColors.input,
    commonColors: customColors.commonColors,
    tableRow: customColors.tableRow,
  } as ExtendedPaletteOptions,

  typography: {
    body2: typography.body2,
    body3: typography.body3,
    link: typography.link,
  } as ExtendedTypographyOptions,
});

export default theme;
