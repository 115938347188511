import Avatar from "@/common/components/avatar/Avatar";
import HeatScore from "@/common/components/heat-score/HeatScore";
import { Box, Stack, Typography, useTheme } from "@mui/material";

type LeadFlowCardProps = {
  title: string;
  heatScore: number;
  avatar?: string;
  lastActivityType?: string;
};

export default function LeadFlowCard({ title, heatScore, avatar, lastActivityType = "" }: LeadFlowCardProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.tableRow.pair,
        padding: "8px",
        borderRadius: "6px",
        border: "1px solid #E7EBED",
        fontWeight: 400,
        transition: "all 0.3s",
        "&:hover": {
          borderColor: theme.palette.midnight.p,
        },
        cursor: "pointer",
        width: "100%",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          color: theme.palette.txt.default,
        }}
      >
        <Typography
          sx={{
            lineHeight: "20px",
            letterSpacing: "0.2px",
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
        {avatar && <Avatar src={avatar} size="small" />}
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <HeatScore score={heatScore} hideLabel />{" "}
        <Typography sx={{ fontSize: "14px" }}>3 days ago - {lastActivityType} </Typography>
      </Stack>
    </Box>
  );
}
