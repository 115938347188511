import { Roles } from "@/common/constants/roles";
import { KnTeamUserRoleUserRoleEnum } from "@/services/generated";
import { useAuth0 } from "@auth0/auth0-react";
import { useMemo } from "react";

const ADMIN_ROLE = {
  roleName: "Account Administrator",
  roleCode: "ADMIN",
  roleDescription: "Account administrator: Can edit team settings",
};

const SUPERADMIN_ROLE = {
  roleName: "Super Administrator",
  roleCode: "SUPERADMIN",
  roleDescription: "Account super administrator: Can modify account administrators",
};

export const useRoles = () => {
  const { user, ...auth0 } = useAuth0();

  const userRoles = useMemo(() => {
    const roles = [...Roles];

    if ([KnTeamUserRoleUserRoleEnum.Superadmin, KnTeamUserRoleUserRoleEnum.Admin].includes(user?.team_user_role)) {
      roles.push(ADMIN_ROLE);

      if (user?.team_user_role === KnTeamUserRoleUserRoleEnum.Superadmin) {
        roles.push(SUPERADMIN_ROLE);
      }
    }

    return roles;
  }, [user?.team_user_role]);

  const canUpdateUsers = useMemo(() => {
    return [
      KnTeamUserRoleUserRoleEnum.Superadmin,
      KnTeamUserRoleUserRoleEnum.Admin,
      KnTeamUserRoleUserRoleEnum.SalesManager,
    ].includes(user?.team_user_role);
  }, [user?.team_user_role]);

  const isAdministrator = useMemo(() => {
    return [KnTeamUserRoleUserRoleEnum.Superadmin, KnTeamUserRoleUserRoleEnum.Admin].includes(user?.team_user_role);
  }, [user?.team_user_role]);

  return {
    userRoles,
    isAdministrator,
    canUpdateUsers,
    auth0: {
      user,
      ...auth0,
    },
  };
};
