import { Box } from "@mui/material";

export const ElipseIcon = ({ color }: { color: string }) => (
  <Box
    sx={{
      height: 24,
      width: 24,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Box
      sx={{
        width: "12px",
        height: "12px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "6px",
        border: `1px solid ${color}`,
      }}
    >
      <Box
        sx={{
          width: "8px",
          height: "8px",
          backgroundColor: color,
          borderRadius: "50%",
          flexGrow: 0,
        }}
      />
    </Box>
  </Box>
);
