import { Box } from "@mui/material";
import Button from "@/common/components/button";
import { PaperAirplaneOutline } from "heroicons-react";
import FormControl from "@mui/material/FormControl";
import AttachmentInput from "@/common/components/attachment/Attachment";
import { Controller, useFormContext } from "react-hook-form";
import FormLabel from "../form-label/FormLabel";
import { SparklesIcon } from "@heroicons/react/24/outline";

interface Props {
  sendMessageText?: string;
  showAttachments?: boolean;
  onChange: (value: string) => void;
}
const mockValue = `
  <div><p>Hey,
  <span style="background-color: #E6FDEE; border-radius: 4; border: '1px #374667 dotted';">
  [prospect_first_name]
  </span>

  </p><p><br></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In rhoncus quam  a felis malesuada vestibulum. Donec in pharetra ante, a ullamcorper  lorem. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed  tempus fringilla orci sit amet pellentesque. Mauris sit amet sagittis  tellus.</p><p><br></p><p>Sincerely,</p><p><br></p><p><span style="background-color: #E6FDEE;">[sales_rep_name]</span></p></div>
`;

const ReplyBox = ({ sendMessageText = "Approve and Send", showAttachments, onChange }: Props) => {
  const handleTextChange = (value: string) => {
    onChange(value);
  };

  const methods = useFormContext();

  const handleGenerate = () => {
    handleTextChange(mockValue);
  };

  return (
    <>
      <Box>
        {showAttachments && (
          <FormControl>
            <Controller
              name="attachments"
              control={methods.control}
              render={({ field }) => (
                <>
                  <FormLabel label="Attachments" />
                  <AttachmentInput {...field} />
                </>
              )}
            />
          </FormControl>
        )}

        <Box sx={{ display: "flex", gap: "8px" }}>
          <Button fullWidth size="sm" variant="secondary" onClick={handleGenerate} rightIcon={<SparklesIcon />}>
            Generate Message
          </Button>

          <Button
            size="sm"
            fullWidth
            rightIcon={
              <PaperAirplaneOutline
                style={{
                  transform: "rotate(90deg)",
                }}
              />
            }
            variant="primary"
          >
            {sendMessageText}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ReplyBox;
