import Drawer from "@mui/material/Drawer";
import { closeAssigneeDrawer } from "@/redux/reducers/tasks/slices/listTask";
import AssigneeFormTask from "@/pages/tasks/assignee/AssigneeTaskForm";
import { useAppDispatch, useAppSelector } from "@/redux/store";

export default function EditAssigneeDrawer() {
  const dispatch = useAppDispatch();
  const assigneeDrawerIsOpen = useAppSelector(({ tasks }) => tasks.listTask.assigneeDrawerIsOpen);

  return (
    <div data-testid="editAssigneeDrawer">
      <Drawer open={assigneeDrawerIsOpen} onClose={() => dispatch(closeAssigneeDrawer())} anchor="right">
        <AssigneeFormTask />
      </Drawer>
    </div>
  );
}
