export const formatTime12HourFormat = (date: any) => {
  const newDate = new Date(date);
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";

  const hours12 = hours % 12 || 12;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  const formattedTime = `${hours12}:${formattedMinutes} ${amOrPm}`;

  return formattedTime;
};

export const isToday = (date: Date, now: Date) => {
  return date.toDateString() === now.toDateString();
};

export const isYesterday = (date: Date, now: Date) => {
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);
  return date.toDateString() === yesterday.toDateString();
};

export const isThisWeek = (date: any, now: any) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const daysAgo = Math.floor((now - date) / oneDay);
  return daysAgo <= now.getDay();
};

export const isLastWeek = (date: any, now: any) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const daysAgo = Math.floor((now - date) / oneDay);
  return daysAgo <= now.getDay() + 7;
};

export const isThisMonth = (date: Date, now: Date) => {
  return date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear();
};

export const isLastMonth = (date: Date, now: Date) => {
  return date.getMonth() === now.getMonth() - 1 && date.getFullYear() === now.getFullYear();
};

export const isThisYear = (date: Date, now: Date) => {
  return date.getFullYear() === now.getFullYear();
};

export const isLastYear = (date: Date, now: Date) => {
  return date.getFullYear() === now.getFullYear() - 1;
};
