import { Box, Typography } from "@mui/material";

export const TitleAndSubtitle = ({
  title,
  subtitle,
  alternativeColor = null,
  customWidth = null,
}: {
  title?: string;
  subtitle?: string | JSX.Element | number;
  alternativeColor?: string | null;
  customWidth?: string | null;
}) => {
  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "8px",
        width: customWidth || "100%",
      }}
    >
      <Typography variant="body2" fontSize="12px" fontWeight="600" textTransform="uppercase">
        {title || "N/A"}
      </Typography>
      {alternativeColor ? (
        <Typography variant="body2" color={alternativeColor}>
          {subtitle || "N/A"}
        </Typography>
      ) : (
        <Typography variant="body2">{subtitle || "N/A"}</Typography>
      )}
    </Box>
  );
};
