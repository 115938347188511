import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";
import { toast } from "react-toastify";
import { AxiosPromise, AxiosResponse } from "axios";
import { ConsentDTO } from "@/services/generated";

export const deauthorizeUserGmail = createAsyncThunk(
  "oauth/deauthorizeUserGmail",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Consent.deauthorizeUserGmail();
      toast.success("Google Calendar deauthorized successfully");
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const deauthorizeUserOutlook = createAsyncThunk(
  "oauth/deauthorizeUserOutlook",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Consent.deauthorizeUserOutlook();
      toast.success("Google Calendar deauthorized successfully");
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const deauthorizeEmailConsent = createAsyncThunk(
  "oauth/deauthorizeEmailConsent",
  async (id: string, { rejectWithValue, dispatch }): Promise<AxiosResponse<ConsentDTO>> => {
    try {
      const response = await Services.Consent.deleteConsent(id);
      toast.success("Google Calendar deauthorized successfully");
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return error.message;
    }
  },
);

export const deauthorizeCRMUrl = createAsyncThunk(
  "oauth/deauthorizeCRMUrl",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Crm.hubspotDeauthorize();
      toast.success("CRM Hubspot deauthorized successfully");
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const deauthorizeGoogleCalendarUrl = createAsyncThunk(
  "oauth/deauthorizeGoogleCalendarUrl",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Calendar.googleDeauthorize();
      toast.success("Google Calendar deauthorized successfully");
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const deauthorizeGraphUrl = createAsyncThunk(
  "oauth/deauthorizeGraphUrl",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Calendar.graphDeauthorize();
      toast.success("Outlook Calendar deauthorized successfully");
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
