import Drawer from "@mui/material/Drawer";
import EditFormTask from "@/pages/tasks/edit/EditTaskForm";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { closeEditTaskDrawer } from "@/redux/reducers/tasks/slices/listTask";

export default function EditTaskDrawer() {
  const dispatch = useAppDispatch();
  const editDrawerIsOpen = useAppSelector(({ tasks }) => tasks.listTask.editDrawerIsOpen);

  return (
    <div data-testid="editTaskDrawer">
      <Drawer open={editDrawerIsOpen} onClose={() => dispatch(closeEditTaskDrawer())} anchor="right">
        <EditFormTask />
      </Drawer>
    </div>
  );
}
