import Avatar from "../avatar/Avatar";
import { ReactComponent as EnvelopeIcon } from "../../assets/svg/envelope.svg";
import { Prospect } from "@/redux/reducers/prospects/slices/types";
import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Phone } from "heroicons-react";
import React from "react";
import { TitleAndSubtitle } from "@/common/components/title-and-subtitle";
import { dateFormatMs } from "@/common/functions/dateFormat";
import { getConsentFromSources } from "@/common/functions/consent";
import ConsentStatusChip from "@/common/components/status-chip/ConsentStatusChip";

export const ProspectInfoBasic = ({ prospect }: { prospect: Prospect }) => {
  const theme = useTheme();

  const { firstName, lastName, email, phoneNumber, companyName, jobTitle, createdAt, consentSources } = prospect;

  const consent = getConsentFromSources(consentSources);

  const dateCreated = createdAt ? dateFormatMs(createdAt / 1000) : null;

  return (
    <Box
      style={{
        alignSelf: "stretch",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "16px",
        display: "flex",
        paddingLeft: "4px",
      }}
    >
      <Box style={{ justifyContent: "flex-start", alignItems: "center", gap: "8px", display: "flex" }}>
        <Avatar label={`${firstName} ${lastName}`} size="small" />
        <Typography variant="body2">
          {firstName} {lastName}
        </Typography>
      </Box>
      {email && (
        <Box style={{ justifyContent: "flex-start", alignItems: "center", gap: "8px", display: "flex" }}>
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "20px",
              backgroundColor: theme.palette.border.default,
              padding: "4px",
              borderRadius: "4px;",
            })}
          >
            <EnvelopeIcon
              style={{
                height: "16px",
                width: "16px",
                color: theme.palette.midnight.p,
              }}
            />
          </Box>
          <Typography variant="body2">{email}</Typography>
        </Box>
      )}
      {phoneNumber && (
        <Box style={{ justifyContent: "flex-start", alignItems: "center", gap: "8px", display: "flex" }}>
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "20px",
              backgroundColor: theme.palette.border.default,
              padding: "4px",
              borderRadius: "4px;",
            })}
          >
            <Phone
              style={{
                height: "16px",
                width: "16px",
                color: theme.palette.midnight.p,
              }}
            />
          </Box>
          <Typography variant="body2">{phoneNumber}</Typography>
        </Box>
      )}
      {companyName && <TitleAndSubtitle title="company" subtitle={companyName || ""} />}
      {jobTitle && <TitleAndSubtitle title="Job title" subtitle={jobTitle || ""} />}

      <Box
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        {dateCreated && <TitleAndSubtitle title="Date Created" subtitle={dateCreated} />}
        <TitleAndSubtitle title="Consent" subtitle={<ConsentStatusChip consent={consent} />} />
      </Box>
      {/* TODO: Fix. */}
      <TitleAndSubtitle title="Timezone" subtitle="(UTC-08:00) Pacific Time (US & Canada)" />
    </Box>
  );
};
