import LinearProgress, { linearProgressClasses, LinearProgressProps } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

const LightBorderedProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: "8px",
  borderRadius: "50px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.midnight[20],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mint.p,
  },
}));

const DarkBorderedProgressBar = styled(LinearProgress)(({ theme }) => {
  return {
    height: "8px",
    borderRadius: "50px",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.background.paper,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.midnight.p,
    },
  };
});

type Props = typeof DarkBorderedProgressBar &
  typeof LightBorderedProgressBar &
  LinearProgressProps & {
    dark?: boolean;
  };

const LinearProgressBar = ({ dark = false, ...props }: Partial<Props>) => {
  return dark ? (
    <DarkBorderedProgressBar variant="determinate" {...props} />
  ) : (
    <LightBorderedProgressBar variant="determinate" {...props} />
  );
};

export default LinearProgressBar;
