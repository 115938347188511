import { Box, useTheme } from "@mui/material";
import Dropdown from "@/common/components/dropdown/Dropdown";
import { DealFiltersInput } from "@/redux/reducers/dealFlow/slices/types";
import { useMemo } from "react";
import { useAppSelector } from "@/redux/store";
import Avatar from "@/common/components/avatar/Avatar";
import { ListBulletIcon } from "@heroicons/react/16/solid";
import { ChartBarSquareIcon, Square3Stack3DIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";

type DealFiltersProps = {
  onSetFilters: (filters: DealFiltersInput) => void;
  filters: DealFiltersInput;
};

export default function Filters({ filters, onSetFilters }: DealFiltersProps) {
  const theme = useTheme();
  const pipelines = useAppSelector(({ dealFlow }) => dealFlow.listPipelines.pipelines);
  const teamUsers = useAppSelector(({ team }) => team.listUsers.users);
  const { id: currentPipelineId } = useParams<{ id?: string }>();
  const currentPipeline = useMemo(() => {
    if (currentPipelineId) {
      return;
    }
    return pipelines?.find(({ id }) => id === currentPipelineId);
  }, [pipelines, currentPipelineId]);

  const stageOptions = (currentPipeline?.stages || []).map((stage) => ({ value: stage.name, label: stage.name }));

  const assigneesOptions = (teamUsers || []).map((assignee) => ({
    icon: () => <Avatar label={`${assignee.firstName!} ${assignee.lastName!}`} src={assignee.imgUrl} size="small" />,
    value: assignee.id!,
    label: `${assignee.firstName!} ${assignee.lastName!}`,
  }));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mb: 2,
      }}
    >
      <Box display="flex" gap={1}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            onClick={() => onSetFilters({ viewType: "kanban", selectedStage: undefined })}
            sx={{
              transition: "all .3s",
              cursor: "pointer",
              p: "12px 14px",
              overflow: "hidden",
              borderRadius: "8px",
              border:
                filters.viewType === "kanban"
                  ? `2px solid ${theme.palette.btnSecondary.default}`
                  : `2px solid ${theme.palette.border.default}`,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              bgcolor: theme.palette.common.white,
              lineHeight: 0,
            }}
          >
            <ChartBarSquareIcon width={16} />
          </Box>
          <Box
            onClick={() => onSetFilters({ viewType: "list" })}
            sx={{
              bgcolor: theme.palette.common.white,
              transition: "all .3s",
              cursor: "pointer",
              p: "12px 14px",
              overflow: "hidden",
              borderRadius: "8px",
              border:
                filters.viewType === "list"
                  ? `2px solid ${theme.palette.btnSecondary.default}`
                  : `2px solid ${theme.palette.border.default}`,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              lineHeight: 0,
            }}
          >
            <ListBulletIcon width={16} />
          </Box>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Dropdown
            label="Assignee"
            placeholder="Assignee"
            placeholderIcon={<UserCircleIcon width={15} />}
            options={assigneesOptions}
            onChange={(option) => onSetFilters({ assigneeId: option.value })}
            value={filters.assigneeId}
          />
        </Box>
        {filters.viewType === "list" && (
          <Box sx={{ width: "100%" }}>
            <Dropdown
              label="Stage"
              placeholder="Stage"
              placeholderIcon={<Square3Stack3DIcon width={15} />}
              options={stageOptions}
              value={filters.selectedStage?.[0]}
              onChange={(option) => onSetFilters({ selectedStage: option.value })}
            />
          </Box>
        )}
      </Box>
      <Dropdown
        label="Date Time Added to Stage"
        placeholder="Date Time Added to Stage"
        options={[
          { label: "This Week", value: "this_week" },
          { label: "This Month", value: "this_month" },
          { label: "Last Month", value: "last_month" },
          { label: "Last 90 Days", value: "last_90_days" },
        ]}
        onChange={console.log}
      />
    </Box>
  );
}
