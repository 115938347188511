import Drawer from "@mui/material/Drawer";
import DrawerLayout from "@/layouts/DrawerLayout";
import { Box, Button, TextField, Typography, Avatar } from "@mui/material";
import { useState } from "react";
import { KnAiAgent } from "@/services/generated";
import { useAppDispatch } from "@/redux/store";
import { sendCallTest } from "@/redux/reducers/ai-voice/listAgents";

interface TestCallProps {
  agent: KnAiAgent;
  open?: boolean;
  onClose?: () => void;
}

export default function TestCall({ agent, open = false, onClose }: TestCallProps) {
  const dispatch = useAppDispatch();
  const [phoneNumber, setPhoneNumber] = useState("+1");

  const handleClose = () => {
    onClose?.();
  };

  const handleTestCall = () => {
    if (!agent.agent_id) {
      return;
    }
    dispatch(
      sendCallTest({
        ai_agent_id: agent.agent_id,
        phone_number: phoneNumber,
      }),
    );
  };

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <DrawerLayout title="Test Call" closeDrawer={handleClose}>
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column", p: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Avatar sx={{ mr: 2 }}>EP</Avatar>
            <Box>
              <Typography variant="subtitle1">{agent.voice_name}</Typography>
              <Typography variant="body2" color="text.secondary">
                {agent.phone_number}
              </Typography>
            </Box>
          </Box>

          <Typography variant="body2" sx={{ mb: 2, bgcolor: "grey.100", p: 1, borderRadius: 1 }}>
            Used as an example for our prospects and trained on how to take outbound calls for SalesAi.
          </Typography>

          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            To Phone Number
          </Typography>
          <TextField
            fullWidth
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="+1"
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <Box sx={{ mt: "auto", display: "flex", gap: 2 }}>
            <Button fullWidth variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button fullWidth variant="primary" onClick={handleTestCall}>
              Test Call
            </Button>
          </Box>
        </Box>
      </DrawerLayout>
    </Drawer>
  );
}
